import React, { useEffect, useState } from 'react';
import TravelHowFar from './TravelHowFar';
import { Paragraph } from './Region';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import GetData from '../../../../api/GetData';
import Radiobuttons from '../Step2Subcomponents.js/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFive } from '../../../../store/stepsSlice';

export default function Traveling(props) {
  const dispatch = useDispatch();
  const wouldTravel = useSelector((state) => state.steps.stepFive.wouldTravel);
  const travelRegionId = useSelector(
    (state) => state.steps.stepFive.travelRegionId
  );

  const [regions, setRegions] = useState(null);

  const handleChange = (value) => {
    dispatch(updateStepFive({ wouldTravel: value }));
  };

  useEffect(() => {
    async function getRegions() {
      let response = await GetData('Region');
      setRegions(response);
    }
    getRegions();
  }, []);

  return (
    <>
      <ComponentWrapper displayFlex alignCenter justifyBetween noPaddingHorizontal fluid>
          <Paragraph autoWidth>
            {props.createProfile
              ? 'Bist du bereit zu reisen?'
              : 'Muss der Bewerber eine Reisebereitschaft mitbringen?'}
          </Paragraph>

          <Radiobuttons
            setTrue={() => handleChange(true)}
            setFalse={() => handleChange(false)}
            name="traveling"
            title="traveling"
            naPromjenu={() => {}}
            value={wouldTravel ? '1' : '0'}
          />

      </ComponentWrapper>

      {wouldTravel && (
        <TravelHowFar
          createProfile={props.createProfile}
          data={regions}
          value={travelRegionId}
          setRegion={(e) =>
            dispatch(updateStepFive({ travelRegionId: e.target.value }))
          }
        />
      )}
    </>
  );
}
