import axios from 'axios';
import config from './config/api.config.js';
import LocalStorageService from './LocalStorageService';
import apiConfig from './config/api.config.js';
import auth from './auth.js';
import { Redirect } from 'react-router';

let calledOnce = false;

const refreshAccessToken = async (accessToken, refreshToken) => {
  const res = await axios
    .post(`${config.apiUrl}/authentication/refresh-token`, {
      token: accessToken,
      refreshToken: refreshToken
    })
    .catch((error) => {
       auth.logout(() =>  <Redirect to="/login" />);
    });
  return res.data;
};

axios.interceptors.request.use(
  async (config) => {
    if (config.url !== `${apiConfig.apiUrl}/Authentication/Login`) {
      let token = LocalStorageService.get('access_token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !calledOnce
    ) {
      originalRequest._retry = true;
      calledOnce = true;

      const token = LocalStorageService.get('access_token');
      const refreshToken = LocalStorageService.get('refreshToken');

      if (token && refreshToken) {
        try {
          const newTokens = await refreshAccessToken(token, refreshToken);
          if (newTokens && newTokens.Token && newTokens.RefreshToken) {
            LocalStorageService.set({
              access_token: newTokens.Token
            });
            LocalStorageService.set({
              refreshToken: newTokens.RefreshToken
            });
        
            originalRequest.headers['Authorization'] =
              'Bearer ' + newTokens.Token;
  
            return axios.request(originalRequest);
          } else {
            return Promise.reject(error);
          }
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
    }
    if (error.response.status === 401) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
