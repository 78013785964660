import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFour } from '../../../../store/stepsSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApplicantWaterLicences(props) {
  const dispatch = useDispatch();
  const selectedSpecial = useSelector(
    (state) => state.steps.stepFour.specialLicences
  );
  const [search, setSearch] = useState('');

  const [specialLicence, setSpecialLicence] = useState([]);

  const handleSpecial = (_, values) => {
    dispatch(updateStepFour({ specialLicences: values }));
  };

  useEffect(() => {
    if (props.values)
      setSpecialLicence(
        props.values.filter((item) => item.LicenseTypeId === 4 || item.LicenseTypeId===3 || item.LicenseTypeId === 2)
      );
  }, [props.values]);

  return (
      <Autocomplete
        className='create-profile-select'
        filterOptions={(x) =>
          x.filter((l) => l.Name.toLowerCase().startsWith(search))
        }
        multiple
        noOptionsText="Keine Option"
        id="special"
        options={specialLicence}
        disableCloseOnSelect
        value={selectedSpecial}
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) => {
          return option.LicenseId === value.LicenseId;
        }}
        onChange={handleSpecial}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{fontSize:"14px"}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
            {option.Category && ` (${option.Category})`}
          </li>
        )}
        style={{ width: '100%', margin: '10px 0 20px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Sonstige"
          />
        )}
      />
  );
}
