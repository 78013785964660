import React from 'react';
import styled from 'styled-components';
import StepsDone from '../assets/images/stepsDone.png';
import { Paragraph } from '../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import { useHistory } from 'react-router-dom';
import { DeviceSize } from '../components/responsive';
import { useMediaQuery } from 'react-responsive';
import SubmitButton from '../components/Buttons/submitButton';
import { Stepper, Step, StepLabel } from '@mui/material';
import { stepsLabel } from './EditProfile';
import {
  useStyles,
  Container,
  LeftSide
} from '../components/CreateProfile/SideBar/Koraci2';
import Logo from '../assets/images/newLogo.png';

const Heading = styled.h2`
  font-size: 27px;
  font-style: normal;
  font-weight: bold;
  line-height: 45px;
  letter-spacing: 0em;
  color: #db002b;
  font-family: Poppins;
  margin-bottom: -20px;
  margin-top: 10px;
`;

export default function Done(props) {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const classes = useStyles();

  return (
    <div className="create-profile">
      <div className="create-profile-container">
        <img src={Logo} className="logo" />

        <Container style={{ alignItems: 'center' }}>
          <LeftSide>
            <Stepper
              className="stepper"
              orientation="horizontal"
              activeStep={5}
              connector={<></>}
            >
              {stepsLabel.map((label, index) => (
                <Step
                  key={label}
                  classes={{
                    root: index <= 5 ? classes.stepContainer : ''
                  }}
                >
                  <StepLabel
                    classes={{
                      iconContainer: classes.icon,
                      labelContainer: classes.labelContainer,
                      disabled: classes.disabledLabel
                    }}
                  >
                    {isMobile ? null : label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </LeftSide>

          <Heading> GESCHAFFT ! </Heading>
          <img className="doneImg" src={StepsDone} alt="steps_done" />
          <Paragraph
            className="doneParagraph"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {props.location.createProfile
              ? 'Dein Bewerber-Profil ist vollständig ausgefüllt. Du bekommst ab sofort die passenden Stellenangebote in dein Dashboard gepusht. Unter "Matches" kannst du deine passenden Stellenanzeigen ansehen und dich direkt bewerben, ohne Anschreiben. Egal wann - egal wo!'
              : 'Ihre Stellenanzeige ist online. Sie können weitere Stellenanzeigen schalten.\n Bewerbungen bekommen Sie direkt in Ihr spiderjob Dashboard.'}
          </Paragraph>

          <SubmitButton
            style={{ marginTop: '50px' }}
            variant="contained"
            color="primary"
            className="create-profile-next"
            onClick={() =>
              history.push(
                props.location.createProfile
                  ? '/bewerber-dashboard'
                  : '/arbeitgeber-dashboard'
              )
            }
          >
            ZUM DASHBOARD
          </SubmitButton>
        </Container>
      </div>
    </div>
  );
}
