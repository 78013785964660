import React from 'react';
import styled from 'styled-components';
import ResetPassSucc from '../../assets/images/passchangedsucc.png';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    width: 100%;
    max-width: 300px;
  }
`;

const Heading = styled.h1`
  color: rgba(232, 55, 65, 1);
  font-size: 26px;
`;

export default function PasswordChangeSucc() {
  const history = useHistory();

  return (
    <Container className="main-container">
      <Heading className="mb-0 text-center">
      Ihr Passwort wurde erfolgreich geändert
      </Heading>
      <div className="my-5">
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/login')}
        >
          Zurück zur Anmeldung
        </Button>
      </div>
      <img src={ResetPassSucc} alt="Reset password"></img>
    </Container>
  );
}
