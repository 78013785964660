import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from '../CreateProfile/Steps';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { Fade, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OptionButton from '../Buttons/OptionButton';
import editWhite from '../../assets//images/edit-white.svg';
import CircularFiller from '../../Pages/UserDashboard/CircularFiller';
import EmptyList from '../../assets/images/empty-list.svg';
import InfoButton from '../../assets/images/info_button.svg';
import { Delete, Edit } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import DeletePrompt from '../../Pages/CompanyDashboard/DeletePrompt';
import './TableWrapper.scss';
import auth from '../../auth';
const Container = styled.div`
  padding: 50px;
  width: 100%;
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  ${(props) =>
    props.isSmall &&
    `
  padding: 30px;
  `}
  ${(props) =>
    props.isExtraSmall &&
    `
  padding: 25px 15px;
  `}
`;
const TableTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: rgb(58, 58, 58);
  margin: 10px 5px;
  ${(props) =>
    props.red &&
    `
  color: #E83741;
  `}
`;
const Status = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgb(58, 224, 105);
  display: inline-block;
  margin: 0 10px 0 0;
  ${(props) =>
    props.inactive &&
    `
  background-color: #E83741;
  `}
`;

const TableWrapper = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let infoText =
    'Die Prozentzahl bei "Match" gibt an, wie viele Übereinstimmungen Du mit der dazugehörigen Stellenausschreibung hast. Je höher die Prozentzahl ist, umso größere Gemeinsamkeiten hat die Stelle mit Deinen Fähigkeiten und deinem Lebenslauf. Vervollständige Dein Profil, damit die vorgeschlagenen Jobs besser zu Dir passen!';
  const [deleteModal, setDeleteModal] = useState(false);

  const generateColumns = (data) => {
    var columns = [];
    data.forEach((element) => {
      let newItem = {
        field: element,
        flex: 1,
        minWidth: 150
      };
      //if any new columns require specific props, add them below

      if (element.toLowerCase() === 'status') {
        newItem.width = 100;
        newItem.headerAlign = 'center';
        newItem.align = 'center';
        newItem.filterable = false;
        newItem.hideSortIcons = true;
        newItem.renderCell = (params) => (
          <>
            {params.value.toLowerCase() === 'aktiv' ? (
              <>
                <Status />
                {params.value}
              </>
            ) : (
              <>
                <Status inactive />
                {params.value}
              </>
            )}
          </>
        );
      }
      if (element.toLowerCase() === 'handlung') {
        newItem.minWidth = 220;
        newItem.headerAlign = 'center';
        newItem.align = 'center';
        newItem.sortable = false;
        newItem.filterable = false;
        newItem.renderCell = (params) =>
          params.value !== undefined ? (
            <>
              <OptionButton
                withHeight
                outlined={params.value.outlined}
                text={params.value.text}
                icon={params.value.icon}
                blue={params.value.blue}
                icon2={editWhite}
                onClick={(e) => {
                  e.stopPropagation();
                  props.openOption && props.openOption(params);
                }}
                className={'ansehen-button'}
              />
              {props.deleteOption && (
                <IconButton
                  className="specific"
                  style={
                    isMobile
                      ? { top: '0px !important', padding: 8 }
                      : { padding: 8 }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    props.deleteOption && props.deleteOption(params);
                  }}
                  size="large"
                >
                  <Delete className={classes.icon} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              <IconButton
                style={{ top: '0px' }} //ovdje
                onClick={(e) => {
                  e.stopPropagation();
                  props.openOption && props.openOption(params);
                }}
                size="large"
              >
                <Edit className={classes.icon} />
              </IconButton>

              <IconButton
                style={{ padding: 8, top: '0px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.deleteOption && props.deleteOption(params);
                  //setDeleteModal(true);
                }}
                size="large"
              >
                <Delete className={classes.icon} />
              </IconButton>

              <DeletePrompt
                open={deleteModal}
                onClose={() => {
                  setDeleteModal(false);
                }}
                onDelete={() => {
                  console.log('delete');
                  props.deleteOption && props.deleteOption(params);
                  setDeleteModal(false);
                }}
              />
            </>
          );
      }

      if (element.toLowerCase() === 'passend') {
        newItem.minWidth = 150;
        newItem.headerAlign = 'center';
        newItem.align = 'center';
        newItem.headerName = 'Match';
        newItem.sortable = false;
        newItem.filterable = false;

        newItem.renderHeader = (params) => (
          <div className="css-1jbbcbn-MuiDataGrid-columnHeaderTitle">
            MATCH
            {auth.isApplicant() && (
              <Tooltip
                title={infoText}
                placement="top"
                enterTouchDelay="1400ms"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <IconButton
                  style={{
                    padding: 8,
                    top: '0px',
                    height: '22px',
                    width: '22px',
                    marginBottom: '2px'
                  }}
                  onClick={(e) => {}}
                  size="large"
                >
                  <img src={InfoButton} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
        newItem.renderCell = (params) => (
          <>
            {params.value === 'quick-apply' ? (
              <p className="quick-apply__badge">Express</p>
            ) : params.value == 'No matching' ? (
              <p style={{ margin: '0', color: '#e83741' }}>Kein Match</p>
            ) : (
              <CircularFiller value={params.value || 0} />
            )}
          </>
        );
      }

      if (element.toLowerCase() === 'company') {
        newItem.minWidth = 250;
        newItem.headerAlign = 'left';
        newItem.headerName = 'Name';
        newItem.align = 'left';
        newItem.sortable = false;
        newItem.filterable = false;
        newItem.renderCell = (params) => (
          <div style={{ height: '90%', display: 'flex', width: '300px' }}>
            <div
              style={{
                width: '55px',
                height: 'auto',
                boxShadow: '0 4px 10px rgba(197, 197, 197, 0.25)',
                borderRadius: '10px',
                padding: '2px',
                display: 'flex',
                marginRight: '10px'
              }}
            >
              <img
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                src={params.value.Profile}
              />
            </div>
            <div style={{ width: 'calc(100% - 65px)' }}>
              <p
                style={{
                  margin: '0',
                  color: '#1D3A5C',
                  lineHeight: '30px',
                  width: '230px'
                }}
              >
                {params.value.Name}
              </p>
              <p
                style={{
                  margin: '0',
                  color: '#767676',
                  fontSize: '13px',
                  lineHeight: '10px'
                }}
              >
                {params.value.Email}
              </p>
            </div>
          </div>
        );
      }

      if (
        element.toLowerCase() === 'name' ||
        element.toLowerCase() === 'adresse' ||
        element.toLowerCase() === 'land'
      ) {
        newItem.minWidth = 250;
      }

      if (element.toLowerCase() === 'typ') {
        newItem.minWidth = 200;
      }
      columns.push(newItem);
    });
    return columns;
  };

  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <Container isSmall={isSmall} isExtraSmall={isExtraSmall}>
      {props.tableTitle && (
        <Row flexWrap>
          <TableTitle red={props.red}>{props.tableTitle}</TableTitle>
          {props.tableFunction}
        </Row>
      )}

      <div style={{ display: 'flex' }}>
        <div style={{ minHeight: props.minHeight || 320, width: '100%' }}>
          <DataGrid
            columns={generateColumns(props.columns)}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.rows}
            hideFooterSelectedRowCount
            className={classes.dataGrid}
            hideFooter
            disableSelectionOnClick
            onCellClick={(cell) => {
              props.onRowClick && props.onRowClick(cell);
            }}
            components={{
              NoRowsOverlay: NoRows
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const NoRows = () => {
  return (
    <div style={{ textAlign: 'center', height: 0, marginTop: '6.5em' }}>
      <img src={EmptyList} alt="Empty" height="150"></img>
      <h5 style={{ margin: 20 }}>Sie haben noch keine Daten</h5>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    'border': 'none',
    'letter-spacing': '0.01071em',
    '& .MuiDataGrid-footer': {
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none'
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#fafafa',
      borderRadius: 5,
      color: '#707070',
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
      fontSize: 12,
      border: 'none'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },

    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: '#fafafa'
    },

    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#c9333c',
      borderRadius: '8px'
    }
  },
  icon: {
    color: '#e83741'
  }
}));

TableWrapper.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  openOption: PropTypes.func
};

export default TableWrapper;
