import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import { Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import SearchBar from '../../components/Beitrage/SearchBar';
import { BlogModal, ModalOuter } from '../../components/modal';
import GetData from '../../api/GetData';
import BlogCard from '../../components/Beitrage/BlogCard';
import { EmptyBox } from './Applications';
import EmptyList from '../../assets/images/empty-list.svg';
import auth from '../../auth';
import SubmitButton from '../../components/Buttons/submitButton';
import { Pagination } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import DeleteData from '../../api/DeleteData';
import DeletePrompt from './DeletePrompt';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const CardsContainer = styled.div`
  margin: 10px auto;
  padding: 20px;
  display: grid;
  gap: 20px;
  justify-content: center;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  width: 100%;
  @media (max-width: 798px) {
    grid-template-columns: none;
    justify-items: center;
  }
`;

const Blogs = () => {
  const location = useLocation();
  const [modalOpen, setmodalOpen] = useState(false);
  const [blogs, setblogs] = useState(null);
  const [loading, setisLoading] = useState(false);
  const [loadingBlogs, setloadingBlogs] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchText, setsearchText] = useState('');
  const [editData, seteditData] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [order, setorder] = useState('descdate');
  const Userid = auth.getId();

  //number of cards on the page
  const largerThan1820 = useMediaQuery({ minWidth: 1820 });
  const [pageSize, setpageSize] = useState(largerThan1820 ? 8 : 6);
  useEffect(() => {
    if (largerThan1820) {
      setpageSize(8);
    } else {
      setpageSize(6);
    }
  }, [largerThan1820]);

  useEffect(() => {
    if (location.state && location.state.blog) {
      seteditData(location.state.blog);
      if (location.state.delete) {
        setdeleteModal(true);
      } else {
        setmodalOpen(true);
      }
    }
  }, [location]);

  //remember the initial blog from server
  const blogsRef = useRef(null);

  const getCompanyBlogs = async () => {
    setloadingBlogs(true);
    const response = await GetData(`Blogs/Paging?&PageSize=9&IncludePicture=true`, {
      PageSize: pageSize,
      PageNumber: currentPage,
      Userid,
      Title: searchText,
      OrderBy: order
    });

    //blogsRef.current = response;
    setcurrentPage(response.CurrentPage);
    setblogs(response);
    setloadingBlogs(false);
  };

  useEffect(() => {
    getCompanyBlogs();
  }, [currentPage, searchText, order, pageSize]);

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  const sortBlogs = (e) => {
    switch (e) {
      case 'a-z':
        setorder('asc');
        break;
      case 'z-a':
        setorder('desc');
        break;
      case 'oldest':
        setorder('ascdate');
        break;
      default:
        setorder('descdate');
        break;
    }
  };

  const onDeleteBlog = async () => {
    await DeleteData('blogs', editData.BlogsId);
    seteditData(null);
    setdeleteModal(false);
    getCompanyBlogs();
  };

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <DashboardLayout noPadding sidebar>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title={'Inhalt'}
          list={[
            { Name: 'Arbeitsplätze', Link: 'jobs' },
            { Name: 'Bewerber', Link: 'bewerber' },
            { Name: 'Blogeinträge', Link: 'blogeinträge' }
          ]}
          storage={'blogeinträge'}
        />

        <ComponentWrapper
          fluid
          padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
        >
          <Row flexWrap>
            <DashboardTitle red> Blogeinträge</DashboardTitle>

            <SubmitButton small onClick={() => setmodalOpen(true)}>
              Neue hinzufügen
            </SubmitButton>
          </Row>
          <ComponentWrapper noPaddingHorizontal className='blogs' style={{padding:'50px'}} displayFlex justifyCenter>
            <SearchBar
              searchText={searchText}
              onChange={(e) => {
                setisLoading(true);
                setTimeout(() => {
                  setsearchText(e);
                  setisLoading(false);
                }, 300);
              }}
              loading={loading}
              onSortChange={sortBlogs}
            />
          </ComponentWrapper>
          {loadingBlogs ? (
            <ComponentWrapper>
              <CircularLoader small />
            </ComponentWrapper>
          ) : (
            <>
              {blogs && blogs.list.length > 0 ? (
                <>
                  <CardsContainer>
                    {blogs.list.map((blog) => (
                      <BlogCard
                        key={blog.BlogsId}
                        value={{
                          text: blog.BlogText,
                          title: blog.Title,
                          src: blog.File.Url,
                          id: blog.BlogsId,
                          userId: blog.UserId,
                          file: blog.File,
                          closeModal: () => {
                            getCompanyBlogs();
                          }
                        }}
                        blog={blog}
                        oneThirdWidth
                        dashboard
                        onDelete={() => {
                          setdeleteModal(true);
                          seteditData(blog);
                        }}
                      />
                    ))}
                  </CardsContainer>
                  {blogs?.TotalPages > 1 && (
                    <Pagination
                      count={blogs?.TotalPages}
                      page={currentPage}
                      onChange={onPageChange}
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </>
              ) : (
                <EmptyBox>
                  <div className="mb-5">
                    <img src={EmptyList} alt="Empty"></img>
                  </div>
                  <h4 className="fw-bold">
                    {blogs?.list !== blogsRef?.current?.list
                      ? 'Keine Ergebnisse verfügbar'
                      : 'Du hast noch keine Blogs'}
                  </h4>
                </EmptyBox>
              )}
            </>
          )}
        </ComponentWrapper>
      </ComponentWrapper>

      <ModalOuter
        open={modalOpen}
        onClose={() => setmodalOpen(false)}
        title="Blogeinträge"
        option="Neue hinzufügen"
      >
        <BlogModal
          closeModal={() => {
            setmodalOpen(false);
            getCompanyBlogs();
          }}
        />
      </ModalOuter>
      <DeletePrompt
        open={deleteModal}
        onClose={() => {
          setdeleteModal(false);
          seteditData(null);
        }}
        onDelete={onDeleteBlog}
      />
    </DashboardLayout>
  );
};

export default Blogs;
