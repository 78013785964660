import React from 'react';
import {Div} from './HomeIcon'

export const JobsIcon = (props) => {
  const [hover, sethover] = React.useState('#A7A7A7');
  return (
    <Div>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={props.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      onMouseEnter={() => sethover('#E83741')}
      onMouseLeave={() => sethover('#A7A7A7')}
    >
      <path
        d="M16.2503 20.5837C18.6436 20.5837 20.5837 18.6436 20.5837 16.2503C20.5837 13.8571 18.6436 11.917 16.2503 11.917C13.8571 11.917 11.917 13.8571 11.917 16.2503C11.917 18.6436 13.8571 20.5837 16.2503 20.5837Z"
        stroke={props.stroke || hover}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.042 20.042L22.7503 22.7503"
        stroke={props.stroke || hover}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33301 6.5H21.6663"
        stroke={props.stroke || hover}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33301 13H8.66634"
        stroke={props.stroke || hover}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33301 19.5H8.66634"
        stroke={props.stroke || hover}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    {props.hoverForParagraph && <p>Jobs</p>}</Div>
  );
};
