import React, { useState, Fragment } from 'react';
import SelectedInput from './SelectedInput';

import { Paragraph } from '../StepSixSubcomponents/Region';
import SelectedSkill from './SelectedSkill';
import SelectAsync from '../../../select/selectAsync';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFour } from '../../../../store/stepsSlice';

export default function ApplicantAdditional(props) {
  const dispatch = useDispatch();
  const selectedAdditional = useSelector(
    (state) => state.steps.stepFour.additionalQualifications
  );

  //array used only for SelectAsync component, because it needs obj with label and value properties
  // instead of Name and QualificationsId
  const [selectAsyncArr, setselectAsyncArr] = useState(
    selectedAdditional.map((item) => ({
      label: item.Name,
      value: item.QualificationsId,
      secondId: item?.secondId
    }))
  );

  const handleQualifications = (event, inputArr, setLevel) => {
    let values = inputArr;
    if (!setLevel) {
      values = inputArr.map((val) => ({
        QualificationsId: val.value,
        Name: val.label,
        secondId: val?.secondId
      }));
    }

    let newValues = [];
    if (setLevel) {
      newValues = values;
    } else {
      let deleteOption = selectedAdditional?.length > values?.length;

      if (deleteOption) {
        for (let index = 0; index < selectedAdditional.length; index++) {
          if (
            values.find(
              (item) =>
                item.QualificationsId ===
                selectedAdditional[index].QualificationsId
            )
          ) {
            newValues.push(selectedAdditional[index]);
          }
        }
      } else {
        for (let index = 0; index < values.length; index++) {
          if (
            selectedAdditional?.find(
              (item) => item.QualificationsId === values[index].QualificationsId
            )
          ) {
            newValues.push(selectedAdditional[index]);
          } else {
            newValues.push(values[index]);
          }
        }
      }
    }
    dispatch(updateStepFour({ additionalQualifications: newValues }));
    let newSelectAsyncArr = [];

    //SelectAsync needs Name and QualificationsId properties nemed label and value
    if (newValues.length > 0) {
      newSelectAsyncArr = newValues.map((item) => ({
        label: item.Name,
        value: item.QualificationsId,
        secondId: item?.secondId
      }));
    }
    setselectAsyncArr(newSelectAsyncArr);
  };

  return (
    <>
      {!props.createProfile && (
        <Paragraph font18 grey style={{ marginBottom: 0, marginTop: '10px' }}>
          Geben Sie hier weitere Zusatzqualifikationen / Kenntnisse ein, die Ihr
          Bewerber mitbringen sollten
        </Paragraph>
      )}

      <Paragraph tiny grey style={{ marginBottom: 0 }}>
        {props.createProfile
          ? 'Zusatzqualifikationen / Kenntnisse von dir auswählst'
          : 'Zusatzqualifikationen / Kenntnisse'}
      </Paragraph>

      <div style={{ width: '100%', margin: '10px 0 10px' }}>
        <SelectAsync
        className='create-profile-select'
          value={selectAsyncArr}
          option={'Qualifications/Paging'}
          onChange={(newValues, e) => {
            handleQualifications(e, newValues, false);
          }}
          searchParam="Name"
          isMulti={true}
          placeholder={
            props.createProfile
              ? 'Erhöhe deine Chance in dem du weitere'
              : 'Mehr hinzufügen'
          }
        />
      </div>
      {selectedAdditional.map((industry, index) => (
        <Fragment key={industry.QualificationsId}>
          <SelectedInput language={industry} vehicleType={'additional'} />
          <SelectedSkill
            data={props.skills}
            values={industry}
            allValues={selectedAdditional}
            index={index}
            setValue={handleQualifications}
            selectedValue={industry.secondId || ''}
          />
        </Fragment>
      ))}
    </>
  );
}
