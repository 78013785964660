import React, { useEffect, useState } from 'react';
import ExtensionIcon from '@mui/icons-material/Extension';
import Chip from '@mui/material/Chip';
import { Paragraph } from './Region';
import { Row } from '../StepTwo';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import GetData from '../../../../api/GetData';
import { Button } from '@mui/material';
import SubmitButton from '../../../Buttons/submitButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFive } from '../../../../store/stepsSlice';
import ModalSteps from '../ModalSteps';

export default function Employment(props) {
  const dispatch = useDispatch();
  const employmentArray = useSelector(
    (state) => state.steps.stepFive.employmentArray
  );

  const [open, setOpen] = React.useState(false);
  const [loadedbenefits, setloadedbenefits] = useState([]);
  const [selectedEmployment, setselectedEmployment] = useState(employmentArray);

  useEffect(() => {
    async function getEmployment() {
      const response = await GetData('Employment');
      const arr = response.filter(
        (x) =>
          !selectedEmployment.find((y) => y.EmploymentId === x.EmploymentId)
      );
      setloadedbenefits(arr);
    }
    getEmployment();
  }, []);

  const removeElement = (niz = null, industrija) => {
    const filteredIndustries = niz.filter(
      (item) => item.EmploymentId !== industrija.EmploymentId
    );
    setloadedbenefits(filteredIndustries);
    return true;
  };
  const removeSelected = (industrija) => {
    const filteredIndustries = selectedEmployment.filter(
      (item) => item.EmploymentId !== industrija.EmploymentId
    );
    setselectedEmployment(filteredIndustries);
  };
  const addLoaded = (industrija) =>
    setloadedbenefits([...loadedbenefits, industrija]);

  const handleClick = (industrija) => {
    removeElement(loadedbenefits, industrija);
    setselectedEmployment([...selectedEmployment, industrija]);
  };

  const body = (
    <>
      <h2 style={{ color: '#E83741' }}>
        Was möchtest du für eine Anstellungsart?{' '}
      </h2>
      {loadedbenefits &&
        loadedbenefits.map((indust, index) => (
          <Chip
            color="secondary"
            icon={<ExtensionIcon />}
            clickable
            label={indust.EmploymentType}
            onClick={() => {
              handleClick(indust);
            }}
            variant="outlined"
          />
        ))}
      <div style={{ width: '100%', height: '50px' }}></div>
      {selectedEmployment &&
        selectedEmployment.map((industry) => (
          <Chip
            color="secondary"
            icon={<ExtensionIcon />}
            clickable
            label={industry.EmploymentType}
            onDelete={() => {
              removeSelected(industry);
              addLoaded(industry);
            }} />
        ))}
      <ComponentWrapper right>
        <SubmitButton
          onClick={() => {
            dispatch(updateStepFive({ employmentArray: selectedEmployment }));
            setOpen(false);
          }}
          valid={selectedEmployment?.length > 0}
        >
          Bestätigen
        </SubmitButton>
      </ComponentWrapper>
    </>
  );

  return (
    <>
      <ComponentWrapper noPaddingHorizontal fluid>
        <Row>
          <Paragraph>
            {props.createProfile
              ? 'Was möchtest du für eine Anstellungsart?'
              : 'Welche Anstellungsart bieten Sie an?'}
          </Paragraph>

          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            style={{ margin: '15px 0' }}
          >
            Wählen
          </Button>
        </Row>
        {!props.createProfile && (
          <Paragraph style={{ fontSize: 14, opacity: 0.6, marginTop: '-1%' }}>
            (Sie können mehrere Angstellungsarten auswählen)
          </Paragraph>
        )}

        <div>
          {selectedEmployment &&
            selectedEmployment.map((industry) => (
              <Chip
                color="secondary"
                icon={<ExtensionIcon />}
                clickable
                label={industry.EmploymentType}
                onDelete={() => {
                  removeSelected(industry);
                  addLoaded(industry);
                  dispatch(
                    updateStepFive({ employmentArray: selectedEmployment })
                  );
                }}
                variant="outlined"
              />
            ))}
        </div>
      </ComponentWrapper>

      <ModalSteps
        open={open}
        onClose={() => {
          dispatch(updateStepFive({ employmentArray: selectedEmployment }));
          setOpen(false);
        }}
        className="special-modal"
      >
        {body}
      </ModalSteps>
    </>
  );
}
