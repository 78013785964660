import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import CircularLoader from '../Loader/CircularLoader';

import styles from './CompanyProfile.module.scss';
import getCoordinates from './getCoordinates';

const CompanyMap = ({ location, _zoom = 12 }) => {
  const [locationMap, setlocationMap] = useState(null);
  const [locationMaps, setlocationMaps] = useState([]);

  //map
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places']
  });

  //map
  const [mapConfig, setMapConfig] = useState({
    zoom: _zoom,
    center: {}
  });

  const { zoom, center } = mapConfig;

  const mapOptions = useMemo(() => {
    return {
      gestureHandling: 'greedy',
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false
    };
  }, []);


  useEffect(() => {
    (async () => {
      if(location?.length){
        setlocationMaps(location.map(el => ({
          lat: parseFloat(el.Latitude),
          lng: parseFloat(el.Longitude)
        })))
        setMapConfig({
          ...mapConfig,
          center: {
            lat: parseFloat(location.find(l => l.Latitude !== null)?.Latitude ?? 0) ?? 0,
            lng: parseFloat(location.find(l => l.Longitude !== null)?.Longitude ?? 0) ?? 0
          }
        });

      }else{
          if (location?.Latitude && location?.Longitude) {
            setlocationMap({
              lat: parseFloat(location.Latitude),
              lng: parseFloat(location.Longitude)
            });
            setMapConfig({
              ...mapConfig,
              center: {
                lat: parseFloat(location.Latitude),
                lng: parseFloat(location.Longitude)
              }
            });
          } else if (location?.FormattedAddress && isLoaded) {
            const res = await getCoordinates(location.FormattedAddress);
            setlocationMap({ lat: res.lat, lng: res.lng });
            setMapConfig({ ...mapConfig, center: { lat: res.lat, lng: res.lng } });
          }
      }
    })();
  }, [location, isLoaded]);

  if (loadError) {
    return null;
  }
  
  return (
    <>
      {(locationMap || locationMaps) && (
        <>
          {isLoaded ? (
            <div className={styles.mapContainer}>
              <GoogleMap
                mapContainerStyle={{
                  height: '450px',
                  width: '100%'
                }}
                options={mapOptions}
                center={center}
                zoom={zoom}
              >
                {locationMaps.length?
                locationMaps.map((lm, index) => <Marker
                key={index}
                  position={{
                    lat: parseFloat(lm?.lat),
                    lng: parseFloat(lm?.lng)
                  }}
                />)
                :
                (location && (
                  <Marker
                    position={{
                      lat: parseFloat(locationMap?.lat),
                      lng: parseFloat(locationMap?.lng)
                    }}
                  />)
                )}
              </GoogleMap>
            </div>
          ) : (
            <CircularLoader small />
          )}
        </>
      )}
    </>
  );
};

export default CompanyMap;
