import React, { useState, useEffect } from 'react';
import ApplicantLanguages from './SetFourSubcomponents/ApplicantLanguages';
import ApplicantLandLicences from './SetFourSubcomponents/AplicantLandLicences';
import ApplicantWaterLicences from './SetFourSubcomponents/AplicantWaterLicences';
import ApplicantAirLicences from './SetFourSubcomponents/ApplicantAirLicences';
import ApplicantSpecialLicences from './SetFourSubcomponents/ApplicantSpecialLicences';
import ApplicantAdditional from './SetFourSubcomponents/ApplicantAdditional';
import { Container } from './StepOne';
import GetData from '../../../api/GetData';
import { DeviceSize } from '../../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import { Paragraph } from './StepSixSubcomponents/Region';
import ApplicantHobbies from './SetFourSubcomponents/ApplicantHobbies';
import ComponentWrapper from '../../ComponentWrapper/ComponentWrapper';
import { Subcontainer } from './StepOne';
import { updateValid } from '../../../store/stepsSlice';
import { useDispatch, useSelector } from 'react-redux';

export function StepFour(props) {
  const [licences, setlicences] = useState(null);
  const [languages, setlanguages] = useState(null);
  const [skills, setskills] = useState(null);
  const [levels, setlevels] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });

  const dispatch = useDispatch();
  const selectedAdditional = useSelector(
    (state) => state.steps.stepFour.additionalQualifications
  );
  const selectedLanguages = useSelector(
    (state) => state.steps.stepFour.languages
  );

  useEffect(() => {
    async function getLicences() {
      let response = await GetData('License');
      setlicences(response);
    }

    async function getLanguages() {
      let response = await GetData('Language');

      setlanguages(response);
    }
    async function getSkills() {
      let response = await GetData('Skills');
      setskills(response);
    }
    async function getLevels() {
      let response = await GetData('Level');
      setlevels(response);
    }

    getLevels();
    getSkills();
    getLanguages();
    getLicences();
  }, []);

  useEffect(() => {
    if (
      (selectedLanguages.length &&
        selectedLanguages.every(
          (lan) => Object.hasOwn(lan, 'secondId') && lan.secondId !== null
        )) ||
      selectedAdditional.length
    ) {
      if (selectedAdditional.length) {
        if (
          selectedAdditional.every(
            (lan) =>
              Object.hasOwn(lan, 'secondId') && lan.secondId !== undefined
          )
        )
          dispatch(updateValid(true));
        else dispatch(updateValid(false));
      } else dispatch(updateValid(true));
    } else {
      dispatch(updateValid(false));
    }
  }, [selectedLanguages, selectedAdditional]);

  return (
    <form className="formTwo">
      <Container style={{ width: '70%' }}>
        {isMobile ? (
          <h4 className="fw-bold fc-p mb-4 mt-3">
            4. Zusatzqualifikationen / Kenntnisse
          </h4>
        ) : null}
        <div className="create-profile-step-four">
          <Subcontainer>
            <ApplicantLanguages
              values={languages}
              levels={levels}
              createProfile={props.createProfile}
            />
            <ComponentWrapper padding="10px 0 0px">
              <Paragraph tiny grey>
                {props.createProfile
                  ? 'Welche Führerscheinklassen / Lizenzen besitzt du?'
                  : 'Welche Führerscheinklassen / Lizenzen benötigt Ihr Bewerber?'}
              </Paragraph>
            </ComponentWrapper>

            <ApplicantLandLicences
              values={licences}
              createProfile={props.createProfile}
            />
            {/* <ApplicantWaterLicences
              values={licences}
              createProfile={props.createProfile}
            /> */}
            <ApplicantSpecialLicences
              values={licences}
              createProfile={props.createProfile}
            />
          </Subcontainer>
          <Subcontainer>
            {/* <ApplicantAirLicences
              values={licences}
              createProfile={props.createProfile}
            /> */}

            <ApplicantAdditional
              skills={skills}
              createProfile={props.createProfile}
            />
            {props.createProfile && <ApplicantHobbies />}
          </Subcontainer>
        </div>
      </Container>
    </form>
  );
}

export default StepFour;
