import { useEffect, useState } from 'react';
import classes from './BluredJobs.module.scss';
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import PartnerJobCard from '../PartnerJobCard/PartnerJobCard';
import ImageTemp from '../../assets/images/profile-temp-image.png';

const EmploymentTypes = [
  'Ausbildung',
  'Minijob',
  'Freiberufler',
  'Vollzeit (Festanstellung)',
  'Teilzeit',
  'Auszubildender'
];
const Cities = [
  'Munich',
  'Berlin',
  'Hamburg',
  'Cologne',
  'Düsseldorf',
  'Mönchengladbach'
];
const JobTitles = [
  'Support-Spezialist',
  'Computerprogrammierer',
  'Prüfer für Qualitätssicherung',
  'Web-Entwickler',
  'IT-Techniker',
  'System-Analytiker'
];
const Industries = [
  'Recht',
  'Kundenbetreuung',
  'Personalwesen und HR',
  'Projektmanagement',
  'Marketing und Werbung',
  'Beratung / Consulting'
];
const Companies = [
  'Herzog-Thompson',
  'Ryan LLC	',
  'Anderson PLC',
  'Leffler-Ebert',
  'Busse',
  'Schulze'
];

const BluredJobs = () => {
  const [jobs, setJobs] = useState([]);

  const getIndex = (index = 6) => {
    return Math.floor(Math.random() * index);
  };

  useEffect(() => {
    const array = [];

    for (let i = 0; i < 3; i++) {
      const job = {};
      job.Profession = { ProfesionName: JobTitles[getIndex()] };
      job.employment = EmploymentTypes[getIndex()];
      job.city = Cities[getIndex()];
      job.companyName = Companies[getIndex()];
      job.industry = Industries[getIndex()];
      job.jobAdvertisementId = 1;
      job.UserPhotoUrl = ImageTemp;
      array.push(job);
    }

    setJobs(array);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes['blured-container']}>
        {jobs.map((job) => (
          <PartnerJobCard  key={Cities + getIndex(1000)} job={job} noButton={true}/>
        ))}
      </div>
      <div className={classes.box}>
        <p>
          Weitere Stellenanzeigen sind nur in der Matching-funktion
          freigeschaltet. </p> 
          <p>Registriere dich jetzt kostenlos und erhalte passende
          matches. Wir vernetzen Dich mit den passenden Jobs!
        </p>
        <div className={classes['box-buttons']}>
          <NavLink to="/login">
            <Button variant="contained" color="primary">
              Login
            </Button>
          </NavLink>
          <NavLink to="/profil-anlegen">
            <Button variant="contained" color="primary">
              Profil anlegen
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default BluredJobs;
