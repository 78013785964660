import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import { CircularProgress } from '@mui/material';
import {ReactComponent as SortIcon } from '../../assets/images/icon-sort.svg'
import {ReactComponent as IconSearch} from "../../assets/images/icon-suchen.svg"


export default function SearchBar(props) {
  const loading = props.loading;

  //every component that uses SearchBar needs to implement its own searching and sorting
  const [searchText, setsearchText] = useState(props.searchText || '');
  const [sortOrder, setsortOrder] = useState(props.sortOrder || 'newest');

  const handleChange = (e) => {
    setsearchText(e.target.value);
  };
  const onSortChange = (e) => {
    setsortOrder(e.target.value);
  };

  const [appState, changeState] = useState({
    activeObject: { id: 1, field: 'Das neuste zuerst' },
    objects: [
      { id: 1, field: 'Das neuste zuerst' },
      { id: 2, field: 'Neueste zuerst' },
      { id: 3, field: 'A - Z' },
      { id: 4, field: 'Z - A' }
    ]
  });

  function toggleActive(index) {
    changeState({ ...appState, activeObject: appState.objects[index] });
    //console.log(appState.activeObject);
  }
  const handleClick = () => {
    props.onChange && props.onChange(searchText);
    props.onSortChange && props.onSortChange(sortOrder);
  }
  return (
    <div className='search-bar'>
      <div className="search-input">
     

        <FormControl variant="outlined" className="w-100">
          <OutlinedInput
            style={{ backgroundColor: 'white', paddingLeft: 25 }}
            placeholder="Suche"
            id="outlined-adornment-password"
            type="text"
            value={searchText}
            onChange={handleChange}
            startAdornment={
              <InputAdornment position="start">
                {loading ? (
                  <CircularProgress size={25} />
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className="sort-input">
        <FormControl variant="outlined" className="w-100 sort-box">
          <Select
            label="Sortieren nach"
            value={sortOrder}
            style={{ backgroundColor: 'white' }}
            onChange={onSortChange}
            startAdornment={
              <InputAdornment position="start">
                  <SortIcon className='sort-icon'/>
                </InputAdornment>
            }
          >
            <MenuItem value={'newest'}>Neueste zuerst</MenuItem>
            <MenuItem value={'oldest'}>Älteste zuerst</MenuItem>
            <MenuItem value={'a-z'}>A-Z</MenuItem>
            <MenuItem value={'z-a'}>Z-A</MenuItem>
          </Select>
        </FormControl>
      </div>
      <button 
      className='blog-search-button'
            onClick={handleClick}>
                <IconSearch/>
                <span>BEITRÄGE<br/> FINDEN</span>
            </button>
    </div>
  );
}
