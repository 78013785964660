import React, { useEffect, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import auth from '../../auth';
import ModalOuter from './ModalOuter';
import { useHistory } from 'react-router-dom';
import { JobTypeColor } from '../Jobs/JobsCard';
import MapPins from '../../assets/images/mapPins.svg';
import clock from '../../assets/images/clock.svg';
import building from '../../assets/images/icon-job-location.svg';
import dots from '../../assets/images/dots.svg';
import person from '../../assets/images/person.svg';
import salary from '../../assets/images/euro-bills.svg';
import JobDetailsItem from '../Job/JobDetailsItem';
import parse from 'html-react-parser';
import GetDataPublic from '../../api/GetDataPublic';
import GetData from '../../api/GetData';
import styles from '../Job/Job.module.scss';
import SocialNetworks from '../Job/SocialNetworks';
import correctLink from '../../utils/correctLink';
import { formatSalary } from '../../utils/formatSalary';

export const JobAdPreviewModal = (props) => {
  const history = useHistory();

  const [user, setuser] = useState(null);
  const [socialNetworks, setsocialNetworks] = useState({
    facebook: null,
    instagram: null,
    twitter: null,
    linkedin: null
  });
  const [employment, setemployment] = useState([]);

  const [contactPerson, setcontactPerson] = useState(null);

  async function getEmployment() {
    let result = await GetData('Employment');
    setemployment(result);
  }

  useEffect(() => {
    (async () => {
      let user;
      if (auth.isAuthenticated()) {
        user = await GetDataPublic(`User/${auth.getId()}`);
        setuser(user);
        
        const net = await GetDataPublic(`organisationsocialnetwork/${auth.getId()}`);
        setsocialNetworks({
            linkedin: net.LinkedinUrl,
            twitter: net.TwitterUrl,
            facebook: net.FacebookUrl,
            instagram: net.InstagramUrl
          });

          if(props.data?.ContactId){
              const person = await GetDataPublic(`contactperson/${props.data.ContactId}`);
              setcontactPerson(person)

          }

      }

    })();

    getEmployment();
  }, [props.data]);

  return (
    <>
      <ModalOuter
        open={props.open}
        onClose={props.closeModal}
        title="Vorschau"
        large
        showDot={false}
        option={''}
      >
       <ComponentWrapper displayFlex flexColumn noPadding minHeight="130vh">
        <>
          <div>
           {props.data?.Photo?.Url && <div className={styles.imageBlock}>
              <img
                src={props.data?.Photo?.Url}
                alt="jobPhoto"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            </div>}
            <ComponentWrapper noPaddingHorizontal center>
                <h4 className={styles.jobHeading} lang='de' style={{hyphens: 'auto'}}>
                {' '}
                {props.data?.Profession?.ProfesionName || props.data?.ProfessionText} (M/W/D)
                <span className={styles.jobSpecification}>{' '}{props.data?.JobAdSpecification}</span>
              </h4>
              <div className={styles.headerRow}>
                <div className={styles.headerItem}>
                  <JobTypeColor
                    inActive={props.data?.isActive === false}
                    style={{ margin: '0 5px' }}
                  />
                  <Paragraph autoWidth title>
                  {props.data?.Employments.length? props.data.Employments.map(s =>  employment?.find(e => e.EmploymentId === s)?.EmploymentType).join(', ') : props.data?.EmploymentText}
                  </Paragraph>
                </div>
                <div className={styles.headerItem}>
                  <span className={styles.headerBullet}></span>
                  <img
                    src={MapPins}
                    alt="location"
                    style={{ margin: '0 5px' }}
                    width="25"
                    height="25"
                  />
                  <Paragraph autoWidth title>
                    {props.data?.JobLocation?.FormattedAddress}
                    {props.data?.JobLocation2?.FormattedAddress && `, ${props.data?.JobLocation2?.FormattedAddress}`} 
                    {props.data?.JobLocation3?.FormattedAddress && `, ${props.data?.JobLocation3?.FormattedAddress}`}
                  </Paragraph>
                </div>
              </div>
            </ComponentWrapper>

            <ComponentWrapper noPaddingHorizontal>
              <div className={styles.infoContainer}>
                <div className={styles.companyInfo}>
                  <img
                    src={user?.ImagePath}
                    alt="blog"
                    style={{
                      width: 120,
                      height: 90,
                      boxShadow:
                        '0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 5%)',
                      borderRadius: 5,
                      cursor: 'pointer',
                      objectFit: 'contain'
                    }}
                    onClick={() =>
                      history.push(`/arbeitgeber/${encodeURIComponent(user?.Name.replace("+", ""))}-${user?.Id}`)
                    }
                  />
                  <Paragraph title style={{ margin: '10px 0' }}>
                    {user?.Name}
                  </Paragraph>
                  {user && (
                      <Paragraph>
                        {user?.LocationAddress}
                      </Paragraph>
                    )}
                  {user?.Website && (
                    <Paragraph style={{ marginTop: 20 }}>
                      <a
                        href={correctLink(user?.Website)}
                        target="_blank"
                      >
                        {user?.Website}
                      </a>
                    </Paragraph>
                  )}

                  <SocialNetworks socialNetworks={socialNetworks} />
                </div>

                <div className={styles.jobInfo}>
                  <JobDetailsItem
                    icon={clock}
                    title={'Beschäftigungsbeginn:'}
                    content={props.data?.StartingDate?.Type}
                  />
                  <JobDetailsItem
                    icon={building}
                    title={'Zweigstelle / Niederlassung:'}
                    content={`${props.data?.Office?.BranchType} ${
                      (props.data?.Office?.Location?.StreetNo && ',') || ''
                    } ${props.data?.Office?.Location?.CityName || ''}`}
                  />
                  {props.data?.ContactId && (
                    <JobDetailsItem
                      icon={person}
                      title={'Ansprechpartner:'}
                      content={`${contactPerson?.FirstName} ${contactPerson?.LastName}`}
                    />
                  )}

                  <JobDetailsItem
                    icon={dots}
                    title={'Branche:'}
                    content={props.data?.Branch?.BranchType}
                  />

                  {props.data?.Salary && 
                    <JobDetailsItem
                    icon={salary}
                    title={'Jahresbruttogehalt:'}
                    content={`${formatSalary(props.data?.Salary.MinAnnualSalary)}€ - ${formatSalary(props.data?.Salary.MaxAnnualSalary)}€`}
                  />

                  }
                </div>
              </div>
            </ComponentWrapper>

            {props.data?.IntroductionText && (
              <div className={styles.jobParagraphContainer}>
                <Paragraph>{parse(props.data?.IntroductionText)}</Paragraph>
              </div>
            )}
            {props.data?.Tasks && (
              <div className={styles.jobParagraphContainer}>
                <Paragraph title large margin>
                  Ihre Aufgaben:
                </Paragraph>

                <Paragraph>{parse(props.data?.Tasks)}</Paragraph>
              </div>
            )}
            {props.data?.Profil && (
              <div className={styles.jobParagraphContainer}>
                <Paragraph title large margin>
                  Ihr Profil:
                </Paragraph>

                <Paragraph>{parse(props.data?.Profil)}</Paragraph>
              </div>
            )}
            {props.data?.Offer && (
              <div className={styles.jobParagraphContainer}>
                <Paragraph title large margin>
                  Wir bieten:
                </Paragraph>

                <Paragraph>{parse(props.data?.Offer)}</Paragraph>
              </div>
            )}
            {props.data?.ClosingText && (
              <div className={styles.jobParagraphContainer}>
                {' '}
                <Paragraph>{parse(props.data.ClosingText)}</Paragraph>
              </div>
            )}
           
          
          </div>
            
        </>
    </ComponentWrapper>
      </ModalOuter>
    </>
  );
};

