
import GooglePlay from '../../assets/images/google-play-badge.png';
import AppStore from '../../assets/images/app-store-badge.png';
import Phone from '../../assets/images/bewerber-section-phone.png';
import {Animated} from "react-animated-css";
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import { maxWidth } from '@mui/system';


const SecondStep = ({isVisible}) => {
    const isMobile = useMediaQuery({maxWidth: DeviceSize.iPadPro});
    return(
        <section className="bewerber-section bewerber-section-second" id='bewerber-second'>
            <div //ubaciti Animated umjesto diva
             /*animationIn="bounceInRight" 
             animationOut="bounceOutRight" 
               animationInDuration={1600} 
             animationOutDuration={1600}
             isVisible={isVisible} */
            className="section-card">

              {/*!isMobile &&  */ <div 
                /* animationIn="bounceInLeft" 
                 animationOut="bounceOutLeft" 
                   animationInDuration={1600} 
                 animationOutDuration={1600}
                 isVisible={isVisible} */
                className='section-phone'>
                    <img src={Phone}/>
                </div>}
              
                <div 
               /*animationIn="bounceInDown" 
               animationOut="bounceOutUp" 
                 animationInDuration={1600} 
               animationOutDuration={1600}
               isVisible={isVisible}*/
               className="section-text" >
                    <h3>Yeeeeeah!<br/>Jetzt auch als App!</h3>
                    <p className="first-p-B">Werde jetzt auch mobil gematcht, vernetzt und<br/> Sei immer auf dem neusten Stand!</p>
                    <p className="second-p">Unsere App für iOS und Android!</p>
                       
                    </div>
     <div className='text-buttons'>   <p className="third-p">Direkt Downloaden</p>    
                <div 
                /* animationIn="bounceInDown" 
                 animationOut="bounceOutUp" 
                   animationInDuration={1600} 
                 animationOutDuration={1600}
                 isVisible={isVisible}*/
                className='download-buttons'>
                 
                <div className="google-play">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=de.spiderjob.spiderjob"
                        style={{
                        display: 'inlineBlock',
                        overflow: 'hidden'
                        }}
                    >
                <img
                  alt="Jetzt bei Google Play"
                  src={GooglePlay}
                />
              </a>
            </div>
            <div className="app-store">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/id1595120799"
                style={{
                  display: 'inlineBlock',
                  overflow: 'hidden',
                  
                }}
              >
                <img
                  src={AppStore}
                  alt="Download on the App Store"
                />
              </a>
            </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default SecondStep;