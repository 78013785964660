import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import auth from '../../auth';
import { Button } from '@mui/material';
import axios from 'axios';
import CustomModal from '../../components/modal/Modal';
import { Flex } from '../../components/Common/Input';
import { hash } from '../../utils/hash';
import config from '../../config/api.config';
import Snackbar from '../snackbar/snackbar';
import jwt from 'jwt-decode';
import Link from '@mui/material/Link';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const NavLinksContrainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav-links a:hover {
    border-color: #e83741;
    cursor: pointer;
  }
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`;

const LinkItem = styled.li`
  height: 100%;
  white-space: nowrap;
  padding: 0 1.3em;
  color: #717171;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  > a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    border-bottom: 4px solid transparent;
    transition: border-color 400ms;
    border-radius: 2px;
    margin-bottom: 0;
  }

  > button a {
    color: inherit !important;
    text-decoration: none;
  }
`;

export const Error = styled.span`
  color: rgb(232, 55, 65);
  font-size: 0.6rem;
  padding: 0 5px 7px 5px;
  display: block;
`;
export function NavLinks(props) {
  return (
    <NavLinksContrainer>
      <LinksWrapper className="nav-links">
        <LinkItem>
          <NavLink to="/bewerber" activeClassName="activeLink">
            Für Bewerber
          </NavLink>
        </LinkItem>
        <LinkItem>
          <NavLink to="/jobs" activeClassName="activeLink">
            Jobs
          </NavLink>
        </LinkItem>
        <LinkItem>
          <NavLink to="/arbeitgeber" activeClassName="activeLink">
            Für Arbeitgeber
          </NavLink>
        </LinkItem>
        <LinkItem>
          <NavLink to="/partner-und-kooperationen" activeClassName="activeLink">
            Partner & Kooperationen
          </NavLink>
        </LinkItem>
        {/* <LinkItem>
          <NavLink to="/ehrenamt" activeClassName="activeLink">
            Ehrenamt
          </NavLink>
        </LinkItem> */}
        <LinkItem>
          <NavLink to="/beiträge" activeClassName="activeLink">
            Beiträge
          </NavLink>
        </LinkItem>
      </LinksWrapper>
    </NavLinksContrainer>
  );
}

export function LogoutDashboard() {
  const history = useHistory();
  const urlDashboard = auth.isCompany()
    ? '/arbeitgeber-dashboard'
    : auth.isOrganization()
    ? '/organisations-dashboard'
    : '/bewerber-dashboard';
  const dashboardClick = () => {
    // if (auth.isTokenExpired) {
    //   auth.logout(() => history.push('/login'));
    // } else {
    history.push(!auth.isAuthenticated() ? '/' : urlDashboard);
    //}
  };
  return (
    <NavLinksContrainer>
      <LinksWrapper>
        {/* <LinkItem>
        <Button variant="contained" color="secondary">
        <NavLink
            onClick={() => auth.logout((_) => history.push('/login'))}
            to="/login"
          >
            Logout
          </NavLink>
          </Button>
        </LinkItem>
        <span style={{ color: '#E83741', fontSize: '28px' }}>|</span> */}
        <LinkItem>
          <Button variant="contained" color="primary" onClick={dashboardClick}>
            Dashboard
          </Button>
        </LinkItem>
      </LinksWrapper>
    </NavLinksContrainer>
  );
}

export function LoginRegister() {
  const [openModal, setOpenModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [valuesLogin, setValuesLogin] = useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const current_timestamp = Math.floor(new Date() / 1000);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState('');

  const [valid, setValid] = useState(false);
  const [validLogin, setValidLogin] = useState(false);

  const [seePassLogin, setSeePassLogin] = useState(false);
  const [seePassRegister, setSeePassRegister] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeLogin = (e) => {
    setValuesLogin({ ...valuesLogin, [e.target.name]: e.target.value });
  };

  const access_token =
    'EAAHVkjGN0ZA0BAHxgilcj3WOua1Ea2iuZBEbzHsp0mio6iIvmwXZAtxL2ReT15e1LspKNlb3mDZBsfej2CTqKCS2bDwOPJHx1jpZAFEWfns6Tl0LJukoMxSzi8mOeZAIO3iHIZC8iOQr11TEfzBwsmWIrQZC4EBRzxYsRbFClDmOShVIjlvLqYJF';
  const pixel_id = '400768238535269';

  const loginUser = (token, refreshToken) => {
    if (!token) return;
    const dataJwt = jwt(token);
    if (dataJwt.Role == '' || dataJwt.Role == 'Admin') {
      showSnackbar(true);
      setLoading(false);
      setSnackbarMessage('Benutzername oder Passwort ist falsch!');
      return;
    }
    return auth.login(
      token,
      dataJwt.exp,
      dataJwt.UserId,
      dataJwt.Role,
      dataJwt.VerifiedAccount,
      dataJwt.PaymentId,
      refreshToken,
      (_) => {
        switch (dataJwt.Role) {
          case 'Applicant':
           history.push('/bewerber-dashboard');
            break;
          case 'Company':
            history.push({
              pathname: '/arbeitgeber-dashboard',
              state: {
                showModal: dataJwt.VerifiedAccount == 'False' ? true : false
              }
            });
            break;
          case 'Organisation':
            history.push({
              pathname: '/organisations-dashboard',
              state: {
                showModal: dataJwt.VerifiedAccount == 'False' ? true : false
              }
            });
            break;
          default:
            history.push('/');
        }
      }
    );
  };

  const onLogin = async () => {
    setLoading(true);

    await axios
      .post(`${config.apiUrl}/Authentication/Login`, {
        username: valuesLogin.email,
        password: valuesLogin.password
      })
      .then((res) => {
        const token = res.data.Token;
        const refreshToken = res.data.RefreshToken;
        setLoading(false);
        loginUser(token, refreshToken);
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(true);
        setLoading(false);
        setSnackbarMessage(error.response.data.ERROR[0]);
      });
  };

  const onSubmit = async () => {
    setLoading(true);
    await axios.post(
      `https://graph.facebook.com/v12.0/${pixel_id}/events?access_token=${access_token}`,
      {
        data: [
          {
            event_name: 'Registration',
            event_time: current_timestamp,
            action_source: 'website',
            event_source_url: 'https://spiderjob.de/profil-anlegen',
            user_data: {
              em: [hash(values.email)]
            },
            custom_data: {
              user_type: 'Applicant'
            }
          }
        ]
      }
    );
    await axios
      .post(`${config.apiUrl}/Authentication/Register?AppType=Web`, {
        email: values.email.toLowerCase(),
        password: values.password,
        userType: 1
      })
      .then((res) => {
        setLoading(false);
        setOpenModal(false);
        history.push('/login');
        //history.push('/erfolgreich-registriert');
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );
        //if (error.response.data.errors) setErrors(error.response.data.errors);
      });
  };

  useEffect(() => {
    setError('');

    if (values.email != '' && values.password != '') setValid(true);
    else setValid(false);

    if (
      values.password != '' &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(values.password)
    ) {
      setError('Passwort entspricht nicht den Anforderungen');
      setValid(false);
    }
  }, [values.email, values.password]);

  useEffect(() => {
    setError('');

    if (valuesLogin.email != '' && valuesLogin.password != '')
      setValidLogin(true);
    else setValidLogin(false);
  }, [valuesLogin.email, valuesLogin.password]);

  return (
    <NavLinksContrainer>
      <LinksWrapper>
        <LinkItem>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setLoginModal(true)}
            style={{ marginRight: '10px' }}
          >
            Login
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            Profil anlegen
          </Button>
        </LinkItem>
      </LinksWrapper>

      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        withRadius
        fixed600
        height="700px"
      >
        <Flex column padding h90>
          <Snackbar
            open={snackbar}
            onClose={() => showSnackbar(false)}
            severity="error"
          >
            {snackbarMessage}
          </Snackbar>

          <p className="bolded-p">Neuen Account erstellen</p>
          <p className="simple-p">
            Jetzt bei spiderjob registrieren und den richtigen Job finden
          </p>

          <p className="email">Email</p>
          <input
            className="input-e"
            onChange={handleChange}
            name="email"
            value={values.email}
          ></input>
          <p className="email">Passwort festlegen</p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #CFCFCF'
            }}
          >
            <input
              className="input-e"
              onChange={handleChange}
              value={values.password}
              name="password"
              type={seePassRegister ? 'text' : 'password'}
              style={{ marginBottom: '0px', border: 'none' }}
            ></input>

            <div
              className='inputEye'
              onClick={() => setSeePassRegister(!seePassRegister)}
            >
              {seePassRegister ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
          </div>
          <Error key={'error'}>{error}</Error>

          <p className="pass">
            Das Passwort muss mindestens 8 Zeichen, Groß- und Kleinbuchstaben
            sowie eine Mischung aus Buchstaben und Zahlen enthalten.
          </p>

          <Button
            variant="contained"
            color="primary"
            className="register-b"
            onClick={onSubmit}
            disabled={!valid}
          >
            Registrieren
          </Button>

          <p className="pass">
            Mit deiner Registrierung stimmst du unseren{' '}
            <span className="span-pass">Nutzungsbedingungen</span> zu. Lies dazu
            auch unsere{' '}
            <span
              className="span-pass"
              onClick={() => history.push('/datenschutz-bestimmungen')}
            >
              Datenschutzerklärung
            </span>
            .
          </p>
          <p className="pass">
            In Erfüllung des Nutzungsvertrages schicken wir dir passende Jobs
            und Infos zu ähnlichen Produkten und Services per E-Mail zu.
            Natürlich kannst du jederzeit von unseren einwilligungsfähigen
            E-Mails und Services abmelden oder dein Profil auf “nicht sichtbar”
            stellen.
          </p>
          <div className="bottom-1">
            <p className="last-p">
              Du hast schon einen Account?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/login')}
              >
                {' '}
                Jetzt einloggen
              </span>
            </p>
            <p className="last-p">
              Sie sind Arbeitgeber?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/kostenloses-erstgespräch')}
              >
                {' '}
                Hier entlang
              </span>
            </p>
          </div>
        </Flex>
      </CustomModal>

      <CustomModal
        open={loginModal}
        onClose={() => setLoginModal(false)}
        withRadius
        fixed600
        height="550px"
      >
        <Flex column padding h90>
          <Snackbar
            open={snackbar}
            onClose={() => showSnackbar(false)}
            severity="error"
          >
            {snackbarMessage}
          </Snackbar>

          <p className="bolded-p">Willkommen zurück!</p>
          <p className="simple-p">
            Jetzt einloggen und den richtigen Job finden.
          </p>

          <p className="email">Email</p>
          <input
            className="input-e"
            onChange={handleChangeLogin}
            name="email"
            value={valuesLogin.email}
          ></input>
          <p className="email">Passwort</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #CFCFCF'
            }}
          >
            <input
              className="input-e"
              onChange={handleChangeLogin}
              value={valuesLogin.password}
              name="password"
              type={seePassLogin ? 'text' : 'password'}
              style={{ marginBottom: '0px', border: 'none' }}
            ></input>
            <div
             className='inputEye'
              onClick={() => setSeePassLogin(!seePassLogin)}
            >
              {seePassLogin ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
          </div>
          <Error key={'error'}>{error}</Error>

          <Link
            onClick={() => history.push('/passwort-vergessen')}
            underline="hover"
            style={{ fontSize: '13px' }}
          >
            Passwort vergessen?
          </Link>

          <Button
            variant="contained"
            color="primary"
            className="register-b"
            onClick={onLogin}
            disabled={!validLogin}
          >
            Einloggen
          </Button>

          <p className="pass">
            Indem du den Button oben klickst, stimmst du unseren
            Nutzungsbedingungen zu. <br></br>
            Lies auch unsere{' '}
            <span
              className="span-pass"
              onClick={() => history.push('/datenschutz-bestimmungen')}
            >
              Datenschutzerklärung
            </span>
            .
          </p>

          <div className="bottom-1">
            <p className="last-p">
              Du hast noch keinen Account bei uns?
              <span
                className="span-pass"
                onClick={() => history.push('/profil-anlegen')}
              >
                {' '}
                Jetzt registrieren
              </span>
            </p>
          </div>
        </Flex>
      </CustomModal>
    </NavLinksContrainer>
  );
}
