import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import auth from '../auth';
import GetData from '../api/GetData';
import MatchingSteps from '../components/Matching/MatchingSteps';
import { useDispatch } from 'react-redux';
import { clearState, setMatchingData } from '../store/stepsSlice';

export default function Matching() {
  const history = useHistory();
  const dispatch = useDispatch();
  const job = JSON.parse(localStorage.getItem('JobAd'));
  const MatchingId = (job && job?.MatchingId) || null;

  const getMatchingData = async (id) => {
    const res = await GetData(`/matching/fullgetbyid/?Id=${id}`);
    console.log(res)

    dispatch(setMatchingData(res));
  };
console.log(MatchingId, job)
  useEffect(() => {
    if (MatchingId) {
      getMatchingData(MatchingId);
    }

    return () => {
      dispatch(clearState());
    };
  }, [MatchingId, getMatchingData]);

  useEffect(() => {
    if (!auth.isCompany()) {
      history.goBack();
    }
  }, [auth.isCompany()]);

  return (
    <>
      <MatchingSteps />
    </>
  );
}
