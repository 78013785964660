import React from "react";
import { useHistory } from "react-router-dom";
import './partnerCard.scss';
import { Button } from "@mui/material";
import {ReactComponent as IconBusiness} from "../../assets/images/icon-business-grey.svg";
import {ReactComponent as IconPin} from "../../assets/images/icon-pin-fill.svg";

const PartnerCard = (props) => {
    const history = useHistory();
    let name = props.partner.PartnerName; //ukoliko je prevelik parner name, prelamamo rijec uz dodatak crtice na kraju prelomljene rijeci
    //nisam nasla gotovu funkciju za to u cssu
    // let name1 = "";
    // let name2 = "";
    // if(name.length > 20) {
    //     name1 = name.substring(0, 20);
    //     name1 = name1 + "-";
    //     name2 = name.substring(20, name.length);
    // }

    return (
        <div className="partner-card">
            <div className="card-image">
                <img src={props.partner?.Picture?.Url}/>

            </div>
            <p className="card-name">
                {name}
            </p>
            
            <div className="card-establishment">
                <IconBusiness/>
                <p>
                    {props.partner.EstablishmentType}
                </p>
            </div>
            <div className="card-location">
            <IconPin/>
            <p>
                {props.partner.Address}
            </p>
            </div>
            {props.partner.MapButton && <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`partner-und-kooperationen/${encodeURIComponent(props.partner.PartnerName?.replace("+", ""))}-${props.partner.PartnerId}`)} 
                 >
                    Entdecken
             </Button>}
        </div>
    )

}

export default PartnerCard;