import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import queryString from 'query-string';
import axios from 'axios';

import PasswordChangeSucc from '../components/Auth/PasswordChangeSucc';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SubmitButton from '../components/Buttons/submitButton';

import config from '../config/api.config';

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  & > p:last-of-type {
    color: #e0303a;
  }
  & > a:last-of-type {
    font-size: 22px;
    color: #e0303a;
    cursor: pointer;
  }

  .password-box {
    text-align: center;
  }

  .password-box span {
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 10% auto;
  font-size: 18px;
`;
const PasswordInput = styled(StyledInput)``;

const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start !important;
  margin-bottom: 5px;
`;

const Heading = styled.h1`
  color: rgba(232, 55, 65, 1);
  font-size: 26px;
  text-align: center;
  margin-bottom: 50px;
`;

export default function ResetPassword() {
  const [visible, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const [values, setValues] = useState({
    token: '',
    email: '',
    confirmPassword: '',
    password: ''
  });

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  useEffect(() => {
    localStorage.clear();
    const query_string = queryString.parse(window.location.search);
    setValues({
      ...values,
      email: query_string.email,
      token: query_string.token
    });
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${config.apiUrl}/Authentication/ResetPassword`, {
        token: values.token,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword
      })
      .then(function (response) {
        setLoading(false);
        // console.log(response);
      })
      .catch((error) => {
        if (error.response) {
          //do something
          setLoading(false);
          console.log(error.response);
        }
      });
    setVisibility(true);
  };

  useEffect(() => {
    if (values.password && values.confirmPassword) setValid(true);
    else setValid(false);
  }, [values.password, values.confirmPassword]);

  return (
    <div>
      <Helmet>
        <title>spiderjob - Passwort neu starten</title>
      </Helmet>
      <Header />
      {visible ? (
        <PasswordChangeSucc></PasswordChangeSucc>
      ) : (
        <div className="main-container">
          <Container>
            <Heading>Gib dein neues Passwort ein</Heading>
            <Label>Passwort*</Label>
            <PasswordInput
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
            />
            <Label>Passwort - Wiederholung*</Label>
            <PasswordInput
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
            />
            <div className="mb-5 password-box">
              <span className="mb-0 text-center op-8">
                Das Passwort muss enthalten: mindestens 8 Zeichen, sowohl Groß-
                als auch Kleinbuchstaben, eine Mischung aus Buchstaben und
                Zahlen.
              </span>
            </div>
            <ButtonContainer>
              <SubmitButton
                buttonClicked={loading}
                onClick={onSubmit}
                valid={valid}
                type="submit"
              >
                Absenden
              </SubmitButton>
            </ButtonContainer>
          </Container>
        </div>
      )}
      <Footer />
    </div>
  );
}
