import React from 'react';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';

export function CustomModal({ open, onClose, className, children, ...props }) {
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const classes = useStyles({ ...props, isExtraSmall, isSmall, isMedium });

  return (
    <Modal open={open} onClose={onClose} className={`${classes.modalWrapper} ${className}`}>
      <div className={classes.paper}>{children}</div>
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  paper: {
    position: 'absolute',
    width: (props) => {
      const { width, small, isExtraSmall, isSmall, isMedium, fixed600 } = props;
      if (width) {
        return width;

      } else if(fixed600) {
        return '600px'
      }
      
      else if (isExtraSmall) {
        return small ? '90%' : '95%';
      } else if (isSmall) {
        return small ? '75%' : '85%';
      } else if (isMedium) {
        return small ? '80%' : '85%';
      } else {
        return small ? '55%' : '70%';
      } 

      // return props.width ? props.width : props.small ? '50%' : '70%';
    },
    height: (props) => {
      const { height, smallHeight, small, isSmall, isExtraSmall } = props;

      if (height) {
        return height;
      } 
      else if (smallHeight) {
        return '40em';
      } else if (small) {
        return '75%';
      } else {
        return '80%';
      }
    },
    maxWidth: (props) => {
      if (props.maxWidth) {
        return props.maxWidth;
      } else {
        return props.small ? '750px' : props.large ? '1200px' : '900px';
      }
    },

    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 10px 0px #96969640',
    borderRadius: '30px',
    outline: 'none',
    overflowY: 'hidden',
    maxHeight: '800px'
  }
}));

export default CustomModal;
