import { Chip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFour } from '../../../../store/stepsSlice';
import SubmitButton from '../../../Buttons/submitButton';
import { ProfileInput } from '../StepOne';
import styles from '../StepFour.module.scss';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';

const ApplicantHobbies = () => {
  const dispatch = useDispatch();
  const hobbies = useSelector((state) => state.steps.stepFour.hobbies);

  const [hobbie, setHobbie] = useState('');
  const handleHobies = (_) => {
    dispatch(updateStepFour({ hobbies: [...hobbies, hobbie] }));
    setHobbie('');
  };
  return (
    <>
      <form className={styles.hobbiesForm} onSubmit={handleHobies}>
        <ProfileInput
          style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
          label="Was sind deine Interessen & Hobbys ?"
          name="hobies"
          value={hobbie}
          onChange={(e) => setHobbie(e.target.value)}
        />
        <SubmitButton
          style={{ margin: '20px 0'}}
          onClick={handleHobies}
          valid={hobbie}
          type="submit"
          className="create-profile-hobies"
        >
          Hinzufügen
        </SubmitButton>
      </form>

      <ComponentWrapper
        fluid
        noPaddingHorizontal
        noPaddingVertical
        displayFlex
        flexWrap
      >
        {hobbies.map((hobbie) => (
          <Chip
            color="secondary"
            clickable
            key={hobbie}
            label={hobbie}
            onDelete={() => {
              const temp = hobbies.filter((x) => x != hobbie);
              dispatch(updateStepFour({ hobbies: temp }));
            }}
            variant="outlined"
          />
        ))}
      </ComponentWrapper>
    </>
  );
};

export default ApplicantHobbies;
