import React from 'react';
import { Paragraph } from './Region';
import PropTypes from 'prop-types';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFive } from '../../../../store/stepsSlice';

export default function CheckBox({
  values,
  value = null,
  createProfile,
  setStartingDate,
  edit
}) {
  const dispatch = useDispatch();
  const startingDateId = useSelector(
    (state) => state.steps.stepFive.startingDateId
  );

  const handleChange = (e) => {
    if (edit) {
      setStartingDate(e.target.value);
    } else {
      dispatch(updateStepFive({ startingDateId: e.target.value }));
    }
  };

  return (
      <ComponentWrapper fluid displayFlex alignCenter noPadding>
        <Paragraph>
          {createProfile
            ? 'Wann ist dein frühestmöglicher Eintrittstermin?'
            : 'Ab wann besetzen Sie Ihre Stelle?'}
        </Paragraph>
        <FormControl fullWidth>
          <Select
          style={{marginLeft: '5px'}}
           className='create-profile-select'
            required
            defaultValue=""
            color="secondary"
            name="land"
            value={startingDateId || value}
            onChange={handleChange}
          >
            {values?.map((item) => (
              <MenuItem value={item.StartingDateId} key={item.StartingDateId}>
                {item.Type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ComponentWrapper>
  );
}

CheckBox.propTypes = {
  values: PropTypes.array
};
CheckBox.defaultProps = {
  values: []
};
