import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  p {
    font-size: 11px;
    color: #a2a2a2;
  }
`;

export default function PasswordExplain() {
  return (
    <Container>
      <p>
        Das Passwort muss enthalten: mindestens 8 Zeichen, sowohl Groß- als auch
        Kleinbuchstaben, eine Mischung aus Buchstaben und Zahlen.
      </p>
    </Container>
  );
}
