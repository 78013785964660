import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import SuccessfulRegisteredImg from '../../assets/images/successfulRegistered.png';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    width: 100%;
    max-width: 300px;
  }
`;

const Heading = styled.h1`
  color: #494949;
  font-size: 26px;
`;

const SuccessfulRegistered = () => {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container className="main-container">
        <Heading className="mb-0 text-center">
        Sie haben sich erfolgreich registriert. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink.
        </Heading>
        <div className="my-5">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/login')}
          >
            Zurück zur Anmeldung
          </Button>
        </div>
        <img src={SuccessfulRegisteredImg} alt="Successfully registered"></img>
      </Container>
      <Footer />
    </>
  );
};

export default SuccessfulRegistered;
