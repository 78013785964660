export const DeviceSize = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
  newLarge: 1200,
  iPadPro: 1024,
  newMedium: 992,
  newSmall: 798,
  newExtraSmall: 576
};
