import React from 'react';
import styled from 'styled-components';
import ErrorImage from '../assets/images/somethingwentwrong.png';
import BackgroundImage from '../assets/images/background_error.svg';
import { Link } from 'react-router-dom';

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${BackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  width: 55%;
  height: 80%;
  & > h1 {
    color: #3a3a3a;
    font-size: 38px;
    font-style: normal;
    font-weight: 900;
    line-height: 57px;
    letter-spacing: 0em;
    margin-bottom: 5%;
  }
  & > a {
    text-decoration: none !important;
    margin-top: 5%;
    background-color: red;
    color: white;
    padding: 1% 2%;
    border-radius: 10px;
    cursor: pointer;
  }
`;

export default function ErrorPage(props) {
  return (
    <MainContainer>
      <CenterContainer>
        <h1>OOPS...SOMETHING WENT WRONG</h1>
        <img src={ErrorImage} alt="oops" />
        <Link to={props.location.url}>
          <p>Try again</p>
        </Link>
      </CenterContainer>
    </MainContainer>
  );
}
