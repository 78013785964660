import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import ContactPage from './Pages/ContactPage';
import Imprint from './Pages/Imprint';
import Voluntering from './Pages/Voluntering';
import LoginPage from './Pages/LoginPageNew';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import Done from './Pages/Done';
import Register from './Pages/Register';
import NotFound from './Pages/NotFound';
import Privacy from './Pages/Privacy';
import Aplicants from './Pages/Aplicants';
import Beiträge from './Pages/Beiträge';
import SingleBlog from './Pages/SingleBlog';
import EditProfile from './Pages/EditProfile';
import RegisterCompany from './Pages/RegisterCompany';
import Matching from './Pages/Matching';
import Arbeitgeber from './Pages/Arbeitgeber';
import ErrorPage from './Pages/ErrorPage';
import UserDashboard from './Pages/UserDashboard/Home';
import Home from './Pages/CompanyDashboard/Home';
import JobsDashboard from './Pages/UserDashboard/JobsDashboard';
import BlogsDashboard from './Pages/UserDashboard/BlogsDashboard';
import JobAd from './Pages/CompanyDashboard/JobAd';
import Blogs from './Pages/CompanyDashboard/Blogs';
import Applications from './Pages/CompanyDashboard/Applications';
import Profile from './Pages/CompanyDashboard/Profile';
import Logos from './Pages/CompanyDashboard/Logos';
import Gallery from './Pages/CompanyDashboard/Gallery';
import Locations from './Pages/CompanyDashboard/Locations';
import SuccessfulRegistered from './components/Auth/SuccessfulRegistered';
import VerifyEmail from './components/Auth/VerifyEmail';
import UserApplications from './Pages/UserDashboard/Applications';
import AccountSettings from './Pages/UserDashboard/AccountSettings';
import ProfileSettings from './Pages/UserDashboard/ProfileSettings/ProfileSettings';
import AGB from './Pages/Agb';
import EmailChanged from './components/Auth/EmailChanged';
import Partner from './Pages/Partner';
import PartnerDetail from './Pages/PartnerDetails';
import Unsubscribe from './Pages/Unsubscribe';
import config from './config/api.config.js';
import JobPage from './Pages/JobPage';
import PrivateRoute, {
  PrivateRedirectRoute,
  LoginRedirectRoute,
  DefaultRoute
} from './utils/PrivateRoute';
import { AllJobs } from './Pages/AllJobs';
import CompanyProfile from './Pages/CompanyProfile';
import { store } from './store/store.js';
import { Provider } from 'react-redux';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  palette: {
    primary: {
      main: '#E83741'
    },
    secondary: {
      main: '#E83741'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: '0.02857em'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '4px 4px 5px'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Montserrat'
  }
});

function App() {
  const [language, setLanguage] = useState('en');
  const value = { language, setLanguage };

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Switch>
            <DefaultRoute
              path={`${config.publicUrl}/nicht-gefunden`}
              component={NotFound}
            />
              <Route exact path="/" render={() => <Redirect to="/bewerber" />} />
            <DefaultRoute
              path={`${config.publicUrl}/bewerber`}
              component={Aplicants}
            />
            <DefaultRoute path={`${config.publicUrl}/beiträge`} component={Beiträge} />
            <DefaultRoute path={`${config.publicUrl}/jobs`} component={AllJobs} />
            <DefaultRoute exact path={`${config.publicUrl}/partner-und-kooperationen`} component={Partner} />
            <DefaultRoute exact path={`${config.publicUrl}/partner-und-kooperationen/:name`} component={PartnerDetail} />
            <DefaultRoute
              path={`${config.publicUrl}/ehrenamt`}
              component={Voluntering}
            />
            <DefaultRoute
              exact
              path={`${config.publicUrl}/arbeitgeber`}
              component={Arbeitgeber}
            />
            <DefaultRoute path={`${config.publicUrl}/error`} component={ErrorPage} />
            {/*Auth */}
            <LoginRedirectRoute
              path={`${config.publicUrl}/login`}
              component={LoginPage}
            />
            <LoginRedirectRoute
              path={`${config.publicUrl}/profil-anlegen`}
              component={Register}
            />
            <LoginRedirectRoute
              path={`${config.publicUrl}/profil-anlegen-arbeitgeber`}
              component={Register}
              companyRegister={true}
            />
            <DefaultRoute
              path={`${config.publicUrl}/konto/erfolgreich-überprüfung`}
              component={VerifyEmail}
            />
            <DefaultRoute
              path={`${config.publicUrl}/konto/email-geändert`}
              component={EmailChanged}
            />
            <PrivateRedirectRoute
              path={`${config.publicUrl}/profil-stammdaten`}
              component={EditProfile}
              value={value}
              type="user"
            />
            <DefaultRoute
              path={`${config.publicUrl}/erfolgreich-registriert`}
              component={SuccessfulRegistered}
            />
             <DefaultRoute path={`${config.publicUrl}/unsubscribe`} component={Unsubscribe} />

            <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard`}
              component={UserDashboard}
              type="user"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard/bewerber`}
              component={UserApplications}
              type="user"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard/account-einstellungen`}
              component={AccountSettings}
              type="user"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard/profileinstellungen`}
              component={ProfileSettings}
              type="user"
            />
             <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard/jobs`}
              component={JobsDashboard}
              type="user"
            />
             <PrivateRoute
              exact
              path={`${config.publicUrl}/bewerber-dashboard/blogs`}
              component={BlogsDashboard}
              type="user"
            />

            {/*Company Pages */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/kostenloses-erstgespräch`}
              component={RegisterCompany}
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard`}
              component={Home}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/jobs`}
              component={JobAd}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/blogeinträge`}
              component={Blogs}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/bewerber`}
              component={Applications}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/profil`}
              component={Profile}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/logo`}
              component={Logos}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/konto`}
              component={AccountSettings}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/galerie`}
              component={Gallery}
              type="company"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/arbeitgeber-dashboard/standorte`}
              component={Locations}
              type="company"
            />

            {/*Organization Pages */}

            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard`}
              component={Home}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/jobs`}
              component={JobAd}
              type="organization"
            />

            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/bewerber`}
              component={Applications}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/profil`}
              component={Profile}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/logo`}
              component={Logos}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/konto`}
              component={AccountSettings}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/galerie`}
              component={Gallery}
              type="organization"
            />
            <PrivateRoute
              exact
              path={`${config.publicUrl}/organisations-dashboard/standorte`}
              component={Locations}
              type="organization"
            />

            {/*DONE*/}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/erledigt`}
              component={Done}
            />

            {/*Restart Password */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/passwort-vergessen`}
              component={ForgotPassword}
            />
            <DefaultRoute
              exact
              path={`${config.publicUrl}/passwort-zurücksetzen`}
              component={ResetPassword}
            />
            {/*Single Blog */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/blogeintrag/:title`}
              component={SingleBlog}
            />

            {/*single  Job Ad */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/job/:id`}
              component={JobPage}
            />

            {/*  company profile */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/arbeitgeber/:name`}
              component={CompanyProfile}
            />
            <DefaultRoute
              exact
              path={`${config.publicUrl}/match`}
              component={Matching}
            />

            <DefaultRoute
              path={`${config.publicUrl}/kontakt`}
              component={ContactPage}
            />
            {/*Hilfe */}
            <DefaultRoute
              exact
              path={`${config.publicUrl}/datenschutz-bestimmungen`}
              component={Privacy}
            />
            <DefaultRoute
              exact
              path={`${config.publicUrl}/impressum`}
              component={Imprint}
            />
            <DefaultRoute exact path={`${config.publicUrl}/agb`} component={AGB} />
            {/*Linkedin */}
            <DefaultRoute
              path={`${config.publicUrl}/login-mit-linkedin`}
              component={() => {
                window.location.href = 'https://example.com/';
                return null;
              }}
            />
            <Route path="*" render={() => <Redirect to="/bewerber" />} />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
