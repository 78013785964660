import React, { useEffect, useRef, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import { Input, Row } from '../CreateProfile/Steps';
import { FormControl, MenuItem, Select } from '@mui/material';
import SubmitButton from '../Buttons/submitButton';
import PostData from '../../api/PostData';
import validator from 'validator';
import { ErrorText } from '../Common/Input';
import ImageWithDelete from '../Dashboard/ImageWithDelete';
// import upload from '../../assets/images/upload.svg';
import UploadFile from '../../api/UploadFile';
import auth from '../../auth';
import GetData from '../../api/GetData';
import Checkbox from '@mui/material/Checkbox';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import defaultPhoto from '../../assets/images/ProfileImage.png';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


export const ContactPersonModal = (props) => {
  const [offices, setoffices] = useState([
    ...new Map(
      props.locations?.map((item) => [item['OfficeId'], item])
    ).values()
  ]);

  const [personValues, setpersonValues] = useState({
    name: props.data.LastName || '',
    vorname: props.data.FirstName || '',
    title: props.data.Title || '',
    email: props.data.Email || '',
    position: props.data.Position || '',
    location: props.data.Location || '',
    userBranchId: props.data.UserBranchId || '',
    userId: props.data.UserId,
    branchId: props.data.BranchId,
    contactPersonId: props.data.ContactPersonId,
    phone: props.data.Phone || null
  });
  const [errors, seterrors] = useState({
    name: '',
    vorname: '',
    title: '',
    email: '',
    position: '',
    location: ''
  });
  //file upload
  const [files, setfiles] = useState(null);

  const [mainConstactPerson, setMainConstactPerson] = useState(
    props.data.IsMain || false
  );

  const [openAlert, setOpenAlert] = useState(false);

  const onFileChange = (e) => {
    //remove old image/object from browser memory
    if (files?.url) {
      URL.revokeObjectURL(files.url);
    }

    if (e.target.files.length > 0) {
      setfiles({
        ...files,
        file: e.target.files[0],
        name: e.target.files[0].name,
        url: URL.createObjectURL(e.target.files[0])
      });
    }
  };

  const fileRef = useRef();

  const getImageFile = async (id) => {
    const fileRes = await GetData(`file/${id}`);
    setfiles({
      // file: fileRes.FileName,
      name: fileRes.OriginalName,
      url: fileRes.Url,
      fileId: fileRes.FileId
    });
  };

  //
  const [disabled, setdisabled] = useState(true);
  const [isLoading, setisLoading] = useState(false);

  const handleInputChange = (name, value) => {
    setpersonValues({ ...personValues, [name]: value });
  };
  const onSubmit = async () => {
    setisLoading(true);
    try {
      let fileRes;
      if (files?.file) {
        const formData = new FormData();
        formData.append('File', files.file);
        formData.append('Type', 'ContactPerson');
        formData.append('UserId', auth.getId());

        //if edit and if uploaded new file; if user didn't upload new image files.file will be just string
        //recieved from server at getImageFile()
        if (
          props.edit &&
          files.FileId &&
          (files.file instanceof File || files.file instanceof Blob)
        ) {
          fileRes = await UploadFile(`file/${files.fileId}`, formData, 'PUT');
        } else {
          fileRes = await UploadFile('File', formData);
        }
      }

      let personData = {
        fileId: fileRes?.FileId || files?.fileId || null,
        firstName: personValues.vorname,
        position: personValues.position,
        mobile: null,
        phone: personValues.phone,
        email: personValues.email,
        lastName: personValues.name
        // title: personValues.title
      };

      let person;
      if (props.edit) {
        person = await PostData(
          `ContactPerson/${personValues.contactPersonId}`,
          personData,
          'PUT'
        );
      } else {
        person = await PostData('ContactPerson', personData);
      }

      let selectedlocation = offices.filter(
        (x) => x.OfficeId === personValues.branchId
      );

      let newUserBranchData = {
        userId: selectedlocation[0].UserId,
        officeId: selectedlocation[0].OfficeId,
        contactPersonId: person.ContactPersonId,
        isMain: mainConstactPerson
      };

      let userBranchid;

      if (props.edit) {
        userBranchid = props.data.UserBranchId;
      }

      if (props.edit) {
        await PostData(
          `UsersBranches/${userBranchid}`,
          newUserBranchData,
          'PUT'
        );
      } else {
        let response = await PostData(`UsersBranches`, newUserBranchData);
        console.log(response);
      }

      setisLoading(false);
      props.closeModal();
    } catch (error) {
      console.log('🚀 ~ error', error);
    }
  };

  useEffect(() => {
    if (props.edit && props.data.FileId) {
      getImageFile(props.data.FileId);
    }
  }, [props.edit]);

  //Validation of the modal
  useEffect(() => {
    let newErrors = { ...errors };
    let validfields = 0;

    //Name - min length 3
    if (personValues.name) {
      if (!validator.isLength(personValues.name, { min: 3 })) {
        newErrors.name = 'Name muss mindestens 3 Zeichen haben';
      } else {
        newErrors.name = '';
        validfields++;
      }
    }

    //vorname -min length 3
    if (personValues.vorname) {
      if (!validator.isLength(personValues.vorname, { min: 3 })) {
        newErrors.vorname = 'Vorname muss mindestens 3 Zeichen haben';
      } else {
        newErrors.vorname = '';
        validfields++;
      }
    }

    //email - is email
    if (personValues.email) {
      if (!validator.isEmail(personValues.email)) {
        newErrors.email = 'Ungültige E-Mail';
      } else {
        newErrors.email = '';
        validfields++;
      }
    }

    //position - min length 3
    if (personValues.position) {
      if (!validator.isLength(personValues.position, { min: 3 })) {
        newErrors.position = 'Position muss mindestens 3 Zeichen haben';
      } else {
        newErrors.position = '';
        validfields++;
      }
    }

    //location - min length 3
    if (personValues.branchId) {
      // if (!validator.isNumeric(personValues.userBranchId)) {
      //   newErrors.location = 'Dieses Feld wird benötigt';
      // } else {
      //   newErrors.location = '';
      // }
      validfields++;
    }

    if (validfields === 5) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
    seterrors(newErrors);
  }, [
    personValues.name,
    personValues.vorname,
    personValues.title,
    personValues.email,
    personValues.position,
    personValues.userBranchId,
    files
  ]);
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <>
      <ComponentWrapper displayFlex justifyCenter noPadding flexWrap={isSmall}>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Vorname*
          </Paragraph>
          <Input
            type="text"
            name="vorname"
            id="vorname"
            required
            value={personValues.vorname}
            noFill
            onChange={(e) => handleInputChange('vorname', e.target.value)}
          />
          <ErrorText>{errors.vorname}</ErrorText>
        </ComponentWrapper>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Name*
          </Paragraph>
          <Input
            type="text"
            name="name"
            id="name"
            required
            value={personValues.name}
            noFill
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          <ErrorText>{errors.name}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper displayFlex justifyCenter noPadding flexWrap={isSmall}>
        {/* <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Titel
          </Paragraph>
          <FormControl fullWidth>
            <Select
              color="secondary"
              name="title"
              value={personValues.title}
              onChange={(e) => {
                handleInputChange('title', e.target.value);
              }}
            >
              {titles &&
                titles.map((title) => (
                  <MenuItem value={title.TitleName} key={title.TitleID}>
                    {title.TitleName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <ErrorText>{errors.title}</ErrorText>
        </ComponentWrapper> */}
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Email*
          </Paragraph>
          <Input
            type="text"
            name="email"
            id="email"
            value={personValues.email}
            noFill
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
          <ErrorText>{errors.email}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper displayFlex justifyCenter noPadding flexWrap={isSmall}>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Position*
          </Paragraph>
          <Input
            type="text"
            required
            name="position"
            id="position"
            value={personValues.position}
            noFill
            onChange={(e) => handleInputChange('position', e.target.value)}
          />
          <ErrorText>{errors.position}</ErrorText>
        </ComponentWrapper>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Standorte*
          </Paragraph>

          <FormControl fullWidth>
            <Select
              required
              defaultValue=""
              color="secondary"
              value={personValues.branchId}
              onChange={(e) => {
                handleInputChange('branchId', e.target.value);
              }}
            >
              {offices.map((item) => (
                <MenuItem value={item.OfficeId} key={item.OfficeId}>
                  {item.Office.BranchType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ErrorText>{errors.location}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper displayFlex justifyStart noPadding flexWrap={isSmall}>
        <ComponentWrapper
          halfFluid={!isSmall}
          fluid={isSmall}
          noPaddingTop
          noPaddingHorizontal={isExtraSmall}
        >
          <Paragraph grey small>
            Telefon
          </Paragraph>
          <Input
            type="text"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^\+?\d+$]/g, '');
              e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
            }}
            name="phone"
            id="phone"
            required
            value={personValues.phone}
            noFill
            onChange={(e) => handleInputChange('phone', e.target.value)}
          />
          {/* <ErrorText>{errors.name}</ErrorText> */}
        </ComponentWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={mainConstactPerson}
            onClick={() => {
              if(mainConstactPerson) {
                setMainConstactPerson(false);
              } else
              setOpenAlert(true);
            }}
          />
          <div>Firmenansprechpartner</div>
        </div>
      </ComponentWrapper>
      <ComponentWrapper noPaddingVertical noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          Bild hochladen
        </Paragraph>
        <div
          style={{
            height: '180px',
            // backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {files?.url ? (
            <ImageWithDelete
              src={files.url}
              key={files.name}
              handleDelete={() => {
                URL.revokeObjectURL(files.url);
                setfiles({ fileId: files.fileId });
                setfiles({ fileId: null });
              }}
            />
          ) : (
            <img
              src={defaultPhoto}
              alt="upload"
              style={{
                width: '180px',
                height: '170px',
                objectFit: 'contain',
                boxShadow:
                  '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                borderRadius: 10
              }}
            />
          )}
        </div>
        <ComponentWrapper noPaddingHorizontal paddingVeticalHalf>
          <Row noJustify>
            <input
              type="file"
              hidden
              onChange={onFileChange}
              ref={fileRef}
              accept="image/*"
            />
            <SubmitButton
              small
              inversed={true}
              onClick={() => {
                fileRef.current.click();
              }}
            >
              Hochladen
            </SubmitButton>
          </Row>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper
        fluid
        displayFlex
        alignCenter
        justifyCenter
        noPaddingVertical
      >
        <SubmitButton
          onClick={onSubmit}
          disabled={disabled}
          buttonClicked={isLoading}
        >
          {props.edit ? 'Änderungen speichern' : 'Neue hinzufügen'}
        </SubmitButton>
      </ComponentWrapper>

      <Dialog open={openAlert}>
        <DialogContent>
          <DialogContentText>
            Der aktuelle Ansprechpartner wird auf Unternehmenseben durch diese
            Aktion ausgetauscht. Sind Sie sicher, dass Sie den Ansprechpartner
            ändern möchten? Dies wirkt sich nur auf Unternehmensebene aus.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenAlert(false);
              setMainConstactPerson(true);
            }}
          >
            Ja
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenAlert(false);
              setMainConstactPerson(false);
            }}
          >
            Nein
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ContactPersonModal.propTypes = {};
