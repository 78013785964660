import React from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { Button } from '@mui/material';
import CustomModal from '../components/modal/Modal';
import { Flex } from '../components/Common/Input';
import { Error } from '../components/navbar/navLinks';

import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import Snackbar from '../components/snackbar/snackbar';

import axios from 'axios';
import { useEffect } from 'react';

import { hash } from '../utils/hash';
import config from '../config/api.config';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const RegisterNew = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const current_timestamp = Math.floor(new Date() / 1000);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState('');
  const [seePassRegister, setSeePassRegister] = useState(false);

  const [valid, setValid] = useState(false);

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const access_token =
    'EAAHVkjGN0ZA0BAHxgilcj3WOua1Ea2iuZBEbzHsp0mio6iIvmwXZAtxL2ReT15e1LspKNlb3mDZBsfej2CTqKCS2bDwOPJHx1jpZAFEWfns6Tl0LJukoMxSzi8mOeZAIO3iHIZC8iOQr11TEfzBwsmWIrQZC4EBRzxYsRbFClDmOShVIjlvLqYJF';
  const pixel_id = '400768238535269';

  const onSubmit = async () => {
    setLoading(true);
    await axios.post(
      `https://graph.facebook.com/v12.0/${pixel_id}/events?access_token=${access_token}`,
      {
        data: [
          {
            event_name: 'Registration',
            event_time: current_timestamp,
            action_source: 'website',
            event_source_url: 'https://spiderjob.de/profil-anlegen',
            user_data: {
              em: [hash(values.email)]
            },
            custom_data: {
              user_type: 'Applicant'
            }
          }
        ]
      }
    );
    await axios
      .post(`${config.apiUrl}/Authentication/Register?AppType=Web`, {
        email: values.email,
        password: values.password,
        userType: 1
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        history.push('/login');
        //history.push('/erfolgreich-registriert');
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );
        //if (error.response.data.errors) setErrors(error.response.data.errors);
      });
  };

  useEffect(() => {
    setError('');

    if (values.email != '' && values.password != '') setValid(true);
    else setValid(false);

    if (
      values.password != '' &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(values.password)
    ) {
      setError('Passwort entspricht nicht den Anforderungen');
      setValid(false);
    }
  }, [values.email, values.password]);
  return (
    <>
      {' '}
      <Header></Header>
      <div className="reg-new">
        <Flex column padding h90>
          <Snackbar
            open={snackbar}
            onClose={() => showSnackbar(false)}
            severity="error"
          >
            {snackbarMessage}
          </Snackbar>

          <p className="bolded-p">Neuen Account erstellen</p>
          <p className="simple-p">
            Jetzt bei spiderjob registrieren und den richtigen Job finden
          </p>

          <p className="email">Email</p>
          <input
            className="input-e"
            onChange={handleChange}
            name="email"
            value={values.email}
          ></input>
          <p className="email">Passwort festlegen</p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #CFCFCF'
            }}
          >
            <input
              className="input-e"
              onChange={handleChange}
              value={values.password}
              name="password"
              type={seePassRegister ? 'text' : 'password'}
              style={{ marginBottom: '0px', border: 'none' }}
            ></input>

            <div
              className="inputEye"
              onClick={() => setSeePassRegister(!seePassRegister)}
            >
              {seePassRegister ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
          </div>
          <Error key={'error'}>{error}</Error>

          <p className="pass">
            Das Passwort muss mindestens 8 Zeichen, Groß- und Kleinbuchstaben
            sowie eine Mischung aus Buchstaben und Zahlen enthalten.
          </p>

          <Button
            variant="contained"
            color="primary"
            className="register-b"
            onClick={onSubmit}
            disabled={!valid}
          >
            Registrieren
          </Button>

          <p className="pass">
            Mit deiner Registrierung stimmst du unseren{' '}
            <span className="span-pass">Nutzungsbedingungen</span> zu. Lies dazu
            auch unsere{' '}
            <span
              className="span-pass"
              onClick={() => history.push('/datenschutz-bestimmungen')}
            >
              Datenschutzerklärung
            </span>
            .
          </p>
          <p className="pass">
            In Erfüllung des Nutzungsvertrages schicken wir dir passende Jobs
            und Infos zu ähnlichen Produkten und Services per E-Mail zu.
            Natürlich kannst du jederzeit von unseren einwilligungsfähigen
            E-Mails und Services abmelden oder dein Profil auf “nicht sichtbar”
            stellen.
          </p>
          <div>
            <p className="last-p">
              Du hast schon einen Account?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/login')}
              >
                {' '}
                Jetzt einloggen
              </span>
            </p>
            <p className="last-p">
              Sie sind Arbeitgeber?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/kostenloses-erstgespräch')}
              >
                {' '}
                Hier entlang
              </span>
            </p>
          </div>
        </Flex>
      </div>
      <Footer></Footer>
    </>
  );
};

// Izvoz komponente kako bi se mogla koristiti u drugim delovima aplikacije
export default RegisterNew;
