import React, { useEffect, useState } from 'react';

import styles from './CompanyProfile.module.scss';
import Linkedin from '../../assets/images/linkedinCompany.svg';
import Facebook from '../../assets/images/facebookCompany.svg';
import Instagram from '../../assets/images/instagramCompany.svg';
import Twitter from '../../assets/images/twitterCompany.svg';
import Youtube from '../../assets/images/youtubeCompany.svg';
const SubpageLinks = ({ changeSubpage, active, company, socialNetworks }) => {
  const [linkedin, setLinkedin] = useState(socialNetworks?.linkedin);
  const [facebook, setFacebook] = useState(socialNetworks?.facebook);
  const [twitter, setTwitter] = useState(socialNetworks?.twitter);
  const [instagram, setInstagram] = useState(socialNetworks?.instagram);
  const [youtube, setYoutube] = useState(socialNetworks?.youtube);

  useEffect(() => {
    if (
      socialNetworks?.linkedin != '' &&
      socialNetworks?.linkedin != null &&
      !socialNetworks?.linkedin?.includes('https://')
    ) {
      setLinkedin('https://' + socialNetworks?.linkedin);
    } else setLinkedin(socialNetworks?.linkedin);
    if (
      socialNetworks?.facebook != '' &&
      socialNetworks?.facebook != null &&
      !socialNetworks?.facebook?.includes('https://')
    ) {
      setFacebook('https://' + socialNetworks?.facebook);
    } else setFacebook(socialNetworks?.facebook);

    if (
      socialNetworks?.instagram != '' &&
      socialNetworks?.instagram != null &&
      !socialNetworks?.instagram?.includes('https://')
    ) {
      setInstagram('https://' + socialNetworks?.instagram);
    } else setInstagram(socialNetworks?.instagram);
    if (
      socialNetworks?.twitter != '' &&
      socialNetworks?.twitter != null &&
      !socialNetworks?.twitter?.includes('https://')
    ) {
      setTwitter('https://' + socialNetworks?.twitter);
    } else setTwitter(socialNetworks?.twitter);

    if (
      socialNetworks?.youtube != '' &&
      socialNetworks?.youtube != null &&
      !socialNetworks?.youtube?.includes('https://')
    ) {
      setYoutube('https://' + socialNetworks?.youtube);
    } else setYoutube(socialNetworks?.youtube);
  }, [socialNetworks, instagram, linkedin, facebook, twitter, youtube]);

  return (
    <>
      <div className={styles.linksWrapper}>
        <div>
          <div className={styles.socialMedia}>
            {linkedin && (
              <a href={linkedin} target="_blank">
                <img src={Linkedin}></img>
              </a>
            )}

            {facebook && (
              <a href={facebook} target="_blank">
                <img src={Facebook}></img>
              </a>
            )}
            {instagram && (
              <a href={instagram} target="_blank">
                <img src={Instagram}></img>
              </a>
            )}

            {twitter && (
              <a href={twitter} target="_blank">
                <img src={Twitter}></img>
              </a>
            )}

            {youtube && (
              <a href={youtube} target="_blank">
                <img src={Youtube}></img>
              </a>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            <p
              className={active === 'Unternehmen' ? styles.activeLink : ''}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                changeSubpage('Unternehmen');
              }}
            >
              Unternehmen
            </p>
            <p
              className={active === 'Stellenangebote' ? styles.activeLink : ''}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                changeSubpage('Stellenangebote');
              }}
            >
              {company ? 'Stellenangebote' : 'Ehrenämter'}
            </p>
            <p
              className={active === 'Beiträge' ? styles.activeLink : ''}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                changeSubpage('Beiträge');
              }}
            >
              Beiträge
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubpageLinks;
