import React, { useState, useEffect } from 'react';
import axios from 'axios';

import config from '../../../config/api.config';
import {
  FormControl,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';

import CheckBox from '../../../components/CreateProfile/Steps/StepSixSubcomponents/CheckBox';
import TravelHowFar from '../../../components/CreateProfile/Steps/StepSixSubcomponents/TravelHowFar';
import Modal from '../../../components/modal/Modal';
import SubmitButton from '../../../components/Buttons/submitButton';
import JobCard from '../../../components/ApplicationCards/jobCard';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import Slider from '../../../components/slider/slider';
import patchdata from '../../../api/patchdata';
import GetData from '../../../api/GetData';
import auth from '../../../auth';

const JobDetails = ({ travelingId, startingDateId, shiftId }) => {
  const [jobDetails, setJobDetails] = useState([
    {
      label: 'Eintrittstermin',
      value: null,
      id: null
    },
    {
      label: 'Reisebereitschaft',
      value: null,
      id: null
    },
    {
      label: 'Schichtbetrieb',
      value: null,
      id: null
    },
    {
      label: 'Berufserfahrung',
      value: null,
      id: null
    },
    {
      label: 'Jahresbruttogehalt',
      value: null,
      id: null
    }
  ]);

  const [userDetails, setUserDetails] = useState([
    { PersonnelResponsibility: '' },
    { MinAnnualSalary: '' },
    { MaxAnnualSalary: '' }
  ]);
  const [startingDate, setStartingDate] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [region, setRegion] = useState(null);
  const [openShiftModal, setOpenShiftModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedStarting, setselectedStarting] = useState(null);
  const [salary, setSalary] = useState([50000, 150000]);
  const getStartingDate = async () => {
    const response = await GetData('StartingDate');
    setStartingDate(response);
  };
  const getAllShifts = async () => {
    const response = await GetData('Shift');
    setShifts(response);
  };
  const getAllRegions = async () => {
    const response = await GetData('Region');
    setRegion(response);
  };

  const GetJobDetails = async () => {
    const region =
      travelingId &&
      new Promise(async (resolve, reject) => {
        resolve(await axios.get(`${config.apiUrl}/Region/${travelingId}`));
      });
    const shift =
      shiftId &&
      new Promise(async (resolve, reject) => {
        resolve(await axios.get(`${config.apiUrl}/Shift/${shiftId}`));
      });
    const date =
      startingDateId &&
      new Promise(async (resolve, reject) => {
        resolve(
          await axios.get(`${config.apiUrl}/StartingDate/${startingDateId}`)
        );
      });
    const response = await GetData(`/user/${auth.getId()}`);
    setUserDetails((prevUserDetails) => {
      const updatedUserDetails = [...prevUserDetails];
      updatedUserDetails[0].PersonnelResponsibility =
        response.PersonnelResponsibility;
      return updatedUserDetails;
    });
    setUserDetails((prevUserDetails) => {
      const updatedUserDetails = [...prevUserDetails];
      updatedUserDetails[1].MinAnnualSalary = response.MinAnnualSalary;
      return updatedUserDetails;
    });
    setUserDetails((prevUserDetails) => {
      const updatedUserDetails = [...prevUserDetails];
      updatedUserDetails[2].MaxAnnualSalary = response.MaxAnnualSalary;
      return updatedUserDetails;
    });
    setSalary([userDetails[1].MinAnnualSalary, userDetails[2].MaxAnnualSalary]);

    await Promise.all([region, shift, date, userDetails]).then((res) => {
      const array = res[3];
      setJobDetails([
        {
          label: 'Eintrittstermin',
          value: res[2]?.data.Type,
          id: res[2]?.data.StartingDateId
        },
        {
          label: 'Reisebereitschaft',
          value: res[0]?.data.RegionName,
          id: res[0]?.data.RegionId
        },
        {
          label: 'Schichtbetrieb',
          value: res[1]?.data.Shifts,
          id: res[1]?.data.ShiftId
        },
        {
          label: 'Berufserfahrung',
          value: array[0].PersonnelResponsibility ? 'JA' : 'NEIN',
          id: 150
        },
        {
          label: 'Jahresbruttogehalt',
          value: array[1].MinAnnualSalary + ' - ' + array[2].MaxAnnualSalary + " €",
          id: 151
        }
      ]);

      setSelectedShift(res[1]?.data.ShiftId);
      setSelectedRegion(res[0]?.data.RegionId);
      setselectedStarting(res[2]?.data.StartingDateId);
    });
  };

  const SaveJobDetails = async () => {
    setLoading(true);

    await patchdata('User', [
      {
        path: '/StartingDateId',
        op: 'replace',
        value: jobDetails[0].id
      },
      {
        path: '/TravelingId',
        op: 'replace',
        value: jobDetails[1].id
      },
      {
        path: '/ShiftId',
        op: 'replace',
        value: jobDetails[2].id
      },
      {
        path: '/PersonnelResponsibility',
        op: 'replace',
        value: userDetails[0].PersonnelResponsibility
      },
      {
        path: '/MinAnnualSalary',
        op: 'replace',
        value: userDetails[1].MinAnnualSalary
      },
      {
        path: '/MaxAnnualSalary',
        op: 'replace',
        value: userDetails[2].MaxAnnualSalary
      }
    ])
      .then((res) => {
        setLoading(false);
        setOpenShiftModal(false);
        setUserDetails(userDetails);
        //GetUserDetails();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const EditJobDetails = () => {
    getAllShifts();
    getStartingDate();
    getAllRegions();

    setOpenShiftModal(true);
  };

  const handleJobDetailsEdit = (value, index, type) => {
    const array = jobDetails;
    array[index].value = type;
    array[index].id = value;
    setJobDetails(array);
  };

  const handleJobDetailsEditUser = (index, value) => {
    const array = jobDetails;
    array[index].value = value === 'true' ? 'JA' : 'NEIN';
    setJobDetails(array);
  };
  const handleJobDetailsEditSalary = (index, min, max) => {
    const array = jobDetails;
    array[index].value = min + ' - ' + max + " €";

    setJobDetails(array);
  };

  useEffect(() => {
    GetJobDetails();
  }, [travelingId, startingDateId, shiftId]);

  return (
    <>
      <Modal
        small
        smallHeight
        open={openShiftModal}
        onClose={() => setOpenShiftModal(false)}
      >
        <ComponentWrapper
          fluid
          displayFlex
          flexColumn
          justifyCenter
          alignCenter
          style={{ height: '100%' }}
        >
          <div style={{ width: '100%', padding: '0.6rem 2rem' }}>
            <CheckBox
              edit
              values={startingDate}
              value={selectedStarting}
              // value={jobDetails[0]?.id}
              createProfile={true}
              setStartingDate={(value) => {
                handleJobDetailsEdit(
                  value,
                  0,
                  startingDate.find((d) => d.StartingDateId == value).Type
                );
                setselectedStarting(value);
              }}
            />
          </div>

          <div style={{ width: '100%', padding: '1rem 2rem' }}>
            <Paragraph autoWidth title>
              Schichtbetrieb
            </Paragraph>
            <FormControl fullWidth>
              <Select
                required
                defaultValue=""
                color="secondary"
                value={selectedShift}
                onChange={(e) => {
                  handleJobDetailsEdit(
                    e.target.value,
                    2,
                    shifts.find((d) => d.ShiftId == e.target.value).Shifts
                  );
                  setSelectedShift(e.target.value);
                }}
              >
                {shifts?.map((item) => (
                  <MenuItem value={item.ShiftId} key={item.ShiftId}>
                    {item.Shifts}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              width: '100%',
              padding: '1rem 2rem'
            }}
          >
            <Paragraph autoWidth title>
              Reisebereitschaft
            </Paragraph>
            <TravelHowFar
              createProfile={true}
              data={region}
              value={selectedRegion}
              setRegion={(e) => {
                handleJobDetailsEdit(
                  e.target.value,
                  1,
                  region.find((d) => d.RegionId == e.target.value).RegionName
                );
                setSelectedRegion(e.target.value);
              }}
            />
          </div>
          <div style={{ width: '100%', padding: '1rem 2rem' }}>
            <ComponentWrapper fluid noPaddingVertical>
              <Paragraph grey small>
                Berufserfahrung mit Personalverantwortung
              </Paragraph>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  value={userDetails[0].PersonnelResponsibility}
                  onChange={() => {}}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={<Paragraph>JA</Paragraph>}
                    onChange={() => {
                      handleJobDetailsEditUser(3, 'true');
                      setUserDetails((prevUserDetails) => {
                        const updatedUserDetails = [...prevUserDetails];
                        updatedUserDetails[0].PersonnelResponsibility = true;
                        return updatedUserDetails;
                      });
                    }}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={<Paragraph>NEIN</Paragraph>}
                    onChange={() => {
                      handleJobDetailsEditUser(3, 'false');
                      setUserDetails((prevUserDetails) => {
                        const updatedUserDetails = [...prevUserDetails];
                        updatedUserDetails[0].PersonnelResponsibility = false;
                        return updatedUserDetails;
                      });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </ComponentWrapper>

            <ComponentWrapper fluid>
              <Paragraph grey small>
                Jahresbruttogehalt
              </Paragraph>
              <ComponentWrapper displayFlex alignCenter noPaddingHorizontal>
                <Slider
                  onChange={(e, value) => {
                    setSalary(value);
                  }}
                  onChangeCommitted={(e, value) => {
                    handleJobDetailsEditSalary(4, value[0], value[1]);

                    setUserDetails((prevUserDetails) => {
                      const updatedUserDetails = [...prevUserDetails];
                      updatedUserDetails[1].MinAnnualSalary = value[0];
                      return updatedUserDetails;
                    });
                    setUserDetails((prevUserDetails) => {
                      const updatedUserDetails = [...prevUserDetails];
                      updatedUserDetails[2].MaxAnnualSalary = value[1];
                      return updatedUserDetails;
                    });
                  }}
                  value={salary}
                  style={{
                    maxWidth: '350px',
                    marginRight: '10px',
                    marginTop: '30px'
                  }}
                  step={10000}
                  min={0}
                  max={200000}
                />
                <Paragraph grey small autoWidth>
                  {' '}
                  €{' '}
                </Paragraph>
              </ComponentWrapper>
            </ComponentWrapper>
          </div>
          <SubmitButton
            buttonClicked={loading}
            onClick={(_) => SaveJobDetails()}
            valid={true}
          >
            Absenden
          </SubmitButton>
        </ComponentWrapper>
      </Modal>

      <JobCard
        title="Eintrittsbereitschaft"
        data={jobDetails}
        onClick={EditJobDetails}
      />
    </>
  );
};

export default JobDetails;
