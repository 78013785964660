import styled, { css } from 'styled-components/macro';

const Input = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 30px auto;
  font-size: 16px;
  &:focus {
    border-color: #e30613;
  }
`;

const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const Flex = styled.div`
  display: flex;
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `}

    ${(props) =>
      props.fitContent &&
      css`
        width: fit-content;
      `}

      ${(props) =>
        props.marginAuto &&
        css`
          margin: 0 auto;
        `}

    ${(props) =>
      props.padding &&
      css`
        padding: 30px;
      `}

  ${(props) =>
    props.CenterCenter &&
    css`
      justify-content: center;
      align-items: center;
    `}

    ${(props) =>
    props.jCenter &&
    css`
      justify-content: center;
    `}

    ${(props) =>
      props.itemsCenter &&
      css`
        align-items: center;
      `}
`;

const ErrorText = styled.span`
  font-size: 11px;
  color: #e83741;
`;

export { Input, Label, Flex, ErrorText };
