import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Subtitle from '../components/ForEmployer/Subtitle';
import styled from 'styled-components';
import Image from '../assets/images/logos_Kunden_neu.png';
import ContactForm from '../components/ForEmployer/ContactForm';
import { DeviceSize } from '../components/responsive';

const MainContainer = styled.div`
  h1 {
    font-size: 28px;
    color: #e83741;
    text-align: center;
    width: 100% !important;
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Subcontainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 5%;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    margin-bottom: 100px;
    h2,
    p {
      text-align: center;
      width: 100%;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .company-image img {
    width: 100%;
  }
`;

const Paragraph = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(232, 55, 65, 1);
  margin: 3% 0;
`;

export default function RegisterCompany() {
  return (
    <MainContainer>
      <Header />
      <h1>Jetzt anmelden und qualifizierte Bewerber erhalten</h1>
      <Container>
        <Subcontainer class="test">
          <Subtitle value={'Unverbindliches & kostenloses'} big={true} />
          <Subtitle
            value={'Erstgespräch mit spiderjob'}
            big={true}
            subtitle={true}
          />
          <Paragraph>
            Wir kontaktieren Sie innerhalb 24 Stunden (außer Samstags, Sonn- und
            Feiertags) um mit Ihnen das Budget zu besprechen. Wir helfen Ihnen
            die perfekten Bewerber schnell und effizient zu finden.
          </Paragraph>
          <div className="company-image text-center">
            <img src={Image} alt="company" />
            <div className="mt-4">
              <small>... und viele mehr.</small>
            </div>
          </div>
        </Subcontainer>
        <Subcontainer>
          <ContactForm />
        </Subcontainer>
      </Container>
      <Footer />
    </MainContainer>
  );
}
