import React from 'react';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { formatSalary } from '../../utils/formatSalary';

const PrettoSlider = withStyles({
  root: {
    color: '#e83741',
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor'
  },
  active: {},
  valueLabel: {
    fontSize: '11px',
    height: 20,
    width: 30
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4
  }
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}


const CustomSlider = ({ onChange, defaultValue, ...otherProps }) => {
  return (
    <PrettoSlider
      onChange={onChange}
      components={{ValueLabel: ValueLabelComponent}}
      defaultValue={defaultValue}
      valueLabelDisplay="on"
      valueLabelFormat={value => formatSalary(value)}
      {...otherProps}
    />
  );
};

export default CustomSlider;
