import React from 'react';
import PropTypes from 'prop-types';
import { DashboardTitle } from '../../Pages/CompanyDashboard/Home';
import styled from 'styled-components';
import { DashboardContainer } from '../../Pages/CompanyDashboard/DashboardLayout';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { useHistory } from 'react-router-dom';
import auth from '../../auth';
import { DeviceSize } from '../responsive';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  background-color: rgb(248, 241, 241);
  width: 15%;
  min-width: 175px;
  // position: fixed;
  flex-grow: 1;
  padding: 50px 10px 50px 30px;
  min-height: 100vh;
  ${(props) =>
    props.isMedium &&
    `
    min-height: initial;
    height: auto;
    width: 100%;
    padding: 30px 30px 0;
    `}
  ${(props) =>
    props.isExtraSmall &&
    `
  
      padding: 30px 15px 0;
      `}
`;
const ListItem = styled.li`
  font-size: 15px;
  line-height: 21px;
  color: #818181;
  padding: 6px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: #e83741;
    font-weight: 500;
  }
  ${(props) =>
    props.active &&
    `
  color: #e83741;
    font-weight: 500;
  `}
`;

const SubSidebar = (props) => {
  function getInitialState() {
    return props.storage.toLowerCase();
  }

  function setSelectedOption(option) {
    localStorage.setItem(`SubSidebar`, option);
    setactive(option);
  }
  const [active, setactive] = React.useState(getInitialState());
  const history = useHistory();
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  return (
    <>
      <Container isMedium={isMedium} isExtraSmall={isExtraSmall}>
        <DashboardTitle red>{props.title}</DashboardTitle>
        <ComponentWrapper
          noPaddingHorizontal={!isMedium}
          noPaddingVertical={isMedium}
        >
          <ul style={{ padding: 0, marginTop: isMedium ? '10px' : '0' }}>
            {props.list.map((item, index) => (
              <ListItem
                key={index}
                isMedium={isMedium}
                active={active === item.Link}
                onClick={() => {
                  setSelectedOption(item.Link);
                  auth.isCompany()
                    ? history.push(`/arbeitgeber-dashboard/${item.Link}`)
                    : history.push(`/organisations-dashboard/${item.Link}`);
                }}
              >
                {item.Name}
              </ListItem>
            ))}
          </ul>
        </ComponentWrapper>
      </Container>
    </>
  );
};

SubSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

export default SubSidebar;
