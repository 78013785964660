import React, { useState, useEffect, Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SelectedSkill from './SelectedSkill';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFour } from '../../../../store/stepsSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApplicantLanguages(props) {
  const dispatch = useDispatch();

  const selectedLanguages = useSelector(
    (state) => state.steps.stepFour.languages
  );

  const [search, setSearch] = useState('');
  const [languages, setLanguages] = useState([]);

  const handleLanguage = (event, values, setLevel) => {
    let newValues = [];
    if (setLevel) {
      //from child compoentn the array is already modified and good
      newValues = values;
    } else {
      let deleteOption = selectedLanguages.length > values.length;

      if (deleteOption) {
        //if deleteing one object
        //loop over selected and check values

        for (let index = 0; index < selectedLanguages.length; index++) {
          if (
            values.find(
              (item) => item.LanguageId === selectedLanguages[index].LanguageId
            )
          ) {
            newValues.push(selectedLanguages[index]);
          }
        }
      } else {
        //if adding one object
        //loop over values and check selected
        //add new values if not in selected
        for (let index = 0; index < values.length; index++) {
          if (
            selectedLanguages?.find(
              (item) => item.LanguageId === values[index].LanguageId
            )
          ) {
            newValues.push(selectedLanguages[index]);
          } else {
            newValues.push(values[index]);
          }
        }
      }
    }
    dispatch(updateStepFour({ languages: newValues }));
  };

  useEffect(() => {
    if (props.values) setLanguages(props.values);
  }, [props.values]);

  return (
    <>
      <Autocomplete
        className='create-profile-select'
        filterOptions={(x) =>
        search ?  x.filter((l) => l.Name.toLowerCase().startsWith(search.toLowerCase())) : x
        }
        noOptionsText="Keine Option"
        multiple
        id="language"
        value={selectedLanguages}
        isOptionEqualToValue={(option, value) => {
          return option.LanguageId === value.LanguageId;
        }}
        options={languages.sort((a, b) => -b.Name.localeCompare(a.Name))}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.Name}
        onChange={(e, values) => {
          handleLanguage(e, values, false);
          setSearch('')
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.LanguageId} style={{fontSize:"14px"}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.Name}
          </li>
        )}
        style={{ width: '100%', margin: '10px 0' }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder= {props.createProfile
              ? 'Welche Sprachen sprichst du?'
              : 'Welche Sprachen muss Ihr Bewerber beherrschen?'}
          />
        )}
      />
      {selectedLanguages &&
        selectedLanguages.map((lang, index) => (
          <Fragment key={lang.LanguageId}>
            <SelectedSkill
              values={lang}
              title={lang.Name}
              data={props.levels}
              allValues={selectedLanguages}
              setValue={handleLanguage}
              index={index}
              selectedValue={lang.secondId || ''}
            />
          </Fragment>
        ))}
    </>
  );
}
