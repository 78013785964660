import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import close from '../../assets//images/close-icon-red.svg';

const DeleteContainer = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
`;
const ImageBlock = styled.div`
  position: relative;
  width: 40%;
  height: 150px;
  ${(props) => props?.width && ` width: ${props.width} `};
  ${(props) => props?.height && ` height: ${props.height} `}
`;

const ImageWithDelete = (props) => {
  return (
    <ImageBlock width={props.width} height={props.height}>
      {props.src.split(".").pop() !== "mp4" ? 
      <img
        src={props.src}
        alt="cover"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          borderRadius: 10,
          cursor: 'pointer'
        }}
        onClick={props.onClick}
      />
      :
         <video
          style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          borderRadius: 10,
          cursor: 'pointer'
        }}
        onClick={props.onClick}
         >
            <source src={props.src} type="video/mp4" />
          </video>
    }
      <DeleteContainer>
        <img
          src={close}
          alt="delete"
          width="26"
          height="26"
          onClick={props.handleDelete}
          style={{
            cursor: 'pointer'
          }}
        />
      </DeleteContainer>
    </ImageBlock>
  );
};

ImageWithDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired
};

export default ImageWithDelete;
