import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import GetData from '../api/GetData';
import CircularLoader from '../components/Loader/CircularLoader';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import SocialShare from '../components/SocialShare/SocialShare';
import { ReactComponent as BackIcon } from '../assets/images/icon-back.svg';
import '../assets/scss/singleBlog.scss';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon } from 'react-share';

export default function SingleBlog() {
  const queryParameters = new URLSearchParams(window.location.search);
  const param = queryParameters.get('param');
  const [blogUserPhoto, setblogUserPhoto] = useState(null);
  const [blogUser, setblogUser] = useState(null);
  const [blog, setblog] = useState({});
  const [loading, setloading] = useState(true);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setloading(true);
      const res = await GetData(`blogs/${params.title.split('-').pop()}`);
      setblogUser(res.User);
      setblogUserPhoto(res.CompanyImageUrl);
      setblog({
        Title: res.Title,
        BlogText: res.BlogText,
        File: res.File
      });
      setloading(false);
    })();
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <>
          <ComponentWrapper minHeight={'80vh'} displayFlex alignCenter>
            <CircularLoader small />
          </ComponentWrapper>
        </>
      ) : (
        // <div className='single-blog'>
        //   <div className='blog-image'>
        //   <img src={blog?.File?.Url} alt="Blog image" />
        //   </div>
        //   <div className='blog-text-position'/>
        //   <div className='blog-text'>
        //     <div className='blog-logo'>
        //     <Link to={`/arbeitgeber/${encodeURIComponent(blogUser?.Name.replace("+", ""))}-${blogUser?.Id}`}>
        //       <img
        //         src={blogUserPhoto}
        //         alt="Logo"/>
        //     </Link>
        //     </div>
        //    {/* {param != "userDash" && <button
        //     onClick={() => history.goBack()}
        //     className='blog-button--back'><BackIcon/> Zurück</button>} */}
        //     <div className="socShare">
        //     <SocialShare />
        //     </div>

        //     <h2>{blog.Title}</h2>
        //     <p>{blog.BlogText}</p>
        //   </div>
        // </div>
        <div className='wrapDiv'>
          <div className="blogHeader">
            <div className="logoAndTitle">
              <div className="blog-logo">
                <Link
                  to={`/arbeitgeber/${encodeURIComponent(
                    blogUser?.Name.replace('+', '')
                  )}-${blogUser?.Id}`}
                >
                  <img src={blogUserPhoto} alt="Logo" />
                </Link>
              </div>
              <div className="blogTitle">{blog.Title}</div>
            </div>

            <div className={'socialButtons'}>
              <FacebookShareButton
                url={window.location.href}
                style={{ marginBottom: '5px' }}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton url={window.location.href}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>
          <div className="blogText">{blog.BlogText}</div>
          <div>
            {' '}
            <img src={blog?.File?.Url} alt="Blog image"  className='blogPhoto' />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}
