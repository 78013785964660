import React, { useState } from 'react';
import styled from 'styled-components';
import { DeviceSize } from '../../components/responsive';
import { BlogModal, ModalOuter } from '../modal';
import { MoreHoriz, Delete, Edit, Visibility } from '@mui/icons-material';
import { Menu, MenuItem, IconButton, ListItemIcon } from '@mui/material';
import { useHistory } from 'react-router-dom';

const Card = styled.div`
  max-width: 330px;
  width: 330px;
  flex: 1;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-shadow: 10px 13px 20px 0 rgba(31, 29, 13, 0.2);
  height: 430px;
  padding: 25px;
  // &:hover {
  //   box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.01),
  //     0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.02),
  //     0 19.3px 10.9px rgba(0, 0, 0, 0.02), 0 20.8px 28.4px rgba(0, 0, 0, 0.01),
  //     0 65px 60px rgba(0, 0, 0, 0.01);
  //   transition: 0.4s;
  // }

  @media only screen and (max-width: ${DeviceSize.newExtraSmall}px) {
    width: 100%;
  }
  ${(props) =>
    props.autoWidth &&
    `
  width:auto;
  `}
`;

const ImageDiv = styled.div`
  height: 140px;
  width: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > img {
    width: 53px;
    height: 41px;
    object-fit: cover;
  }
`;

const BlogTitle = styled.h3`
  font-size: 16px;
  color: #1f1d0d;
  font-weight: bold;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 15px;
`;

const TextAndLink = styled.div`
  height: 133px;
  width: 100%;
  text-overflow: ellipsis;
  overflow-y: hidden;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const BlogText = styled.p`
  color: #929292;
  font-size: 13px;
  margin-bottom: 30px;
  overflow-wrap: anywhere;
`;
const ViewMore = styled.a`
  color: #fff;
  position: absolute;
  background-color: #e71e2c;
  width: 55%;
  height: 24px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -10px;

  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;
export default function BlogCard(props) {
  const [modalOpen, setmodalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  return (
    <>
      <Card
        style={{ cursor: 'pointer' }}
        autoWidth={props.autoWidth}
        oneThirdWidth={props.oneThirdWidth}
        onClick={() => {
            history.push({
              pathname: `/blogeintrag/${encodeURIComponent(
                props.blog.Title.replace('+', '')
              )}-${props.blog.BlogsId}`,
              state: {
                blog: props.blog
              }
            });
        }}
      >
        <ImageDiv>
          <img src={props.value.src} alt="blog" />
        </ImageDiv>
        <Heading>
          <img
            src={props.blog.ProfileImage}
            alt="Logo"
            style={{
              borderRadius: '50%',
              objectFit: 'contain',
              width: '90px',
              height: '90px',
              position: 'absolute',
              backgroundColor: '#fff',
              border: 'solid 2px #e71e2c',
              margin: '0 auto',
              left: '0',
              right: '0',
              top: '120px'
            }}
          />
          <BlogTitle>{props.value.title}</BlogTitle>
        </Heading>
        <TextAndLink>
          <BlogText>{props.value.text}</BlogText>
        </TextAndLink>
        <div className="text-center">
          {props.dashboard || props.isOwner ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
              style={{ position: 'absolute', right: 0, bottom: 0 }}
            >
              {Boolean(anchorEl) ? (
                <MoreHoriz color="secondary" />
              ) : (
                <MoreHoriz />
              )}
            </IconButton>
          ) : (
            <ViewMore>Mehr lesen</ViewMore>
          )}
        </div>
      </Card>
      <ModalOuter
        open={modalOpen}
        onClose={() => setmodalOpen(false)}
        title="Blogeinträge"
        option="Edit"
      >
        <BlogModal
          edit
          text={props.value.text}
          title={props.value.title}
          file={props.value.file}
          id={props.value.id}
          closeModal={() => {
            setmodalOpen(false);
            props.value.closeModal();
          }}
        />
      </ModalOuter>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuItem
          //selected={option === 'Pyxis'}
          onClick={() =>
            history.push({
              pathname: `/blogeintrag/${encodeURIComponent(
                props.blog.Title.replace('+', '')
              )}-${props.blog.BlogsId}`,
              state: {
                blog: props.blog
              }
            })
          }
        >
          <ListItemIcon>
            <Visibility />
          </ListItemIcon>
          Sicht
        </MenuItem>
        <MenuItem
          //selected={option === 'Pyxis'}
          onClick={() => setmodalOpen(true)}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Bearbeiten
        </MenuItem>
        <MenuItem onClick={props.onDelete} style={{ color: '#e83741' }}>
          <ListItemIcon>
            <Delete color="primary" />
          </ListItemIcon>
          Löschen
        </MenuItem>
      </Menu>
    </>
  );
}
