import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import { Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import SubmitButton from '../../components/Buttons/submitButton';
import auth from '../../auth';
import UploadFile from '../../api/UploadFile';
import DeleteData from '../../api/DeleteData';
import GetData from '../../api/GetData';
import Snackbar from '../../components/snackbar/snackbar';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import CircularLoader from '../../components/Loader/CircularLoader';

const Logos = () => {
  const [isLoadingHeader, setisLoadingHeader] = useState(false);
  const [isLoadingProfile, setisLoadingProfile] = useState(false);

  const [HeaderPhoto, setHeaderPhoto] = useState(null);
  const [ProfilePhoto, setProfilePhoto] = useState(null);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMsg, setsnackbarMsg] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  var HeaderfileRef = useRef();
  var ProfilefileRef = useRef();

  const UserId = auth.getId();

  const onFileChange = async (event, file, setloader) => {
    if (event.target.files.length > 0) {
      setloader(true);
      const formData = new FormData();
      formData.append('File', event.target.files[0]);
      //formData.append('Type', 'Upload image');
      formData.append('UserId', UserId);

      try {
        await UploadFile(`File/${file.FileId}`, formData, 'PUT');

        getImage('Header', setHeaderPhoto);
        getImage('Profile', setProfilePhoto);
        setloader(false);
        setsnackbarMsg('Erfolgreich aktualisiert');
        setsnackbar(true);
      } catch (error) {
        console.log('🚀 ~ error', error);
        setloader(false);
        setsnackbarMsg('Serverfehler, bitte versuchen Sie es erneut');
        setsnackbar(true);
      }
    }
  };
  const onDelete = async (id, type) => {
    await DeleteData('File', id);
    getImage('Header', setHeaderPhoto);
    getImage('Profile', setProfilePhoto);
  };

  async function getImage(type, setvalue) {
    const response = await GetData(`File?UserId=${UserId}&Type=${type}`);

    setvalue(response[0]);
  }
  useEffect(() => {
    (async () => {
      setisLoading(true);

      const res = await Promise.allSettled([
        GetData(`File?UserId=${UserId}&Type=Header`),
        GetData(`File?UserId=${UserId}&Type=Profile`)
      ]);
      setHeaderPhoto(res[0].value[0]);
      setProfilePhoto(res[1].value[0]);
      setisLoading(false);
    })();
  }, []);

  const uploadFile = (type) => {
    if (type === 'Header') {
      HeaderfileRef.current.click();
    } else {
      ProfilefileRef.current.click();
    }
  };

  return (
    <DashboardLayout noPadding sidebar>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title={'Ihr Profil'}
          list={[
            { Name: 'Basisdaten', Link: 'profil' },
            { Name: 'Logo & Header-Grafik', Link: 'logo' },
            { Name: 'Galerie', Link: 'galerie' },
            { Name: 'Passwort und Konto', Link: 'konto' }
          ]}
          storage={'logo'}
        />
        {isLoading ? (
          <ComponentWrapper
            minHeight="100vh"
            displayFlex
            alignCenter
            justifyCenter
            fluid
          >
            <CircularLoader />
          </ComponentWrapper>
        ) : (
          <ComponentWrapper
            fluid
            padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
          >
            <Snackbar
              open={snackbar}
              onClose={() => setsnackbar(false)}
              severity="success"
            >
              {snackbarMsg}
            </Snackbar>
            <Row>
              <DashboardTitle red> Header Bild</DashboardTitle>
            </Row>

            <ComponentWrapper noPaddingHorizontal>
              <Row>
                <img
                  src={HeaderPhoto && HeaderPhoto.Url}
                  alt="header"
                  width="100%"
                  height="300"
                  style={{
                    objectFit: 'cover',
                    borderRadius: 10,
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                  }}
                />
              </Row>
              <ComponentWrapper center noPaddingBottom>
                <Row center noJustify>
                  <SubmitButton
                    buttonClicked={isLoadingHeader}
                    onClick={() => uploadFile('Header')}
                    valid={true}
                    type="submit"
                  >
                    Ändern
                  </SubmitButton>
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      onFileChange(e, HeaderPhoto, setisLoadingHeader)
                    }
                    ref={HeaderfileRef}
                    multiple
                    accept="image/*"
                  />
                  <SubmitButton onClick={() => onDelete(HeaderPhoto.FileId)}>
                    Löschen
                  </SubmitButton>
                </Row>
              </ComponentWrapper>
            </ComponentWrapper>
            <Row>
              <DashboardTitle red> Logo</DashboardTitle>
            </Row>
            <ComponentWrapper noPaddingHorizontal>
              <Row>
                <div
                  style={{
                    width: isExtraSmall ? '100%' : isMedium ? '50%' : '33%'
                  }}
                >
                  <img
                    src={ProfilePhoto && ProfilePhoto.Url}
                    alt="header"
                    width="100%"
                    height="300"
                    style={{
                      objectFit: 'contain',

                      boxShadow:
                        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                      borderRadius: 10
                    }}
                  />
                  <ComponentWrapper center noPaddingBottom>
                    <Row center noJustify>
                      <SubmitButton
                        small
                        buttonClicked={isLoadingProfile}
                        onClick={uploadFile}
                        valid={true}
                        type="submit"
                      >
                        Ändern
                      </SubmitButton>
                      <SubmitButton
                        small
                        onClick={() => onDelete(ProfilePhoto.FileId)}
                      >
                        Löschen
                      </SubmitButton>
                      <input
                        type="file"
                        hidden
                        onChange={(e) =>
                          onFileChange(e, ProfilePhoto, setisLoadingProfile)
                        }
                        ref={ProfilefileRef}
                        multiple
                        accept="image/*"
                      />
                    </Row>
                  </ComponentWrapper>
                </div>
              </Row>
            </ComponentWrapper>
          </ComponentWrapper>
        )}
      </ComponentWrapper>
    </DashboardLayout>
  );
};

export default Logos;
