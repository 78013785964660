import PostData from '../api/PostData';
import UploadFile from '../api/UploadFile';
import auth from '../auth';

export async function UploadJobAd(data, images, jobId) {
  const UserId = auth.getId();

  var newFile = images;
  //File upload only if image is not already in gallery

  if (images) {
    if (!images?.FileId) {
      const formData = new FormData();
      formData.append('File', images.file);
      formData.append('Type', 'Upload image');
      formData.append('UserId', UserId);

      newFile = await UploadFile('File', formData);
    }
  }

  let jobadData = {
    isActive: true,
    includeMatching: false,
    introductionText: data.introduction,
    tasks: data.yourTasks,
    profil: data.yourProfile,
    offer: data.weOffer,
    closingText: data.closingText,
    matchingId: data.matching ?? null,
    startingDateId: data.startEmployment ?? null,
    officeId: data.branch[0] ?? null,
    jobLocationId: data?.branchData[0]? data?.branchData[0].Location.LocationId : null, 

    office2Id: data.branch[1] ?? null,
    jobLocation2Id: data?.branchData[1]? data?.branchData[1].Location.LocationId : null, 
    office3Id: data.branch[2] ?? null,
    jobLocation3Id: data?.branchData[2]? data?.branchData[2].Location.LocationId : null, 

    photoId: newFile?.FileId ?? null,
    contactId: data.contactId,
    branchId: data.industry ?? null,
    ApplicationUserId: Number(UserId),
    IsVolunteering: auth.isCompany() ? false : true,
    minAnnualSalary: data.minAnnualSalary ?? 0,
    maxAnnualSalary: data.maxAnnualSalary ?? 0,
    jobAdSpecification: data.jobAdSpecification ?? '',
    applicantAddressing: data.applicantAddressing,
    shouldRedirectExternal: data.shouldRedirectExternal === 'spiderjob' ? false : true,
    redirectExternalUrl: data.externalCareerLink
  };

  if (auth.isOrganization()) {
    jobadData = {
      ...jobadData,
      ProfessionText: data.heading.trim(),
      EmploymentText: data.employmentType
    };
  } else {
    jobadData = {
      ...jobadData,
      professionId: data.heading.value,
      employmentIds: data.employmentType || null
    };
  }

  if (jobId) {
    jobadData = {
      ...jobadData,
      jobAdvertisementId:jobId
    };
    const respon =  await PostData(`JobAdvertisement/${jobId}`, jobadData, 'PUT');
    console.log(respon)
  } else {
    const respons = await PostData('JobAdvertisement', jobadData);
    console.log(respons)
  }
}

export default UploadJobAd;
