import React from 'react';
import styled from 'styled-components';
import Computer from '../../assets/images/computer.svg';
import Pencil from '../../assets/images/pencil.svg';
import Upload from '../../assets/images/dashboard-upload.svg';
import Office from '../../assets/images/office.svg';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import config from '../../config/api.config';
import { useHistory } from 'react-router-dom';

const Paragraph = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: #717171;
`;
const Container = styled.div`
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  ${(props) =>
    props.isSmall &&
    `
    padding: 20px 10px;  
    `}
`;

const BlockInside = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5em;
`;

const Statistic = (props) => {
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const history = useHistory();
  return (
    <Container isSmall={isSmall}>
      <ComponentWrapper
        noPadding
        fluid
        fluidHeight
        displayFlex
        justifyAround
        flexWrap={isMedium}
      >
        <ComponentWrapper
          displayFlex
          alignStart
          width="276px"
          padding={isSmall ? '20px 10px' : '20px'}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={Computer}
            alt="job ads"
            onClick={() => history.push('/arbeitgeber-dashboard/jobs')}
          />

          <BlockInside>
            <Paragraph>Stellenanzeigen</Paragraph>
            <span style={{ fontSize: 40, fontWeight: 600, color: '#084B89' }}>
              {props.jobAd}
            </span>
          </BlockInside>
        </ComponentWrapper>
        <ComponentWrapper
          displayFlex
          alignStart
          width="276px"
          padding={isSmall ? '20px 10px' : '20px'}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={Pencil}
            alt="blogs"
            onClick={() =>
              history.push('/arbeitgeber-dashboard/blogeintr%C3%A4ge')
            }
          />
          <BlockInside>
            <Paragraph>Blogbeiträge</Paragraph>
            <span style={{ fontSize: 40, fontWeight: 600, color: '#F37500' }}>
              {props.blogs}
            </span>
          </BlockInside>
        </ComponentWrapper>
        <ComponentWrapper
          displayFlex
          alignStart
          width="276px"
          padding={isSmall ? '20px 10px' : '20px'}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={Upload}
            alt="applications"
            onClick={() => history.push('/arbeitgeber-dashboard/bewerber')}
          />
          <BlockInside>
            <Paragraph>Bewerbungen</Paragraph>
            <span style={{ fontSize: 40, fontWeight: 600, color: '#DF0028' }}>
              {props.applications}
            </span>
          </BlockInside>
        </ComponentWrapper>
        <ComponentWrapper
          displayFlex
          alignStart
          width="276px"
          padding={isSmall ? '20px 10px' : '20px'}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={Office}
            alt="offices"
            onClick={() => history.push('/arbeitgeber-dashboard/standorte')}
          />
          <BlockInside>
            <Paragraph>Standorte</Paragraph>
            <span style={{ fontSize: 40, fontWeight: 600, color: '#00A337' }}>
              {props.office}
            </span>
          </BlockInside>
        </ComponentWrapper>
      </ComponentWrapper>
    </Container>
  );
};
export default Statistic;
