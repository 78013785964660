import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import SentEmailConfirm from '../components/Auth/SentEmailConfirm';
import Snackbar from '../components/snackbar/snackbar';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import config from '../config/api.config';

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > p:last-of-type {
    color: #e0303a;
  }
  & > a:last-of-type {
    font-size: 22px;
    color: #e0303a;
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 10% auto;
  font-size: 18px;
`;
const EmailInput = styled(StyledInput)``;

const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start !important;
  margin-bottom: 5px;
`;

const Heading = styled.h1`
  font-size: 28px;
  color: rgba(232, 55, 65, 1);
`;
const SubHeading = styled.h3`
  color: rgba(142, 143, 143, 1);
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 50px;
`;
const Subcontainer = styled.div`
  width: 100%;
`;

export default function ForgotPassword() {
  const { register, handleSubmit } = useForm();
  const [visible, setVisibility] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState('');
  const [snackbar, showSnackbar] = useState(false);

  const onSubmit = (logindata) => {
    axios({
      method: 'post',
      url: `${config.apiUrl}/Authentication/ForgotPassword`,
      data: {
        email: logindata.email
      }
    })
      .then(function (response) {
        console.log(response);
        setVisibility(true);
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.ERROR[0]);
          setVisibility(false);
          showSnackbar(true);
        }
      });
  };

  function handleChange(e) {
    setEmail(e.target.value);
  }
  return (
    <div>
      <Helmet>
        <title>spiderjob - Passwort vergessen</title>
      </Helmet>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="error"
      >
        {errors}
      </Snackbar>
      <Header />
      {visible ? (
        <SentEmailConfirm email={email} />
      ) : (
        <div className="main-container">
          <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
            <Container>
              <Heading className="text-center">Passwort vergessen?</Heading>
              <SubHeading className="text-center">
                Gebe bitte hier Deine E-Mail Adresse ein!
              </SubHeading>
              <Subcontainer>
                {/* {!issuccessful ? <ErrorMessage message={errors} /> : null} */}
                <Label>E-mail*</Label>
                <EmailInput
                  type="email"
                  name="email"
                  {...register('email')}
                  onChange={handleChange}
                />
              </Subcontainer>
              <Button variant="contained" color="primary" type="submit">
                Absenden
              </Button>
            </Container>
          </form>
          <div className="text-center mb-5">
            <p className="mb-1 op-8">
              Daten werden auf den Systemen des Providers zwischengespeichert.{' '}
            </p>
            <Link
              to="/datenschutz-bestimmungen"
              style={{
                textDecoration: 'none',
                color: '#E30613'
              }}
            >
              Hier können Sie die Datenschutzerklärung einsehen.
            </Link>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
