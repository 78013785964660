import React from 'react';
import {Div} from './HomeIcon'

export const BlogsIcon = (props) => {
  const [hover, sethover] = React.useState('#A7A7A7');
  return (
    <Div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      onMouseEnter={() => sethover('#E83741')}
      onMouseLeave={() => sethover('#A7A7A7')}
    >
      <path
        d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z"
        stroke={props.stroke || hover}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13H7L10 16H14L17 13H20"
        stroke={props.stroke || hover}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
      {props.hoverForParagraph && <p>Blog</p>}</Div>
  );
};
