import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  color: white;
  background: linear-gradient(rgba(254, 140, 76, 0.6), rgba(198, 71, 0, 0.6));
  outline: none;
  border: none;
  height: 60px;
  border-radius: 10px;
  margin: 3% 0;
  font-size: 22px;
`;
export default function ButtonPrivacy(params) {
  return (
    <div>
      <Button>{params.value}</Button>
    </div>
  );
}
