import { Modal } from '@mui/material';
import React from 'react';
import styles from './ModalSteps.module.scss';
import closeImg from '../../../assets/images/close-icon.svg';

/**
 * component used as wrapper (or base) modal for modals used at
 * create profile and matching steps.
 */
const ModalSteps = ({ open, onClose, children, small }) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.modalScreen}>
      <div
        className={
          small
            ? `${styles.modalWrapper} ${styles.smallModal}`
            : styles.modalWrapper
        }
      >
        <div className={styles.borderLeft}></div>
        <img
          src={closeImg}
          alt="X"
          className={styles.closeBtn}
          onClick={onClose}
        />
        <div className={styles.modalContent}>{children}</div>
      </div>
    </Modal>
  );
};

export default ModalSteps;
