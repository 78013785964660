import React from 'react';
import styled from 'styled-components';
import PriceCard from './PriceCard';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5% 0 0;
  padding: 50px 0;
`;
const packages = [
  {
    month: 'AB 199 €',
    value: 'basic',
    subtitle: [
      <p className="jobs-number">
        bis zu <br />
        <strong>2 Stellenanzeigen</strong>
        <br /> parallel
      </p>
    ],
    link: 'https://www.digistore24.com/product/463530'
  },
  {
    month: 'AB 399 €',
    main: true,
    value: 'BUSINESS',
    subtitle: [
      <p className="jobs-number jobs-number-main">
        bis zu <br />
        <strong>5 Stellenanzeigen</strong>
        <br /> parallel
      </p>
    ],
    link: 'https://www.digistore24.com/product/463532'
  },
  {
    month: 'AB 599 €',
    value: 'BUSINESS',
    titleAdd: 'plus',
    subtitle: [
      <p className="jobs-number">
        bis zu <br />
        <strong>15 Stellenanzeigen</strong>
        <br /> parallel
      </p>
    ],
    link: 'https://www.digistore24.com/product/463535'
  },
  {
    month: 'AB 899 €',
    value: 'flat',
    subtitle: [
      <p className="jobs-number">
        unbegrenzte <br />
        <strong>Stellenanzeigen</strong>
        <br /> parallel
      </p>
    ],
    link: 'https://www.digistore24.com/product/463536'
  }
];
export default function Pricing({ id }) {
  return (
    <div style={{marginTop: '200px', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      <p className="second-p">Preise & Pakete</p>
      <Container id={id}>
        {packages.map((_package) => (
          <PriceCard
            month={_package.month}
            value={_package.value}
            titleAdd={_package.titleAdd}
            main={_package.main}
            subtitle={_package.subtitle}
            items={[
              'Arbeitgeberprofil mit Logo',
              'Bild- & Video-Einbindung',
              'Persönlicher Ansprechpartner',
              'Mehrere Standorte',
              'Nachträgliches Bearbeiten möglich',
              'Für alle Mobilgeräte optimiert',
              'Passende Bewerber erhalten',
              'Beiträge posten',
              'Flexible Laufzeit',
              'Sichtbarkeit auf Google für Jobs',
              'Social-Media-Kampagnen',
              'Eigenes Dashboard',
              'Uneingeschränkte Sichtbarkeit',
              'Unbegrenzte Bewerbungen'
            ]}
            link={_package.link}
          />
        ))}
      </Container>
    </div>
  );
}
