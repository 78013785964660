import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExtensionIcon from '@mui/icons-material/Extension';
import Chip from '@mui/material/Chip';
import { Paragraph } from './Region';
import { Row } from '../StepTwo';
import GetData from '../../../../api/GetData';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import { Button } from '@mui/material';
import SubmitButton from '../../../Buttons/submitButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFive } from '../../../../store/stepsSlice';
import ModalSteps from '../ModalSteps';

export const OpenModal = styled.button`
  background-color: #e30613;
  border: none;
  outline: none;
  width: 120px;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
`;

export default function Benefits(props) {
  const dispatch = useDispatch();
  const benefitsArray = useSelector(
    (state) => state.steps.stepFive.benefitsArray
  );

  const [open, setOpen] = useState(false);
  const [loadedbenefits, setloadedbenefits] = useState([]);
  const [selectedBenefits, setselectedBenefits] = useState(benefitsArray);

  useEffect(() => {
    async function getBenefits() {
      const response = await GetData('Benefits');

      const arr = response.filter(
        (x) => !selectedBenefits.find((y) => y.BenefitsId === x.BenefitsId)
      );

      setloadedbenefits(arr);
    }
    getBenefits();
  }, []);

  const removeElement = (niz = null, industrija) => {
    const filteredIndustries = niz.filter(
      (item) => item.BenefitsId !== industrija.BenefitsId
    );
    setloadedbenefits(filteredIndustries);
    return true;
  };
  const removeSelected = (industrija) => {
    const filteredIndustries = selectedBenefits.filter(
      (item) => item.BenefitsId !== industrija.BenefitsId
    );
    setselectedBenefits(filteredIndustries);
  };
  const addLoaded = (industrija) =>
    setloadedbenefits([...loadedbenefits, industrija]);

  const handleClick = (industrija) => {
    removeElement(loadedbenefits, industrija);
    setselectedBenefits([...selectedBenefits, industrija]);
  };

  const body = (
    <>
      <h2 style={{ color: '#E83741' }}>Welche Benefits sind dir wichtig? </h2>
      {loadedbenefits &&
        loadedbenefits.map((indust, index) => (
          <Chip
            color="secondary"
            icon={<ExtensionIcon />}
            clickable
            label={indust.Name}
            key={index}
            onClick={() => {
              handleClick(indust);
            }}
            variant="outlined"
          />
        ))}
      <div style={{ width: '100%', height: '50px' }}></div>
      {selectedBenefits &&
        selectedBenefits.map((industry, index) => (
          <Chip
            color="secondary"
            icon={<ExtensionIcon />}
            clickable
            key={index}
            label={industry.Name}
            onDelete={() => {
              removeSelected(industry);
              addLoaded(industry);
            }}
          />
        ))}
      <ComponentWrapper right>
        <SubmitButton
          onClick={() => {
            dispatch(updateStepFive({ benefitsArray: selectedBenefits }));
            setOpen(false);
          }}
          valid={selectedBenefits?.length > 0}
        >
          Bestätigen
        </SubmitButton>
      </ComponentWrapper>
    </>
  );

  return (
    <>
      <ComponentWrapper noPaddingHorizontal fluid>
        <Row>
          <Paragraph>
            {props.createProfile
              ? 'Welche Benefits sind dir wichtig?'
              : 'Welche Benefits bieten Sie an?'}
          </Paragraph>

          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            style={{ margin: '15px 0' }}
          >
            Wählen
          </Button>
        </Row>
        {!props.createProfile && (
          <Paragraph style={{ fontSize: 14, opacity: 0.6, marginTop: '-1%' }}>
            (Sie können mehrere Benefits auswählen)
          </Paragraph>
        )}
        <div>
          {selectedBenefits &&
            selectedBenefits.map((industry, index) => (
              <Chip
                color="secondary"
                icon={<ExtensionIcon />}
                clickable
                key={index}
                label={industry.Name}
                onDelete={() => {
                  removeSelected(industry);
                  addLoaded(industry);
                  dispatch(updateStepFive({ benefitsArray: selectedBenefits }));
                }}
                variant="outlined"
              />
            ))}
        </div>
      </ComponentWrapper>
      <ModalSteps
        open={open}
        onClose={() => {
          dispatch(updateStepFive({ benefitsArray: selectedBenefits }));
          setOpen(false);
        }}
        className="special-modal"
      >
        {body}
      </ModalSteps>
    </>
  );
}
