import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem',
    textTransform: 'unset',
    top: '0px !important',
    width: '100%',
    height: '45px',
    padding: (props) => (props.small ? '3px 1em' : '6px 16px')
  }
}));
const InversedButton = withStyles({
  root: {
    'backgroundColor': 'white',
    'border': '1px solid #E83741',
    'color': '#E83741',
    'width': '100%',
    'height': '45px',
    '&:hover': {
      backgroundColor: '#E83741',
      color: '#fff',
      border: '1px solid #transparent'
    }
  }
})(Button);

const ColoredButton = withStyles({
  root: {
    'backgroundColor': '#db1c2b',

    'color': '#fff',
    'width': '100% !important',
    'height': '45px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#E83741',
      border: '1px solid #E83741'
    }
  }
})(Button);

const SubmitButton = ({
  onClick,
  className,
  buttonClicked = false,
  valid = true,
  children,
  small,
  inversed = false,
  ...props
}) => {
  const classes = useStyles({ small, ...props });
  return (
    <div className={className} style = {props.specific ? {width: '100%', display: 'flex', justifyContent: 'center'}: {}}>
      {valid && !buttonClicked ? (
        <>
          {inversed ? (
            <InversedButton
              onClick={buttonClicked ? null : onClick}
              variant="outlined"
              className={classes.root}
             
              {...props}
            >
              {children}
            </InversedButton>
          ) : (
            <ColoredButton
              onClick={buttonClicked ? null : onClick}
              variant={'contained'}
              className={classes.root}
            
              {...props}
            >
              {children}
            </ColoredButton>
          )}
        </>
      ) : (
        <Button
          variant={'contained'}
          disabled
       
          className={classes.root}
          {...props}
        >
          {buttonClicked ? (
            <CircularProgress disableShrink color="inherit" size="1.7rem" />
          ) : (
            children
          )}
        </Button>
      )}
    </div>
  );
};

export default SubmitButton;
