import React, { useEffect, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import { Input, Row } from '../CreateProfile/Steps';
import GetData from '../../api/GetData';
import ImageWithDelete from '../Dashboard/ImageWithDelete';
import ModalOuter from './ModalOuter';
import Gallery from 'react-photo-gallery';
import PostData from '../../api/PostData';
import auth from '../../auth';
import SubmitButton from '../Buttons/submitButton';
import UploadFile from '../../api/UploadFile';
import { StyledTextarea } from '../../Pages/ContactPage';
import { ErrorText } from '../Common/Input';
import validator from 'validator';
import upload from '../../assets/images/upload.svg';

export const BlogModal = (props) => {
  const [blogValue, setblogValue] = useState({
    title: props.title || '',
    text: props.text || ''
  });
  const [file, setfile] = useState(props.file || null);
  const [gallery, setGallery] = React.useState([]);
  const [disabled, setdisabled] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [galleryModal, setgalleryModal] = useState(false);
  const [errors, seterrors] = useState({
    title: '',
    text: ''
  });

  var fileRef = React.useRef();
  const UserId = auth.getId();

  async function getGallery() {
    const response = await GetData(`File?UserId=${UserId}&Type=Gallery`);

    let newGallery = [];
    response.forEach((element) => {
      newGallery.push({
        ...element,
        width: 1,
        height: 1,
        src: element.Url
      });
    });
    setGallery(newGallery);
  }
  React.useEffect(() => {
    getGallery();
  }, []);

  const uploadFile = () => {
    fileRef.current.click();
  };
  const onFileChange = (e) => {
    //remove old image/object from browser memory
    if (file?.url) {
      URL.revokeObjectURL(file.url);
    }
    if (e.target.files.length > 0) {
      setfile({
        File: e.target.files[0],
        Name: e.target.files[0].name,
        Url: URL.createObjectURL(e.target.files[0])
      });
    }
  };

  const handleInputChange = (name, value) => {
    setblogValue({ ...blogValue, [name]: value });
  };

  const onSubmit = async () => {
    setisLoading(true);
    try {
      let newFile = file;
      //if there is a photo that is not in gallery yet
      if (file) {
        if (!newFile.FileId) {
          const formData = new FormData();
          formData.append('File', file.File);
          formData.append('Type', 'Upload image');
          formData.append('UserId', UserId);

          newFile = await UploadFile('File', formData);
        }
      }

      let blogData = {
        title: blogValue.title.trim(),
        blogText: blogValue.text,
        fileId: (newFile && newFile.FileId) || null,
        userId: Number(UserId)
      };

      if (props.edit) {
        blogData.blogsid = props.id;
        await PostData(`Blogs/${props.id}`, blogData, 'PUT');
      } else {
        await PostData('Blogs', blogData);
      }

      setisLoading(false);
      //close modal if evereything goes ok
      props.closeModal();
    } catch (error) {
      setisLoading(false);
      console.log('🚀 ~ error', error);
    }
  };

  //Validation of fields
  useEffect(() => {
    let newErrors = { ...errors };
    let validfields = 0;

    //title - min length 3 max 150
    if (blogValue.title) {
      if (!validator.isLength(blogValue.title, { min: 3, max: 150 })) {
        newErrors.title = 'Title muss mindestens 3 Zeichen haben';
      } else {
        newErrors.title = '';
        validfields++;
      }
    }

    //text -min length 10
    if (blogValue.text) {
      if (!validator.isLength(blogValue.text, { min: 10 })) {
        newErrors.text = 'Text muss mindestens 10 Zeichen haben';
      } else {
        newErrors.text = '';
        validfields++;
      }
    }

    if (validfields === 2) {
      setdisabled(false);
    } else setdisabled(true);
    seterrors(newErrors);
  }, [blogValue.title, blogValue.text]);

  return (
    <>
      <ComponentWrapper fluid noPaddingTop>
        <Paragraph grey small noMargin>
          Titel
        </Paragraph>
        <Input
          type="text"
          name="title"
          id="title"
          required
          multiline
          value={blogValue.title}
          noFill
          onChange={(e) => handleInputChange('title', e.target.value)}
        />
        <ErrorText>{errors.title}</ErrorText>
      </ComponentWrapper>
      <ComponentWrapper fluid noPaddingTop>
        <Paragraph grey small noMargin>
          Blogpost-Text
        </Paragraph>

        <StyledTextarea
          value={blogValue.text}
          onChange={(e) => handleInputChange('text', e.target.value)}
          rows="5"
        />
        <ErrorText>{errors.text}</ErrorText>
      </ComponentWrapper>
      <ComponentWrapper>
        <Row noJustify>
          <input
            type="file"
            hidden
            onChange={onFileChange}
            ref={fileRef}
            accept="image/*"
          />
          <SubmitButton small inversed={true} onClick={uploadFile}>
            Hochladen
          </SubmitButton>
          <SubmitButton small inversed={true} onClick={() => setgalleryModal(true)}>
            Aus Galerie hochladen
          </SubmitButton>
        </Row>
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop>
        <div
          style={{
            // height: '11em',
            backgroundColor: file ? 'white' : '#E5C2C2',
            // display: 'flex',
            // alignItems: 'center'
            width: '100%'
          }}
        >
          {file ? (
            <ImageWithDelete src={file.Url} handleDelete={() => setfile()} />
          ) : (
            <img
              src={upload}
              alt="upload"
              style={{ margin: 'auto', display: 'block', maxWidth: '500px' }}
            />
          )}{' '}
        </div>
      </ComponentWrapper>
      <ComponentWrapper padding="15px 10px 0" displayFlex justifyCenter>
        <SubmitButton
          onClick={onSubmit}
          disabled={disabled}
          buttonClicked={isLoading}
        >
          {props.edit ? 'Änderungen speichern' : 'Blogbeitrag hochladen'}
        </SubmitButton>
      </ComponentWrapper>

      <ModalOuter
        open={galleryModal}
        onClose={() => setgalleryModal(false)}
        title="Galerie"
        option="Wählen Sie ein Foto aus"
        small
      >
        <Gallery
          photos={gallery}
          onClick={(e, object) => {
            setfile(object.photo);
            setgalleryModal(false);
          }}
        />
      </ModalOuter>
    </>
  );
};
