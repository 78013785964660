import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const MainContent = styled.div`
  height: 90%;
  width: 100%;
  border-radius: 13px;
  box-shadow: 0 7.5px 17.5px 0 rgba(0, 0, 0, 0.39);
  padding-bottom: 27px;
  padding-top: 70px;
  background-color: ${(props) => (props.main ? '#db1c2b' : '#fff')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > hr {
    width: 80%;
    outline: none !important;
    border: none;
    border-bottom: 2px solid white;
    margin: 3% auto;
  }
`;

export default function PriceCard(props) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="price-card"
      style={{
        transform: props.main && 'scale(1.2)',
        margin: props.main && '0 40px',
        marginBottom: open && '90px'
      }}
    >
      <div className="price-title">
        <p>spider</p>
        <h5>
          {props.value}
          <span>{props.titleAdd}</span>
        </h5>
      </div>
      <MainContent main={props.main}>
        <p className="price" style={{ color: props.main && '#fff' }}>
          {props.month}*<br />
          <span>BUDGET / MONAT</span>
        </p>
        {props.subtitle}

        <div
          className="collapse-button"
          style={{ borderColor: props.main && '#fff' }}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ExpandLess style={{ color: props.main && '#fff' }} />
          ) : (
            <ExpandMore style={{ color: props.main && '#fff' }} />
          )}
        </div>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <ul style={{ color: props.main && '#fff' }}>
            {props.items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Collapse>
        {open && (
          <Button
          className='singleButton'
            variant="contained"
            color={props.main ? 'secondary' : 'primary'}
            onClick={() => window.open(props.link, '_blank')}
          >
            Jetzt schalten
          </Button>
        )}
      </MainContent>
    </div>
  );
}
