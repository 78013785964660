import React from 'react';
import styled from 'styled-components';
import Checked from '../../assets/images/Frame.png';
import JobApp from '../../assets/images/JobApp.svg';
import { DeviceSize } from '../../components/responsive';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 30px;
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    white-space: nowrap;
  }

  div.left-stat {
    border-left: 1px dashed #c7c7c7;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;

    p {
      white-space: normal;
    }

    div.left-stat {
      margin-top: 20px;
      border-left: none;
    }
  }
`;

export default function JobsStats(props) {

  return (
    <Container>
      <SubContainer>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img
            src={Checked}
            alt="checked"
            style={{
              backgroundColor: '#F39200',
              padding: '10px',
              borderRadius: '50px',
              marginRight: '15px'
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
          >
            <p
              style={{ color: '#717171', fontSize: '15px', marginBottom: '0' }}
            >
              Jobs, die Ihren Kriterien entsprechen
            </p>
            <p
              style={{
                color: '#E88B00',
                fontSize: '40px',
                marginBottom: '0',
                lineHeight: '1'
              }}
            >
              {props.numOfTopJobs}
            </p>
          </div>
        </div>
        {/* {isMobile ? null : <img src={ArrowRight} alt="Arrow" /> } */}
      </SubContainer>
      <SubContainer>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <img
            src={JobApp}
            alt="Job app"
            style={{
              marginRight: '15px'
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
          >
            <p
              style={{ color: '#717171', fontSize: '14px', marginBottom: '0' }}
            >
              Bewerbungen insgesamt
            </p>
            <p
              style={{
                color: '#DF0028',
                fontSize: '40px',
                marginBottom: '0',
                lineHeight: '1'
              }}
            >
              {props.applicationCount}
            </p>
          </div>
        </div>
      </SubContainer>
    </Container>
  );
}
