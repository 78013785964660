import React from 'react';
import Footer from '../../src/components/footer/Footer'
import Header from '../../src/components/header/Header';
import { Button } from '@mui/material';
import { Flex } from '../components/Common/Input';
import { Error } from '../components/navbar/navLinks';

import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Snackbar from '../components/snackbar/snackbar';

import axios from 'axios';
import { useEffect } from 'react';
import config from '../config/api.config';

import jwt from 'jwt-decode';
import Link from '@mui/material/Link';
import auth from '../auth';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const LoginPageNew = () => {
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [valuesLogin, setValuesLogin] = useState({
    email: '',
    password: ''
  });
  const history = useHistory();
  const handleChangeLogin = (e) => {
    setValuesLogin({ ...valuesLogin, [e.target.name]: e.target.value });
   
  }

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [validLogin, setValidLogin] = useState(false);
  const [seePassLogin, setSeePassLogin] = useState(false);
  const location = useLocation().search;
  const token = new URLSearchParams(location).get('token');

  const access_token =
  'EAAHVkjGN0ZA0BAHxgilcj3WOua1Ea2iuZBEbzHsp0mio6iIvmwXZAtxL2ReT15e1LspKNlb3mDZBsfej2CTqKCS2bDwOPJHx1jpZAFEWfns6Tl0LJukoMxSzi8mOeZAIO3iHIZC8iOQr11TEfzBwsmWIrQZC4EBRzxYsRbFClDmOShVIjlvLqYJF';
const pixel_id = '400768238535269';


  const loginUser = (token, refreshToken) => {
    if(!token) return;
    const dataJwt = jwt(token);
    if(dataJwt.Role == '' || dataJwt.Role == 'Admin') {
      showSnackbar(true);
      setLoading(false);
      setSnackbarMessage("Benutzername oder Passwort ist falsch!");
      return;
    }
    return auth.login(
      token,
      dataJwt.exp,
      dataJwt.UserId,
      dataJwt.Role,
      dataJwt.VerifiedAccount,
      dataJwt.PaymentId,
      refreshToken,
      (_) => {
        switch (dataJwt.Role) {
          case 'Applicant':
           /* if (dataJwt.VerifiedAccount == 'False')
             // history.push('/profil-stammdaten');
            else*/ history.push('/bewerber-dashboard');
            break;
          case 'Company':
            history.push({
              pathname: '/arbeitgeber-dashboard',
              state: {
                showModal: dataJwt.VerifiedAccount == 'False' ? true : false
              }
            });
            break;
          case 'Organisation':
            history.push({
              pathname: '/organisations-dashboard',
              state: {
                showModal: dataJwt.VerifiedAccount == 'False' ? true : false
              }
            });
            break;
          default:
            history.push('/');
        }
      }
    );
  };
  if (token) {
    loginUser(token);
  }

  const onLogin = async () => {
    setLoading(true);

    await axios
      .post(`${config.apiUrl}/Authentication/Login`, {
        username: valuesLogin.email,
        password: valuesLogin.password
      })
      .then((res) => {
        const token = res.data.Token;
        const refreshToken = res.data.RefreshToken;
        setLoading(false);
        loginUser(token, refreshToken);
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(true);
        setLoading(false);
        setSnackbarMessage(error.response.data.ERROR[0]);
      });
  }

  useEffect(() => {
    setError('');

    if (valuesLogin.email != '' && valuesLogin.password != '') setValidLogin(true);
    
    else setValidLogin(false);

   
  }, [valuesLogin.email, valuesLogin.password]);

  return (
  
    <>
    <Header></Header>
<div className='log-new'>
    <Flex column padding h90>
          <Snackbar
            open={snackbar}
            onClose={() => showSnackbar(false)}
            severity="error"
          >
            {snackbarMessage}
          </Snackbar>

          <p className="bolded-p">Willkommen zurück!</p>
          <p className="simple-p">
            Jetzt einloggen und den richtigen Job finden.
          </p>

          <p className="email">Email</p>
          <input
            className="input-e"
            onChange={handleChangeLogin}
            name="email"
            value={valuesLogin.email}
          ></input>
          <p className="email">Passwort</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #CFCFCF'
            }}
          >
          <input
            className="input-e"
            onChange={handleChangeLogin}
            value={valuesLogin.password}
            name="password"
            type={seePassLogin ? "text" : "password"}
            style={{ marginBottom: '0px' , border: 'none'}}
          ></input>
          <div
              className='inputEye'
              onClick={() => setSeePassLogin(!seePassLogin)}
            >
              {seePassLogin ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
            </div>
          <Error key={'error'}>{error}</Error>

          <Link
            onClick={() => history.push('/passwort-vergessen')}
            underline="hover"
            style={{ fontSize: '13px' }}
          >
            Passwort vergessen?
          </Link>

          <Button
            variant="contained"
            color="primary"
            className="register-b"
            onClick={onLogin}
            disabled={!validLogin}
          >
            Einloggen
          </Button>

          <p className='pass'>Indem du den Button oben klickst, stimmst du unseren Nutzungsbedingungen zu. <br></br>
          Lies auch unsere      <span
              className="span-pass"
              onClick={() => history.push('/datenschutz-bestimmungen')}
            >
              Datenschutzerklärung
            </span>.</p>

          <div>
            <p className="last-p">
            Du hast noch keinen Account bei uns? 
              <span
                className="span-pass"
                onClick={() => history.push('/profil-anlegen')}
              >
                {' '}
                Jetzt registrieren
              </span>
            </p>
          
          </div>
        </Flex>
        </div>
    <Footer></Footer>
    
    </>
  );
};

export default LoginPageNew;