import axios from 'axios';
import config from '../config/api.config';

const PostPublicData = async (url, data, method = 'POST') => {

  return new Promise(async (resolve, reject) => {
    const parsedData = JSON.stringify(data);
    let urlNew = config.apiUrl +"/"+ url;
    try {
      let result;
      if (method == 'PUT') {
        result = await axios.put(urlNew, parsedData, {
          headers: { 'Content-Type': 'application/json' }
        });
      } else if (method == 'POST') {
        result = await axios.post(urlNew, parsedData, {
          headers: { 'Content-Type': 'application/json' }
        });
      }
      resolve(result.data);
    } catch (error) {
      console.log(`Error: ${error}`);
      reject(error);
    }
  });
};

export default PostPublicData;
