import React from 'react';
import { Div } from './HomeIcon';

export const ApplicationIcon = (props) => {
  const [hover, sethover] = React.useState('#A7A7A7');
  return (
    <Div>
      <svg
        style={{ marginLeft: '2px' }}
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill={props.fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        onMouseEnter={() => sethover('#E83741')}
        onMouseLeave={() => sethover('#A7A7A7')}
      >
        <path
          d="M16.659 2.92804H10.6454C10.4013 2.92804 10.1629 2.82781 9.99822 2.65744L8.12186 0.680849C7.67083 0.231269 7.05374 -0.00355884 6.42875 4.07684e-05H2.34101C1.05023 3.65903e-06 0 1.05023 0 2.34097V13.7381C0 15.0289 1.05023 16.0791 2.34101 16.0791H16.6676H16.6697C17.9598 16.0734 19.0057 15.0181 19 13.7309V5.26901C19 3.97824 17.9497 2.92804 16.659 2.92804ZM17.5682 13.7338C17.5703 14.235 17.1644 14.6452 16.6662 14.6473H2.34101C1.83988 14.6473 1.43183 14.2393 1.43183 13.7381V2.34097C1.43183 1.83985 1.83988 1.43179 2.34101 1.43105H6.44738C6.45524 1.43105 6.46311 1.43105 6.47101 1.43105C6.71011 1.43105 6.94208 1.52627 7.09812 1.68091L8.96446 3.64818C9.40258 4.10062 10.0154 4.35979 10.6454 4.35979H16.659C17.1601 4.35979 17.5681 4.76785 17.5681 5.26897V13.7338H17.5682Z"
          stroke={props.stroke || hover}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
         // fill="#A7A7A7"
        />
      </svg>
      {props.hoverForParagraph && (
        <p style={{ marginTop: '0px' }}>Bewerbungen</p>
      )}
    </Div>
  );
};
