import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import styled from 'styled-components';
import ButtonPrivacy from '../components/Privacy/ButtonPrivacy';

export const Container = styled.div`
  .subh-2 {
    font-size: 22px;
  }
`;

export const Heading = styled.h1`
  margin: 0 0 5% 0;
  text-align: center;
  font-weight: 500;
`;

export const SubHeading = styled.h2`
  color: rgba(232, 55, 65, 1);
  margin-top: 2%;
  font-size: 22px;
  &:first-of-type {
    margin-top: 5%;
  }
`;

export const Span = styled.span`
  color: #525066;
  font-size: 20px;
  font-weight: 500;
  display: inline-block !important;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  color: rgba(82, 80, 102, 1);
  margin: 1% 0;
  font-weight: 500;
`;

export const Linke = styled.a`
  color: #f87f3b;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
`;

export const List = styled.ul`
  width: 70%;
  margin: 2% auto;
`;
export const ListItem = styled.li`
  margin: 2% 0;
`;
export default function Privacy() {

  return (
    <div>
      <Helmet>
        <title>spiderjob - Datenschutz</title>
      </Helmet>
      <Header />
      <Container className="main-container">
        <Heading className="heading">Datenschutzerklärung</Heading>
        <Span>
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere
        </Span>
        <Span> der EU-Datenschutzgrundverordnung (DSGVO), ist:</Span>
        <Span></Span>

        <div className="impressumHeader">Widerrufsrecht</div>
        <div>
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondereder
          EU-Datenschutzgrundverordnung (DSGVO), ist: 
        </div>
        <div className="impressumHeader">Ihre Betroffenenrechte</div>
        <div>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </div>
        <br></br>
        <div>
          • Auskunft über Ihre bei uns gespeicherten Daten und deren
          Verarbeitung (Art. 15 DSGVO),
        </div>
        <div>
          • Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
        </div>
        <div>• Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</div>
        <div>
          • Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
          gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
        </div>
        <div>
          • Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
          DSGVO) und
        </div>
        <div>
          • Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
          eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
          (Art. 20 DSGVO).
        </div>
        <br></br>
        <div>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen. Eine Liste der
          Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift
          finden Sie diesem Link
        </div>
        <div className="impressumHeader">
          Erfassung allgemeiner Informationen beim Besuch unserer Website
        </div>
        <div> Art und Zweck der Verarbeitung: </div>
        <br></br>
        <div>
          Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
          registrieren oder anderweitig Informationen übermitteln, werden
          automatisch Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Ser- vice-Providers, Ihre IP-Adresse und ähnliches.
        </div>
        <br></br>
        <div>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</div>
        <br></br>
        <div>
          • Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
        </div>
        <div>• Sicherstellung einer reibungslosen Nutzung unserer Website,</div>
        <div>• Auswertung der Systemsicherheit und -stabilität sowie</div>
        <div>• zu weiteren administrativen Zwecken.</div>
        <br></br>
        <div>
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Informationen dieser Art werden von uns ggfs. statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </div>
        <br></br>
        <div className="impressumHeader">Rechtsgrundlage:</div>
        <div>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an der Verbes- serung der Stabilität
          und Funktionalität unserer Website.
        </div>
        <div className="impressumHeader">Empfänger:</div>
        <div>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
          werden.
        </div>
        <div className="impressumHeader">Speicherdauer:</div>
        <div>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist für die Daten, die der
          Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </div>
        <div className="impressumHeader">
          Bereitstellung vorgeschrieben oder erforderlich:
        </div>
        <div>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorge- schrieben. Ohne die IP-Adresse ist
          jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
          gewährleis- tet. Zudem können einzelne Dienste und Services nicht
          verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein
          Widerspruch ausgeschlossen.
        </div>

      </Container>
      <Footer />
    </div>
  );
}
