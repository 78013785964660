const getCoordinates = async (address) => {
  const geocoder = new window.google.maps.Geocoder();
  try {
    const res = await geocoder.geocode({ address });
    const location = res.results[0].geometry.location;
    return { lat: location.lat(), lng: location.lng() };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default getCoordinates;
