import React from 'react';

import Chip from '@mui/material/Chip';
import SchoolIcon from '@mui/icons-material/School';

import { ModalOuter } from '../../../components/modal';
import SubmitButton from '../../../components/Buttons/submitButton';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';

const Modal = ({
  open,
  modalTitle,
  setOpen,
  loadedChips,
  selectedChips,
  handleClick,
  removeSelected,
  loading,
  SaveChanges
}) => {
  return (
    <ModalOuter
      showDot={false}
      open={open}
      title={modalTitle}
      onClose={() => setOpen(false)}
    >
      <div style={{ height: '100%', position: 'relative' }}>
        <div style={{ marginBottom: '50px' }}>
          {loadedChips?.map((indust) => (
            <Chip
              color="secondary"
              icon={<SchoolIcon />}
              clickable
              key={indust?.id}
              label={indust?.name}
              onClick={() => {
                handleClick(indust);
              }}
              variant="outlined"
            />
          ))}
        </div>
        <div>
          {selectedChips?.map((chip) => (
            <Chip
              color="secondary"
              icon={<SchoolIcon />}
              clickable
              key={chip.id}
              label={chip.name}
              onDelete={() => {
                removeSelected(chip);
              }} />
          ))}
        </div>
        <ComponentWrapper displayFlex justifyCenter fluid>
          <SubmitButton
            buttonClicked={loading}
            onClick={() => SaveChanges(modalTitle)}
            valid={true}
          >
            Speichern
          </SubmitButton>
        </ComponentWrapper>
      </div>
    </ModalOuter>
  );
};

export default Modal;
