import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../auth';
import "../index.css";

export const PrivateRedirectRoute = ({
  component: Component,
  type,
  ...rest
}) => {
  const renderRoute = (props, type) => {
    if (auth.isAuthenticated() && type === 'user') {
      return auth.isApplicant() && !auth.isVerified() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      );
    }
    return <Redirect to="/login" />;
  };
  return <Route {...rest} render={(props) => renderRoute(props, type)} />;
};

export const LoginRedirectRoute = ({ component: Component, ...rest }) => {
  const renderRoute = (props, rest) => {
    if (!auth.isAuthenticated()) {
      return <Component {...props} {...rest} />;
    } else {
      return (
        <Redirect
          to={
            auth.isApplicant()
              ? '/bewerber-dashboard'
              : auth.isOrganization()
              ? '/organisations-dashboard'
              : '/arbeitgeber-dashboard'
          }
        />
      );
    }
  };

  return <Route {...rest} render={(props) => renderRoute(props, rest)} />;
};

const PrivateRoute = ({ component: Component, type, ...rest }) => {
  const renderRoute = (props, type) => {
    if (auth.isAuthenticated()) {
      if (type === 'company') {
        return auth.isCompany() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }
      if (type === 'user') {
        return auth.isApplicant() ? (
            <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }
      if (type === 'organization') {
        return auth.isOrganization() ? (
          <Component {...props} isOrganization />
        ) : (
          <Redirect to="/login" />
        );
      }
    }
    return <Redirect to="/login" />;
  };

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={(props) => renderRoute(props, type)} />
  );
};

export const DefaultRoute = ({
  component: Component,
  type,
  ...rest
}) => {

  const renderRoute = (props, type) => ( <Component {...props} />);

  return <Route {...rest} render={(props) => renderRoute(props, type)} />;
};

export default PrivateRoute;
