import React, { useEffect, useState } from 'react';
import { ModalOuter } from '../../../components/modal';
import SelectAsync from '../../../components/select/selectAsync';
import { Button } from '@mui/material';
const ModalVocational = ({ open, update, selectedItems, onClose }) => {
  const [itemsArray, setitemsArray] = useState([]);

  useEffect(() => {
    const arr = selectedItems.map((item) => {
      return {
        label: item.Profession.ProfesionName,
        value: item.Profession.ProfessionId,
        ApplicantDesiredProfessionId: item.ApplicantDesiredProfessionId
      };
    });
    setitemsArray(arr);
  }, [selectedItems]);

  return (
    <>
      <ModalOuter
        open={open}
        title={'Ausbildung'}
        showDot={false}
        onClose={() => {
          update(itemsArray);
          onClose();
        }}
      >
        <>
          <div>
            <SelectAsync
              value={itemsArray}
              option={'Profession/Paging'}
              onChange={(valuesArr) => setitemsArray(valuesArr)}
              isMulti={true}
              placeholder={'Mehr hinzufügen'}
            />
          </div>
          <Button
          variant="contained"
          color="primary"
          style={{ position: 'absolute', margin: '0 auto', bottom: '0px', left: '0px', right: '0px'}}
          onClick={() => {
            update(itemsArray);
            onClose();
          }}
          disabled={false}
        >
          Speichern
        </Button>
        </>
      </ModalOuter>
    </>
  );
};

export default ModalVocational;
