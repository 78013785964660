import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import config from '../../../config/api.config';
import patchdata from '../../../api/patchdata';
import JobCard from '../../../components/ApplicationCards/jobCard';
import { GoogleAutocomplete } from '../../../components/GoogleAutocomplete';
import { Paragraph } from '../../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import Slider from '../../../components/slider/slider';
import SubmitButton from '../../../components/Buttons/submitButton';
import Modal from '../../../components/modal/Modal';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';

const JobRegion = ({ jobRegionId, classes }) => {
  const [jobDetails2, setJobDetails2] = useState(null);
  const [openJobModal, setOpenJobModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [umkreis, setUmkreis] = useState(0);

  const GetJobRegion = async () => {
    if (jobRegionId)
      await axios
        .get(`${config.apiUrl}/Location/${jobRegionId}`)
        .then((res) => setJobDetails2(res.data))
        .catch((err) => console.error(err));
  };

  const SaveJobRegion3 = async () => {
    setLoading(false);
    if (!jobRegionId) {
      await axios
        .post(`${config.apiUrl}/Location`, {
          streetNumber: jobDetails2.StreetNumber?.toString(),
          streetName: jobDetails2.StreetName,
          district: jobDetails2.District,
          city: jobDetails2.City,
          country: jobDetails2.Country,
          postalCode: jobDetails2.PostalCode,
          latitude: jobDetails2.Latitude?.toString(),
          longitude: jobDetails2.Longitude?.toString(),
          formattedAddress: jobDetails2.FormattedAddress,
          radius: jobDetails2.Radius.toString()
        })
        .then(async (res) => {
          await patchdata('User', [
            {
              path: '/JobRegionId',
              op: 'replace',
              value: res.data.LocationId
            }
          ]);
          setLoading(false);
          setOpenJobModal(false);
          GetJobRegion();
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      await axios
        .put(`${config.apiUrl}/Location/${jobRegionId}`, {
          streetNumber: jobDetails2.StreetNumber?.toString(),
          streetName: jobDetails2.StreetName,
          district: jobDetails2.District,
          city: jobDetails2.City,
          country: jobDetails2.Country,
          postalCode: jobDetails2.PostalCode,
          latitude: jobDetails2.Latitude?.toString(),
          longitude: jobDetails2.Longitude?.toString(),
          formattedAddress: jobDetails2.FormattedAddress,
          radius: jobDetails2.Radius.toString()
        })
        .then((res) => {
          setLoading(false);
          setOpenJobModal(false);
          GetJobRegion();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  // const SaveJobRegion2 = async () => {
  //   setLoading(true);
  //   await axios
  //     .put(`${config.apiUrl}/Location/${jobRegionId}`, {
  //       streetNumber: jobDetails2.StreetNumber?.toString(),
  //       streetName: jobDetails2.StreetName,
  //       district: jobDetails2.District,
  //       city: jobDetails2.City,
  //       country: jobDetails2.Country,
  //       postalCode: jobDetails2.PostalCode,
  //       latitude: jobDetails2.Latitude?.toString(),
  //       longitude: jobDetails2.Longitude?.toString(),
  //       formattedAddress: jobDetails2.FormattedAddress,
  //       radius: jobDetails2.Radius.toString()
  //     })
  //     .then((res) => {
  //       setLoading(false);
  //       setOpenJobModal(false);
  //       GetJobRegion();
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setLoading(false);
  //     });
  // };

  const handleRadius = (_, newValue) => {
    setJobDetails2({ ...jobDetails2, Radius: newValue });
  };

  const onGoogleSelected = (place) => {
    setJobDetails2({
      ...jobDetails2,
      StreetNumber: place?.streetNumber,
      StreetName: place?.streetName,
      District: place?.district,
      City: place?.city,
      Country: place?.country,
      PostalCode: place?.postalCode,
      Latitude: place?.latitude,
      Longitude: place?.longitude,
      FormattedAddress: place?.formattedAddress
    });
  };

  useEffect(() => {
    if (jobRegionId) GetJobRegion();
  }, [jobRegionId]);

  return (
    <>
      <Modal
        small
        smallHeight
        open={openJobModal}
        onClose={() => setOpenJobModal(false)}
      >
        {' '}
        <p
          style={{
            padding: '20px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '22px'
          }}
        >
          In welchem Umkreis suchen Sie?{' '}
        </p>
        <ComponentWrapper
          fluid
          displayFlex
          flexColumn
          justifyCenter
          alignCenter
          style={{ height: '100%' }}
        >
          <div style={{ padding: '1rem 2rem', width: '100%' }}>
            <Paragraph title>Job region</Paragraph>
            <GoogleAutocomplete
              onPlaceSelected={onGoogleSelected}
              defaultValue={jobDetails2?.City}
              type="(regions)"
            />
          </div>
          <div
            style={{
              padding: '1rem 2rem',
              width: '100%',
              marginBottom: '6rem'
            }}
          >
            <Paragraph title>Umkreis</Paragraph>
            <Slider
              className="job-region__slider"
              onChange={handleRadius}
              defaultValue={parseInt(jobDetails2?.Radius === undefined ? 0 : jobDetails2?.Radius)}
            />
          </div>
          <SubmitButton
            buttonClicked={loading}
            onClick={(_) => SaveJobRegion3()}
            valid={true}
          >
            Speichern
          </SubmitButton>
        </ComponentWrapper>
      </Modal>

      <JobCard
        title="Umkreissuche"
        classes={classes}
        data={[
          {
            label: 'Stadt',
            value: jobDetails2?.City
          },
          {
            label: 'Land',
            value: jobDetails2?.Country
          },
          {
            label: 'Umkreis',
            value: (jobDetails2?.Radius === undefined ? 0 : jobDetails2?.Radius ) + ' km'
          }
        ]}
        onClick={() => setOpenJobModal(true)}
      />
    </>
  );
};

export default JobRegion;
