import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { IconButton, Tooltip, Fade } from '@mui/material';
import InfoButton from '../../../../assets/images/infoButtonIcon.svg';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3)
  }
}));

export default function ErrorRadios(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
    props.napromjenu(event.target.value);
  };

  useEffect(() => {
    setValue(props.selectedValue?.toString());
  }, [props.selectedValue]);

  return (
    <FormControl
      component="fieldset"
      error={error}
      className={classes.formControl}
      style={props.style}
    >
      <RadioGroup
        row={props.isColumn ? false : true}
        aria-label="quiz"
        name={props.name ? props.name : 'quiz'}
        value={value}
        onChange={handleRadioChange}
      >
        <div style={{ display: 'flex' }}>
          <FormControlLabel
            value={props.value1}
            control={<Radio />}
            label={props.label1}
          />
          {props.info && (
            <Tooltip
              title={
                'Sie möchten die spiderjob-Matching-Software für Ihre Stellenanzeigen verwenden und ein passgenaues Matching in Anspruch nehmen? Dann wählen Sie diese Option aus.'
              }
              placement="top"
              enterTouchDelay="1400ms"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                style={{
                  padding: 8,
                  height: '22px',
                  width: '22px',
                  margin: 'auto'
                }}
                onClick={(e) => {}}
                size="large"
              >
                <img src={InfoButton} />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div style={{display: 'flex'}}>

        <FormControlLabel
          value={props.value2}
          control={<Radio />}
          label={props.label2}
        />
         {props.info && (
            <Tooltip
              title={
                'Sie möchten Ihr eigenes Stellenportal verlinken? Bitte tragen Sie die URL in das dafür vorgesehene Feld ein.'
              }
              placement="top"
              enterTouchDelay="1400ms"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                style={{
                  padding: 8,
                  height: '22px',
                  width: '22px',
                 marginTop: 'auto',
                 marginBottom: 'auto'
                }}
                onClick={(e) => {}}
                size="large"
              >
                <img src={InfoButton} />
              </IconButton>
            </Tooltip>
          )}
        </div>

        {props.label3 && (
          <FormControlLabel
            value={props.value3}
            control={<Radio />}
            label={props.label3}
          />
        )}
      </RadioGroup>
    </FormControl>
  );
}
