import React, { useEffect } from 'react';
import CustomStepper from '../components/CreateProfile/SideBar/Koraci2';
import StepOne from '../components/CreateProfile/Steps/StepOne';
import StepTwo from '../components/CreateProfile/Steps/StepTwo';
import StepThree from '../components/CreateProfile/Steps/StepThree';
import StepFour from '../components/CreateProfile/Steps/StepFour';
import StepFive from '../components/CreateProfile/Steps/StepFive';
import Loader from '../components/Loader/loader';
import { useDispatch } from 'react-redux';
import { clearState } from '../store/stepsSlice';
import "../assets/scss/createProfile.scss";

export const stepsLabel = [
  'Persönliche Daten',
  'Berufserfahrung',
  'Schulische Ausbildung',
  'Zusatzqualifikationen & Kenntnisse',
  'Fast geschafft!'
];

export default function EditProfile() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const stepsComponent = [
    <StepOne />,
    <StepTwo createProfile />,
    <StepThree createProfile />,
    <StepFour createProfile />,
    <StepFive createProfile />,
    <Loader createProfile />
  ];
  return (
      <CustomStepper 
        stepsLabel={stepsLabel}
        stepsComponent={stepsComponent}
      />
  );
}
