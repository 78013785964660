import React from 'react';

import { ReactComponent as EditButton } from '../../assets/images/editButton.svg';
import './card.css';

const DegreeCard = ({
  title,
  onClick,
  data = [],
  noEditIcon = false,
  classes = ''
}) => {
  const renderRows = () =>
    data.map((el, index) => (
      <p style={{ color: '#717171' }} key={index}>
        {el}
      </p>
    ));
  return (
    <div className={`strasseCard jobCard ${classes}`}>
      <div className="strasseCard__header">
        <p>{title}</p>
        {!noEditIcon && <EditButton onClick={onClick} />}
      </div>
      <div className="jobCard__content">{renderRows()}</div>
      <div></div>
    </div>
  );
};

export default DegreeCard;
