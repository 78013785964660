import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as MapPins } from '../../assets/images/mapPins.svg';
import { ReactComponent as JobTypeIcon } from '../../assets/images/jobType.svg';
import { ReactComponent as BriefCase } from '../../assets/images/briefcase.svg';
import { ReactComponent as Salary } from '../../assets/images/euro-bills.svg';
import { IconButton, MenuItem, ListItemIcon, Menu } from '@mui/material';
import {
  AssignmentTurnedIn,
  Delete,
  Edit,
  MoreHoriz,
  Storage
} from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Switch } from '@mui/material';
import { DeviceSize } from '../responsive';
import { useMediaQuery } from 'react-responsive';
import auth from '../../auth';
import { useHistory, useLocation } from 'react-router';
import PostData from '../../api/PostData';
import { formatSalary } from '../../utils/formatSalary';

export const Card = styled.div`
  height: 350px;
  min-width: 300px;
  // width: 40%;
  max-width: 370px;
  padding: 20px;
  // margin: 10px;
  border-radius: 10px;
  background-color: #fff;

  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(205, 205, 205, 0.25);
  &:hover {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.01),
      0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.02),
      0 19.3px 10.9px rgba(0, 0, 0, 0.02), 0 20.8px 28.4px rgba(0, 0, 0, 0.01),
      0 65px 60px rgba(0, 0, 0, 0.01);
    cursor: pointer;
    transition: 0.4s;
  }
  ${(props) =>
    props.isExtraSmall &&
    css`
      width: 100%;
      min-width: 260px;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.noMaxWidth &&
    css`
      max-width: none;
    `}
`;

const JobType = styled.span`
  margin-left: 5%;
  display: inline;
  font-size: 14px;
  width: 85%;
`;
export const JobTypeColor = styled.div`
  width: 12px;
  height: 12px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: #31cc5d;
  display: inline-block;
  margin-right: 3%;
  margin-left: -5%;

  ${(props) =>
    props.inActive &&
    `
    background: #e83741;
  `}
`;
const SubContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
  flex-wrap: wrap;

  & div {
    margin: 5px;
  }
  ${(props) =>
    props.flexColumn &&
    `
    flex-direction: column;
    align-items:flex-start;
  `}
`;
const SubContainer1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  flex-wrap: wrap;

  & div {
    margin: 5px;
  }
  ${(props) =>
    props.flexColumn &&
    `
    flex-direction: column;
    align-items:flex-start;
  `}
`;

const JobTitle = styled.h4`
  margin-top: 10%;
  font-size: 20px;
  hyphens: auto;
`;

const Small = styled.small`
  color: #3d3d3d;
  font-size: 14px;
  margin: 0 5px;
`;
const CompanyName = styled.p`
  margin-left: 10px;
  font-size: 14px;
  margin-bottom: 0;
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SwitchContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#31cc5d'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#31cc5d'
  }
}));

export default function JobsCard({
  employment,
  title,
  city,
  city2 = null,
  city3 = null,
  industry,
  companyName,
  dashboard,
  onClick,
  onMoreClick,
  width,
  noMaxWidth,
  onDelete,
  jobData,
  updateJobsList,
  changeSnacbar = () => {},
  getAllJobs = () => {}
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [checked, setChecked] = useState(jobData.isActive);

  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  return (
    <>
      <Card
        width={width}
        noMaxWidth={noMaxWidth}
        onClick={onClick}
        isExtraSmall={isExtraSmall}
      >
        <JobType>
          <JobTypeColor inActive={!checked} />
          {Array.isArray(employment)
            ? `${employment[0].EmploymentType} ${
                employment.length > 1 ? `+${employment.length - 1}` : ' '
              }`
            : employment}
          {location.pathname === '/arbeitgeber-dashboard/jobs' &&
            Number(auth.getId()) === jobData?.ApplicationUserId && (
              <SwitchContainer
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <CustomSwitch
                  checked={checked}
                  color="primary"
                  onChange={async () => {
                    setChecked(!checked);
                    const res = await PostData(
                      `JobAdvertisement/${jobData.JobAdvertisementId}`,
                      { ...jobData, isActive: !checked },
                      'PUT'
                    );

                    updateJobsList(jobData.JobAdvertisementId, res);
                  }}
                />
              </SwitchContainer>
            )}
        </JobType>
        <JobTitle>
          {jobData.Profession?.ProfesionName ?? jobData.ProfessionText} (M/W/D)
        </JobTitle>
        <ContentContainer>
          <SubContainer flexColumn>
            <div>
              <MapPins />
              <Small>
                {city}
                {city2 && ','}
              </Small>
              {city2 && (
                <Small>
                  {city2}
                  {city3 && ','}
                </Small>
              )}
              {city3 && <Small>{city3}</Small>}
            </div>

            <div>
              <JobTypeIcon />
              <Small>{industry}</Small>
            </div>
            {jobData.MinAnnualSalary !== 0 && (
              <div>
                <Salary
                  style={{ width: '15px', height: 'auto', fill: '#44952c' }}
                />
                <Small>
                  {formatSalary(jobData.MinAnnualSalary)}€ -{' '}
                  {formatSalary(jobData.MaxAnnualSalary)}€
                </Small>
              </div>
            )}
          </SubContainer>
          <SubContainer1>
            <div style={{display:'flex', justifyContent: 'space-between'}}>
            <BriefCase />
            <CompanyName>{companyName}</CompanyName></div>
            {dashboard && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                style={{
                 top: '0px', left: 'auto', right: 'auto'
                }}
              >
                {Boolean(anchorEl) ? (
                  <MoreHoriz color="primary" />
                ) : (
                  <MoreHoriz />
                )}
              </IconButton>
            )}
          </SubContainer1>
        </ContentContainer>
      </Card>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={onMoreClick}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Bearbeiten
        </MenuItem>
        {auth.isCompany() && (
          <MenuItem
            onClick={() => {
              localStorage.setItem('JobAd', JSON.stringify(jobData));
              history.push('/match');
            }}
          >
            <ListItemIcon>
              <AssignmentTurnedIn />
            </ListItemIcon>
            Matching hinzufügen
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            history.push({
              pathname: '/arbeitgeber-dashboard/bewerber',
              state: {
                searchJob: jobData
              }
            });
          }}
        >
          <ListItemIcon>
            <Storage />
          </ListItemIcon>
          Bewerbungen
        </MenuItem>
        <MenuItem
          onClick={async () => {
            try {
              await PostData(
                `jobadvertisement/duplicateadv?&id=${jobData.JobAdvertisementId}`
              );
              getAllJobs();
            } catch (error) {
              if (
                error.response?.status === 400 &&
                error.response?.data?.ERROR[0] &&
                (error.response?.data?.ERROR[0] ===
                  'Sie müssen ein aktives Abonnement haben, um neue Stellenanzeigen zu veröffentlichen!' ||
                  error.response.data.ERROR[0].includes('Das Limit von '))
              ) {
                changeSnacbar(error.response.data.ERROR[0], 'error');
              } else {
                console.log('🚀 ~ error', error);
              }
            }
          }}
        >
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          Kopieren
        </MenuItem>
        <MenuItem onClick={onDelete} style={{ color: '#e83741' }}>
          <ListItemIcon>
            <Delete color="primary" />
          </ListItemIcon>
          Löschen
        </MenuItem>
      </Menu>
    </>
  );
}
