/* Libraries */
import axios from 'axios';
import auth from '../auth';
import { Redirect } from 'react-router';
import config from '../config/api.config';

const DeleteData = async (url, id) => {
  //if (auth.isTokenExpired()) auth.logout(() => <Redirect to="/login" />);
  const accessToken = auth.getToken();

  if (!accessToken) {
    return <Redirect to="/" />;
  }
  return new Promise(async (resolve, reject) => {
    try {
      let urlNew = config.apiUrl +"/"+ url;
      const result = await axios.delete(`${urlNew}/${id}`, {
       
      });
      resolve(result.data);
    } catch (error) {
      console.log(`Error: ${error}`);

      reject(error);
    }
  });
};

export default DeleteData;
