/* Libraries */
import { Redirect } from 'react-router-dom';
import auth from '../auth';
import axios from 'axios';
import config from '../config/api.config';

const GetData = async (url, params = {}) => {
  //if (auth.isTokenExpired()) auth.logout(() => <Redirect to="/login" />);
 
  let urlNew = config.apiUrl +"/"+ url;
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(urlNew, {
        params: params
      })
      resolve(result.data);
    } catch (error) {
      console.log(`Error: ${error}`);

      reject(error);
    }
  });
};
export default GetData;
