import React from 'react';
import { NavLinks } from './navLinks';
import { MobileNavLinks } from './mobileNavLinks';
import styled from 'styled-components';
import { Logo } from '../logo/logo';
import { Accessibility } from './accessibilty';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import auth from '../../auth';

import './navbar.css';

const NavBarContainer = styled.div`
  width: 100%;
  height: 157px;
  background-color: #fff;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  @media (max-width: 767px) {
   
    height: 100px;
  }
`;
const MobileSection = styled.div`
  padding-top:18px;
 
  @media (max-width: 767px) {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const LeftSection1 = styled.div`
  display: inline-block;
  flex-direction: row;
  align-items: center;
  width: 75%;
  margin-bottom: 30.6px;
  margin-top: 39px;
`;

const RightSection1 = styled.div`
  display: inline-block;
  flex-direction: row;
  align-items: center;
  width: 25%;
`;

const LeftSection2 = styled.div`
  float: left;
  
  margin-left:30px;

  @media (max-width: 767px) {
    margin: auto;
    float: none;
    display: flex;
  }
`;

const RightSection2 = styled.div`
  float: right;
  margin-right:30px;
  margin-top:28px;
  @media (max-width: 767px) {
    position: absolute;
    margin-right: 0px;
    margin-top: 0px;
    right: 20px;
  }
`;

export function Navbar(props) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newLarge });

  return (
    <NavBarContainer className='maxWidth'>
      <div style={{width:'99%'}}>
        {!isMobile ? (
          <div>
              <LeftSection1>
                <Logo />
              </LeftSection1>

            <RightSection1><Accessibility /></RightSection1>
           {!auth.isAuthenticated() && <NavLinks />}
          </div>
        ) : (
          <MobileSection>
            {
              <LeftSection2>
                <Logo />
              </LeftSection2>
            }
            {<RightSection2>{!auth.isAuthenticated() && <MobileNavLinks />}</RightSection2>}
          </MobileSection>
        )}
      </div>
    </NavBarContainer>
  );
}
