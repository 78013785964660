import React, { useState } from 'react';
import RadioButtons from '../Step2Subcomponents.js/RadioButtons';
import { Paragraph } from '../StepSixSubcomponents/Region';
import { Row } from '../StepTwo';
import { ReactComponent as Delete } from '../../../../assets/images/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepTwo } from '../../../../store/stepsSlice';
import ModalSelectNew from '../../../modal/ModalSelectNew';
import { ReactComponent as Plus } from '../../../../assets/images/IconPlus.svg';

import { useMediaQuery } from 'react-responsive';

export default function WorkExp({ createProfile, industry }) {
  const dispatch = useDispatch();
  const hasWorkExperience = useSelector(
    (state) => state.steps.stepTwo.hasWorkExperience
  );
  const hasPersonalExperience = useSelector(
    (state) => state.steps.stepTwo.hasPersonalExperience
  );
  const workExperience = useSelector(
    (state) => state.steps.stepTwo.workExperience
  );

  const [selectedItems, setselectedItems] = useState(workExperience);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpenModal(!openModal);
  };

  const isMobile = useMediaQuery({ maxWidth: '992px' });

  return (
    <>
      <Row>
        <Paragraph autoWidth>
          {createProfile
            ? 'Hast du schon Berufserfahrung/en?'
            : 'Benötigt der Bewerber weitere Berufserfahrung/en?'}
        </Paragraph>

        <RadioButtons
          setTrue={() => dispatch(updateStepTwo({ hasWorkExperience: true }))}
          setFalse={() => dispatch(updateStepTwo({ hasWorkExperience: false }))}
          name="work"
          naPromjenu={() => {}}
          tip={'workexist'}
          value={hasWorkExperience ? '1' : '0'}
        />
      </Row>

      {hasWorkExperience && (
        <>
          <Row>
            <Paragraph autoWidth>
              Benötigt der Bewerber Berufserfahrung/en mit
              Personalverantwortung?
            </Paragraph>
            <RadioButtons
              name="personel"
              naPromjenu={() => {}}
              tip={'workexist'}
              setTrue={() =>
                dispatch(updateStepTwo({ hasPersonalExperience: true }))
              }
              setFalse={() =>
                dispatch(updateStepTwo({ hasPersonalExperience: false }))
              }
              value={hasPersonalExperience ? '1' : '0'}
            />
          </Row>
          <div className="vocational-left my-2">
            <Paragraph autoWidth>
              {createProfile
                ? 'Berufserfahrungen hinzufügen'
                : 'Fügen Sie zu, welche Berufserfahrungen der Bewerber benötigt'}
            </Paragraph>
            {!openModal ? (
              <Plus
                style={{ 'margin-left': '8px', 'transform': 'scale(0.8)' }}
                onClick={handleOpen}
              ></Plus>
            ) : (
              <button
                className="vocational-button--plus vocational-button--minus"
                onClick={handleOpen}
              >
                -
              </button>
            )}
          </div>
          <ModalSelectNew
            closeButton
            createProfile={createProfile}
            title={
              createProfile
                ? 'Bitte füge deine Berufserfahrung hinzu'
                : 'Welche Berufserfahrungen sind notwendig'
            }
            open={openModal}
            onClose={() => {
              dispatch(updateStepTwo({ workExperience: selectedItems }));
              setOpenModal(false);
            }}
            firstData={industry}
            values={selectedItems}
            setValues={(values) => {
              setselectedItems(values);
            }}
            seconOption="Profession/Paging"
            firstParagraph={
              createProfile
                ? isMobile
                  ? 'Branche'
                  : 'In welcher Branche hast du Berufserfahrung/en ?'
                : 'In welcher Branche benötigt der Bewerber Berufserfahrung/en ?'
            }
            secondParagraph={
              createProfile
                ? 'Wähle deinen Beruf'
                : 'In welchem Beruf benötigt der Bewerber Berufserfahrung/en ?'
            }
            thirdParagraph={
              createProfile
                ? 'Wie lange hast du diesen Beruf ausgeübt?'
                : 'Wie viele Jahre sollte der Bewerber diesen Beruf mindestens ausgeübt haben ?'
            }
          />
          <div style={{ width: '100%', maxWidth: '800px' }}>
            {selectedItems.map((el) => (
              <div
                className="brancheCard__card"
                style={{
                  border: '1px solid #9A9A9A',
                  color: '#818181',
                  fontSize: '14px',
                  margin: '20px 0'
                }}
              >
                {[
                  industry?.find((i) => i.BranchId == el.industryId)
                    ?.BranchType,
                  el.professionName,
                  el.years == '3-'
                    ? 'weniger als 3 Jahre'
                    : el.years == '3+'
                    ? 'mehr als 3 Jahre'
                    : 'egal',
                  <Delete
                    onClick={() => {
                      const temp = selectedItems.filter(
                        (x) =>
                          x.industryId != el.industryId ||
                          x.proffesionId != el.proffesionId
                      );

                      setselectedItems(temp);
                      dispatch(updateStepTwo({ workExperience: temp }));
                    }}
                  />
                ].map((e) => (
                  <span style={{ margin: '0 3px' }}>{e}</span>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
