import React, { useRef, useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import { Input, Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import { StyledTextarea } from '../ContactPage';
import SubmitButton from '../../components/Buttons/submitButton';
import GetData from '../../api/GetData';
import auth from '../../auth';
import UploadCompanyProfile from '../../utils/uploadCompanyProfile';
import { GoogleAutocomplete } from '../../components/GoogleAutocomplete';
import Snackbar from '../../components/snackbar/snackbar';
import validator from 'validator';
import { ErrorText } from '../../components/Common/Input';
import Linkedin from '../../assets/images/linkedinCompany.svg';
import Facebook from '../../assets/images/facebookCompany.svg';
import Instagram from '../../assets/images/instagramCompany.svg';
import Twitter from '../../assets/images/twitterCompany.svg';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import Youtube from '../../assets/images/youtubeCompany.svg';

const Profile = () => {
  const [basicData, setbasicData] = useState({
    companyName: '',
    zipcode: '',
    location: '',
    country: '',
    telefon: '',
    city: '',
    latitude: '',
    longitude: '',
    email: '',
    companyText: '',
    address: '',
    website: '',
    locationId: ''
  });
  const dataRef = useRef(basicData);
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  //used to determine if location is added for the first time, if it is (true) than that location shuld also
  //be added to company locations (standorte page)
  const [addLocation, setaddLocation] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [links, setlinks] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [newInput, setNewInput] = useState('');
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarUrl, setsnackbarUrl] = useState(false);
  const [snackbarUrlWrong, setsnackbarUrlWrong] = useState(false);
  const [errors, seterrors] = useState({
    companyName: '',
    address: '',
    website: '',
    email: '',
    telefon: '',
    companyText: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: ''
  });

  const userId = auth.getId();

  function onGoogleSelected(place) {
    setbasicData({
      ...dataRef.current,
      address: place?.formattedAddress,
      latitude: place?.latitude?.toString(),
      longitude: place?.longitude?.toString(),
      location: place?.city,
      country: place?.country
    });
  }

  async function getUser() {
    const response = await GetData(`User/${auth.getId()}`);

    let newBasicData = {
      ...basicData,
      companyName: response?.Name || '',
      zipcode: response?.LocationPostcode || '',
      location: response?.LocationCity || '',
      telefon: response?.Phone || '',
      email: response?.ContactEmail || '',
      companyText: response?.AboutMe || '',
      address: response?.LocationAddress || '',
      website: response?.Website || '',
      locationId: response?.LocationId || '',
      country: response?.Country || ''
    };
    setbasicData(newBasicData);

    setaddLocation(response?.LocationAddress ? false : true);

    dataRef.current = newBasicData;
  }

  const getOrganisationLinks = async () => {
    const res = await GetData(`organisationsocialnetwork/${userId}`);

    //request property indicates whether request should be with POST method (false) or with PUT (true)
    //if response of /organisationsocialnetwork/id is empty string than request: false, otherwise there is something already in database
    //and request will be PUT
    if (res === '') {
      setlinks({
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
        youtube: '',
        request: false
      });
    } else {
      setlinks({
        linkedin: res.LinkedinUrl || '',
        twitter: res.TwitterUrl || '',
        facebook: res.FacebookUrl || '',
        instagram: res.InstagramUrl || '',
        youtube: res.YoutubeUrl || '',
        request: true
      });
    }
  };

  const handeleSocialNetworkChange = (e) => {
    setlinks({ ...links, [e.target.name]: e.target.value });
  };

  const handleAddNewChange = (e) => {
    setNewInput(e.target.value);
  };

  useEffect(() => {
    if (newInput.includes('instagram')) {
      setlinks({ ...links, ['instagram']: newInput });
    }
    if (newInput.includes('linkedin')) {
      setlinks({ ...links, ['linkedin']: newInput });
    }
    if (newInput.includes('facebook')) {
      setlinks({ ...links, ['facebook']: newInput });
    }
    if (newInput.includes('twitter')) {
      setlinks({ ...links, ['twitter']: newInput });
    }
    if (newInput.includes('youtube')) {
      setlinks({ ...links, ['youtube']: newInput });
    }
  }, [newInput]);

  useEffect(() => {
    setNewInput("");
  }, [links])

  useEffect(() => {
    (async () => {
      setloadingData(true);
      await Promise.allSettled([getUser(), getOrganisationLinks()]);
      setloadingData(false);
    })();
  }, []);

  const handleInputChange = (name, value) => {
    setbasicData({ ...basicData, [name]: value });
    dataRef.current = { ...basicData, [name]: value };
  };

  const handleAddClick = () => {
    //setlinks({ ...links, ["novi"]: "Enter link"});
    setAddNew(true);
    setNewInput('');
    setsnackbarUrlWrong(false);
  };

  const onSubmit = async () => {
    try {
      if (validateFields()) {
        setisLoading(true);

        if (
          newInput != null &&
          newInput != '' &&
          !newInput.includes('instagram') &&
          !newInput.includes('twitter') &&
          !newInput.includes('facebook') &&
          !newInput.includes('linkedin') &&
          !newInput.includes('youtube')
        ) {
          setsnackbarUrlWrong(true);
        }

        await UploadCompanyProfile(basicData, links, addLocation);

        setAddNew(false);
        setisLoading(false);
        setsnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Validation of fields
  const validateFields = () => {
    let newErrors = { ...errors };
    let isValid = true;

    if (validator.isEmpty(basicData.companyName)) {
      newErrors.companyName = 'Dieses Feld wird benötigt.';
      isValid = false;
    } else {
      newErrors.companyName = '';
    }

    if (validator.isEmpty(basicData.address)) {
      newErrors.address = 'Dieses Feld wird benötigt.';
      isValid = false;
    } else {
      newErrors.address = '';
    }

    if (basicData.website.length !== 0 && !validator.isURL(basicData.website)) {
      newErrors.website = 'Dies ist keine gültige URL.';
      isValid = false;
    } else {
      newErrors.website = '';
    }

    if (!validator.isEmail(basicData.email.trim())) {
      newErrors.email = 'Ungültige E-Mail.';
      isValid = false;
    } else {
      newErrors.email = '';
    }

    if (validator.isEmpty(basicData.telefon)) {
      newErrors.telefon = 'Dieses Feld wird benötigt.';
      isValid = false;
    } else {
      newErrors.telefon = '';
    }

    if (!validator.isLength(basicData.companyText, { min: 10 })) {
      newErrors.companyText = 'Text muss mindestens 10 Zeichen haben.';
      isValid = false;
    } else {
      newErrors.companyText = '';
    }

    ['facebook', 'instagram', 'twitter', 'linkedin', 'youtube'].forEach(
      (item) => {
        if (!validator.isURL(links[item]) && !validator.isEmpty(links[item])) {
          // newErrors[item] = 'Dies ist keine gültige URL.';
          // isValid = false;
          setsnackbarUrl(true);
          isValid = false;
        } else {
          newErrors[item] = '';
        }
      }
    );

    seterrors(newErrors);
    return isValid;
  };

  return (
    <DashboardLayout noPadding sidebar>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title="Ihr Profil"
          list={[
            { Name: 'Basisdaten', Link: 'profil' },
            { Name: 'Logo & Header-Grafik', Link: 'logo' },
            { Name: 'Galerie', Link: 'galerie' },
            { Name: 'Passwort und Konto', Link: 'konto' }
          ]}
          storage={'profil'}
        />
        {loadingData ? (
          <ComponentWrapper displayFlex fluid justifyCenter alignCenter>
            <CircularLoader />
          </ComponentWrapper>
        ) : (
          <ComponentWrapper
            fluid
            padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
          >
            <Snackbar
              open={snackbar}
              onClose={() => setsnackbar(false)}
              severity="success"
            >
              Erfolgreich aktualisiert
            </Snackbar>

            <Snackbar
              open={snackbarUrl}
              onClose={() => setsnackbarUrl(false)}
              severity="error"
            >
              Dies ist keine gültige URL.
            </Snackbar>

            <Snackbar
              open={snackbarUrlWrong}
              onClose={() => setsnackbarUrlWrong(false)}
              severity="error"
            >
              Sie können nur Social Media Links hinzufügen.
            </Snackbar>

            {/* {!isExtraSmall && !isMedium && ( */}
            <Row>
              <DashboardTitle red> Basisdaten*</DashboardTitle>
            </Row>
            {/* )} */}

            <Row flexWrap={isMedium}>
              <ComponentWrapper
                fluid
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  {auth.isCompany() ? 'Firmenname' : 'Organisation/Verein'}
                </Paragraph>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={basicData.companyName}
                  noFill
                  onChange={(e) =>
                    handleInputChange('companyName', e.target.value)
                  }
                />
                <ErrorText>{errors.companyName}</ErrorText>
              </ComponentWrapper>
              <ComponentWrapper
                fluid
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Standort*
                </Paragraph>
                {/* When address already exists, send formattedAddress value to defaultValue prop */}
                <GoogleAutocomplete
                  style={{ width: '100% !important' }}
                  onPlaceSelected={onGoogleSelected}
                  defaultValue={basicData.address}
                />
                <ErrorText>{errors.address}</ErrorText>
              </ComponentWrapper>
            </Row>
            <Row flexWrap={isMedium} alignStart>
              <ComponentWrapper
                fluid
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Webseite
                </Paragraph>
                <Input
                  type="text"
                  name="website"
                  id="website"
                  value={basicData.website}
                  noFill
                  onChange={(e) => handleInputChange('website', e.target.value)}
                />
                <ErrorText>{errors.website}</ErrorText>
              </ComponentWrapper>
              <ComponentWrapper
                fluid
                // noPaddingTop
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  E-mail*
                </Paragraph>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  required
                  value={basicData.email}
                  noFill
                  onChange={(e) => handleInputChange('email', e.target.value)}
                />
                <Paragraph grey style={{ fontWeight: 300, fontSize: '12px' }}>
                  *Diese E-Mail wird verwendet, um Ihr{' '}
                  {auth.isCompany() ? 'Firmenprofil' : 'profil'} anzuzeigen.
                </Paragraph>
                <ErrorText>{errors.email}</ErrorText>
              </ComponentWrapper>
            </Row>
            <Row>
              <ComponentWrapper
                halfFluid={!isMedium}
                fluid={isMedium}
                noPaddingTop
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Telefon*
                </Paragraph>
                <Input
                  type="text"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^\+?\d+$]/g, '');
                    e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
                  }}
                  name="telefon"
                  id="telefon"
                  required
                  value={basicData.telefon}
                  noFill
                  onChange={(e) => handleInputChange('telefon', e.target.value)}
                />
                <ErrorText>{errors.telefon}</ErrorText>
              </ComponentWrapper>
            </Row>

            {/* Social media links */}
            <Row flexWrap={isMedium}>
              <ComponentWrapper
                fluid
                noPaddingHorizontal={isExtraSmall || isMedium}
                noPaddingTop
              >
                <Paragraph grey small weight600 marginBottom10 marginTop20>
                  Neue Links hinzufügen
                </Paragraph>
                {Object.entries(links).map(
                  ([key, value]) =>
                    value &&
                    key != 'request' && (
                        <Row flexStart key={key}>
                          {' '}
                          <Input
                            type="url"
                            name={key}
                            id={key}
                            value={value}
                            backColor
                            marginBottom
                            width60
                            marginRight
                            onChange={handeleSocialNetworkChange}
                          />
                          {key === 'facebook' && (
                            <img
                              style={{ marginBottom: '10px' }}
                              src={Facebook}
                            ></img>
                          )}
                          {key === 'twitter' && (
                            <img
                              style={{ marginBottom: '10px' }}
                              src={Twitter}
                            ></img>
                          )}
                          {key === 'instagram' && (
                            <img
                              style={{ marginBottom: '10px' }}
                              src={Instagram}
                            ></img>
                          )}
                          {key === 'linkedin' && (
                            <img
                              style={{ marginBottom: '10px' }}
                              src={Linkedin}
                            ></img>
                          )}
                          {key === 'youtube' && (
                            <img
                              style={{ marginBottom: '10px' }}
                              src={Youtube}
                            ></img>
                          )}
                        </Row>
                    )
                )}
                {addNew && (
                  <Input
                    placeholder="Link eingeben"
                    type="url"
                    name={'novi'}
                    id={'novi'}
                    value={newInput}
                    backColor
                    width60
                    onChange={handleAddNewChange}
                  />
                )}

                <a
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={handleAddClick}
                >
                  <Paragraph font14 color>
                    + Neuen Link hinzufügen
                  </Paragraph>
                </a>

                {/* <Paragraph grey small>
                  Facebook
                </Paragraph>
                <Input
                  type="url"
                  name={'facebook'}
                  id={0}
                  value={links['facebook']}
                  noFill
                  onChange={handeleSocialNetworkChange}
                />
                <ErrorText>{errors.facebook}</ErrorText>
              </ComponentWrapper>
              <ComponentWrapper
                fluid
                noPaddingTop
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Instagram
                </Paragraph>
                <Input
                  type="url"
                  name={'instagram'}
                  id={0}
                  value={links['instagram']}
                  noFill
                  onChange={handeleSocialNetworkChange}
                />
                <ErrorText>{errors.instagram}</ErrorText>
              </ComponentWrapper>
            </Row>
            <Row flexWrap={isMedium}>
              <ComponentWrapper
                fluid
                noPaddingTop
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Twitter
                </Paragraph>
                <Input
                  type="url"
                  name={'twitter'}
                  id={0}
                  value={links['twitter']}
                  noFill
                  onChange={handeleSocialNetworkChange}
                /> */}
                {/* <ErrorText>{errors.twitter}</ErrorText>
              </ComponentWrapper>
              <ComponentWrapper
                fluid
                noPaddingTop
                noPaddingHorizontal={isExtraSmall || isMedium}
              >
                <Paragraph grey small>
                  Linkedin
                </Paragraph>
                <Input
                  type="url"
                  name={'linkedin'}
                  id={0}
                  value={links['linkedin']}
                  noFill
                  onChange={handeleSocialNetworkChange}
                />
                <ErrorText>{errors.linkedin}</ErrorText> */}
              </ComponentWrapper>
            </Row>
            <ComponentWrapper
              fluid
              noPaddingHorizontal={isExtraSmall || isMedium}
              padding="30px 20px "
            >
              <ComponentWrapper padding="10px 0">
                <Paragraph grey small noMargin>
                  {auth.isOrganization()
                    ? 'Über die Institution*'
                    : 'Über das Unternehmen*'}
                </Paragraph>
              </ComponentWrapper>
           

           

              <StyledTextarea
                value={basicData.companyText}
                onChange={(e) =>
                  handleInputChange('companyText', e.target.value)
                }
                rows="5"
              />
             
              


              <ErrorText>{errors.companyText}</ErrorText>
            </ComponentWrapper>
            <ComponentWrapper
              center
              noPaddingHorizontal={isExtraSmall || isMedium}
            >
              <SubmitButton onClick={onSubmit} buttonClicked={isLoading}>
                Speichern
              </SubmitButton>
            </ComponentWrapper>
          </ComponentWrapper>
        )}
      </ComponentWrapper>
    </DashboardLayout>
  );
};

export default Profile;
