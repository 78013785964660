import React, { useState } from 'react';
import PasswordReset from '../../assets/images/passwordReset.png';
import styled from 'styled-components';
import axios from 'axios';
import config from '../../config/api.config';

const Container = styled.div`
  width: 50%;
  margin: 90px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > p:last-of-type {
    color: #e0303a;
  }
  & > a:last-of-type {
    font-size: 22px;
    color: #e0303a;
    cursor: pointer;
  }
`;
const Heading = styled.h1`
  color: rgba(232, 55, 65, 1);
  margin: 4% 0;
  text-align: center;
`;

const Span = styled.span`
  margin: 2% 0;
`;

export default function SentEmailConfirm(props) {
  const [errors, setErrors] = useState('');
  const onSubmit = (props) => {
    console.log(props.email);
    axios({
      method: 'post',
      url: `${config.apiUrl}/Authentication/ForgotPassword`,
      data: {
        email: props.email
      }
    })
      .then(function (response) {
        console.log(response);
      })
      .catch((error) => {
        if (error.response) {
          //do something
          console.log(error.response.data.ERROR[0]);
          setErrors(error.response.data.ERROR[0]);
        }
      });
  };
  return (
    <div>
      <Container>
        <Heading>Ein Link wurde soeben an Deine Email Adresse gesendet</Heading>
        <img src={PasswordReset} alt="reset password"></img>
        <Span>Keinen Link erhalten?</Span>
        <a onClick={onSubmit}>
          <p>ERNEUT SENDEN</p>
        </a>
      </Container>
    </div>
  );
}
