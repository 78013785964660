import Houses from '../../assets/images/bewerber-section-houses.png';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {Animated} from "react-animated-css";
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';

const FourthStep = ({isVisible}) => {
    const history = useHistory();
    const isMobile = useMediaQuery({maxWidth: "767px"});
    return(
        <section className="bewerber-section bewerber-section-second bewerber-section-fourth" id='bewerber-fourth'>
            <div 
             /*animationIn="bounceInRight" 
             animationOut="bounceOutRight" 
               animationInDuration={1600} 
             animationOutDuration={1600}
             isVisible={isVisible} */
            className="section-card">
                <div 
                 /*animationIn="bounceInUp" 
                 animationOut="bounceOutUp" 
                   animationInDuration={1600} 
                 animationOutDuration={1600}
                 isVisible={isVisible} */
                className='section-home'>
                    <img src={Houses}/>
                    <div className="shadow"></div>
                </div>
                <div 
                /* animationIn="bounceInRight" 
                 animationOut="bounceOutRight" 
                   animationInDuration={1600} 
                 animationOutDuration={1600}
                 isVisible={isVisible} */
                className="section-text">
                    <p className='first-p-B--red' style={isMobile ? {marginTop: '0px'}: {}}>Schluss mit klassischen Bewerbungen</p>
                    <h3 style={isMobile ? {marginBottom: '20px'}: {}}>Jetzt ist Matching-Time!</h3>
                    <p className="first-p-B" style={isMobile ? {marginBottom: '20px'}: {}}>Wenn du ein Match hast, kannst du Dich mit nur einem <br/>Klick bewerben, ohne Anschreiben und ohne weiteren<br/> Zeitaufwand!</p>

<p className="first-p-B">Ein Match bedeutet auch, dass das Unternehmen genauso<br/> einen Bewerber wie DICH sucht.</p>           
                </div>
            </div>
            <div
             /*animationIn="bounceInRight" 
             animationOut="bounceOutRight" 
               animationInDuration={1600} 
             animationOutDuration={1600}
             isVisible={isVisible}*/ >
            <Button variant="contained" color="primary" onClick={() => history.push('/profil-anlegen')}>
              Profil anlegen
            </Button>
            </div>
        </section>
    )
}

export default FourthStep;