import React, { useState, useEffect } from 'react';
import CheckBox from './StepSixSubcomponents/CheckBox';
import Traveling from './StepSixSubcomponents/Traveling';
import { Paragraph } from './StepSixSubcomponents/Region';
import ComponentWrapper from '../../ComponentWrapper/ComponentWrapper';
import Slider from '../../slider/slider';

import Shifts from './StepSixSubcomponents/Shifts';
import { Container } from './StepTwo';
import GetData from '../../../api/GetData';

import Benefits from './StepSixSubcomponents/Benefits';
import Employment from './StepSixSubcomponents/Employment';
import Branche from './StepSixSubcomponents/Branche';
import Region from './StepSixSubcomponents/Region';
import { DeviceSize } from '../../responsive';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonalData, updateValid} from '../../../store/stepsSlice';

export function StepFive(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.steps.stepOne.personalData);
  const stepFiveData = useSelector((state) => state.steps.stepFive);
  const [startingDate, setstartingDate] = useState(null);
  const [shifts, setshifts] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const [salary, setSalary] = useState([50000,150000]);

  useEffect(() => {
    async function getStartingDate() {
      let response = await GetData('StartingDate');
      setstartingDate(response);
    }
    async function getShifts() {
      let response = await GetData('Shift');
      setshifts(response);
    }
    getShifts();
    getStartingDate();
  }, []);

  const onChangeCommitted = (e, value) => {
    dispatch(setPersonalData({ minAnnualSalary:  value[0], maxAnnualSalary: value[1] }));
  };

  useEffect(() => {
      setSalary([userData?.minAnnualSalary ?? 50000, userData?.maxAnnualSalary ?? 150000])
  }, [userData])

  useEffect(() => {
    if (stepFiveData.startingDateId && 
      (!stepFiveData.workInShifts || (stepFiveData.workInShifts && stepFiveData.shiftId)) &&
      stepFiveData.employmentArray.length) {
      dispatch(updateValid(true));
    } else {
      dispatch(updateValid(false));
    }
  }, [stepFiveData.startingDateId, stepFiveData.workInShifts, stepFiveData.shiftId, stepFiveData.employmentArray.length]);

  return (
    <form className='formTwo'>
    <Container>
      {isMobile ? (
        <h4 className="fw-bold fc-p mb-4 mt-3">5. FAST GESCHAFFT !</h4>
      ) : null}
      <CheckBox values={startingDate} createProfile={props.createProfile} />
      <Traveling createProfile={props.createProfile} />
      <Shifts values={shifts} createProfile={props.createProfile} />
      {props.createProfile && (
        <Region
          createProfile={props.createProfile}
          step="stepFive"
          dontCheckValidation
        />
      )}

{props.createProfile && <ComponentWrapper fluid noPaddingHorizontal displayFlex alignCenter justifyBetween 
      style={isMobile ? {flexDirection: 'column'}: {}}>
      <Paragraph autoWidth noPadding > Jahresbruttogehalt? </Paragraph>
<ComponentWrapper halfFluid displayFlex alignCenter noPaddingHorizontal justifyEnd       style={isMobile ? {width: '100%'}: {}}>
        <Slider   
              onChange={(e, value) => {
                setSalary(value)
              }}
              onChangeCommitted={onChangeCommitted}
              value={salary}
              style={isMobile ? {maxWidth: '350px', marginRight:'10px', width: '100%'} : { maxWidth: '350px', marginRight:'10px' }}
              step={10000}
              min={0}
              max={200000}
            />
            <Paragraph grey small autoWidth    style={isMobile ? {width: '10%'}: {}}> € </Paragraph>
      </ComponentWrapper>
      </ComponentWrapper>}

      <Benefits createProfile={props.createProfile} />
      {!props.createProfile && (
        <Region createProfile={false} step="stepFive" dontCheckValidation />
      )}
      <Employment createProfile={props.createProfile} />
      <Branche createProfile={props.createProfile} />
    </Container>
    </form>
  );
}
export default StepFive;
