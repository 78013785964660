import React from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './TextEditor.module.scss';

const TextEditor = (props) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(convertFromHTML(props.value))
  );

  const onChange = (edState) => {
    const content = edState.getCurrentContent();

    setEditorState(edState);

    if (props.onChange) {
      const isEditorEmpty = !content.hasText();
      const currentPlainText = content.getPlainText();
      const lengthOfTrimmedContent = currentPlainText.trim().length;
      const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;

      if (isEditorEmpty || isContainOnlySpaces) {
        props.onChange('');
      } else {
        props.onChange(convertToHTML(content));
      }
    }
  };

  const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'list',
      'textAlign',
      'link',
      'image',
      'history'
    ],
    inline: {
      inDropdown: false,
      className: `${styles.set}`,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
    },
    blockType: {
      inDropdown: true,
      className: `${styles.dropdown}`,
      dropdownClassName: `${styles.dropdownList}`,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code']
    },
    list: { className: `${styles.set}`, options: ['unordered', 'ordered'] },
    textAlign: { className: `${styles.set}` },
    link: { className: `${styles.set}` },
    history: { className: `${styles.set}` },
    image: { class: `${styles.set}` }
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onChange}
      placeholder="Schreiben Sie etwas!"
      toolbar={toolbarOptions}
      wrapperClassName={styles.wrapper}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      stripPastedStyles={true}
    />
  );
};

export default TextEditor;
