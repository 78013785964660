/* Libraries */
import axios from 'axios';
import auth from '../auth';
import config from '../config/api.config';
import { Redirect } from 'react-router-dom';


const BASE_URL = `${config.apiUrl}/`;

const UploadFile = async (url, data, method = 'POST') =>
  new Promise(async (resolve, reject) => {
    const accessToken = auth.getToken();
    //if(auth.isTokenExpired()) auth.logout(() => <Redirect to="/login" />)

    if (!accessToken) {
      console.log('no access token');
      return window.location.replace('http://localhost:5000/login');
    }

    try {
      const result = await axios({
        method: method,
        url: BASE_URL + url,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      // console.log(result);

      resolve(result.data);
    } catch (error) {
      console.log(`Error: ${error}`);

      reject(error);
    }
  });

export default UploadFile;
