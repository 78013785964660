import React from 'react';
import styled from 'styled-components';

const SubtitleHeading = styled.h2`
  font-size: ${(props) => (props.big ? '26px' : '20px')};
  color: ${(props) => (props.red ? '#E83741' : '#3A3A3A')};
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  margin-top: ${(props) => (props.subtitle ? '0%' : '5%')};
  margin-bottom: ${(props) => (props.lastchild ? '5%' : 'none')};
  //margin-left:${(props) => (props.mobilna ? '5%' : '5%')}
`;

export { SubtitleHeading };

export default function Subtitle(props) {
  return (
    <>
      <SubtitleHeading
        red={props.red}
        subtitle={props.subtitle}
        lastchild={props.lastchild}
        big={props.big}
      >
        {props.value}
      </SubtitleHeading>
    </>
  );
}
