import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';

const Container = styled.div`
  width: 100%;
  padding: 0 1%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #818181;
  margin-bottom: 0 !important;
`;

export default function SelectedSkill({
  data,
  values,
  title,
  selectedValue,
  setValue,
  allValues,
  index
}) {
  const [selectedValueState, setSelectedValueState] =
    React.useState(selectedValue);

  var firstArray = [];
  data?.forEach((item) => {
    let newItem = {
      Id: Object.values(item)[0],
      Name: Object.values(item)[1],
      Description: item.Description
    };
    firstArray.push(newItem);
  });

  const handleInputChange = (event) => {
    setSelectedValueState(event.target.value);
    if (allValues) {
      let newValue = { ...values, secondId: event.target.value };
      let newAllValues = [];

      for (let i = 0; i < allValues.length; i++) {
        i === index
          ? newAllValues.push(newValue)
          : newAllValues.push(allValues[i]);
      }

      setValue(event, newAllValues, true);
    } else {
      setValue(event, [{ ...values, secondId: event.target.value }], true);
    }
  };

  return (
    <Container>
        {title != null && <Paragraph >{title}</Paragraph>}
      <FormControl style={{ width: '80%' }}>
          <Select
       
            className='create-profile-select'
            required
            defaultValue=""
            color="secondary"
            name="position"
            value={selectedValueState}
            onChange={(event) => {
              handleInputChange(event);
            }}
          >
            {firstArray.map((item, index) => (
              <MenuItem key={index} value={item.Id.toString()}>
                {item.Name} {item.Description && '(' + item.Description + ')'}
              </MenuItem>
            ))}
          </Select>

      </FormControl>
    </Container>
  );
}
