import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import { Button } from '@mui/material';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import JobsStats from './JobsStats';
import DashboardLayout from '../CompanyDashboard/DashboardLayout';
import { DashboardTitle } from '../CompanyDashboard/Home';
import { DeviceSize } from '../../components/responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import TableWrapper from '../../components/DataTable/TableWrapper';

import NoApplications from '../../assets/images/noApplications.png';

import config from '../../config/api.config';
import auth from '../../auth';
import { useMediaQuery } from 'react-responsive';

import CustomModal from '../../components/modal/Modal';
import stepsDone from '../../assets/images/stepsDone.png';
import { Flex } from '../../components/Common/Input';
import SubmitButton from '../../components/Buttons/submitButton';

import patchdata from '../../api/patchdata';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ul {
    padding-left: 35px;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;
    align-items: flex-start;

    ul {
      padding-left: 0;
      list-style-type: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NothingFound = styled.div`
  padding: 50px;
  width: 100%;
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: #3a3a3a;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
  }

  img {
    height: 200px;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    padding: 20px;

    img {
      height: 100px;
    }
  }
`;

const Container = styled.div`
  padding: 6.5em 3.5em;
  width: 100%;
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: #3a3a3a;
    font-size: 19px;
    width: 50%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
  }
  > div {
    margin-top: 50px;
  }
  > div > button {
    border-radius: 5px !important;
  }
`;

export default function Userhome() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [applicationCount, setApplicationCount] = useState(0);
  const [numOfTopJobs, setNumOfTopJobs] = useState(0);
  const [topThree, setTopThree] = useState([]);

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  
  const [openModal, setOpenModal] = useState(false);
  const [verifiedAccount, setVerifiedAccount] = useState(false);

  const getUser = async () => {
    await axios
      .get(`${config.apiUrl}/User/${auth.getId()}`)
      .then((res) => {
        setUser(res.data);
        if(res.data.VerifiedAccount === false) {
          console.log(res.data.VerifiedAccount)
          setOpenModal(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const GetTopThreeJobs = async () => {
    await axios
      .get(`${config.apiUrl}/MatchingResults/GetTopThreeJobs`, {
        params: {
          UserId: auth.getId()
        }
      })
      .then((res) => {
        setTopThree(
          res?.data.TopThree.map((job) => ({
            id: job.AdvertisementMatchingId,
            jobAdId: job.AdvertisementMatchingId,
            Company: {
              Name: job.CompanyName,
              Email: job.CompanyEmail,
              Profile: job.ImageUrl
            },
            Job: job.ProfessionName || job.ProfessionText,
            Ort: job.City,
            Passend: Math.round(job.MatchingResult, 2),
            Handlung: { text: 'Ansehen', blue: true }
          }))
        );
        setApplicationCount(res?.data.NumberOfUsersJobsApplied);
        setNumOfTopJobs(res?.data.NumberOfJobsThatMatchUser);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.allSettled([getUser(), GetTopThreeJobs()]);

      setLoading(false);
    })();
  }, []);

  return (
    <DashboardLayout noPadding>
      <ComponentWrapper
        fluid
        padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
      >
        <Row style={{ justifyContent: 'initial' }}>
          <DashboardTitle>Dashboard</DashboardTitle>
          <ul style={{ marginBottom: '0' }}>
            <li style={{ fontSize: 18, color: '#494949' }}>
              {user?.FirstName} {user?.LastName}
            </li>
          </ul>
        </Row>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ height: '35px' }}
            onClick={() => history.push('/bewerber-dashboard/jobs')}
          >
            Alle Jobs ansehen
          </Button>
        </ButtonContainer>
        <ComponentWrapper
          noPaddingHorizontal
          style={{ borderBottom: '1px solid #E8E8E8' }}
        >
          {loading ? (
            <Container>
              <CircularLoader />
            </Container>
          ) : topThree.length ? (
            <TableWrapper
              tableTitle={''}
              columns={['Company', 'Job', 'Ort', 'Passend', 'Handlung']}
              rows={topThree}
              openOption={(data) =>
                history.push(
                  `/job/${encodeURIComponent(data.row.Job.replace('+', ''))}-${
                    data.row.jobAdId
                  }`
                )
              }
              minHeight={230}
            />
          ) : (
            <NothingFound>
              <p>Es gibt keinen Job, der Ihren Kriterien entspricht</p>
              <img src={NoApplications} alt="No applications" />
            </NothingFound>
          )}
        </ComponentWrapper>
        <JobsStats
          applicationCount={applicationCount}
          numOfTopJobs={numOfTopJobs}
        />
      </ComponentWrapper>

      <CustomModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          withRadius
          small
          height='auto'

        >
          <Flex column CenterCenter style={{ padding: '20px' }}>
            <h3 style={{ color: '#e83741', fontWeight: 600, marginBottom: 40 }}>
              GESCHAFFT!
            </h3>
            <p style={{ marginBottom: 40 }}>
              Möchtest du dein Profil jetzt ausfüllen? 
            </p>
            <img
              src={stepsDone}
              alt="stepsDone"
              style={{ marginBottom: 30 }}
            ></img>
            <ComponentWrapper>
              <SubmitButton
                small
                onClick={async () => {
                  //localStorage.setItem('SelectedOption', 'profile');
                  await patchdata('User', [
                    {
                      path: '/VerifiedAccount',
                      op: 'replace',
                      value: true
                    }
                  ]).then((res) => {
                    auth.setVerifiedTrue();
                   
                  });
                  history.push(
                    `/bewerber-dashboard/profileinstellungen`
                  );
                }}
              >
                Ja
              </SubmitButton>
            </ComponentWrapper>
            <SubmitButton small inversed={true} onClick={() => setOpenModal(false)}>
              Nein
            </SubmitButton>
          </Flex>
        </CustomModal>
    </DashboardLayout>
  );
}
