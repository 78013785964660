import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { ReactComponent as EditButton } from '../../assets/images/editButton.svg';
import './card.css';

const StrasseCard = ({
  title,
  onClick,
  columns = [],
  rows = [],
  noEditIcon = false
}) => {
  const classes = useStyles();

  const generateColumns = (data) =>
    data.map((element) => {
      return {
        field: element,
        flex: 1,
        sortable: false,
        hide: false
      };
    });

  return (
    <div className="strasseCard">
      <div className="strasseCard__header">
        <p>{title}</p>
        {!noEditIcon && <EditButton onClick={onClick} />}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ minHeight: 250, width: '100%' }}>
          <DataGrid
            columns={generateColumns(columns)}
            rows={rows}
            hideFooterSelectedRowCount
            className={classes.dataGrid}
            hideFooter
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            color="secondary"
            components={{
              NoRowsOverlay: 'dd'
            }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    'border': 'none',
    '& .MuiDataGrid-footer': {
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px dashed #C7C7C7'
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#fafafa',
      borderRadius: 5,
      color: '#707070',
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
      fontSize: 12,
      border: 'none',
      padding: 0
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-menuIcon': {
      display: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },

    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: '#fafafa'
    },

    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#c9333c',
      borderRadius: '8px'
    }
  }
}));

export default StrasseCard;
