import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import Years from '../CreateProfile/Steps/Step2Subcomponents.js/Years';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import SubmitButton from '../Buttons/submitButton';
import SelectAsync from '../select/selectAsync';
import { ReactComponent as Delete } from '../../assets/images/delete.svg';
import Snackbar from '../snackbar/snackbar';

import './modal.css';
import '../../assets/scss/createProfile.scss'
import ModalSteps from '../CreateProfile/Steps/ModalSteps';

const Title = styled.h2`
  color: #e83741;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export function ModalSelectNew({
  open,
  title,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  firstData,
  seconOption,
  createProfile,
  onClose,
  values = null,
  setValues = () => {},
  small,
  closeButton = false
}) {
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [snackbar, showSnackbar] = useState(false);

  const [modalValues, setModalValues] = useState({
    industryId: '',
    proffesionId: '',
    professionName: '',
    years: '3+'
  });
  const [firstArray, setFirstArray] = useState([])

  useEffect(() => {
    setFirstArray(firstData?.map((item) => {
      return ({
        Id: Object.values(item)[0],
        Name: Object.values(item)[1]
      })
    }))

  }, [firstData])

  const handleValuesChange = (name, value) => {
    setModalValues({ ...modalValues, [name]: value });
  };

  const spasiULocalData = (array) => {
    setValues(array);
    setModalValues({
      industryId: '',
      proffesionId: '',
      professionName: '',
      years: '3+'
    });
  };

  useEffect(() => {
    if (modalValues.industryId && modalValues.proffesionId) setValid(true);
    else setValid(false);
  }, [modalValues.industryId, modalValues.proffesionId]);

  const modalBody = (
    <form>
      <ComponentWrapper noPaddingHorizontal>
        <Title>{title}</Title>
      </ComponentWrapper>

      {/*first paragraph */}
      <InputContainer>

        <FormControl className={classes.formControl}>
          <Select
            className='create-profile-select'
            defaultValue=""
            displayEmpty={true}
            value={modalValues.industryId}
            onChange={(e) => {
              handleValuesChange('industryId', e.target.value);
            }}
            renderValue={value => firstArray?.find(nat => nat.Id === value)?.Name ?? firstParagraph}
          >
            {firstArray?.map((index) => (
                <MenuItem value={index.Id} key={index.Id}>
                  {index.Name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </InputContainer>

      <InputContainer>
        <SelectAsync
            className='create-profile-select'
            placeholder={secondParagraph}
          value={modalValues.proffesionId ? {
            value: modalValues.professionId,
            label: modalValues.professionName
          }: null}
          option={seconOption}
          onChange={(e) =>
            setModalValues({
              ...modalValues,
              proffesionId: e.value,
              professionName: e.label
            })
          }
        />
      </InputContainer>

      <InputContainer>
        {thirdParagraph && (
          <>
            <Paragraph>{thirdParagraph}</Paragraph>
            <Years
              dodajGodinu={(value) => {
                handleValuesChange('years', value);
              }}
              createProfile={createProfile}
              value={modalValues.years}
            />
          </>
        )}
      </InputContainer>

      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <SubmitButton
          onClick={() => {
            let newArray = values ? [...values] : [];
            if (newArray.length) {
              let exist = false;
              newArray.forEach((el) => {
                if (JSON.stringify(el) === JSON.stringify(modalValues))
                  exist = true;
              });

              if (exist) {
                showSnackbar(true);
              } else {
                newArray.push(modalValues);
                spasiULocalData(newArray);
                // if (!store) onClose();
              }
            } else {
              newArray.push(modalValues);
              spasiULocalData(newArray);
              //   if (!store) onClose();
            }
          }}
          valid={valid}
        >
          Hinzufügen
        </SubmitButton>
      </ComponentWrapper>

      {values && (
        <div className="modal-select__result">
          {values?.map((el) => (
            <div
              className="brancheCard__card"
              style={{
                border: '1px solid #9A9A9A',
                color: '#818181',
                fontSize: '14px',
                margin: '20px 0'
              }}
            >
              {[
                firstArray?.find((i) => i.Id == el.industryId)?.Name,
                el.professionName,
                thirdParagraph &&
                  (el.years == '3-'
                    ? 'weniger als 3 Jahre'
                    : el.years == '3+'
                    ? 'mehr als 3 Jahre'
                    : 'egal'),
                <Delete
                  onClick={() => {
                    const temp = values.filter(
                      (x) =>
                        x.industryId != el.industryId ||
                        x.proffesionId != el.proffesionId
                    );

                    setValues(temp);
                  }}
                />
              ].map((e) => (
                <span style={{ margin: '0 3px' }}>{e}</span>
              ))}
            </div>
          ))}
        </div>
      )}

      {closeButton && (
        <ComponentWrapper fluid displayFlex justifyEnd noPaddingHorizontal noPaddingTop>
          <SubmitButton onClick={onClose}>Fertig</SubmitButton>
        </ComponentWrapper>
      )}
    </form>
  );

  return (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="error"
      >
        Sie können keine doppelten Werte hinzufügen
      </Snackbar>
      <ModalSteps open={open} onClose={onClose} small={small}>
        {modalBody}
      </ModalSteps>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default ModalSelectNew;
