import React from 'react';
import styled from 'styled-components';
import '../../assets/scss/App.scss';
import auth from '../../auth';
import { LoginRegister, LogoutDashboard } from './navLinks';
const AccessibiltyContainer = styled.div`
  display: flex;
  justify-content: ${props => props.isLogged ? 'flex-end' : ''}
 
`;

export function Accessibility(props) {
  return (
    <AccessibiltyContainer isLogged = {auth.isAuthenticated()}>
      {auth.isAuthenticated() ? <LogoutDashboard /> : <LoginRegister />}
    </AccessibiltyContainer>
  );
}
