import React, { Fragment, useEffect, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import PropTypes from 'prop-types';
import { DashboardTitle } from '../../Pages/CompanyDashboard/Home';
import { Row } from '../CreateProfile/Steps';
import close from '../../assets/images/close-icon.svg';
import { ReactComponent as Salary } from '../../assets/images/euro-bills.svg';
import CustomModal from './Modal';
import { ModalInner } from './ModalOuter';
import GetData from '../../api/GetData';
import StrasseCard from '../ApplicationCards/strasseCard';
import BrancheCard from '../ApplicationCards/brancheCard';
import DegreeCard from '../ApplicationCards/degreeCard';
import axios from 'axios';
import config from '../../config/api.config';
import CircularFiller from '../../Pages/UserDashboard/CircularFiller';
import styles from './ApplicationModal.module.scss';
import MapPins from '../../assets/images/mapPins.svg';
import { JobTypeColor } from '../Jobs/JobsCard';
import ApplicantInfoCard from './ApplicantInfoCard';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import CircularLoader from '../Loader/CircularLoader';
import JobCard from '../ApplicationCards/jobCard';
import { formatSalary } from '../../utils/formatSalary';

export const ApplicationModal = ({
  open,
  onClose,
  userId,
  email,
  userName,
  job,
  match,
  officeApplications
}) => {
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState({});
  const [hobbies, setHobbies] = useState([]);
  const [language, setLanguage] = useState([]);

  const [street, setStreet] = useState([]);
  const [water, setWater] = useState([]);
  const [air, setAir] = useState([]);
  const [other, setOther] = useState([]);

  const [profession, setProfession] = useState([]);
  const [college, setCollege] = useState([]);
  const [branche, setBranche] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [employment, setEmployment] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [vocational, setvocational] = useState([]);
  const [jobRegion, setjobRegion] = useState(null);
  const [jobDetails, setjobDetails] = useState(null);

  const GetJobRegion = async (id) => {
    await axios
      .get(`${config.apiUrl}/Location/${id}`)
      .then((res) => setjobRegion(res.data))
      .catch((err) => console.error(err));
  };

  const GetHobbies = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantHobbies`, {
        params: {
          ApplicantId: userId,
          IncludeHobbies: true
        }
      })
      .then((res) => setHobbies(res.data))
      .catch((err) => console.error(err));
  };
  const GetLicences = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantLicense`, {
        params: {
          ApplicantId: userId,
          IncludeLicense: true
        }
      })
      .then((res) => {
        let street = [];
        let air = [];
        let water = [];
        let other = [];

        res.data.forEach((licence) => {
          switch (licence.License.LicenseTypeId) {
            case 1:
              street.push(licence);
              break;
            case 2:
              water.push(licence);
              break;
            case 3:
              air.push(licence);
              break;
            case 4:
              other.push(licence);
              break;
          }
        });

        setStreet(street);
        setWater(water);
        setAir(air);
        setOther(other);
      });
  };
  const GetLanguageLevel = async () => {
    await axios
      .get(`${config.apiUrl}/LanguageLevel`, {
        params: {
          ApplicantId: userId,
          IncludeLanguage: true,
          IncludeLevel: true
        }
      })
      .then((res) => setLanguage(res.data))
      .catch((err) => console.error(err));
  };
  const GetProfession = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantProfession`, {
        params: {
          ApplicantId: userId,
          IncludeBranch: true,
          IncludeProfession: true
        }
      })
      .then((res) => setProfession(res.data))
      .catch((err) => console.error(err));
  };
  const GetCollege = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantCollege`, {
        params: {
          ApplicantId: userId,
          IncludeCollege: true
        }
      })
      .then((res) => setCollege(res.data))
      .catch((err) => console.error(err));
  };
  const GetBranche = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantDesiredProfession`, {
        params: {
          ApplicantId: userId,
          IncludeBranch: true,
          IncludeProfession: true,
          IsDesired: true
        }
      })
      .then((res) => setBranche(res.data))
      .catch((err) => console.error(err));
  };
  const GetQualification = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantQualifications`, {
        params: {
          ApplicantId: userId,
          IncludeSkills: true,
          IncludeQualifications: true
        }
      })
      .then((res) => setQualification(res.data))
      .catch((err) => console.error(err));
  };
  const GetVocational = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantDesiredProfession`, {
        params: {
          ApplicantId: userId,
          IncludeBranch: false,
          IncludeProfession: true,
          IsDesired: false
        }
      })
      .then((res) => {
        setvocational(res.data);
      })
      .catch((err) => console.error(err));
  };
  const GetCertificate = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantCertificate`, {
        params: {
          ApplicantId: userId,
          IncludeCertificate: true
        }
      })
      .then((res) => setCertificate(res.data))
      .catch((err) => console.error(err));
  };
  const GetEmployment = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantEmployment`, {
        params: {
          ApplicantId: userId,
          IncludeEmployment: true
        }
      })
      .then((res) => setEmployment(res.data))
      .catch((err) => console.error(err));
  };
  const GetBenefit = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantBenefits`, {
        params: {
          ApplicantId: userId,
          IncludeBenefit: true
        }
      })
      .then((res) => setBenefit(res.data))
      .catch((err) => console.error(err));
  };

  const GetJobDetails = async (user_) => {
    await Promise.allSettled([
      user_.TravelingId &&
        axios.get(`${config.apiUrl}/Region/${user_.TravelingId}`),
      user_.ShiftId && axios.get(`${config.apiUrl}/Shift/${user_.ShiftId}`),
      user_.StartingDateId &&
        axios.get(`${config.apiUrl}/StartingDate/${user_.StartingDateId}`)
    ]).then((res) => {
      setjobDetails([
        {
          label: 'Eintrittstermin',
          value: res[2]?.value?.data.Type,
          id: res[2]?.value?.data.StartingDateId
        },
        {
          label: 'Reisebereitschaft',
          value: res[0]?.value?.data.RegionName,
          id: res[0]?.value?.data.RegionId
        },
        {
          label: 'Schichtbetrieb',
          value: res[1]?.value?.data.Shifts,
          id: res[1]?.value?.data.ShiftId
        }
      ]);
    });
  };

  useEffect(() => {
    (async () => {
      if (userId) {
        setloading(true);
        const res = await GetData(`User/${userId}`);
        setuser(res);

        await Promise.allSettled([
          GetJobDetails(res),
          GetJobRegion(res.JobRegionId),
          GetHobbies(),
          GetLicences(),
          GetLanguageLevel(),
          GetProfession(),
          GetCollege(),
          GetBranche(),
          GetQualification(),
          GetCertificate(),
          GetVocational(),
          GetEmployment(),
          GetBenefit()
        ]);
        setloading(false);
      }
    })();
  }, [userId]);

  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });

  return (
    <CustomModal
      open={open}
      onClose={() => {
        setuser(null);
        onClose();
      }}
      large
    >
      <ComponentWrapper displayFlex flexColumn grow fluidHeight noPadding>
        <div className={styles.modalHeader}>
          <ComponentWrapper padding={'5px 10px'} grow>
            <Row alignStart>
              <Row alignStart noJustify>
                <DashboardTitle red>
                  {userName}
                  <p
                    style={{
                      color: '#c1c1c1',
                      fontSize: 20,
                      fontWeight: 400,
                      marginBottom: 8
                    }}
                  >
                    {email}
                  </p>
                  <p
                    style={{
                      color: '#c1c1c1',
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    Anrede
                    
                    <span
                      style={{
                        marginLeft: 5,
                        color: '#e83741',
                        fontWeight: 600
                      }}
                    >
                      {user?.Gender?.GenderType}
                    </span>
                  </p>
                </DashboardTitle>

                <CircularFiller size={55} value={match || 0} />
              </Row>
            </Row>
          </ComponentWrapper>
          <ComponentWrapper padding={'5px 10px'} grow>
            <div className={styles.jobInfo}>
              <h1> {job?.Profession?.ProfesionName} </h1>
              <div className={styles.headerRow}>
                {officeApplications?.map((oa) => (
                  <>
                    <div className={styles.headerItem}>
                      <img
                        src={MapPins}
                        alt="location"
                        style={{ margin: '0 5px' }}
                        width="25"
                        height="25"
                      />
                      <p>{oa.Office?.BranchType}</p>
                    </div>
                    <div className={styles.headerItem}>
                      <JobTypeColor style={{ margin: '0 5px' }} />
                      <p>
                        {oa.Employments?.map((e) => e.EmploymentType).join(
                          ', '
                        )}
                      </p>
                    </div>
                  </>
                ))}
              </div>
              {user?.MinAnnualSalary !== 0 && (
                <div className={styles.headerItem}>
                  <Salary
                    style={{
                      width: '22px',
                      margin: '0 5px',
                      height: 'auto',
                      fill: '#44952c'
                    }}
                  />
                  <p>
                    {formatSalary(user?.MinAnnualSalary)}€ -{' '}
                    {formatSalary(user?.MaxAnnualSalary)}€{' '}
                  </p>
                </div>
              )}
            </div>
          </ComponentWrapper>
          <img
            src={close}
            className={styles.closeButton}
            alt="close"
            onClick={() => {
              setuser(null);
              onClose();
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {loading ? (
          <>
            <ComponentWrapper fluidHeight displayFlex justifyCenter>
              <CircularLoader />
            </ComponentWrapper>
          </>
        ) : (
          <ModalInner noPadding={isSmall}>
            <ComponentWrapper
              fluid
              displayFlex
              justifyBetween
              noPadding
              flexWrap
            >
              <ApplicantInfoCard userId={userId} user={user} />

              {college.length > 0 && (
                <DegreeCard
                  title="Hochschulabschluss"
                  data={college.map(({ CollegeDegree }) => (
                    <Fragment key={CollegeDegree.CollegeDegreeId}>
                      {CollegeDegree.DegreeName}
                    </Fragment>
                  ))}
                  noEditIcon
                  classes={styles.firstTwoCards}
                />
              )}
            </ComponentWrapper>

            <ComponentWrapper
              fluid
              displayFlex
              justifyBetween
              noPadding
              flexWrap
            >
              {street.length > 0 && (
                <StrasseCard
                  title="Straße"
                  columns={['name', 'kategorie']}
                  rows={street?.map(({ License }) => {
                    return {
                      id: License.LicenseId,
                      name: License.Name,
                      kategorie: License.Category
                    };
                  })}
                  noEditIcon
                />
              )}
              {water.length > 0 && (
                <StrasseCard
                  title="Wasser"
                  columns={['name', 'kategorie']}
                  noEditIcon
                  rows={water.map(({ License }) => {
                    return {
                      id: License.LicenseId,
                      name: License.Name,
                      kategorie: License.Category
                    };
                  })}
                />
              )}
              {/* </ComponentWrapper> */}
              {/* <ComponentWrapper fluid displayFlex justifyBetween noPadding> */}
              {air.length > 0 && (
                <StrasseCard
                  title="Luft"
                  columns={['name', 'kategorie']}
                  rows={air.map(({ License }) => {
                    return {
                      id: License.LicenseId,
                      name: License.Name,
                      kategorie: License.Category
                    };
                  })}
                  noEditIcon
                />
              )}
              {other.length > 0 && (
                <StrasseCard
                  title="Sonstige"
                  columns={['name', '']}
                  rows={other.map(({ License }) => {
                    return {
                      id: License.LicenseId,
                      name: License.Name,
                      kategorie: License.Category
                    };
                  })}
                  noEditIcon
                />
              )}
              {/* </ComponentWrapper> */}
              {/* <ComponentWrapper> */}
              {profession.length > 0 && (
                <BrancheCard
                  title="Berufserfahrung/en"
                  data={profession.map((prof) => [
                    prof.Branch?.BranchType,
                    prof.Profession?.ProfesionName,
                    prof.MoreThan3Year
                      ? 'mehr als 3 Jahre'
                      : 'weniger als 3 Jahre'
                  ])}
                  noEditIcon
                  style={{ width: '100%' }}
                />
              )}

              {branche.length > 0 && (
                <>
                  <BrancheCard
                    title="Die Branche, in der er arbeiten möchte"
                    data={branche.map((branch) => [
                      branch.Branch?.BranchType,
                      branch.Profession?.ProfesionName
                    ])}
                    noEditIcon
                    style={{ width: '100%' }}
                  />
                </>
              )}
              {jobRegion && (
                <JobCard
                  title="Weitere Angaben"
                  data={[
                    {
                      label: 'Stadt',
                      value: jobRegion?.City
                    },
                    {
                      label: 'Land',
                      value: jobRegion?.Country
                    },
                    {
                      label: 'Umkreis',
                      value: jobRegion?.Radius + 'km'
                    }
                  ]}
                  noEditIcon
                />
              )}

              {hobbies.length > 0 && (
                <DegreeCard
                  classes="hobbysCard"
                  title="Interessen & Hobbys"
                  data={hobbies.map((hobbie) => hobbie.Hobbie)}
                  noEditIcon
                />
              )}
              {/* <ComponentWrapper fluid displayFlex justifyBetween noPadding> */}
              {language.length > 0 && (
                <StrasseCard
                  title="Sprachen"
                  columns={['name', 'kategorie']}
                  rows={language.map((lan) => {
                    return {
                      id: lan.LanguageLevelId,
                      name: lan.Language?.Name,
                      kategorie: lan.Level?.LevelType
                    };
                  })}
                  noEditIcon
                />
              )}
              {qualification.length > 0 && (
                <StrasseCard
                  title="Zusatzqualifikationen / Kenntnisse"
                  columns={['name', 'niveau']}
                  rows={qualification.map((lan) => {
                    return {
                      id: lan.ApplicantQualificationsId,
                      name: lan.Qualifications.Name,
                      niveau: lan.Skills?.Skill
                    };
                  })}
                  noEditIcon
                />
              )}
              {/* </ComponentWrapper> */}

              {/* <ComponentWrapper fluid displayFlex justifyBetween noPadding> */}
              {employment.length > 0 && (
                <StrasseCard
                  title="Anstellungsart"
                  columns={['name', '']}
                  rows={employment.map(({ Employment }) => {
                    return {
                      id: Employment.EmploymentId,
                      name: Employment.EmploymentType
                    };
                  })}
                  noEditIcon
                />
              )}
              {benefit.length > 0 && (
                <StrasseCard
                  title="Benefits"
                  columns={['name', '']}
                  rows={benefit.map(({ Benefits }) => {
                    return { id: Benefits.BenefitsId, name: Benefits.Name };
                  })}
                  noEditIcon
                />
              )}
              {/* </ComponentWrapper> */}

              {/* <ComponentWrapper fluid displayFlex justifyBetween noPadding> */}

              <StrasseCard
                title="Schulabschluss"
                columns={['name']}
                rows={certificate.map(({ SchoolCertificate }) => {
                  return {
                    id: SchoolCertificate.SchoolCertificateId,
                    name: SchoolCertificate.CertificateType
                  };
                })}
                noEditIcon
              />
              <StrasseCard
                title="Ausbildung"
                columns={['name']}
                rows={vocational.map(({ Profession }) => {
                  return {
                    id: Profession.ProfessionId,
                    name: Profession.ProfesionName
                  };
                })}
                noEditIcon
              />
              {jobDetails && (
                <JobCard title="Weitere Angaben" data={jobDetails} noEditIcon />
              )}
            </ComponentWrapper>
          </ModalInner>
        )}
      </ComponentWrapper>
    </CustomModal>
  );
};

ApplicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired
  // userId: PropTypes.isRequired
};

export default ApplicationModal;
