import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TrendingCard from '../../components/TrendingCard/TrendingCard';
import fire from '../../assets/images/fire.png';
import arrow from '../../assets/images/arrow.png';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import Statistic from './Statistic';
import TableWrapper from '../../components/DataTable/TableWrapper';
import DashboardLayout from './DashboardLayout';
import GetData from '../../api/GetData';
import CustomModal from '../../components/modal/Modal';
import stepsDone from '../../assets/images/stepsDone.png';
import { Flex } from '../../components/Common/Input';
import SubmitButton from '../../components/Buttons/submitButton';
import { useHistory, useLocation } from 'react-router-dom';
import { JobAdModal, ModalOuter } from '../../components/modal';
import DeleteData from '../../api/DeleteData';
import { useMediaQuery } from 'react-responsive';

import auth from '../../auth';
import ApplicationModal from '../../components/modal/applicationModal';
import QuickApplyModal from '../../components/modal/QuickApplyModal';
import { DeviceSize } from '../../components/responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import DeletePrompt from './DeletePrompt';

export const DashboardTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #494949;
  ${(props) =>
    props.red &&
    `
  color:#e83741;
  `}
`;

const Home = (props) => {
  const history = useHistory();
  const location = useLocation();

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  const [loading, setloading] = useState(true);
  const [jobAds, setjobAds] = useState(null);
  const [user, setuser] = useState(null);

  const [jobApplications, setjobApplications] = useState(null);
  const [jobAdModal, setjobAdModal] = useState(false);
  const [quickApplyModal, setQuickApplyModal] = useState(false);
  const [editData, seteditData] = useState();
  //modal is shown only if you came from /login route
  const [modalopen, setmodalopen] = useState(
    (location.state && location.state.showModal) || false
  );
  const [deleteModal, setdeleteModal] = useState(false);

  const [applicationModal, setapplicationModal] = useState(false);
  const [statistics, setstatistics] = useState({
    NumberOfApplicants: 0,
    NumberOfBlogs: 0,
    NumberOfJobs: 0,
    NumberOfLocations: 0,
    lastApplicant: {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      date: ''
    },
    mostFreqeuntJob: {
      professionName: '',
      employmentText: '',
      city: ''
    }
  });

  const Userid = auth.getId();

  async function getJobApplication() {
    const response = await GetData(
      `JobApplicant?CompanyId=${Userid}&IncludeUser=true&IncludeJobAdv=true`
    );
    setjobApplications(response);
  }

  async function getJobAds() {
    const response = await GetData(
      `JobAdvertisement/Paging?PageNumber=1&PageSize=6&UserId=${Userid}&IncludePhoto=true&IncludeContact=true&IncludeOffice=true&IncludeBranch=true&IncludeStartingDate=true${
        auth.isOrganization() ? '&VolunteeringOnly=true' : ''
      }`
    );
    setjobAds(response);
  }

  async function getUser(id, set) {
    let response = await GetData(`User/${id}`);
    set(response);
  }

  const getStatistics = async () => {
    const res = await GetData(`user/getbyidcompanydashboard`, { id: Userid });
    setstatistics(res);
  };

  useEffect(() => {
    getUser(Userid, setuser);
    (async () => {
      setloading(true);
      getStatistics();

      await Promise.allSettled([getJobAds(), getJobApplication()]);
      setloading(false);
    })();
  }, []);

  const onDelete = async () => {
    await DeleteData(editData.type, editData.JobAdvertisementId);
    await getJobAds();
    seteditData(null);
    setdeleteModal(false);
  };

  return (
    <DashboardLayout noPadding>
      <ComponentWrapper
        fluid
        padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
      >
        <ComponentWrapper displayFlex flexWrap alignCenter noPaddingHorizontal>
          <DashboardTitle> Dashboard</DashboardTitle>
          <ul style={{ paddingLeft: '3em', marginBottom: 0 }}>
            <li style={{ fontSize: 18, color: '#494949' }}>{user?.Name}</li>
          </ul>
        </ComponentWrapper>
        <ComponentWrapper
          noPadding
          displayFlex
          justifyBetween
          flexWrap={isSmall}
        >
          <ComponentWrapper
            noPaddingLeft
            noPaddingHorizontal={isSmall}
            fluid
            displayFlex
            grow
          >
            <TrendingCard
              cardTitle={'Der Job mit den meisten Bewerbungen'}
              title={
                statistics?.mostFreqeuntJob?.professionName ||
                'Noch keine Daten'
              }
              subtitle={statistics?.mostFreqeuntJob?.employmentText} //"Development intern"
              place={statistics?.mostFreqeuntJob?.city}
              type={statistics?.mostFreqeuntJob?.professionName && 'Office'}
              date=""
              bgColor="#FFEDED"
              cardTitleColor="#DB2B35"
              icon={fire}
            />
          </ComponentWrapper>
          <ComponentWrapper
            noPaddingRight
            noPaddingHorizontal={isSmall}
            fluid
            displayFlex
            grow
          >
            <TrendingCard
              cardTitle={'Letzter Bewerber'}
              title={
                (statistics?.lastApplicant?.firstName &&
                  `${statistics?.lastApplicant?.firstName} ${statistics?.lastApplicant?.lastName}`) ||
                'Noch keine Daten'
              }
              subtitle={statistics?.lastApplicant?.email}
              place={statistics?.lastApplicant?.LocationCity}
              type=""
              date=""
              bgColor="#FFF4DE"
              cardTitleColor="#E1A800"
              icon={arrow}
            />
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper noPaddingHorizontal>
          <Statistic
            jobAd={statistics.NumberOfJobs}
            office={statistics.NumberOfLocations}
            applications={statistics.NumberOfApplicants}
            blogs={statistics.NumberOfBlogs}
          />
        </ComponentWrapper>

        {/* Tables */}
        {loading ? (
          <ComponentWrapper>
            <CircularLoader small />
          </ComponentWrapper>
        ) : (
          <>
            <ComponentWrapper noPaddingHorizontal>
              <TableWrapper
                tableTitle={
                  props.isOrganization
                    ? 'Anzeigenvorschau für Freiwilligenarbeit'
                    : 'Vorschau der Stellenanzeige'
                }
                tableFunction={
                  <p
                    style={{
                      color: '#E83741',
                      fontSize: 16,
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      history.push(
                        `${
                          auth.isOrganization()
                            ? 'organisations-dashboard'
                            : 'arbeitgeber-dashboard'
                        }/jobs`
                      )
                    }
                  >
                    Alle Jobs ansehen
                  </p>
                }
                columns={['Name', 'Standort', 'Typ', 'Status', 'Handlung']}
                rows={generateJobAdRows(jobAds && jobAds.list)} //{adRows}
                onRowClick={(e) => {
                  const jobClicked =
                    jobAds &&
                    jobAds.list.filter((x) => x.JobAdvertisementId === e.id)[0];

                  history.push({
                    pathname: `/job/${encodeURIComponent(
                      jobClicked.Profession?.ProfesionName?.replace('+', '') ??
                        jobClicked.ProfessionText?.replace('+', '')
                    )}-${jobClicked.JobAdvertisementId}`,
                    state: {
                      jobAd: jobClicked,
                      showModal: jobClicked.MatchingId ? false : true
                    }
                  });
                }}
                openOption={(data) => {
                  const jobClicked =
                    jobAds &&
                    jobAds.list.filter(
                      (x) => x.JobAdvertisementId === data.row.id
                    )[0];
                  seteditData(jobClicked);
                  setjobAdModal(true);
                }}
                deleteOption={(data) => {
                  const jobClicked =
                    jobAds &&
                    jobAds.list.filter(
                      (x) => x.JobAdvertisementId === data.row.id
                    )[0];

                  jobClicked.type = 'JobAdvertisement';

                  seteditData(jobClicked);
                  setdeleteModal(true);
                }}
              />
            </ComponentWrapper>
            <ComponentWrapper noPaddingHorizontal>
              <TableWrapper
                tableTitle={
                  props.isOrganization
                    ? 'Ehrenamtliche Bewerbungen'
                    : 'Bewerbungen'
                }
                tableFunction={
                  <p
                    style={{
                      color: '#E83741',
                      fontSize: 16,
                      cursor: 'pointer '
                    }}
                    onClick={() =>
                      history.push(
                        `${
                          auth.isOrganization()
                            ? 'organisations-dashboard'
                            : 'arbeitgeber-dashboard'
                        }/bewerber`
                      )
                    }
                  >
                    Alle Bewerber anzeigen
                  </p>
                }
                columns={['Name', 'Job', 'Standort', 'Passend', 'Handlung']}
                rows={generateApplicationsRows(jobApplications)}
                openOption={(data) => {
                  seteditData(data.row);
                  data.row.ApplicationType === "quick-apply"? setQuickApplyModal(true) :  setapplicationModal(true);
                }}
              />
            </ComponentWrapper>
          </>
        )}
        <ModalOuter
          open={jobAdModal}
          onClose={() => {
            seteditData();
            setjobAdModal(false);
          }}
          title="
          Arbeitsplätze"
          option={'Bearbeiten'}
          large
        >
          <JobAdModal
            closeModal={() => {
              setjobAdModal(false);
              seteditData();
              getJobAds();
            }}
            edit={editData ?? ''}
            setEditData={seteditData}
          />
        </ModalOuter>
        <ApplicationModal
          open={applicationModal}
          onClose={() => {
            setapplicationModal(false);
            seteditData(null);
          }}
          userId={editData && editData.UserId}
          jobId={editData && editData.JobId}
          match={editData && editData.Passend}
          matchingId={editData && editData.MatchingId}
          userName={editData && editData.Name}
          email={editData && editData.Email}
          officeApplications={editData && editData.OfficeApplications}
          job={
            editData &&
            jobAds?.list.filter(
              (item) => item.JobAdvertisementId === editData.JobId
            )[0]
          }
          title="Application"
          option="View application"
        />

        <CustomModal
          open={modalopen}
          onClose={() => setmodalopen(false)}
          withRadius
          small
        >
          <Flex column CenterCenter style={{ height: '100%' }}>
            <h3 style={{ color: '#e83741', fontWeight: 600, marginBottom: 40 }}>
              GESCHAFFT !
            </h3>
            <p style={{ marginBottom: 40 }}>
              Möchtest du dein Profil jetzt ausfüllen?
            </p>
            <img
              src={stepsDone}
              alt="stepsDone"
              style={{ marginBottom: 30 }}
            ></img>
            <ComponentWrapper>
              <SubmitButton
                small
                onClick={() => {
                  localStorage.setItem('SelectedOption', 'profile');
                  history.push(
                    `${
                      auth.isOrganization()
                        ? 'organisations-dashboard'
                        : 'arbeitgeber-dashboard'
                    }/profil`
                  );
                }}
              >
                Ja
              </SubmitButton>
            </ComponentWrapper>
            <SubmitButton
              small
              inversed={true}
              onClick={() => setmodalopen(false)}
            >
              Nein
            </SubmitButton>
          </Flex>
        </CustomModal>

        <QuickApplyModal
          openModal= {quickApplyModal}
          setOpenModal= {setQuickApplyModal}
          data={editData}
          />

        {/* delete item modal */}
        <DeletePrompt
          open={deleteModal}
          onClose={() => {
            setdeleteModal(false);
            seteditData(null);
          }}
          onDelete={onDelete}
        />
      </ComponentWrapper>
    </DashboardLayout>
  );
};

const generateJobAdRows = (data) => {
  var newRows = [];
  data &&
    data.forEach((element) => {
      newRows.push({
        id: element.JobAdvertisementId,
        Name: element.Profession?.ProfesionName || element.ProfessionText,
        Standort: element.JobLocation.City,
        Typ: element.Employments.length
          ? element.Employments.map((e) => e.EmploymentType).join(', ')
          : element.EmploymentText,
        Status: element.isActive ? 'Aktiv' : 'Inaktiv'
        // Handlung: { text: 'Edit', outlined: true, icon: edit }
      });
    });
  return newRows;
};
const generateApplicationsRows = (applications) => {
  var newRows = [];
  applications &&
    applications.forEach((element, index) => {
      newRows.push({
        id: index,
        Name: element.Applicant? `${element.Applicant?.FirstName} ${element.Applicant?.LastName}` : `${element.QuickApplyUser?.Name} ${element.QuickApplyUser?.LastName}`,
        Job:
          element?.JobAdvertisement?.Profession?.ProfesionName ||
          element?.JobAdvertisement?.ProfessionText,
          Email: element.Applicant? element.Applicant?.Email : element.QuickApplyUser?.Email,
        Standort: element.JobAdvertisement?.JobLocation?.City,
        Passend: element.ApplicationType === "quick-apply"? "quick-apply" : element.Result,
        Handlung: { text: 'Siehe Bewerbung', outlined: true },
        UserId: element.ApplicantId,
        JobId: element.JobAdvertisementId,
        MatchingId: element.JobAdvertisement?.MatchingId,
        OfficeApplications: element.OfficeApplications,
        ApplicationType: element.ApplicationType,
        PhoneNumber: element.QuickApplyUser?.PhoneNumber
      });
    });
  return newRows;
};

export default Home;
