import React, { useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import config from '../config/api.config.js';
import styled from 'styled-components';
import Snackbar from './snackbar/snackbar';

const Container = styled.div`
  flex-grow: 1;
  input {
    //height: 10px;
    width: 100%;
    position: relative;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: transparent;
    border-bottom: 1px solid #dddddd;
    padding-left: 8px;
  }
`;

export const GoogleAutocomplete = ({
  onPlaceSelected,
  defaultValue,
  className,
  placeholder,
  type = 'address',

}) => {
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  //is address selected from dropdown?
  const [isAddressSelected, setisAddressSelected] = useState();
  //is input field focused at least once
  const [isFocusedOnce, setisFocusedOnce] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;

    ((input) => {
      /* Store original event listener */
      const _addEventListener = input.addEventListener;

      const addEventListenerWrapper = (type, listener) => {
        if (type === 'keydown') {
          /* Store existing listener function */
          const _listener = listener;
          listener = (event) => {
            /* Simulate a 'down arrow' keypress if no address has been selected */
            const suggestionSelected =
              document.getElementsByClassName('pac-item-selected')?.length > 0;
            if (event.which == 13 && !suggestionSelected) {
              const e = new KeyboardEvent('keydown', {
                key: 'ArrowDown',
                code: 'ArrowDown',
                keyCode: 40,
                which: 40
              });
              _listener.apply(input, [e]);
            }
            _listener.apply(input, [event]);
          };
        }
        _addEventListener.apply(input, [type, listener]);
      };

      input.addEventListener = addEventListenerWrapper;
    })(input);
  }, []);

  const handleOnPlaceSelect = (place) => {
    console.log(place);
    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      setSnackbarMessage(
        `${place.name}  ist keine gültige Adresse. Bitte versuchen Sie es erneut.`
      );
      showSnackbar(true);
      return;
    }

    if (place.geometry) {
      let gAddress = {};
      gAddress.latitude = place.geometry.location.lat();
      gAddress.longitude = place.geometry.location.lng();
      gAddress.formattedAddress = place.formatted_address;

      const list = place.address_components;
      list.forEach((el) => {
        if (el.types.includes('street_number')) {
          gAddress.streetNumber = el.long_name;
        } else if (el.types.includes('route')) {
          gAddress.streetName = el.long_name;
        } else if (el.types.includes('sublocality_level_1')) {
          gAddress.district = el.long_name;
        } else if (el.types.includes('locality')) {
          gAddress.city = el.long_name;
        } else if (el.types.includes('country')) {
          gAddress.country = el.long_name;
        } else if (el.types.includes('postal_code')) {
          gAddress.postalCode = el.long_name;
        }
      });
      // console.log('select');
      setisAddressSelected(true);
      onPlaceSelected(gAddress);
    }
  };

  // useEffect(() => {
  //   // if user deleted input text for autocomplete and did not select new address from dropdown list delete info about address in parent component
  //   if (!isAddressSelected) {
  //     onPlaceSelected({});
  //   }
  // }, [isAddressSelected]);

  useEffect(() => {
    if (defaultValue?.length > 0) {
      setisAddressSelected(true);
    }
  }, [defaultValue]);

  // const onTextChanged = () => {
  //   onPlaceSelected({});
  // };

  return (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="warning"
      >
        {snackbarMessage}
      </Snackbar>
      <Container>
        <AutoComplete
          options={{
            types: [type],
            componentRestrictions: {
              country: 'de'
            }
          }}
          language="de"
          ref={inputRef}
          apiKey={config.googleApi}
          onPlaceSelected={(place) => handleOnPlaceSelect(place)}
          onChange={(e) => {
            // console.log(e.target.value);
            // setisAddressSelected(false);
            onPlaceSelected({});
          }}
          onFocus={() => {
            setisFocusedOnce(true);
          }}
          spellCheck={false}
          defaultValue={defaultValue}
          placeholder={placeholder ?? 'Ort eingeben'}
          className={className}
        />
      </Container>
    </>
  );
};
