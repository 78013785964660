import React, { useEffect, useState } from 'react';
import { Row, Label } from '../../CreateProfile/Steps/StepOne';
import { Container } from '../../CreateProfile/Steps/StepTwo';
import { Paragraph } from '../../CreateProfile/Steps/StepSixSubcomponents/Region';
import ComponentWrapper from '../../ComponentWrapper/ComponentWrapper';
import Region from '../../CreateProfile/Steps/StepSixSubcomponents/Region';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicantAge } from '../../../store/stepsSlice';
import CustomSlider from '../../slider/slider';

export function StepOne() {
  // const dispatch = useDispatch();
  // const applicantAge = useSelector((state) => state.steps.stepOne.applicantAge);

  // useEffect(() => {
  //   setages([applicantAge.ageMin, applicantAge.ageMax]);
  // }, [applicantAge]);

  // const [ages, setages] = useState([applicantAge.ageMin, applicantAge.ageMax]);

  // const handleChange = (e, newValue) => {
  //   setages(newValue);
  // };

  // const onChangeCommitted = (e, newValues) => {
  //   dispatch(setApplicantAge({ ageMin: newValues[0], ageMax: newValues[1] }));
  // };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto'
      }}
    >
      <Container>
        <Label>
          Bitte füllen Sie alle Felder aus damit wir Sie mit den passenden
          Bewerbern vernetzen können !
        </Label>

        <Region store={'STEP_ONE'} step={'stepOne'} />

        {/*Bewerber age*/}
        {/* <ComponentWrapper noPaddingHorizontal fluid displayFlex alignCenter>
          <Paragraph>Wie alt sollte Ihr Bewerber sein? </Paragraph>
          <CustomSlider
            value={ages}
            onChange={handleChange}
            onChangeCommitted={onChangeCommitted}
            min={18}
            max={65}
            style={{ maxWidth: '350px', margin: '20px 0 5px' }}
            valueLabelDisplay="on"
          />
        </ComponentWrapper> */}
      </Container>
    </form>
  );
}

export default StepOne;
