import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Logo1  from '../../assets/images/newLogo.png';
import auth from '../../auth';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:300px;

  @media (max-width: 1200px) {
    margin-left:0;
  }
`;

const LogoImg = styled.div`
  width: 300px;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    img{
      width: 220px;
    }
  }
  @media (max-width: 330px) {
    width: 100%;
    img{
      width: 180px;
    }
  }
`;

export function Logo(props) {
  const history = useHistory();
  return (
    <LogoWrapper>
      <LogoImg>
          <img src={Logo1} onClick={() => history.push(!auth.isAuthenticated() ? '/'
          : (auth.isCompany()
            ? '/arbeitgeber-dashboard'
            : (auth.isOrganization()
              ? '/organisations-dashboard'
              : '/bewerber-dashboard')))}/>
      </LogoImg>
    </LogoWrapper>
  );
}
