import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Title from './Title';
import { useHistory } from 'react-router-dom';
import { DeviceSize } from '../../components/responsive';
import { Link } from 'react-scroll';
import ProfilePicture from '../../assets/images/contact-image.png';
import { ReactComponent as Phone } from '../../assets/images/phone-red.svg';
import { ReactComponent as Mail } from '../../assets/images/mail-red.svg';
import { ReactComponent as Play } from '../../assets/images/play-button.svg';

const Card = styled.div`
  border-radius: 14px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: ${(props) => (props.mobilna ? 'column' : 'row')};
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 0 auto;
  padding: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  button {
    text-align: center !important;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    width: 80vw;
    flex-direction: column;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 50px;
  @media only screen and (max-width: ${DeviceSize.newLarge}px) {
    padding-left: 0px;
    width: 100%;
  }
  @media only screen and (max-width: ${DeviceSize.newSmall}px) {
    align-items: center;
  }
`;

export { Title };
export default function ContactPerson(props) {
  const history = useHistory();
  return (
    <div className="contact-person">
      <Card mobilna={props.mobilna} className="person-card">
        <ContactInfo>
          <p className="fur-arbeitgeber">Für Arbeitgeber</p>
          <h4>
            Personalsuche einfach <br />
            gemacht!
          </h4>
          {/* <p className="ihr-partner">IHR ANSPRECHPARTNER</p>
          <p className="name">Philipp Goebel</p> */}
          <p className="name">Wir haben Ihr Interesse geweckt?</p>
          <p className="name" style={{marginBottom: '20px'}}>Kontaktieren Sie uns: </p>
          <p className="info">
            <Phone /> 07634 69 89 676
          </p>
          <p className="info">
            <Mail /> info@spiderjob.de
          </p>
        </ContactInfo>

        <button
          className="button-video"
          onClick={() =>
            window.open('https://www.youtube.com/watch?v=qPebvcfbMcI', '_blank')
          }
        >
          <Play /> Zum Video
        </button>
        {/* <img src={ProfilePicture} className="person-picture" /> */}
      </Card>
      <div className="contact-person-buttons">
        <Link to={'function'} smooth spy duration={100}>
          <Button variant="contained" color="primary">
            {' '}
            So funktioniert’s{' '}
          </Button>
        </Link>
        <Link to={'package'} smooth spy duration={100}>
          <Button variant="contained" color="primary">
            Pakete & Preise
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          className="text-center"
          onClick={() => history.push('/kostenloses-erstgespräch')}
        >
          Beratung erhalten
        </Button>
      </div>
    </div>
  );
}
