import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import Years from '../../../components/CreateProfile/Steps/Step2Subcomponents.js/Years';
import { Paragraph } from '../../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import SubmitButton from '../../../components/Buttons/submitButton';
import SelectAsync from '../../../components/select/selectAsync';
import { ReactComponent as Delete } from '../../../assets/images/delete.svg';
import ModalSteps from '../../../components/CreateProfile/Steps/ModalSteps';

const Title = styled.h2`
  color: #e83741;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export function ModalSelect({
  open,
  title,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  firstData,
  selectedData,
  secondOption,
  onClose,
  save,
  deleteData,
  loading,
  branch = false
}) {
  const classes = useStyles();

  const [values, setValues] = useState({
    firstDataId: null,
    secondDataId: '',
    secondDataName: '',
    years: '3-'
  });

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  // useEffect(() => {
  //   setValues({
  //     firstDataId: '',
  //     secondDataId: '',
  //     secondDataName: '',
  //     years: '0'
  //   });
  // }, []);

  const modalBody = (
    <form>
      <ComponentWrapper noPaddingHorizontal>
        <Title>{title}</Title>
      </ComponentWrapper>

      {/*first paragraph */}
      <InputContainer>
        <Paragraph>{firstParagraph}</Paragraph>

        <Autocomplete
          options={firstData}
          noOptionsText="Keine Option"
          getOptionLabel={(option) => option.name}
          value={values.firstDataId}
          name="firstDataId"
          onChange={(e, value) => handleChange('firstDataId', value)}
          style={{ width: '100%', margin: '2% 0' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={' '} />
          )}
        />
      </InputContainer>

      <InputContainer>
        <Paragraph>{secondParagraph}</Paragraph>
        <SelectAsync
          value={{ value: values.secondDataId, label: values.secondDataName }}
          option={secondOption}
          onChange={(e) =>
            setValues({
              ...values,
              secondDataId: e.value,
              secondDataName: e.label
            })
          }
        />
      </InputContainer>

      <InputContainer>
        {thirdParagraph && !branch && (
          <>
            <Paragraph>{thirdParagraph}</Paragraph>
            <Years
              createProfile={true}
              dodajGodinu={(value) => handleChange('years', value)}
              value={values.years}
            />
          </>
        )}
      </InputContainer>

      <ComponentWrapper noPaddingHorizontal>
        <SubmitButton
          buttonClicked={loading}
          onClick={(_) => {
            save(values);
            setValues({
              firstDataId: null,
              secondDataId: '',
              secondDataName: '',
              years: '3-'
            });
          }}
          valid={values.firstDataId != null && values.secondDataId != '' && values.secondDataName != ''}
        >
          Hinzufügen
        </SubmitButton>
      </ComponentWrapper>

      <div className="brancheCard__content">
        {selectedData.map((prof) => (
          <div className="brancheCard__card" style={{ fontSize: '0.8rem' }}>
            {[
              prof.Branch.BranchType,
              prof.Profession?.ProfesionName,
              !branch &&
                (prof.MoreThan3Year
                  ? 'mehr als 3 Jahre'
                  : 'weniger als 3 Jahre'),
              <Delete
                onClick={() =>
                  deleteData(
                    branch
                      ? prof.ApplicantDesiredProfessionId
                      : prof.ApplicantProfessionId
                  )
                }
              />
            ].map((e) => (
              <span>{e}</span>
            ))}
          </div>
        ))}
      </div>
      <ComponentWrapper fluid displayFlex justifyEnd noPaddingHorizontal>
        <SubmitButton onClick={onClose}>Fertig</SubmitButton>
      </ComponentWrapper>
    </form>
  );

  return (
    <ModalSteps open={open} onClose={onClose}>
      {modalBody}
    </ModalSteps>
  );
}

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   position: 'absolute',
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[3],
  //   padding: theme.spacing(3),
  //   borderRadius: '30px',
  //   borderLeft: '35px solid #E83741',
  //   outline: 'none'
  // },
  formControl: {
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default ModalSelect;
