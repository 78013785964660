import React from 'react';
import styles from './ApplicationModal.module.scss';
import emailImg from '../../assets/images/contact-icon-3.png';
import phoneImg from '../../assets/images/contact-icon-2.png';

const ApplicantInfoCard = ({ user }) => {
  return (
    <>
      <div
        className={`strasseCard jobCard ${styles.applicantCard}  ${styles.firstTwoCards} `}
      >
        <div className={styles.imageContainer}>
          <img src={user?.ImagePath} alt="Img" />
        </div>
        <div className={styles.infoContainer}>
          <div className="strasseCard__header">
            <p>
              {' '}
              {user?.Title?.TitleName} {user?.FirstName} {user?.LastName}
            </p>
          </div>
          {user?.Email && (
            <div className={styles.contactItem}>
              <img src={emailImg} alt="" />
              <p> {user?.Email} </p>
            </div>
          )}

          {user?.Phone && (
            <div className={styles.contactItem}>
              <img src={phoneImg} alt="" />
              <p> {user?.Phone} </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicantInfoCard;
