import React from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import facebookIcon from '../../assets/images/facebook_filled.png';
import instagramIcon from '../../assets/images/instagram.png';
import linkedinIcon from '../../assets/images/linkedin_filled_2.jpg';
import twitterIcon from '../../assets/images/twitter.png';
import correctLink from '../../utils/correctLink';

import styles from './Job.module.scss';

const SocialNetworks = ({ socialNetworks }) => {
  const { facebook, twitter, linkedin, instagram } = socialNetworks;

  return (
    <>
      {(facebook || twitter || linkedin || instagram) && (
        <ComponentWrapper
          displayFlex
          alignCenter
          noPaddingHorizontal
          flexWrap
          className={styles.socialContainer}
        >
          <Paragraph autoWidth>Social Media</Paragraph>
          <ComponentWrapper displayFlex noPadding>
            {facebook && (
              <a target="_blank" href={correctLink(facebook)}>
                <img
                  src={facebookIcon}
                  alt="fb"
                  className={styles.socialIcon}
                />
              </a>
            )}
            {linkedin && (
              <a target="_blank" href={correctLink(linkedin)}>
                <img
                  src={linkedinIcon}
                  alt="ln"
                  className={styles.socialIcon}
                />
              </a>
            )}
            {twitter && (
              <a target="_blank" href={correctLink(twitter)}>
                <img src={twitterIcon} alt="tw" className={styles.socialIcon} />
              </a>
            )}
            {instagram && (
              <a target="_blank" href={correctLink(instagram)}>
                <img
                  src={instagramIcon}
                  alt="ig"
                  className={styles.socialIcon}
                />
              </a>
            )}
          </ComponentWrapper>
        </ComponentWrapper>
      )}
    </>
  );
};

export default SocialNetworks;
