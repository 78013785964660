import React, { useEffect, useState } from 'react';
import { Paragraph } from '../StepSixSubcomponents/Region';
import GetData from '../../../../api/GetData';
import SubmitButton from '../../../Buttons/submitButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepThree } from '../../../../store/stepsSlice';
import {ReactComponent as Cancel} from "../../../../assets/images/icon-cancel.svg";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {ReactComponent as Plus} from '../../../../assets/images/IconPlus.svg';


export default function HighSchool(props) {
  const dispatch = useDispatch();
  const highschoolArray = useSelector(
    (state) => state.steps.stepThree.highschoolArray
  );
  const [loadedSchools, setSchools] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState(highschoolArray);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getSchoolCertificate() {
      const response = await GetData('SchoolCertificate');
      setSchools(response);
    }
    getSchoolCertificate();
  }, []);

  const removeSelected = (industrija) => {
    const filteredIndustries = selectedIndustries.filter(
      (item) => item.SchoolCertificateId !== industrija.SchoolCertificateId
    );
    setSelectedIndustries(filteredIndustries);
    dispatch(updateStepThree({ highschoolArray: filteredIndustries}))
  };


  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  }

  const body = (
    <div className='highSchool'>
      <Select
            className='create-profile-select'
            defaultValue=""
            displayEmpty={true}
            multiple
            value={selectedIndustries.map(el => el.SchoolCertificateId)}
            onChange={(e) => {
              setSelectedIndustries(loadedSchools.filter(el => e.target.value.includes(el.SchoolCertificateId)))
            }}
            renderValue={(value) =>value.length? value.map(s => selectedIndustries?.find(nat => nat.SchoolCertificateId === s)?.CertificateType).join(', ') : 'Wähle dein Schulabschluss'}

          >
            {loadedSchools?.map((index) => (
                <MenuItem value={index.SchoolCertificateId} key={index.SchoolCertificateId}>
                  {index.CertificateType}
                </MenuItem>
              ))}
          </Select>
        <SubmitButton className="buttonHif"
          onClick={() => {
            dispatch(updateStepThree({ highschoolArray: selectedIndustries }));
          }}
          valid={selectedIndustries?.length > 0}
        >
          Hinzufügen
        </SubmitButton>

    </div>
  );

  return (
    <div className='vocational-wrapper'>
      <div className="vocational">
      <div className="vocational-left">
        <Paragraph autoWidth>
          {props.createProfile
            ? 'Schulabschluss hinzufügen'
            : 'Welchen Schulabschluss sollte der Bewerber haben?'}
        </Paragraph>
        {!open?  <Plus style={{"margin-left": "8px", "transform" : 'scale(0.8)'}} onClick={handleOpen}></Plus>
          :
          <button className='vocational-button--plus vocational-button--minus' onClick={handleOpen}>-</button>
          }
      </div>
      <div className='vocational-selected'>
        {highschoolArray.map(voc => <p key={voc.SchoolCertificateId}>{voc.CertificateType} <Cancel onClick={() => removeSelected(voc)}/></p>)}
       </div>
       </div>
          {open && body}
      {!props.createProfile && (
        <Paragraph style={{ fontSize: 14, opacity: 0.6, marginTop: '-1%' }}>
          (Sie können mehrere Schulabschlüsse auswählen)
        </Paragraph>
      )}
   </div>
  );
}
