import React from 'react';
import connection from '../../assets/images/connectionIcon.svg';
import help from '../../assets/images/helpIcon.svg';
import partner from '../../assets/images/partnerIcon.svg';
import instagram from '../../assets/images/instagramIcon.png';
import facebook from '../../assets/images/facebookIcon.png';
import youtube from '../../assets/images/youtubeIcon.png';
import circles from '../../assets/images/FooterCircles.png';
import logo from '../../assets/images/loginLogo.jpg';
import '../../assets/scss/App.scss';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  width: 100%;
  .main-container {
    display: flex;
  }
`;

const Link = styled.p`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  margin-bottom:0.5rem;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterContainer = styled.div`
  width: 100%;
`;

const Div = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default function Footer() {

  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isLaptop = useMediaQuery({ maxWidth: DeviceSize.laptop });

  return (
    <Container className='maxWidth' style={{ margin: '0 auto'}}>
      <div className="footer" style={{ marginTop: '100px' ,}}>
        {!isLaptop && (
          <div className="circles">
            {' '}
            <img
              src={logo}
              style={{
                width: '320px',
                height: 'auto',
                position: 'absolute',
                marginTop: '85px',
                marginLeft: '100px'
              }}
            />
            <img
              src={circles}
              style={{
                width: '220%',
                height: '100%',
                objectFit:'cover'
              }}
            />
          </div>
        )}

        <div
          className="main-container"
          style={!isMobile ? { marginRight: '100px' } : { marginLeft: '50px' }}
        >
          <FooterContainer>
            <div className="footerChild">
              {/* <div className="arrowup" onClick={scrollToTop}>
                <img src={arrowup} alt="Connection" />
              </div> */}
            </div>
            <div
              className={`footerChild footer-links ${
                isMobile ? 'mobile-footer' : ''
              }`}
            >
              <div className="group">
                <ImageContainer>
                  <img src={help} alt="help" />
                </ImageContainer>

                <Div>HILFE</Div>

                <div id="title"></div>
                <List>
                  <li>
                    <Link onClick={() => history.push('/impressum')}>
                      Impressum
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => history.push('/datenschutz-bestimmungen')}
                    >
                      Datenschutz
                    </Link>
                  </li>
                </List>
              </div>
              <div className="group">
                <ImageContainer>
                  <img src={connection} alt="Connection" />
                </ImageContainer>
                <Div>LINKS</Div>
                <div id="title"></div>
                <List>
                  <li>
                    <Link onClick={() => history.push('/kontakt')}>
                      Kontakt
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => history.push('/agb')}>AGB</Link>
                  </li>
                </List>
              </div>
              <div className="group">
                <ImageContainer>
                  <img src={partner} alt="Partner" />
                </ImageContainer>

                <Div>PARTNER</Div>
                <div id="title"></div>

                <List>
                  <li>
                    <Link
                      onClick={() =>
                        window.open('https://www.das-onlinebuch.de/', '_blank')
                      }
                    >
                      Das Onlinebuch
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        window.open('https://regionalmitherz.de', '_blank')
                      }
                    >
                      Regional mit Herz
                    </Link>
                  </li>
                </List>
              </div>
            </div>
            {/* Social media*/}

            <div
              className="footerChild follow-us-child"
              style={
                isMobile
                  ? { marginTop: '50px', marginRight: '55px' }
                  : { marginTop: '50px' }
              }
            >
              <div id="social-media">
                <div id="icons" style={{ gap: '1rem' }}>
                  <a
                    target="_blank"
                    href="https://instagram.com/spiderjob_deutschland?utm_medium=copy_link"
                  >
                    <img src={instagram} alt="Ig" id="instagram" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Berufsnetzwerk/"
                  >
                    <img src={facebook} alt="Fb" id="facebook" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCs-UZk8O-SNLCBLEzd-vABg"
                  >
                    <img src={youtube} alt="Yt" id="youtube" />
                  </a>
                </div>
              </div>
            </div>
          </FooterContainer>
        </div>
      </div>
    </Container>
  );
}
