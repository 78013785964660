import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Paragraph } from './Region';
import { Row } from '../StepTwo';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import Radiobuttons from '../Step2Subcomponents.js/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFive } from '../../../../store/stepsSlice';

export default function Shifts(props) {
  const dispatch = useDispatch();
  const workInShifts = useSelector(
    (state) => state.steps.stepFive.workInShifts
  );
  const shiftId = useSelector((state) => state.steps.stepFive.shiftId);

  const handleChange = (value) => {
    dispatch(updateStepFive({ workInShifts: value }));
  };

  const loadedShifts = (props.values && props.values) ?? [];
  return (
    <>
      <ComponentWrapper  displayFlex alignCenter justifyBetween noPaddingHorizontal fluid>
          <Paragraph autoWidth>
            {props.createProfile
              ? 'Möchtest du im Schichtbetrieb arbeiten?'
              : 'Muss Ihr Bewerber im Schichtbetrieb arbeiten?'}
          </Paragraph>

          <Radiobuttons
            setTrue={() => handleChange(true)}
            setFalse={() => handleChange(false)}
            name="shift"
            title="shift"
            naPromjenu={() => {}}
            value={workInShifts ? '1' : '0'}
          />
      </ComponentWrapper>

      {workInShifts && (
        <div className="w-100">
          {!props.createProfile && <Paragraph title>Wähle...</Paragraph>}
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <Select
                className='create-profile-select'
              required
              defaultValue=""
              color="secondary"
              name={props.name}
              value={shiftId ?? null}
              onChange={(e) =>
                dispatch(updateStepFive({ shiftId: e.target.value }))
              }
            >
              {loadedShifts.map((item) => (
                <MenuItem value={item.ShiftId} key={item.ShiftId}>
                  {item.Shifts}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}
