import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './CircularLoader.module.scss';

export default function CircularLoader({ message, small }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '50px 0'
      }}
      className={small ? styles.smallLoader : null}
    >
      <h4 className="mb-3">{message}</h4>
      <CircularProgress />
    </div>
  );
}
