import React, { useEffect, useState } from 'react';
import submitMatching from '../../utils/submitMatching';
import submitCandidate from '../../utils/submitCandidate';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import CircularLoader from '../Loader/CircularLoader';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const LoaderDiv = styled.div``;

export default function Loader(props) {
  const history = useHistory();
  const steps = useSelector((state) => state.steps);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: '992px' });

  const submitData = async () => {
    try {
      if (props.JobAd) {
        await submitMatching(steps);
        history.push('/erledigt');
      } else if (props.createProfile) {
        setIsLoading(true);
        await submitCandidate(steps);
        history.push({
          pathname: '/erledigt',
          createProfile: true
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log('🚀 ~ error', error);
      history.push({ pathname: '/error', url: window.location.pathname });
      setIsLoading(false);
    }
  };

  useEffect(() => submitData(), []);

  return isMobile && isLoading ? (
    <ComponentWrapper fluidHeight displayFlex justifyCenter>
      <CircularLoader />
    </ComponentWrapper>
  ) : (
    <CircularLoader></CircularLoader>
  
    // <LoaderDiv>
    //   <div className="body">
    //     <span>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //     </span>
    //     <div className="base">
    //       <span></span>
    //       <div className="face"></div>
    //     </div>
    //   </div>
    //   <div className="longfazers">
    //     <span></span>
    //     <span></span>
    //     <span></span>
    //     <span></span>
    //   </div>
    //   <h1 className="Bilal">Alt-F4 speeds things up.</h1>
    // </LoaderDiv>
  );
}
