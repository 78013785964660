import React, { useEffect, useState } from 'react';
import { Paragraph } from './Region';
import { Row } from '../StepTwo';
import GetData from '../../../../api/GetData';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import { Button } from '@mui/material';
import { ReactComponent as Delete } from '../../../../assets/images/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import ModalSelectNew from '../../../modal/ModalSelectNew';
import { updateStepFive } from '../../../../store/stepsSlice';

export default function Branche(props) {
  const dispatch = useDispatch();
  const branchArray = useSelector((state) => state.steps.stepFive.branchArray);
  const [open, setOpen] = useState(false);
  const [branch, setBranch] = useState(null);

  const [selectedItems, setselectedItems] = useState(branchArray);

  useEffect(() => {
    async function getBranches() {
      let result = await GetData('Branch');
      setBranch(result);
    }
    getBranches();
  }, []);

  return (
    <>
      <ComponentWrapper noPaddingHorizontal fluid>
        <Row>
          <Paragraph>
            {props.createProfile
              ? 'In welcher Branche möchtest du arbeiten?'
              : 'Bitte fügen Sie Ihre Branche hinzu'}
          </Paragraph>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            style={{ margin: '15px 0' }}
          >
            Wählen
          </Button>
        </Row>
        {!props.createProfile && (
          <Paragraph style={{ fontSize: 14, opacity: 0.6, marginTop: '-1%' }}>
            (Sie können mehrere Branchen auswählen)
          </Paragraph>
        )}

        <div className="modal-select__result">
          {selectedItems.map((el) => (
            <div
              key={el.industryId}
              className="brancheCard__card"
              style={{
                border: '1px solid #9A9A9A',
                color: '#818181',
                fontSize: '14px',
                margin: '20px 0'
              }}
            >
              {[
                branch?.find((i) => i.BranchId == el.industryId)?.BranchType,
                el.professionName,
                <Delete
                  onClick={() => {
                    const temp = selectedItems.filter(
                      (x) =>
                        x.industryId != el.industryId ||
                        x.proffesionId != el.proffesionId
                    );

                    setselectedItems(temp);
                    dispatch(updateStepFive({ branchArray: temp }));
                  }}
                />
              ].map((e, index) => (
                <span key={index} style={{ margin: '0 3px' }}>
                  {e}
                </span>
              ))}
            </div>
          ))}
        </div>
      </ComponentWrapper>

      <ModalSelectNew
        title={
          props.createProfile
            ? 'In welcher Branche möchtest du arbeiten?'
            : 'In welcher Branche suchen Sie?'
        }
        open={open}
        onClose={() => {
          dispatch(updateStepFive({ branchArray: selectedItems }));
          setOpen(false);
        }}
        firstData={branch}
        values={selectedItems}
        setValues={(values) => {
          setselectedItems(values);
        }}
        seconOption="Profession/Paging"
        firstParagraph={
          props.createProfile
            ? 'Gebe hier deine Branche ein?'
            : 'Geben Sie hier Ihre Branche an?'
        }
        secondParagraph={
          props.createProfile
            ? 'Teile uns hier die genaue Stellenbezeichnung ein die du suchst'
            : 'Teilen Sie uns hier Ihre genaue Stellenbezeichnung mit die Sie suchen'
        }
        closeButton={true}
      />
    </>
  );
}
