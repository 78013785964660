import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import { Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import SearchBar from '../../components/Beitrage/SearchBar';
import { JobAdModal, ModalOuter } from '../../components/modal';
import { JobAdPreviewModal } from '../../components/modal/jobAdPreviewModal'
import GetData from '../../api/GetData';
import { EmptyBox } from './Applications';
import EmptyList from '../../assets/images/empty-list.svg';
import JobsCard from '../../components/Jobs/JobsCard';
import auth from '../../auth';
import SubmitButton from '../../components/Buttons/submitButton';
import { useHistory, useLocation } from 'react-router-dom';
import { CardWrap } from './Gallery';
import DeleteData from '../../api/DeleteData';
import { Pagination } from '@mui/material';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import DeletePrompt from './DeletePrompt';
import Snackbar from '../../components/snackbar/snackbar';

const JobAd = (props) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [jobAds, setjobAds] = useState(null);
  const [user, setuser] = useState({});
  const [searchText, setsearchText] = useState('');
  const [editData, seteditData] = useState(null);
  const [loading, setisLoading] = useState(false);
  const [loadingJobs, setloadingJobs] = useState(true);
  const [deleteModal, setdeleteModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [order, setorder] = useState('descdate');
  const [snackbar, setsnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  //number of cards on the page
  const largerThan1700 = useMediaQuery({ minWidth: 1700 });
  const [pageSize, setpageSize] = useState(largerThan1700 ? 8 : 6);
  useEffect(() => {
    if (largerThan1700) {
      setpageSize(8);
    } else {
      setpageSize(6);
    }
  }, [largerThan1700]);

  const Userid = auth.getId();
  const jobsRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const [baseJobbQuery] = useState(
    `JobAdvertisement/Paging?&IncludePhoto=true&IncludeContact=true&IncludeOffice=true&IncludeBranch=true&IncludeStartingDate=true&IncludeSalary=true${
      auth.isOrganization() ? '&VolunteeringOnly=true' : ''
    }`
  );

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const getJobAds = async () => {
    setloadingJobs(true);
    const response = await GetData(baseJobbQuery, {
      PageSize: pageSize,
      PageNumber: currentPage,
      Userid,
      JobTitle: searchText,
      OrderBy: order
    });
    setjobAds(response);
    setcurrentPage(response.CurrentPage);
    setloadingJobs(false);
  };
  const getUser = async () => {
    const response = await GetData(`User/${Userid}`);
    setuser(response);
  };

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    getJobAds();
  }, [baseJobbQuery, searchText, currentPage, order, pageSize]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (location.state && location.state.job) {
      seteditData(location.state.job);
      if (location.state.delete) {
        setdeleteModal(true);
      } else {
        setmodalOpen(true);
      }
    }
  }, [location]);

  const onDelete = async () => {
    await DeleteData('JobAdvertisement', editData.JobAdvertisementId);
    seteditData(null);
    setdeleteModal(false);
    getJobAds();
  };

  const sortJobs = (e) => {
    switch (e) {
      case 'a-z':
        setorder(auth.isCompany() ? 'asccom' : 'ascorg');
        break;
      case 'z-a':
        setorder(auth.isCompany() ? 'desccom' : 'descorg');
        break;
      case 'oldest':
        setorder('ascdate');
        break;
      default:
        setorder('descdate');
        break;
    }
  };

  //used to update jobs list when property of certain job is changed (in order not to make get request for all jobs again)
  //specifically used for changing isActive property
  //!!!NOTE: CURRENTLY ONLY SUITABLE FOR CHANGING isActive PROPERTY
  const updateJobsList = (jobId, newJobObj) => {
    let newArr = [...jobAds.list];
    const oldJobObjIndex = newArr.findIndex(
      (x) => x.JobAdvertisementId === jobId
    );
    newArr[oldJobObjIndex] = {
      ...newArr[oldJobObjIndex],
      isActive: newJobObj.isActive
    };

    setjobAds({ ...jobAds, list: newArr });
  };

  const changeSnacbar = (message, severity = 'success') => {
    setsnackbar({ open: true, message, severity });
  };

  return (
    <DashboardLayout noPadding sidebar>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title={'Inhalt'}
          list={
            props.isOrganization
              ? [
                  { Name: 'Ehrenamt', Link: 'jobs' },
                  { Name: 'Bewerber', Link: 'bewerber' }
                ]
              : [
                  { Name: 'Arbeitsplätze', Link: 'jobs' },
                  { Name: 'Bewerber', Link: 'bewerber' },
                  { Name: 'Blogeinträge', Link: 'blogeinträge' }
                ]
          }
          storage={'jobs'}
        />
        <ComponentWrapper
          fluid
          padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
        >
          <Snackbar
            open={snackbar.open}
            onClose={() =>
              setsnackbar({ ...snackbar, open: false, message: '' })
            }
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Snackbar>
          <Row flexWrap>
            <DashboardTitle red>
              {' '}
              {props.isOrganization ? 'Ehrenamt' : 'Arbeitsplätze'}
            </DashboardTitle>

            <SubmitButton small onClick={() => setmodalOpen(true)}>
              Neue hinzufügen
            </SubmitButton>
          </Row>
          <ComponentWrapper noPaddingHorizontal className='blogs' style={{padding:'50px', marginBottom: '15px'}} displayFlex justifyCenter>
            <SearchBar
              searchText={searchText}
              loading={loading}
              onChange={(e) => {
                setisLoading(true);
                setTimeout(() => {
                  setsearchText(e);
                  setisLoading(false);
                }, 300);
              }}
              onSortChange={sortJobs}
            />
          </ComponentWrapper>

          {loadingJobs ? (
            <ComponentWrapper>
              <CircularLoader small />
            </ComponentWrapper>
          ) : (
            <>
              {jobAds && jobAds.list.length > 0 ? (
                <>
                  <CardWrap margin="0px" maxWidth="1500px">
                    {jobAds.list.map((job) => (
                      <JobsCard
                        key={job.JobAdvertisementId}
                        employment={
                          job.Employments.length? job.Employments : job.EmploymentText
                        }
                        city={job.JobLocation?.City}
                        companyName={user.Name}
                        industry={job.Branch.BranchType}
                        jobData={job}
                        updateJobsList={updateJobsList}
                        dashboard
                        onMoreClick={() => {
                          setmodalOpen(true);
                          seteditData(job);
                        }}
                        onDelete={() => {
                          setdeleteModal(true);
                          seteditData(job);
                        }}
                        onClick={(e) => {
                          history.push({
                            pathname: `/job/${encodeURIComponent(job.Profession?.ProfesionName?.replace("+", "") ?? job.ProfessionText?.replace("+", ""))}-${job.JobAdvertisementId}`,
                            state: {
                              jobAd: job,
                              showModal: job.MatchingId ? false : true
                            }
                          });
                        }}
                        changeSnacbar={changeSnacbar}
                        getAllJobs={getJobAds}
                      />
                    ))}
                  </CardWrap>
                  {jobAds && jobAds.TotalPages > 1 && (
                    <ComponentWrapper
                      fluid
                      displayFlex
                      noPaddingHorizontal
                      justifyStart
                    >
                      <Pagination
                        count={jobAds && jobAds.TotalPages}
                        page={currentPage}
                        onChange={onPageChange}
                        //  / color="secondary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                      />
                    </ComponentWrapper>
                  )}
                </>
              ) : (
                <EmptyBox>
                  <div className="mb-5">
                    <img src={EmptyList} alt="Empty"></img>
                  </div>
                  <h4 className="fw-bold">
                    {jobAds &&
                    jobAds.list !== (jobsRef.current && jobsRef.current.list)
                      ? 'Keine Ergebnisse verfügbar'
                      : 'Du hast noch keine Jobs'}
                  </h4>
                </EmptyBox>
              )}
            </>
          )}
        </ComponentWrapper>
      </ComponentWrapper>

      <ModalOuter
        open={modalOpen}
        onClose={() => {
          setmodalOpen(false);
          seteditData(null);
        }}
        title={props.isOrganization ? 'Ehrenamt' : 'Arbeitsplätze'}
        option={editData ? 'Bearbeiten' : 'Neue hinzufügen'}
        large
        header={ 
       <SubmitButton style={{marginTop:'5px'}} small onClick={() => setPreviewModal(true)}>
        Vorschau
      </SubmitButton>}
      >
        <JobAdModal
          closeModal={() => {
            setmodalOpen(false);
            seteditData(null);
            getJobAds();
          }}
          edit={editData ?? ''}
          setEditData={seteditData}
        />
      </ModalOuter>
      <JobAdPreviewModal data={editData} open={previewModal} closeModal={() => setPreviewModal(false)}/>
      <DeletePrompt
        open={deleteModal}
        onClose={() => {
          setdeleteModal(false);
          seteditData(null);
        }}
        onDelete={onDelete}
      />
    </DashboardLayout>
  );
};

export default JobAd;
