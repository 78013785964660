import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import { DeviceSize } from '../../../../components/responsive';

import { Row } from '../StepTwo';
import { GoogleAutocomplete } from '../../../GoogleAutocomplete';

import Slider from '../../../slider/slider';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setRegion, updateValid } from '../../../../store/stepsSlice';
import { useEffect } from 'react';

export const Paragraph = styled.p`
  width: 100%;
  font-size: 15px;
  margin-bottom: 0;
  overflow-wrap: break-word;


  ${(props) =>
    props.color &&
    `
    color: #218FDF;
  `}
    ${(props) =>
    props.marginBottom10 &&
    `
    margin-bottom: 10px;
  `}

  ${(props) =>
    props.marginTop20 &&
    `
    margin-top: 20px;
  `}
  ${(props) =>
    props.autoWidth &&
    `
  width:auto;

  @media (max-width: 992px) {
    width: 100%;
  }
  `}
  ${(props) =>
    props.font14 &&
    `
  font-size: 14px;
  `}
  ${(props) =>
    props.title &&
    `
  font-weight:bold;
  `}
    ${(props) =>
    props.grey &&
    `
    color:#929292;
  `}
    ${(props) =>
    props.small &&
    `
      font-size: 16px;

  `}

  ${(props) =>
    props.font18 &&
    `
      font-size: 18px;
  `}

    ${(props) =>
    props.large &&
    `
      font-size: 20px;
  `}
  ${(props) =>
    props.tiny &&
    `
      font-size: 14px;

  `}
     ${(props) =>
    props.margin &&
    `
      margin: revert;
  `}
    ${(props) =>
    props.noMargin &&
    `
          margin: auto;

  `}
  ${(props) =>
    props.textRight &&
    `
          text-align: right;

  `}

  ${(props) =>
    props.weight600 &&
    `
      font-weight: 600;
  `}
`;

export const Column = styled.div`
  display: block;
  margin-top: 20px;
  flex: 1;
`;

const InputContainer = styled.div`
  display flex;
  width: 100%;

  div:first-child {
    max-width:500px
  }
  
  div:nth-child(2) {
    margin-left: 10px;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;

    div:first-child {
      margin-right: 0;
    }
    
    div:nth-child(2) {
      margin-left: 0;
    }
  }
`;

export default function Region({ step, createProfile, dontCheckValidation }) {
  const dispatch = useDispatch();
  const regionData = useSelector((state) => state.steps[`${step}`].region);
  const [radius, setradius] = useState(regionData.radius);
  useEffect(() => {
    setradius(regionData.radius);
  }, [regionData.radius]);

  const onGoogleSelected = (place) => {
    dispatch(
      setRegion({
        data: {
          streetNumber: place?.streetNumber || null,
          streetName: place?.streetName || null,
          district: place?.district || null,
          city: place?.city || null,
          country: place?.country || null,
          postalCode: place?.postalCode || null,
          latitude: place?.latitude || null,
          longitude: place?.longitude || null,
          formattedAddress: place?.formattedAddress || null
        },
        step: step
      })
    );
  };
  const handleChange = (event, newValue) => {
    setradius(newValue);
  };

  const onChangeCommitted = () => {
    dispatch(setRegion({ data: { radius }, step: step }));
  };

  useEffect(() => {
    if (!dontCheckValidation) {
      if (regionData?.formattedAddress) {
        dispatch(updateValid(true));
      } else {
        dispatch(updateValid(false));
      }
    }
  }, [regionData?.formattedAddress]);

  return (
    <>
    <ComponentWrapper fluid displayFlex alignCenter justifyBetween noPaddingHorizontal>
      <Paragraph>
        {createProfile
          ? 'In welcher Region suchst du eine Stelle?'
          : step === 'stepFive'
          ? 'In welcher Region besetzen Sie Ihre Stelle?'
          : 'Aus welcher Region sollte Ihr Bewerber kommen?'}
      </Paragraph>

      <InputContainer>
        <GoogleAutocomplete
          onPlaceSelected={onGoogleSelected}
          defaultValue={regionData?.formattedAddress || ''}
          className='create-profile-select'
          placeholder="PLZ oder Ort eingeben…"
          type='(regions)'
        />
      </InputContainer>
    </ComponentWrapper>

      <Row>
        <ComponentWrapper noPaddingHorizontal fluid displayFlex alignCenter className="pb-0">
          <Paragraph>In welchem Umkreis suchen Sie?</Paragraph>
          <Slider
            onChange={handleChange}
            value={radius}
            onChangeCommitted={onChangeCommitted}
            style={{ maxWidth: '350px' }}
          />
        </ComponentWrapper>
      </Row>
    </>
  );
}
