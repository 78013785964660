import { ReactComponent as ListCheckmark } from '../../assets/images/list-checkmark.svg';
import GirlImage from '../../assets/images/bewerber-first-img.png';
import Mirror from '../../assets/images/bewerber-first-mirror.png';
import SearchBar from '../SearchBar/SearchBar';
import { Animated } from 'react-animated-css';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import AdComponent from './AdComponent';

const FirstStep = ({ isVisible }) => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.iPadPro });
  const smallMobile = useMediaQuery({ maxWidth: 350 });
  return (
    <section className="bewerber-section" id="bewerber-first">
      <div //umjesto diva staviti Animated kako bi se vratile animacije
        /*animationIn="bounceInDown" 
               animationOut="bounceOutUp" 
                 animationInDuration={1600} 
               animationOutDuration={1600}
               isVisible={isVisible} */
        className="section-card"
        style={smallMobile ? {height: '700px'}: {}}
      >
        <div
          /* animationIn="bounceInLeft" 
                animationOut="bounceOutLeft" 
                  animationInDuration={1600} 
                animationOutDuration={1600}
                animationOutDuration={1600}
                isVisible={isVisible} */
          className="section-text"
        >
          <p className="first-p-B">
            Die <span>Jobbörse</span> die matcht <span>&</span> vernetzt
          </p>
          <h3>Finde das, was zu Dir passt!</h3>
          <p className="first-p-B">
            #job #ausbildung #studium #praktikum #karriere
          </p>

                    <ul>
                           <li><ListCheckmark/>Kein Anschreiben mehr nötig</li>
                           <li><ListCheckmark/>Nie wieder Bewerbungsstress</li>
                           <li><ListCheckmark/>Geringer Zeitaufwand</li>
                           <li><ListCheckmark/>Immer und überall von unterwegs bewerben</li>
                           <li><ListCheckmark/>Nie wieder Jobs suchen - Jobs kommen zu Dir</li>
                    </ul>
           
                </div>
                {!isMobile &&<img src={GirlImage}/> }
                
            <SearchBar landing={true} animated={false} isVisible={isVisible}/>
            <AdComponent/>


            </div>
            {/* <div 
             
               className="first-section-mirror">
            <img src={Mirror} className="first-section-mirror"/>
            </div> */}
        </section>
    )
}

export default FirstStep;
