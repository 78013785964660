const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
    }

    return _service;
  }

  function _set(props) {
    Object.entries(props).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
  }

  function _get(key) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : undefined;
  }

  function _clear() {
    Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
  }

  function _all() {
    return { ...localStorage };
  }

  return {
    getService: _getService,
    set: _set,
    get: _get,
    clear: _clear,
    all: _all
  };
})();

export default LocalStorageService;
