import React from 'react';
import styled from 'styled-components';

const TitleTag = styled.h1`
  font-size: 28px;
  color: #e83741;
  text-align: center;
  margin-top: ${(props) => (props.mobilna ? '15%' : '7%')};
  width: 100% !important;
`;

export default function Title(props) {
  return (
    <>
      <TitleTag mobilna={props.mobilna}>{props.value}</TitleTag>
    </>
  );
}
