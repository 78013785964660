import React from 'react';
import { ReactComponent as EditButton } from '../../assets/images/editButton.svg';
import './card.css';

const JobCard = ({
  title,
  onClick,
  data = [],
  noEditIcon = false,
  classes = ''
}) => {
  const renderRows = () =>
    data &&
    data.map((el, index) => (
      <tr key={index}>
        <td>{el.label}</td>
        <td>{el.value}</td>
      </tr>
    ));
  return (
    <div className={`strasseCard jobCard ${classes}`}>
      <div className="strasseCard__header">
        <p>{title} </p>
        {!noEditIcon && <EditButton onClick={onClick} />}
      </div>
      <table>
        <tbody>{renderRows()}</tbody>
      </table>
      <div></div>
    </div>
  );
};

export default JobCard;
