import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import ImageWithDelete from '../../components/Dashboard/ImageWithDelete';
import UploadFile from '../../api/UploadFile';
import GetData from '../../api/GetData';
import DeleteData from '../../api/DeleteData';
import auth from '../../auth';
import { EmptyBox } from './Applications';
import EmptyList from '../../assets/images/empty-list.svg';
import SubmitButton from '../../components/Buttons/submitButton';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import CircularLoader from '../../components/Loader/CircularLoader';
import Snackbar from '../../components/snackbar/snackbar';
import ImageModal from '../../components/modal/ImageModal';

export const CardWrap = styled.div`
  display: grid;
  gap: 20px;
  justify-content: start;

  @media (max-width: 750px) {
    justify-content: center;
  }
  @media (min-width: 750px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  // & > * {
  //   margin: 1em;
  // }

  ${(props) =>
    props.gap &&
    `
    gap: ${props.gap}
`}
  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth}
    `}
`;

const Gallery = (props) => {
  const [gallery, setGallery] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const fileRef = useRef();
  const UserId = auth.getId();
  const [loadingGallery, setloadingGallery] = useState(true);
  const [snackbar, setsnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [imageSource, setimageSource] = useState(null);

  const onFileChange = async (event) => {
    setisLoading(true);
    const formData = new FormData();
    formData.append('File', event.target.files[0]);
    formData.append('Type', 'Upload image');
    formData.append('UserId', UserId);

    try {
      await UploadFile('File', formData);
      getGallery();
      setisLoading(false);
    } catch (error) {
      console.log('🚀 ~ error', error);
    }
  };

  const onDelete = async (id) => {
    try {
      await DeleteData('File', id);
      await getGallery();
    } catch (err) {
      setsnackbar({
        open: true,
        message:
          err.response?.data?.ERROR[0] ||
          'Unerwarteter Fehler. Bitte versuche es erneut.',
        severity: 'error'
      });
    }
  };

  async function getGallery() {
    const response = await GetData(`File?UserId=${UserId}&Type=Gallery`);
    setGallery(response);
  }
  useEffect(() => {
    (async () => {
      setloadingGallery(true);
      await getGallery();
      setloadingGallery(false);
    })();
  }, []);

  const uploadFile = () => {
    fileRef.current.click();
  };
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <DashboardLayout noPadding sidebar>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title={'Ihr Profil'}
          list={[
            { Name: 'Basisdaten', Link: 'profil' },
            { Name: 'Logo & Header-Grafik', Link: 'logo' },
            { Name: 'Galerie', Link: 'galerie' },
            { Name: 'Passwort und Konto', Link: 'konto' }
          ]}
          storage={'galerie'}
        />
        <Snackbar
          open={snackbar.open}
          onClose={() => setsnackbar({ ...snackbar, open: false, message: '' })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Snackbar>
        {loadingGallery ? (
          <ComponentWrapper
            minHeight="100vh"
            displayFlex
            alignCenter
            justifyCenter
            fluid
          >
            <CircularLoader />
          </ComponentWrapper>
        ) : (
          <ComponentWrapper
            fluid
            padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
          >
            <ComponentWrapper
              displayFlex
              flexWrap
              noPadding
              justifyBetween
              fluid
            >
              <DashboardTitle red> Galerie</DashboardTitle>
              <SubmitButton
                buttonClicked={isLoading}
                onClick={uploadFile}
                valid={true}
                type="submit"
              >
                Foto/Video hinzufügen
              </SubmitButton>
              <input
                type="file"
                hidden
                onChange={onFileChange}
                ref={fileRef}
                multiple
                // accept="image/*"
              />
            </ComponentWrapper>
            <ComponentWrapper padding="20px 10px">
              <CardWrap gap="30px">
                {gallery?.length > 0 ? (
                  gallery.map((image) => (
                    <ImageWithDelete
                      src={image.Url}
                      key={image.FileId}
                      handleDelete={() => onDelete(image.FileId)}
                      width={'auto'}
                      height="250px"
                      onClick={() => {
                        setimageSource(image.Url);
                      }}
                    />
                  ))
                ) : (
                  <EmptyBox>
                    <div className="mb-5">
                      <img src={EmptyList} alt="Empty"></img>
                    </div>
                    <h4 className="fw-bold">
                      Du hast noch keine Fotos in deiner Galerie
                    </h4>
                  </EmptyBox>
                )}
              </CardWrap>
            </ComponentWrapper>
          </ComponentWrapper>
        )}
      </ComponentWrapper>
      <ImageModal
        src={imageSource}
        onClose={() => {
          setimageSource(null);
        }}
      />
    </DashboardLayout>
  );
};

export default Gallery;
