import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { ModalOuter } from '../../../components/modal';
import SubmitButton from '../../../components/Buttons/submitButton';
import SelectedSkill from '../../../components/CreateProfile/Steps/SetFourSubcomponents/SelectedSkill';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';

const ModalAutocomplete = ({
  openSeconModal,
  modalTitle,
  setSelectedOptions,
  setOpenSeconModal,
  autocompleteOptions,
  selectedOptions,
  loading,
  SaveChanges,
  handleOptions,
  levels,
  textLabel
}) => {
  return (
    <ModalOuter
      showDot={false}
      open={openSeconModal}
      title={modalTitle}
      onClose={() => {
        setSelectedOptions([]);
        setOpenSeconModal(false);
      }}
    >
      <div style={{ height: '90%', position: 'relative' }}>
        <div>
          {autocompleteOptions && (
            <>
              <Autocomplete
                multiple
                noOptionsText="Keine Option"
                options={autocompleteOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                value={autocompleteOptions?.filter((a) =>
                  selectedOptions?.some((s) => s.id == a.id)
                )}
                onChange={(e, values) => handleOptions(e, values, false)}
                style={{ width: '100%', margin: '2% 0' }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={textLabel} />
                )}
              />
              {levels.length > 0 && (
                <>
                  {selectedOptions.length > 0 &&
                    selectedOptions.map((option, index) => (
                      <>
                        <SelectedSkill
                          values={option}
                          title={option.name}
                          data={levels}
                          allValues={selectedOptions}
                          setValue={handleOptions}
                          index={index}
                          selectedValue={option.secondId || null}
                        />
                      </>
                    ))}
                </>
              )}
            </>
          )}
        </div>
        <ComponentWrapper fluid displayFlex justifyCenter>
          <SubmitButton
            buttonClicked={loading}
            onClick={(_) => SaveChanges(modalTitle)}
            valid={true}
          >
            Speichern
          </SubmitButton>
        </ComponentWrapper>
      </div>
    </ModalOuter>
  );
};

export default ModalAutocomplete;
