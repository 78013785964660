import React from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Row } from '../CreateProfile/Steps';
import styled from 'styled-components';
import building from './building.png';
import locations from './locations.png';

const SmallParagraph = styled.p`
  font-size: 12px;
  color: #3c3c3c;
  margin-bottom: 0px;
`;

const TrendingCard = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.bgColor,
        width: '100%',
        height: '100%',
        borderRadius: 10,
        padding: 20,
        boxShadow: '0px 4px 10px rgba(255, 255, 255, 0.25)'
      }}
    >
      <ComponentWrapper noPaddingVertical>
        {props.icon && (
          <img
            src={props.icon}
            alt="title"
            style={{ marginRight: 10, marginLeft: -30 }}
          />
        )}
        <span
          style={{
            color: props.cardTitleColor,
            fontSize: 20,
            fontWeight: 600
          }}
        >
          {props.cardTitle}
        </span>
      </ComponentWrapper>
      <ComponentWrapper noPaddingBottom>
        <Row>
          <p style={{ fontSize: 16, fontWeight: 600, color: '#5b5b5b' }}>
            {props.title}
          </p>
          <SmallParagraph>{props.date}</SmallParagraph>
        </Row>
        <p style={{ fontSize: 14, color: '#5b5b5b' }}>{props.subtitle}</p>
        <br />
        <Row noJustify>
          {props.place && (
            <>
              <img src={locations} alt="location" />
              <SmallParagraph>{props.place}</SmallParagraph>

              <div
                style={{
                  borderRadius: '50%',
                  width: 5,
                  height: 5,
                  backgroundColor: '#3c3c3c'
                }}
              ></div>
            </>
          )}
          {props.type && (
            <>
              <img src={building} alt="type" />

              <SmallParagraph>{props.type}</SmallParagraph>
            </>
          )}
        </Row>
      </ComponentWrapper>
    </div>
  );
};

export default TrendingCard;
