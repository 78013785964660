import React, { useEffect, useState } from 'react';
import { Paragraph } from '../StepSixSubcomponents/Region';
import GetData from '../../../../api/GetData';
import SubmitButton from '../../../Buttons/submitButton';
import { updateStepThree } from '../../../../store/stepsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Cancel } from '../../../../assets/images/icon-cancel.svg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as Plus } from '../../../../assets/images/IconPlus.svg';

export default function College(props) {
  const dispatch = useDispatch();
  const collegeArray = useSelector(
    (state) => state.steps.stepThree.collegeArray
  );

  const [loadedColleges, setColleges] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState(collegeArray);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getColleges() {
      const response = await GetData('CollegeDegree');
      setColleges(response);
    }
    getColleges();
  }, []);

  const removeSelected = (industrija) => {
    const filteredIndustries = selectedIndustries.filter(
      (item) => item.CollegeDegreeId !== industrija.CollegeDegreeId
    );
    setSelectedIndustries(filteredIndustries);
    dispatch(updateStepThree({ collegeArray: filteredIndustries }));
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="vocational-wrapper">
      <div className="vocational">
        <div className="vocational-left">
          <Paragraph autoWidth>
            {props.createProfile
              ? 'Hochschulabschluss hinzufügen'
              : 'Wählen Sie den gewünschten Hochschulabschluss aus'}
          </Paragraph>
          {!open ? (
            <Plus
              style={{ 'margin-left': '8px', 'transform': 'scale(0.8)' }}
              onClick={handleOpen}
            ></Plus>
          ) : (
            <button
              className="vocational-button--plus vocational-button--minus"
              onClick={handleOpen}
            >
              -
            </button>
          )}
        </div>
        <div className="vocational-selected">
          {collegeArray.map((voc) => (
            <p key={voc.CollegeDegreeId}>
              {voc.DegreeName} <Cancel onClick={() => removeSelected(voc)} />
            </p>
          ))}
        </div>
      </div>

      {open && (
        <div className="highSchool">
          <Select
            className="create-profile-select"
            defaultValue=""
            displayEmpty={true}
            multiple
            value={selectedIndustries.map((el) => el.CollegeDegreeId)}
            onChange={(e) => {
              setSelectedIndustries(
                loadedColleges.filter((el) =>
                  e.target.value.includes(el.CollegeDegreeId)
                )
              );
            }}
            renderValue={(value) =>
              value.length
                ? value
                    .map(
                      (s) =>
                        selectedIndustries?.find(
                          (nat) => nat.CollegeDegreeId === s
                        )?.DegreeName
                    )
                    .join(', ')
                : 'Wähle dein Schulabschluss'
            }
          >
            {loadedColleges?.map((index) => (
              <MenuItem
                value={index.CollegeDegreeId}
                key={index.CollegeDegreeId}
              >
                {index.DegreeName}
              </MenuItem>
            ))}
          </Select>
          <SubmitButton
            className="buttonHif"
            onClick={() => {
              dispatch(updateStepThree({ collegeArray: selectedIndustries }));
            }}
            valid={selectedIndustries?.length > 0}
          >
            Hinzufügen
          </SubmitButton>
        </div>
      )}

      {!props.createProfile && (
        <Paragraph style={{ fontSize: 14, opacity: 0.6, marginTop: '-1%' }}>
          (Sie können mehrere Hochschulabschlüsse auswählen)
        </Paragraph>
      )}
    </div>
  );
}
