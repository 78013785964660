import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/scss/App.scss';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

export default function Imprint() {
  return (
    <div>
      <Helmet>
        <title>spiderjob - Impressum</title>
      </Helmet>
      <Header />
      <div className="imprint main-container">
        <h1>Impressum</h1>
        <h2>Anbieter dieser Webseiten</h2>
        <div id="info">
          <p className="mb-1">spiderjob GmbH</p>
          <p className="mb-1">Freiburger Straße 33</p>
          <p className="mb-1">79427 Eschbach</p>
          <p>Telefonnummer: 07634 69 89 676</p>
          <span>Website: </span>
          <span>
            <a
              href="http://www.spiderjob.de"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none'
              }}
              className="red"
            >
              www.spiderjob.de
            </a>
          </span>
          <br></br>
          <span>Mail: </span>
          <span>
            <a
              href="mailto:info@spiderjob.de"
              style={{
                textDecoration: 'none'
              }}
              className="red"
            >
              {' '}
              info@spiderjob.de
            </a>
          </span>
        </div>
        <div className="mt-4">
          <p className="m-0">Geschäftsführer: Marco Sprengler, Matthias Braun</p>
        </div>
        <div className="textblock">
          <p>Umsatzsteuer-Identifikationsnummer: DE346758523</p>
          <p>
            Dieses Impressum gilt auch für die Facebookseite:{' '}
            <a
              href="https://www.facebook.com/spiderjob-113578913686459/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none'
              }}
              className="red"
            >
              www.facebook.com/spiderjob-113578913686459/
            </a>
          </p>
          <p>Hinweise zur Website Urheberrechtliche Hinweise</p>
          <span>Bilder: </span>
          <span>
            <a
              href="http://www.stock.adobe.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none'
              }}
              className="red"
            >
              www.stock.adobe.com
            </a>
          </span>
        </div>
        {/*Prvi textbox */}
        <div className="text-with-title">
          <h2>Haftung für Inhalte</h2>
          <p>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Als
            Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
            diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
          </p>
          <br></br>
          <p>
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <br></br>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <br></br>
        </div>
        {/*Treci textbox */}
        <div className="text-with-title">
          <h2>Haftung für Links</h2>
          <p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich.
          </p>
          <br />
          <p>
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </div>
        {/*Cetvrti textbox */}
        <div className="text-with-title">
          <h2>Urheberrecht </h2>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung, öffentliche
            Zugänglichmachung und jede Art der Verwertung außerhalb der Grenzen
            des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers.{' '}
          </p>
          <br />
          <p>
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet.{' '}
          </p>
          <br />
          <p>
            Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
            werden, bitten wir um einen entsprechenden Hinweis. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
            umgehend entfernen.{' '}
          </p>
        </div>
        {/*Peti textbox */}
        <div className="text-with-title">
          <h2>Hinweis zur Online-Streitbeilegung </h2>
          <p>
            Die EU-Kommission stellt auf der Internetseite{' '}
            <a
              href="http://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none'
              }}
              className="red"
            >
              ec.europa.eu/consumers/odr/
            </a>{' '}
            die Möglichkeit zur Verfügung, ein Beschwerdeverfahren zur
            Online-Streitbeilegung für Verbraucher (OS) durchzuführen. Wir sind
            nicht bereit und nicht verpflichtet, an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.{' '}
          </p>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}