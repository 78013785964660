import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import SubmitButton from '../components/Buttons/submitButton';
import Snackbar from '../components/snackbar/snackbar';

import { DeviceSize } from '../components/responsive';

import Icon1 from '../assets/images/contact-icon-1.png';
import Icon2 from '../assets/images/contact-icon-2.png';
import Icon3 from '../assets/images/contact-icon-3.png';

import config from '../config/api.config';
import { Link } from 'react-router-dom';

const ContactContainer = styled.div`
  input {
    background-color: transparent !important;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 36px auto;
  font-size: 16px;
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 36px auto;
  font-size: 16px;
`;

const EmailInput = styled(StyledInput)``;
const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  ${EmailInput}:focus ~ & {
    color: #5264ae;
  }
`;
const FormContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-flow: row wrap;
  justify-content: space-evenly;
  font-size: 14px;
`;
const Paragraph = styled.p`
  width: 100%;
`;

const List = styled.ul`
  margin: 5% auto !important;
  line-height: 30px;
`;
const ListItem = styled.li`
  &::marker {
    color: #e83741;
  }
`;
export const KundenGroup = styled.div`
  display: flex;

  & > p {
    color: #3a3a3a;
  }
  margin: 10% 0 5% 5%;
  &:last-child {
    margin-top: 12%;
  }

  .contact-image-container {
    margin-right: 20px;
  }
`;

export const KundenServiceDiv = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-bottom: 100px;
  & > img {
    margin-top: 1.5%;
  }
  ${(props) =>
    props.small &&
    `
    width:50%;
  `}
`;

const InputContainer = styled.div`
  display: flex;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;
  }
`;

const LeftInputs = styled.div`
  flex: 1;
  margin-right: 20px;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    margin-right: 0;
  }
`;

const RightInputs = styled.div`
  flex: 1;
  margin-left: 20px;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    margin-left: 0;
  }
`;

const ContactTextContainer = styled.div`
  width: 60%;
  margin: 0 auto;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    width: 100%;
  }
`;

export default function ContactPage() {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: ''
  });
  const [buttonClicked, setButtonClicked] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [valid, setValid] = useState(false);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  useEffect(() => {
    if (state.email && state.fullName && state.message) setValid(true);
    else setValid(false);
  }, [state.email, state.fullName, state.message]);

  const submit = async () => {
    await setButtonClicked(true);
    await axios
      .post(`${config.apiUrl}/Email/SendMail`, state)
      .then((res) => {
        setButtonClicked(false);
        setState({
          fullName: '',
          email: '',
          message: ''
        });

        //setSnackbarMessage(res.data.Message);
        setSnackbarMessage("Ihr Nachricht wurde erfolgreich verschickt!")
        setSeverity('success');
        showSnackbar(true);
      })
      .catch((error) => {
        if (error.response) {
          setButtonClicked(false);
          setSnackbarMessage('Error while sending message to email.');
          setSeverity('error');
          showSnackbar(true);
          console.error(error.response.data);
        }
      });
  };

  return (
    <div>
      <Helmet>
        <title>spiderjob - Kontakt</title>
      </Helmet>

      <Header />
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity={severity}
      >
        {snackbarMessage}
      </Snackbar>

      <ContactContainer className="main-container">
        <h1 className="fc-p mb-5">Kontakt</h1>
        <div>
          <InputContainer>
            <LeftInputs>
              <div>
                <Label>Vollständiger Name*</Label>
                <StyledInput
                  name="fullName"
                  type="text"
                  value={state.fullName}
                  onChange={handleChange}
                />
              </div>

              <div>
                <Label>E-mail*</Label>
                <StyledInput
                  name="email"
                  type="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </div>
            </LeftInputs>

            <RightInputs>
              <div>
                <Label>Nachricht*</Label>
                <StyledTextarea
                  name="message"
                  type="text"
                  rows="6"
                  value={state.message}
                  onChange={handleChange}
                />
              </div>
            </RightInputs>
          </InputContainer>
        </div>

        {/* End of the form */}
        <FormContainer className="mb-5">
          <Paragraph className="mb-2">
            Daten werden auf den Systemen des Providers zwischengespeichert
          </Paragraph>
          <Paragraph className="mb-4 fc-p">
            <Link
              to="/datenschutz-bestimmungen"
              style={{
                textDecoration: 'none',
                color: '#E30613'
              }}
            >
              Hier können Sie die Datenschutzerklärung einsehen.
            </Link>
          </Paragraph>
          <SubmitButton
            onClick={submit}
            buttonClicked={buttonClicked}
            valid={valid}
          >
            Absenden
          </SubmitButton>
        </FormContainer>

        <ContactTextContainer>
          <h4 className="text-center fc-p">
            Ihr persönlicher Ansprechpartner ist immer für Sie da!{' '}
          </h4>
          <Paragraph>Kundennähe wird bei spiderjob groß geschrieben.</Paragraph>
          <Paragraph>
            Die exzellente Kundenbetreuung unseres Vertriebs zeichnet sich aus
            durch:
          </Paragraph>
          <List>
            <ListItem>persönliche, kompetente Ansprechpartner</ListItem>
            <ListItem>jahrelange Erfahrung und umfangreiches Knowhow</ListItem>
            <ListItem>
              Wir beraten Sie bei der Wahl des richtigen spiderpaketes für Ihr
              Unternehmen
            </ListItem>
          </List>
          <KundenServiceDiv>
            <h5 className="text-center fc-p">Kundenservice</h5>
            <div>
              <KundenGroup>
                <div className="contact-image-container">
                  <img src={Icon1} alt="Icon 1"></img>
                </div>
                <div>
                  <p className="fw-bold mb-1">Öffnungszeiten</p>
                  <span className="op-8">
                    Montag bis Freitag: 9.00 bis 18.00 Uhr
                  </span>
                </div>
              </KundenGroup>

              <KundenGroup>
                <div className="contact-image-container">
                  <img src={Icon2} alt="Icon 2"></img>
                </div>
                <div>
                  <p className="fw-bold mb-1">Telefonnummer</p>
                  <span className="op-8">
                    07634 69 89 676 (kostenfreie Hotline)
                  </span>
                </div>
              </KundenGroup>
              <KundenGroup>
                <div className="contact-image-container">
                  <img src={Icon3} alt="Icon 3"></img>
                </div>
                <div>
                  <p className="fw-bold mb-1">E-mail</p>
                  <span className="op-8">info@spiderjob.de</span>
                </div>
              </KundenGroup>
            </div>
          </KundenServiceDiv>
        </ContactTextContainer>
      </ContactContainer>

      <Footer />
    </div>
  );
}
