import React, { useEffect, useRef, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import { Input } from '../CreateProfile/Steps';
import UploadBranch from '../../utils/uploadBranch';
import SubmitButton from '../Buttons/submitButton';
import { GoogleAutocomplete } from '../GoogleAutocomplete';
import validator from 'validator';
import { ErrorText } from '../Common/Input';

export const LocationModal = (props) => {
  const [locationValues, setlocationValues] = useState({
    poslovnica: props.data.Name ?? '',
    city: props.data.Stadt ?? '',
    address: props.data.Adresse ?? '',
    latitude: props.data.Latitude ?? '',
    longitude: props.data.Longitude ?? '',
    locationId: props.data.LocationId,
    branchId: props.data.BranchId,
    postalCode: props.data.PostalCode ?? '',
    country: props.data.Land ?? '',
    id: props.data.id,
    userBranchId: props.data.UserBranchId,
    streetName: props.data.Adresse,
    formattedAddress: props.data.FormatedAddress
  });

  const [disabled, setdisabled] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [errors, seterrors] = useState({
    name: '',
    location: ''
  });

  const locationValuesRef = useRef(locationValues);

  const handleInputChange = (name, value) => {
    locationValuesRef.current = { ...locationValues, [name]: value };
    setlocationValues({ ...locationValues, [name]: value });
    // if (disabled) {
    //   setdisabled(false);
    // }
  };
  const OnSubmit = async () => {
    try {
      setisLoading(true);
      await UploadBranch(locationValues, props.edit);
      setisLoading(false);
      props.closeModal();
    } catch (error) {
      console.log('🚀 ~ error', error);
      setisLoading(false);
    }
  };

  function onGoogleSelected(place) {
    setlocationValues({
      ...locationValuesRef.current,
      formattedAddress: place?.formattedAddress,
      latitude: place?.latitude,
      longitude: place?.longitude,
      city: place?.city,
      country: place?.country,
      postalCode: place?.postalCode,
      streetName: place?.streetName,
      address: place?.streetName
    });
  }

  //Validation of fields
  useEffect(() => {
    let newErrors = { ...errors };
    if (!locationValues.poslovnica || !locationValues.formattedAddress) setdisabled(true)
      else setdisabled(false)
    //Name - min length 3
    if (locationValues.poslovnica) {
      if (!validator.isLength(locationValues.poslovnica, { min: 3 })) {
        newErrors.name = 'Name muss mindestens 3 Zeichen haben';
        setdisabled(true)
      } else {
        newErrors.name = '';
        locationValues.formattedAddress && setdisabled(false);
      }
    }

    //location -field required
    if (locationValues.formattedAddress) {
      if (validator.isEmpty(locationValues.formattedAddress)) {
        newErrors.location = 'Dieses Feld wird benötigt';
      } else {
        newErrors.location = '';
      }
    }
    seterrors(newErrors);
  }, [locationValues.poslovnica, locationValues.formattedAddress]);

  return (
    <>
      <ComponentWrapper
        fluid
        noPaddingVertical
        displayFlex
        justifyBetween
        flexColumn
        fluidHeight
      >
        <ComponentWrapper noPadding>
          <ComponentWrapper noPadding>
            <Paragraph grey small noMargin>
              Zweigname
            </Paragraph>
            <Input
              type="text"
              name="poslovnica"
              id="poslovnica"
              required
              multiline
              value={locationValues.poslovnica}
              noFill
              onChange={(e) => handleInputChange('poslovnica', e.target.value)}
            />
            <ErrorText>{errors.name}</ErrorText>
          </ComponentWrapper>
          <ComponentWrapper fluid noPaddingHorizontal>
            <Paragraph grey small>
              Standort
            </Paragraph>
            <GoogleAutocomplete
              onPlaceSelected={onGoogleSelected}
              defaultValue={locationValues.formattedAddress}
            />
            <ErrorText>{errors.location}</ErrorText>
          </ComponentWrapper>
        </ComponentWrapper>

        <ComponentWrapper displayFlex justifyCenter noPadding>
          <SubmitButton
            onClick={OnSubmit}
            disabled={disabled}
            buttonClicked={isLoading}
            style={{
              float: 'end'
            }}
          >
            {props.edit ? 'Änderungen speichern' : 'Neue hinzufügen'}
          </SubmitButton>
        </ComponentWrapper>
      </ComponentWrapper>
    </>
  );
};
