import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  valid: false,
  stepOne: {
    // applicantAge: { ageMin: 25, ageMax: 55 },
    region: { radius: 20 },
    personalData: {}
  },
  stepTwo: {
    completedApprenticeship: false,
    vocationalArray: [],
    hasWorkExperience: false,
    hasPersonalExperience: false,
    workExperience: []
  },
  stepThree: {
    completedHighSchool: false,
    highschoolArray: [],
    completedCollege: false,
    collegeArray: []
  },
  stepFour: {
    hobbies: [],
    languages: [],
    landLicences: [],
    waterLicences: [],
    airLicences: [],
    specialLicences: [],
    additionalQualifications: []
  },
  stepFive: {
    startingDateId: '',
    wouldTravel: false,
    travelRegionId: null,
    workInShifts: false,
    shiftId: null,
    benefitsArray: [],
    region: { radius: 20 },
    employmentArray: [],
    branchArray: []
  }
};

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    updateValid(state, { payload }) {
      state.valid = payload;
    },
    // setApplicantAge(state, { payload }) {
    //   state.stepOne.applicantAge = {
    //     ageMin: payload.ageMin,
    //     ageMax: payload.ageMax
    //   };
    // },
    setRegion(state, { payload }) {
      state[`${payload.step}`].region = {
        ...state[`${payload.step}`].region,
        ...payload?.data
      };
    },
    setPersonalData(state, { payload }) {
      state.stepOne.personalData = {
        ...state.stepOne.personalData,
        ...payload
      };
    },
    //although the same, seperate reducer functions are used for each step,
    //to facilitate debugging and tracking of state
    updateStepTwo(state, { payload }) {
      state.stepTwo = { ...state.stepTwo, ...payload };
    },
    updateStepThree(state, { payload }) {
      state.stepThree = { ...state.stepThree, ...payload };
    },
    updateStepFour(state, { payload }) {
      state.stepFour = { ...state.stepFour, ...payload };
    },
    updateStepFive(state, { payload }) {
      state.stepFive = { ...state.stepFive, ...payload };
    },
    clearState(state) {
      return initialState;
    },
    setMatchingData(state, { payload }) {
      const {
        Matching,
        MatchingDesiredProfessionFalse,
        MatchingDesiredProfessionTrue,
        Professions,
        Certificates,
        Colleges,
        Languages,
        Licenses,
        Qualifications,
        Benefits,
        Employments
      } = payload;
      const { FillingPosition, JobRegionForApplicant } = Matching;

      return {
        ...state,
        stepOne: {
          // applicantAge: {
          //   ageMin: Matching.ApplicantOldMin,
          //   ageMax: Matching.ApplicantOldMax
          // },
          region: {
            streetNumber: JobRegionForApplicant?.StreetNumber || null,
            streetName: JobRegionForApplicant?.StreetName || null,
            district: JobRegionForApplicant?.District || null,
            city: JobRegionForApplicant?.City || null,
            country: JobRegionForApplicant?.Contry || null,
            postalCode: JobRegionForApplicant?.PostalCode || null,
            latitude: JobRegionForApplicant?.Latitude || null,
            longitude: JobRegionForApplicant?.Longitude || null,
            formattedAddress: JobRegionForApplicant?.FormattedAddress || null,
            radius: Number(JobRegionForApplicant?.Radius) || 20,
            locationId: JobRegionForApplicant?.LocationId || null
          }
        },
        stepTwo: {
          completedApprenticeship: Matching.CompletedApprenticeship,
          vocationalArray:
            MatchingDesiredProfessionFalse.map((item) => ({
              value: item.Profession.ProfessionId,
              label: item.Profession.ProfesionName,
              branchId: item.BranchId,
              MatchingDesiredProfessionId: item.MatchingDesiredProfessionId
            })) || [],
          hasWorkExperience: Matching.CrofessionalExperience,
          hasPersonalExperience: Matching.PersonnelResponsibility,
          workExperience: Professions.map((item) => ({
            industryId: item.BranchId,
            proffesionId: item.ProfessionId,
            professionName: item.Profession.ProfesionName,
            years:
              item.MoreThan3Year === null
                ? '0'
                : item.MoreThan3Year === false
                ? '3-'
                : '3+',
            MatchingProfessionId: item.MatchingProfessionId
          }))
        },
        stepThree: {
          completedHighSchool: Matching.SchoolCertificate,
          highschoolArray: Certificates.map((item) => ({
            SchoolCertificateId: item.SchoolCertificateId,
            CertificateType: item.SchoolCertificate.CertificateType,
            MatchingCertificateId: item.MatchingCertificateId
          })),
          completedCollege: Matching.UniversityDegree,
          collegeArray: Colleges.map((item) => ({
            CollegeDegreeId: item.CollegeDegreeId,
            DegreeName: item.CollegeDegree.DegreeName,
            MatchingCollegeId: item.MatchingCollegeId
          }))
        },
        stepFour: {
          hobbies: [],
          languages:
            Languages.map((item) => ({
              LanguageId: item.LanguageId,
              Name: item.Language.Name,
              secondId: item.Level?.LevelId || null,
              MatchingLanguageId: item.MatchingLanguageId
            })) || [],
          landLicences:
            Licenses.filter((item) => item.License.LicenseTypeId === 1).map(
              (item) => ({
                LicenseId: item.LicenseId,
                Name: item.License.Name,
                LicenseTypeId: item.License.LicenseTypeId,
                Category: item.License.Category,
                MatchingLicenseId: item.MatchingLicenseId
              })
            ) || [],
          // waterLicences:
          //   Licenses.filter((item) => item.License.LicenseTypeId === 2).map(
          //     (item) => ({
          //       LicenseId: item.LicenseId,
          //       Name: item.License.Name,
          //       LicenseTypeId: item.License.LicenseTypeId,
          //       Category: item.License.Category,
          //       MatchingLicenseId: item.MatchingLicenseId
          //     })
          //   ) || [],
          // airLicences:
          //   Licenses.filter((item) => item.License.LicenseTypeId === 3).map(
          //     (item) => ({
          //       LicenseId: item.LicenseId,
          //       Name: item.License.Name,
          //       LicenseTypeId: item.License.LicenseTypeId,
          //       Category: item.License.Category,
          //       MatchingLicenseId: item.MatchingLicenseId
          //     })
          //   ) || [],
          specialLicences:
            Licenses.filter(
              (item) =>
                item.License.LicenseTypeId === 4 ||
                item.License.LicenseTypeId === 3 ||
                item.License.LicenseTypeId === 2
            ).map((item) => ({
              LicenseId: item.LicenseId,
              Name: item.License.Name,
              LicenseTypeId: item.License.LicenseTypeId,
              Category: item.License.Category,
              MatchingLicenseId: item.MatchingLicenseId
            })) || [],
          additionalQualifications:
            Qualifications.map((item) => ({
              QualificationsId: item.QualificationsId,
              Name: item.Qualifications.Name,
              secondId: item.Skills?.SkillsId || null,
              MatchingQualificationsId: item.MatchingQualificationsId
            })) || []
        },
        stepFive: {
          startingDateId: Matching.StartingDateId || '',
          wouldTravel: Matching.Travel || false,
          travelRegionId: Matching.TravelRegionId,
          workInShifts: Matching.Shifts || false,
          shiftId: Matching.ShiftId,
          benefitsArray:
            Benefits.map((item) => ({
              BenefitsId: item.BenefitsId,
              Name: item.Benefits.Name,
              MatchingBenefitsId: item.MatchingBenefitsId
            })) || null,
          region: {
            streetNumber: FillingPosition?.StreetNumber || null,
            streetName: FillingPosition?.StreetName || null,
            district: FillingPosition?.District || null,
            city: FillingPosition?.City || null,
            country: FillingPosition?.Contry || null,
            postalCode: FillingPosition?.PostalCode || null,
            latitude: FillingPosition?.Latitude || null,
            longitude: FillingPosition?.Longitude || null,
            formattedAddress: FillingPosition?.FormattedAddress || null,
            radius: Number(FillingPosition?.Radius) || 20,
            locationId: FillingPosition?.LocationId || null
          },
          employmentArray:
            Employments.map((item) => ({
              EmploymentId: item.EmploymentId,
              EmploymentType: item.Employment.EmploymentType,
              MatchingEmploymentId: item.MatchingEmploymentId
            })) || [],
          branchArray:
            MatchingDesiredProfessionTrue.map((item) => ({
              industryId: item.BranchId,
              proffesionId: item.Profession?.ProfessionId,
              professionName: item.Profession?.ProfesionName,
              MatchingDesiredProfessionId: item.MatchingDesiredProfessionId
            })) || []
        }
      };
    }
  }
});

export const {
  updateValid,
  // setApplicantAge,
  setPersonalData,
  setRegion,
  updateStepTwo,
  updateStepThree,
  updateStepFour,
  updateStepFive,
  clearState,
  setMatchingData
} = stepsSlice.actions;

export default stepsSlice.reducer;
