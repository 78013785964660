import LocalStorageService from './LocalStorageService';

class Auth {
  constructor() {
    this.load();
  }

  login(accessToken, expToken, userId, type, verified, paid, refreshToken, redirectTo) {
    const _config = {
      access_token: accessToken,
      exp_token: expToken,
      loggedUserId: userId,
      type,
      verified,
      paid,
      refreshToken: refreshToken
    };
    LocalStorageService.set(_config);
    this.load(_config);

    redirectTo();
  }

  load(_config) {
    const storage = _config ? _config : LocalStorageService.all();
    const { access_token, exp_token, loggedUserId, type, verified, paid , refreshToken} = storage;
    this.authenticated = !!access_token;
    this.type = type;
    this.userId = loggedUserId;
    this.accessToken = access_token;
    this.expToken = exp_token;
    this.verified = verified == 'False'? false : true;
    this.paid = paid;
    this.refreshToken = refreshToken;
  }

  dispose() {
    this.authenticated = false;
    this.verified = false;
    this.paid = "";
    this.type = this.userId = this.accessToken = this.expToken = null;
  }

  getId() {
    return this.userId;
  }
  getToken() {
    return this.accessToken;
  }

  getRefresh() {
    return this.refreshToken;
  }

  getType() {
    return this.type;
  }

  isApplicant() {
    return this.type === 'Applicant';
  }

  isOrganization() {
    return this.type === 'Organisation';
  }

  isCompany() {
    return this.type === 'Company';
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isVerified() {
    return this.verified;
  }
  setVerifiedTrue(){
    this.verified = true;
  }

  isPaid(){
    return this.paid != ""? true : false;
  }

  setPaid(value){
    this.paid = value;
  }

  isTokenExpired() {
    return new Date().getTime() / 1000 > this.expToken;
  }
  logout(logoutRedirect) {
    LocalStorageService.clear();
    this.dispose();
    logoutRedirect();
  }
}

export default new Auth();
