import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import FirstStep from '../../assets/images/bewerber-fifth-step-1.png';
import SecondStep from '../../assets/images/bewerber-fifth-step-2.png';
import ThirdStep from '../../assets/images/bewerber-fifth-step-3.png';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';

const FifthStep = ({ isVisible }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({maxWidth: "767px"});

  return (
    <section
      className="bewerber-section bewerber-section-second bewerber-section-fifth"
      id="bewerber-fifth"
    >
      <div
       /* animationIn="bounceInUp"
        animationOut="bounceOutDown"
        animationInDuration={1800}
        animationOutDuration={1600}
        isVisible={isVisible}*/
        className="section-content"
      >
        <div
        /*  animationIn="bounceInLeft"
          animationOut="bounceOutLeft"
          animationInDuration={1800}
          animationOutDuration={1600}
          isVisible={isVisible}*/
          className="content-title"
        >
          <p>In nur 3 Schritten zum neuen Job</p>
          <h4>So einfach funktioniert’s!</h4>
        </div>
        <div
         /* animationIn="bounceInUp"
          animationOut="bounceOutDown"
          animationInDuration={1800}
          animationOutDuration={1600}
          isVisible={isVisible}*/
          className="content-steps"
        >
          <div>
            <img src={FirstStep} />
            <h5>
              Kostenlos
              <br /> registrieren
            </h5>
            <p>
             Registriere dich kostenlos auf unserer Website und finde den Job, der zu dir passt!
            </p>
          </div>
          <div>
            <img src={SecondStep} />
            <h5>
              Erstelle dein
              <br /> persönliches Profil
            </h5>
            <p>
              Erstelle dein persönliches und aussagekräftiges Profil mit Namen,
              Adresse, Alter, Ausbildung, Berufserfahrung, Interessen, etc.
            </p>
          </div>
          <div>
            <img src={ThirdStep} />
            <h5>
              Füge deine <br /> Matching-Kriterien ein
            </h5>
            <p>
              Nun musst Du nur noch die spiderjob Matching-Kriterien wie
              Berufserfahrung, Sprachkenntnisse usw. ausfüllen und schon geht’s
              los!
            </p>
          </div>
        </div>
      </div>
      <div
        /*animationIn="bounceInUp"
        animationOut="bounceOutDown"
        animationInDuration={1800}
        animationOutDuration={1600}
        isVisible={isVisible}*/
      >
        <Button className='lastButton'
          variant="contained"
          color="primary"
          onClick={() => history.push('/profil-anlegen')}
        >
          Profil anlegen
        </Button>
      </div>
    </section>
  );
};

export default FifthStep;
