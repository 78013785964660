import React, { useState } from 'react';
import './searchBar.scss';
import { GoogleAutocomplete } from '../GoogleAutocomplete';
import { MenuItem, Select, TextField } from '@mui/material';
import GetDataPublic from '../../api/GetDataPublic';
import { useEffect } from 'react';
import { ReactComponent as IconSearch } from '../../assets/images/icon-suchen.svg';
import { useHistory } from 'react-router-dom';
import { Animated } from 'react-animated-css';

import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';



const SearchBar = (props) => {
  const history = useHistory();
  const [city, setCity] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [title, setTitle] = useState('');
  const [employment, setEmployment] = useState('');
  const [company, setCompany] = useState('');
  const [branch, setBranch] = useState('');
  const [partner, setPartner] = useState('');

  const [allEmployments, setAllEmployment] = useState([]);
  const [allBranches, setBranches] = useState([]);
  const isMobileSmall = useMediaQuery({ maxWidth: "767px" });
  const onGoogleSelected = (place) => {
    setLatitude(place?.latitude);
    setLongitude(place?.longitude);
    place?.city ? setCity(place?.city) : setCity(place);
  };

  const getData = async () => {
    const employmentData = await GetDataPublic(`Employment`);
    setAllEmployment(employmentData);
    const branchData = await GetDataPublic(
      props.voluntering ? 'Branch?IsJobs=false' : 'Branch'
    );
    setBranches(branchData);
  };
  const handleClick = async () => {
    props.partners && (await props.getJobs(partner))
    props.job && (await props.getJobs(title, company, employment, branch));
    (props.allJobs || props.voluntering) &&
      (await props.getJobs(title, city, employment, branch, latitude, longitude));
    props.landing &&
      history.push({
        pathname: '/jobs',
        state: {
          title: title,
          city: city,
          employment: employment,
          branch: branch,
          latitude: latitude,
          longitude: longitude
        }
      });
  };

  useEffect(() => {
    setTitle(props.title ?? '');
    setCity(props.city ?? '');
    setEmployment(props.employmentId ?? '');
    setBranch(props.branchId ?? '');
    setCompany(props.company ?? '');
  }, [props.title, props.city, props.employmentId, props.branchId, props.company]);

  useEffect(() => {
    getData();
  }, []);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.iPadPro });
  const searchBarContent = () => (
    <>
      <div className="search-fields" style={props.companyProfile && isMobile ? {marginTop: '90px'} : {}}> 
        <div
          className="search-fields-row"
          style={
            isMobile
              ? {
                  marginBottom:
                    (props.job ||
                      props.landing ||
                      props.allJobs ||
                      props.voluntering) &&
                    '15px',

                  flexDirection: 'column'
                }
              : {
                  marginBottom:
                    (props.job ||
                      props.landing ||
                      props.allJobs ||
                      props.voluntering) &&
                    '15px'
                }
          }
        >
          {!props.job &&
            !props.landing &&
            !props.allJobs &&
            !props.voluntering && (
              <Select
                value={partner}
                onChange={(e) => {
                  setPartner(e.target.value);
                }}
                className={`search-bar-input ${!partner && 'gewerbepark'}`}
                style = {  isMobileSmall
                  ? {  width: '100%', marginBottom: '10px' } : !isMobile ? {marginRight: '10px',} : { marginBottom: '10px'}}
                displayEmpty
                renderValue={(value) =>
                  value
                    ? props.partners.find((v) => v.PartnerId == value)
                        ?.PartnerName
                    : 'Gewerbepark, Schule, Universität…'
                }
              >
                {props.partners?.map((partner) => (
                  <MenuItem key={partner.PartnerId} value={partner.PartnerId}>
                    {partner.PartnerName}
                  </MenuItem>
                ))}
              </Select>
            )}

          {(props.job ||
            props.landing ||
            props.allJobs ||
            props.voluntering) && (
            <TextField
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              placeholder={isMobileSmall? "Stichwort, Jobtitel...": "Stichwort, Jobtitel oder Firmenname"} 
              className={`search-bar-input ${!title && 'gewerbepark'}`}
              style={
                isMobileSmall
                  ? {
                      marginRight: '26px',
                      marginBottom: '10px',
                      width: '100%'
                    }
                  : { marginRight: '26px', marginBottom: '10px' }
              }
            />
          )}

          {(!props.job ||
            props.landing ||
            props.allJobs ||
            props.voluntering) && (
            <GoogleAutocomplete style={
              !isMobile
                ? {
                  
                    marginLeft: '0px',
                    marginTop: '10px',
                    width: '100%',
                  
                  }
                : {}
            }
              onPlaceSelected={onGoogleSelected}
              defaultValue={city}
              placeholder="PLZ oder Ort"
              className="search-bar-input search-bar-googlemap"
              type="(regions)"
          
              
            />
          )}

          {props.job && (
            <Select
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              displayEmpty
              renderValue={(value) =>
                value
                  ? props.allCompanies.find((e) => e.Id == value)?.Name
                  : props.company ?  props.allCompanies.find((e) => e.Id == props.company)?.Name : 
                  `Firma im ${props.partnerName}`
              }
              className={`search-bar-input ${!company && 'gewerbepark firma'}`}
              
            >
              {props.allCompanies?.map((company) => (
                <MenuItem key={company.Id} value={company.Id}>
                  {company.Name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        {(props.job || props.landing || props.allJobs) && (
          <div
            className="search-fields-row"
            style={
              isMobile
                ? {
                    flexDirection: 'column'
                  }
                : {}
            }
          >
            <Select
              value={employment}
              onChange={(event) => setEmployment(event.target.value)}
              displayEmpty
              renderValue={(value) =>
                value
                  ? allEmployments.find((e) => e.EmploymentId == value)
                      ?.EmploymentType
                  : props.btnStudien  ? "Duales Studium, Abschlussarbeit, Werkstudent" : props.btnAusbildung ? "Ausbildung": 'Anstellungsart'
                  //hardkodirano iz PartnerDetails.jsx
              }
              className={`search-bar-input ${
                !employment && 'gewerbepark anstellungsart'
              }`}
              style={isMobileSmall ? { width: '100%' } : {}}
            >
              <MenuItem key={'noKey'} value={''}>
                Egal
              </MenuItem>
              {allEmployments.map((employment) => (
                <MenuItem
                  key={employment.EmploymentId}
                  value={employment.EmploymentId}
                >
                  {employment.EmploymentType}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={branch}
              onChange={(event) => setBranch(event.target.value)}
              displayEmpty
              renderValue={(value) =>
                value
                  ? allBranches.find((e) => e.BranchId == value)?.BranchType
                  : 'Branche'
              }
              className={`search-bar-input ${!branch && 'gewerbepark branch'}`}
              style={
                isMobileSmall
                  ? { marginLeft: '0px', marginTop: '10px', width: '100%' }
                  : isMobile ? { marginLeft: '0px' , marginTop: '10px'} : {marginLeft: '26px'}
              }
            >
              <MenuItem key={'noKey'} value={''}>
                Egal
              </MenuItem>
              {allBranches.map((branch) => (
                <MenuItem key={branch.BranchId} value={branch.BranchId}>
                  {branch.BranchType}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {props.voluntering && (
          <div className="search-fields-row">
            <Select
             
              value={branch}
              onChange={(event) => setBranch(event.target.value)}
              displayEmpty
              renderValue={(value) =>
                value
                  ? allBranches.find((e) => e.BranchId == value)?.BranchType
                  : 'Kategorie wählen'
              }
              className={`search-bar-input ${
                !branch && 'gewerbepark voluntering'
              }`}
              style={isMobileSmall ? { width: '320px' } : {}}
            >
              <MenuItem key={'noKey'} value={''}>
                Egal
              </MenuItem>
              {allBranches.map((branch) => (
                <MenuItem key={branch.BranchId} value={branch.BranchId}>
                  {branch.BranchType}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
      <button
        className='searchB'
        onClick={handleClick}
        style={
         
          isMobile ? 
          {
                top:
                  (props.job ||
                    props.landing ||
                    props.allJobs) ?
                  '22%' : ( 
                    props.voluntering)  ? '15%' : (props.partners) && props.blogsDash ? '-5%': '-5px',
                right:
                  (
                    props.landing ||
                    props.allJobs ||
                    props.voluntering || props.blogsDash) ?
                  '0px' : (props.job ||props.partners) && '-5%'
              } : 
              {
                top:
                  (props.job ||
                    props.landing ||
                    props.allJobs ||
                    props.voluntering) &&
                  '5px',
                right:
                  (
                    props.allJobs ||
                    props.voluntering) ?
                  '0px' : (props.job ||props.landing) ? ' -5%' : (props.partners || props.blogsDash) && '10px'
              }
        }
      >
        <IconSearch />
        {props.job || props.landing || props.allJobs || props.voluntering
          ? 'FREIE STELLE FINDEN'
          : 'Suchen'}
      </button>
    </>
  );

  return (
    <>
      {props.animated ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1600}
          animationOutDuration={1600}
          isVisible={props.isVisible}
          className="search-bar"
        >
          {searchBarContent()}
        </Animated>
      ) : (
        <div className="search-bar" >{searchBarContent()}</div>
      )}
    </>
  );
};

export default SearchBar;
