import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import GetDataPublic from '../api/GetDataPublic';
import GetData from '../api/GetData';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import CompanyMain from '../components/CompanyProfile/CompanyMain';
import SubpageLinks from '../components/CompanyProfile/SubpageLinks';
import { AllJobs } from './AllJobs';
import Beiträge from './Beiträge';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import CircularLoader from '../components/Loader/CircularLoader';
import SocialShare from '../components/SocialShare/SocialShare';
import '../assets/scss/companyProfile.scss';
import { useMediaQuery } from 'react-responsive';

const CompanyProfile = () => {
  const [loading, setloading] = useState(true);
  const [company, setcompany] = useState(null);
  const [branches, setBranches] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const [profilePhoto, setprofilePhoto] = useState(null);
  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: 1128 });
  const [socialMedia, setSocialMedia] = useState(null);

  useEffect(() => {
    (async () => {
      setloading(true);
      const res = await GetDataPublic(
        `user/companyprofile?id=${params.name.split('-').pop()}`
      );
      const branches = await GetData(
        `UsersBranches?IncludeOffice=true&IncludeContactPerson=true&UserId=${params.name
          .split('-')
          .pop()}`
      );
      const jobs = await GetDataPublic(
        `JobAdvertisement/Paging?&&CheckIsActive=true&IncludeOffice=true&IncludeBranch=true&${`UserId=${params.name
          .split('-')
          .pop()}`}`
      );

      setHeaderPhoto(res.HeaderImageUrl);
      setprofilePhoto(res.ProfileImageUrl);
      setBranches(branches);
      setJobs(jobs);
      setcompany({
        Id: res.Id,
        Name: res.CompanyName,
        LocationAddress: res.CompanyLocation?.FormattedAddress,
        Website: res.CompanyWebsite,
        AboutMe: res.AboutMe,
        Phone: res.Phone,
        ContactEmail: res.ContactEmail,
        UserType: res.UserType,
        Image: res.HeaderImageUrl
      });
      setloading(false);
      const socialArray = res.SocialNetworks[0];
      setSocialMedia({
        facebook: socialArray?.FacebookUrl,
        instagram: socialArray?.InstagramUrl,
        linkedin: socialArray?.LinkedinUrl,
        twitter: socialArray?.TwitterUrl,
        youtube: socialArray?.YoutubeUrl
      });
    })();
  }, []);

  const [activeSubpage, setactiveSubpage] = useState('Unternehmen');

  const displaySubpage = () => {
    switch (activeSubpage) {
      case 'Stellenangebote':
        return (
          <AllJobs
            isOrganisationProfile={company.UserType === 'Organisation'}
            companyProfile
            userId={company.Id}
          />
        );
      case 'Beiträge':
        return <Beiträge companyProfile userId={company.Id} />;
      default:
        return (
          <CompanyMain
            company={company}
            branches={branches}
            profilePhoto={profilePhoto}
            jobs={jobs}
            setactiveSubpage={setactiveSubpage}
          />
        );
    }
  };

  return (
    <ComponentWrapper displayFlex flexColumn noPadding minHeight="130vh">
      <Header />
      {loading ? (
        <>
          <ComponentWrapper displayFlex justifyCenter alignCenter grow>
            <CircularLoader />
          </ComponentWrapper>
        </>
      ) : (
        <>
          <div className="company-profile maxWidth">
            <div className="profile-image">
              <img src={headerPhoto} alt="companyProfile" />
            </div>
           
              {/* <div
                className="background-margin"
                style={{ marginTop: '20px' }}
              /> */}
              <div className="company-content maxWidth">
                <img
                  src={profilePhoto}
                  alt="company image"
                  className="company-image"
                />

                <SubpageLinks
                  changeSubpage={setactiveSubpage}
                  active={activeSubpage}
                  company={company.UserType === 'Company'}
                  socialNetworks={socialMedia}
                />

                {/* {!isMobile && (
                  <SocialShare style={ activeSubpage == "Stellenangebote" ? { right: '118px', top: '-1990px' }:{ right: '118px', top: '-990px' } } />
                )} */}
              </div>
              <div className="subpage-div">{displaySubpage()}</div>
              <div className="company-social-links">
                <SocialShare />
              </div>
           
          </div>
        </>
      )}

      <Footer />
    </ComponentWrapper>
  );
};

export default CompanyProfile;
