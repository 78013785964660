import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

import auth from '../../../auth';
import config from '../../../config/api.config';

import JobDetails from './jobDetails';
import JobRegion from './jobRegion';

import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import StrasseCard from '../../../components/ApplicationCards/strasseCard';
import BrancheCard from '../../../components/ApplicationCards/brancheCard';
import DegreeCard from '../../../components/ApplicationCards/degreeCard';
import Snackbar from '../../../components/snackbar/snackbar';

import ModalChip from './modalChip';
import ModalAutocomplete from './modalAutocomplete';
import ModalBranch from './modalBranch';
import HobbieModal from './modalHobbie';

import GetData from '../../../api/GetData';
import PostData from '../../../api/PostData';
import DeleteData from '../../../api/DeleteData';
import ModalVocational from './ModalVocational';
import ModalQualifications from './ModalQualifications';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../../components/responsive';

const ProfileMatchingData = ({
  jobRegionId,
  travelingId,
  startingDateId,
  shiftId
}) => {
  // const [hobbies, setHobbies] = useState([]);
  const [language, setLanguage] = useState([]);

  //licence
  const [street, setStreet] = useState([]);
  // const [water, setWater] = useState([]);
  // const [air, setAir] = useState([]);
  const [other, setOther] = useState([]);

  const [profession, setProfession] = useState([]);
  const [college, setCollege] = useState([]);
  const [branche, setBranche] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [employment, setEmployment] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [vocational, setvocational] = useState([]);

  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [openSeconModal, setOpenSeconModal] = useState(false);
  const [openThirdModal, setOpenThirdModal] = useState(false);
  // const [openHobbieModal, setOpenHobbieModal] = useState(false);
  const [openVocationalModal, setopenVocationalModal] = useState(false);
  const [openQualificationModal, setopenQualificationModal] = useState(false);

  //all data
  const [loadedChips, setLoadedChips] = useState(null);
  const [selectedChips, setSelectedChips] = useState(null);

  const [autocompleteOptions, setAutocompleteOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [levels, setLevels] = useState([]);
  const [textLabel, setTextLabel] = useState('');
  const [branchOptions, setBranchOptions] = useState([]);

  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [loading, setLoading] = useState(false);

  // const GetHobbies = async () => {
  //   await axios
  //     .get(`${config.apiUrl}/ApplicantHobbies`, {
  //       params: {
  //         ApplicantId: auth.getId(),
  //         IncludeHobbies: true
  //       }
  //     })
  //     .then((res) => setHobbies(res.data))
  //     .catch((err) => console.error(err));
  // };
  const GetLicences = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantLicense`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeLicense: true
        }
      })
      .then((res) => {
        let street = [];
        // let air = [];
        // let water = [];
         let other = [];

         res.data.forEach((licence) => {
          switch (licence.License.LicenseTypeId) {
            case 1:
              street.push(licence);
              break;
            case 2:
              other.push(licence);
              break;
            case 3:
              other.push(licence);
              break;
            case 4:
              other.push(licence);
              break;
          }
        });

        setStreet(street);
        //  setWater(water);
        //setAir(air);
        setOther(other);
      });
  };
  const GetLanguageLevel = async () => {
    await axios
      .get(`${config.apiUrl}/LanguageLevel`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeLanguage: true,
          IncludeLevel: true
        }
      })
      .then((res) => {
        setLanguage(res.data);
      })
      .catch((err) => console.error(err));
  };
  const GetProfession = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantProfession`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeBranch: true,
          IncludeProfession: true
        }
      })
      .then((res) => setProfession(res.data))
      .catch((err) => console.error(err));
  };
  const GetCollege = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantCollege`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeCollege: true
        }
      })
      .then((res) => setCollege(res.data))
      .catch((err) => console.error(err));
  };
  const GetBranche = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantDesiredProfession`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeBranch: true,
          IncludeProfession: true,
          IsDesired: true
        }
      })
      .then((res) => setBranche(res.data))
      .catch((err) => console.error(err));
  };
  const GetVocational = async () => {
    axios
      .get(`${config.apiUrl}/ApplicantDesiredProfession`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeBranch: false,
          IncludeProfession: true,
          IsDesired: false
        }
      })
      .then((res) => {
        setvocational(res.data);
      })
      .catch((err) => console.error(err));
  };
  const GetQualification = async () => {
    try {
      const res = await axios.get(`${config.apiUrl}/ApplicantQualifications`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeSkills: true,
          IncludeQualifications: true
        }
      });

      setQualification(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const GetCertificate = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantCertificate`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeCertificate: true
        }
      })
      .then((res) => setCertificate(res.data))
      .catch((err) => console.error(err));
  };
  const GetEmployment = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantEmployment`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeEmployment: true
        }
      })
      .then((res) => setEmployment(res.data))
      .catch((err) => console.error(err));
  };
  const GetBenefit = async () => {
    await axios
      .get(`${config.apiUrl}/ApplicantBenefits`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeBenefit: true
        }
      })
      .then((res) => setBenefit(res.data))
      .catch((err) => console.error(err));
  };
  //load all data
  const getAllColleges = async () => {
    let response = await GetData('CollegeDegree');
    setLoadedChips(
      response
        .filter(
          (r) => !college.some((c) => c.CollegeDegreeId == r.CollegeDegreeId)
        )
        .map((r) => {
          return {
            id: r.CollegeDegreeId,
            name: r.DegreeName
          };
        })
    );
  };
  const getAllSchoolCertificate = async () => {
    let response = await GetData('SchoolCertificate');
    setLoadedChips(
      response
        .filter(
          (r) =>
            !certificate.some(
              (c) => c.SchoolCertificateId == r.SchoolCertificateId
            )
        )
        .map((r) => {
          return {
            id: r.SchoolCertificateId,
            name: r.CertificateType
          };
        })
    );
  };
  const getAllEmployment = async () => {
    let response = await GetData('Employment');
    setLoadedChips(
      response
        .filter(
          (r) => !employment.some((c) => c.EmploymentId == r.EmploymentId)
        )
        .map((r) => {
          return {
            id: r.EmploymentId,
            name: r.EmploymentType
          };
        })
    );
  };
  const getAllBenefit = async () => {
    let response = await GetData('Benefits');
    setLoadedChips(
      response
        .filter((r) => !benefit.some((c) => c.BenefitsId == r.BenefitsId))
        .map((r) => {
          return {
            id: r.BenefitsId,
            name: r.Name
          };
        })
    );
  };

  const getAllLicence = async (id) => {
    let response = await GetData('License');
    if(id === 1) {
      setAutocompleteOptions(
        response
          .filter((item) => item.LicenseTypeId === id)
          .map((r) => {
            return {
              id: r.LicenseId,
              name: r.Category ? `${r.Name} (${r.Category?.trim()})` : `${r.Name}`
            };
          })
      );
    }
    else {
      setAutocompleteOptions(
        response
          .filter((item) => item.LicenseTypeId === 2 || item.LicenseTypeId === 3 || item.LicenseTypeId === 4)
          .map((r) => {
            return {
              id: r.LicenseId,
              name: r.Category ? `${r.Name} (${r.Category?.trim()})` : `${r.Name}`
            };
          })
      );
    }
  
  };
  const getAllLanguages = async () => {
    let response = await GetData('Language');
    setAutocompleteOptions(
      response.map((r) => {
        return {
          id: r.LanguageId,
          name: r.Name
        };
      })
    );
  };
  const getAllLevels = async () => {
    let response = await GetData('Level');
    setLevels(response);
  };
  const getAllQualifications = async () => {
    let response = await GetData('Qualifications');
    setAutocompleteOptions(
      response.map((r) => {
        return {
          id: r.QualificationsId,
          name: r.Name
        };
      })
    );
  };
  const getAllSkills = async () => {
    let response = await GetData('Skills');
    setLevels(response);
  };
  const getAllBranches = async () => {
    const response = await GetData('Branch');
    setBranchOptions(
      response.map((r) => {
        return {
          id: r.BranchId,
          name: r.BranchType
        };
      })
    );
  };

  useEffect(() => {
    // GetHobbies();
    GetLicences();
    GetLanguageLevel();
    GetProfession();
    GetCollege();
    GetBranche();
    GetQualification();
    GetVocational();
    GetCertificate();
    GetEmployment();
    GetBenefit();
  }, []);

  const SaveCollegeDegree = async () => {
    const deleteDegree = college?.filter(
      (c) => !selectedChips?.some((chip) => c.CollegeDegreeId == chip.id)
    );
    const addDegree = selectedChips?.filter(
      (chip) => !college?.some((c) => c.CollegeDegreeId == chip.id)
    );

    await Promise.allSettled([
      deleteDegree?.forEach(async (d) => {
        await axios
          .delete(`${config.apiUrl}/ApplicantCollege/${d.ApplicantCollegeId}`)
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      addDegree?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantCollege`, {
            firstId: auth.getId(),
            secondId: a.id
          })
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        await GetCollege();
      })
      .catch((err) => console.error(err));
  };
  const SaveCertificate = async () => {
    console.log('here');
    const deleteCertificate = certificate?.filter(
      (c) =>
        !selectedChips?.some(
          (chip) => c.SchoolCertificate.SchoolCertificateId == chip.id
        )
    );
    const addCertificate = selectedChips?.filter(
      (chip) =>
        !certificate?.some(
          (c) => c.SchoolCertificate.SchoolCertificateId == chip.id
        )
    );

    await Promise.allSettled([
      deleteCertificate?.forEach(async (d) => {
        await axios
          .delete(
            `${config.apiUrl}/ApplicantCertificate/${d.ApplicantCertificateId}`
          )
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      addCertificate?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantCertificate`, {
            firstId: auth.getId(),
            secondId: a.id
          })
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        setOpen(false);
        await GetCertificate();
      })
      .catch((err) => console.error(err));
  };
  const SaveEmployment = async () => {
    const _delete = employment?.filter(
      (c) => !selectedChips?.some((chip) => c.EmploymentId == chip.id)
    );
    const _add = selectedChips?.filter(
      (chip) => !employment?.some((c) => c.EmploymentId == chip.id)
    );

    await Promise.allSettled([
      _delete?.forEach(async (d) => {
        await axios
          .delete(
            `${config.apiUrl}/ApplicantEmployment/${d.ApplicantEmploymentId}`
          )
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      _add?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantEmployment`, {
            firstId: auth.getId(),
            secondId: a.id
          })
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        await GetEmployment();
      })
      .catch((err) => console.error(err));
  };
  const SaveBenefit = async () => {
    const _delete = benefit?.filter(
      (c) => !selectedChips?.some((chip) => c.BenefitId == chip.id)
    );
    const _add = selectedChips?.filter(
      (chip) => !benefit?.some((c) => c.BenefitId == chip.id)
    );
    await Promise.allSettled([
      _delete?.forEach(async (d) => {
        await axios
          .delete(`${config.apiUrl}/ApplicantBenefits/${d.ApplicantBenefitsId}`)
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      _add?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantBenefits`, {
            firstId: auth.getId(),
            secondId: a.id
          })
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        await GetBenefit();
      })
      .catch((err) => console.error(err));
  };
  // const SaveHobbies = async () => {
  //   const _delete = hobbies?.filter(
  //     (c) => !selectedOptions?.some((option) => c.HobbiesId == option.id)
  //   );
  //   const _add = selectedOptions?.filter(
  //     (option) => !hobbies?.some((c) => c.HobbiesId == option.id)
  //   );
  //   await Promise.allSettled([
  //     _delete?.forEach(async (d) => {
  //       await axios
  //         .delete(`${config.apiUrl}/ApplicantHobbies/${d.ApplicantHobbiesId}`)
  //         .then((res) => {
  //           if (loading || openSeconModal) setLoading(false);
  //           setOpenSeconModal(false);
  //         })
  //         .catch((err) => console.error(err));
  //     }),
  //     _add?.forEach(async (a) => {
  //       await axios
  //         .post(`${config.apiUrl}/ApplicantHobbies`, {
  //           firstId: auth.getId(),
  //           secondId: a.id
  //         })
  //         .then((res) => {
  //           setLoading(false);
  //           setOpenSeconModal(false);
  //         })
  //         .catch((err) => console.error(err));
  //     })
  //   ])
  //     .then(async (res) => {
  //       setLoading(false);
  //       setOpenSeconModal(false);
  //       await GetHobbies();
  //     })
  //     .catch((err) => console.error(err));
  // };
  const SaveLicence = async (type) => {
    const _delete = type?.filter(
      (c) => !selectedOptions?.some((option) => c.LicenseId == option.id)
    );
    const _add = selectedOptions?.filter(
      (option) => !type?.some((c) => c.LicenseId == option.id)
    );

    await Promise.allSettled([
      _delete?.forEach(async (d) => {
        await axios
          .delete(`${config.apiUrl}/ApplicantLicense/${d.ApplicantLicenseId}`)
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      _add?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantLicense`, {
            firstId: auth.getId(),
            secondId: a.id
          })
          .then((res) => {
            setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        await GetLicences();
      })
      .catch((err) => console.error(err));
  };
  const SaveLanguage = async () => {
    const _delete = language?.filter(
      (c) =>
        !selectedOptions?.some(
          (option) => c.LanguageLevelId == option.languageLevelId
        )
    );
    const _add = selectedOptions?.filter(
      (option) => !language?.some((c) => c.LanguageId == option.id)
    );
    const _edit = selectedOptions?.filter((option) =>
      language?.some(
        (c) =>
          option.languageLevelId &&
          c.LanguageLevelId == option.languageLevelId &&
          c.LevelId != parseInt(option.secondId)
      )
    );

    await Promise.allSettled([
      _delete?.forEach(async (d) => {
        await axios
          .delete(`${config.apiUrl}/LanguageLevel/${d.LanguageLevelId}`)
          .then((res) => {
            if (loading || openSeconModal) setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      _add?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/LanguageLevel`, {
            applicantId: auth.getId(),
            languageId: a.id,
            levelId: parseInt(a.secondId)
          })
          .then((res) => {
            setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      }),
      _edit?.forEach(async (a) => {
        await axios
          .put(`${config.apiUrl}/LanguageLevel/${a.languageLevelId}`, {
            applicantId: auth.getId(),
            languageId: a.id,
            levelId: parseInt(a.secondId)
          })
          .then((res) => {
            setLoading(false);
            setOpenSeconModal(false);
          })
          .catch((err) => console.error(err));
      })
    ])
      .then(async (res) => {
        setLoading(false);
        setOpenSeconModal(false);
        await GetLanguageLevel();
      })
      .catch((err) => console.error(err));
  };
  const SaveQualifications = async (newQualifications) => {
    console.log(newQualifications);
    const _delete = qualification?.filter(
      (c) =>
        !newQualifications?.some(
          (option) =>
            c.ApplicantQualificationsId == option.ApplicantQualificationsId
        )
    );
    const _add = newQualifications?.filter(
      (option) =>
        !qualification?.some(
          (c) => c.QualificationsId == option.QualificationsId
        )
    );
    const _edit = newQualifications?.filter((option) =>
      qualification?.some(
        (c) =>
          option.ApplicantQualificationsId &&
          c.ApplicantQualificationsId === option.ApplicantQualificationsId &&
          c.SkillsId !== parseInt(option.secondId)
      )
    );

    await Promise.allSettled([
      _delete?.forEach(async (d) => {
        await axios
          .delete(
            `${config.apiUrl}/ApplicantQualifications/${d.ApplicantQualificationsId}`
          )
          .then((res) => {})
          .catch((err) => console.error(err));
      }),
      _add?.forEach(async (a) => {
        await axios
          .post(`${config.apiUrl}/ApplicantQualifications`, {
            applicantId: auth.getId(),
            qualificationsId: a.QualificationsId,
            skillsId: parseInt(a.secondId)
          })
          .then((res) => {})
          .catch((err) => console.error(err));
      }),
      _edit?.forEach(async (a) => {
        await axios
          .put(
            `${config.apiUrl}/ApplicantQualifications/${a.ApplicantQualificationsId}`,
            {
              applicantId: auth.getId(),
              qualificationsId: a.QualificationsId,
              skillsId: parseInt(a.secondId)
            }
          )
          .then((res) => {})
          .catch((err) => console.error(err));
      })
    ])
      .then((res) => {
        setLoading(false);
        GetQualification();
      })
      .catch((err) => console.error(err));
  };
  const SaveProfession = async (data) => {
    setLoading(true);
    await PostData('ApplicantProfession', {
      applicantId: auth.getId(),
      branchId: data.firstDataId.id,
      professionId: data.secondDataId,
      moreThan3Year:
        data.years == '' ? null : data.years === '3+' ? true : false
    })
      .then((res) => {
        GetProfession();
        setLoading(false);
        // setOpenThirdModal(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showSnackbar(true);
          setSnackbarMessage(
            error.response.data.ERROR
              ? error.response.data.ERROR[0]
              : error.response.data.title
          );
        }
        // setOpenThirdModal(false);
      });
  };
  const DeleteProfession = async (id) => {
    await DeleteData('ApplicantProfession', id).then((res) => {
      GetProfession();
    });
  };

  const SaveBranch = async (data) => {
    setLoading(true);
    await PostData('ApplicantDesiredProfession', {
      applicantId: auth.getId(),
      branchId: data.firstDataId.id,
      professionId: data.secondDataId,
      IsDesired: true
    })
      .then((res) => {
        GetBranche();
        setLoading(false);
        // setOpenThirdModal(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showSnackbar(true);
          setSnackbarMessage(
            error.response.data.ERROR
              ? error.response.data.ERROR[0]
              : error.response.data.title
          );
        }
        // setOpenThirdModal(false);
      });
  };
  const DeleteBranch = async (id) => {
    await DeleteData('ApplicantDesiredProfession', id).then(() => {
      GetBranche();
    });
  };

  const updateVocational = async (newValuesArr) => {
    const newIds = newValuesArr.map((item) => item.value);
    const currentIds = vocational.map((item) => item.Profession.ProfessionId);

    for (const item of newIds) {
      if (!currentIds.includes(item)) {
        await PostData('ApplicantDesiredProfession', {
          applicantId: auth.getId(),
          professionId: item,
          IsDesired: false
        });
      }
    }

    for (const item of currentIds) {
      if (!newIds.includes(item)) {
        const vocationalItem = vocational.find((x) => x.ProfessionId === item);
        await DeleteData(
          `ApplicantDesiredProfession`,
          vocationalItem.ApplicantDesiredProfessionId
        );
      }
    }

    GetVocational();
  };

  const EditCollegeDegree = () => {
    getAllColleges();
    setSelectedChips(
      college.map(({ CollegeDegree }) => {
        return {
          id: CollegeDegree.CollegeDegreeId,
          name: CollegeDegree.DegreeName
        };
      })
    );

    setModalTitle('Hochschulabschluss');
    setOpen(true);
  };
  const EditSchoolCertificate = () => {
    getAllSchoolCertificate();
    setSelectedChips(
      certificate.map(({ SchoolCertificate }) => {
        return {
          id: SchoolCertificate.SchoolCertificateId,
          name: SchoolCertificate.CertificateType
        };
      })
    );
    setModalTitle('Schulabschluss');
    setOpen(true);
  };
  const EditEmployment = () => {
    getAllEmployment();
    setSelectedChips(
      employment.map(({ Employment }) => {
        return {
          id: Employment.EmploymentId,
          name: Employment.EmploymentType
        };
      })
    );
    setModalTitle('Anstellungsart');
    setOpen(true);
  };
  const EditBenefit = () => {
    getAllBenefit();
    setSelectedChips(
      benefit.map(({ Benefits }) => {
        return {
          id: Benefits.BenefitsId,
          name: Benefits.Name
        };
      })
    );
    setModalTitle('Benefits');
    setOpen(true);
  };
  const EditStrasseLicence = () => {
    setLevels([]);

    getAllLicence(1);
    setSelectedOptions(
      street.map(({ License }) => {
        return {
          id: License.LicenseId,
          name: License.Name
        };
      })
    );
    setTextLabel('Welche Führerscheinklassen / Lizenzen besitzt du?');
    setModalTitle('Straße');
    setOpenSeconModal(true);
  };
  // const EditWaterLicence = () => {
  //   setLevels([]);

  //   getAllLicence(2);
  //   setSelectedOptions(
  //     water.map(({ License }) => {
  //       return {
  //         id: License.LicenseId,
  //         name: License.Name
  //       };
  //     })
  //   );
  //   setTextLabel('Wasser');
  //   setModalTitle('Wasser');
  //   setOpenSeconModal(true);
  // };
  // const EditAirLicence = () => {
  //   setLevels([]);

  //   getAllLicence(3);
  //   setSelectedOptions(
  //     air.map(({ License }) => {
  //       return {
  //         id: License.LicenseId,
  //         name: License.Name
  //       };
  //     })
  //   );
  //   setTextLabel('Luft');
  //   setModalTitle('Luft');
  //   setOpenSeconModal(true);
  // };
  const EditSpecialLicence = () => {
    setLevels([]);

    getAllLicence(4);
    setSelectedOptions(
      other.map(({ License }) => {
        return {
          id: License.LicenseId,
          name: License.Name
        };
      })
    );
    setTextLabel('Welche sonstigen Führerscheine/Lizenzen besitzt du?');
    setModalTitle('Sonstige');
    setOpenSeconModal(true);
  };
  const EditLanguage = () => {
    getAllLanguages();
    getAllLevels();

    setSelectedOptions(
      language.map((lan) => {
        return {
          id: lan.LanguageId,
          name: lan.Language.Name,
          secondId: lan.LevelId,
          languageLevelId: lan.LanguageLevelId
        };
      })
    );
    setTextLabel('Welche Sprachen sprichst du?');
    setModalTitle('Sprachen');
    setOpenSeconModal(true);
  };
  const EditQualifications = () => {
    GetQualification();
    getAllSkills();
    setopenQualificationModal(true);
  };
  const EditProfession = () => {
    getAllBranches();
    setModalTitle('Bitte fügen Deine Berufserfahrung hinzu');

    setOpenThirdModal(true);
  };
  const EditBranch = () => {
    getAllBranches();
    setModalTitle('In welcher Branche möchtest du arbeiten?');

    setOpenThirdModal(true);
  };

  const handleClick = (industrija) => {
    removeElement(loadedChips, industrija);
    setSelectedChips([...selectedChips, industrija]);
  };

  const removeElement = (niz = null, industrija) => {
    setLoadedChips(niz?.filter((item) => item.id !== industrija.id));
  };

  const removeSelected = (industrija) => {
    setSelectedChips(selectedChips.filter((item) => item.id !== industrija.id));
    setLoadedChips([...loadedChips, industrija]);
  };

  const handleOptions = (event, values, setLevel = false) => {
    if (!levels.length) setSelectedOptions(values);
    else {
      let newValues = [];
      if (setLevel) newValues = values;
      else {
        if (selectedOptions.length > values.length)
          newValues = selectedOptions.filter((op) =>
            values.find((item) => item.id == op.id)
          );
        else {
          for (let index = 0; index < values.length; index++)
            newValues.push(
              selectedOptions.find((item) => item.id === values[index].id)
                ? selectedOptions[index]
                : values[index]
            );
        }
      }
      setSelectedOptions(newValues);
    }
  };

  const SaveChanges = (type) => {
    setLoading(true);
    switch (type) {
      case 'Hochschulabschluss':
        SaveCollegeDegree();
        break;
      case 'Schulabschluss':
        SaveCertificate();
        break;
      case 'Anstellungsart':
        SaveEmployment();
        break;
      case 'Benefits':
        SaveBenefit();
        break;
      // case 'Interessen & Hobbys':
      //   SaveHobbies();
      //   break;
      case 'Straße':
        SaveLicence(street);
        break;
      // case 'Wasser':
      //   SaveLicence(water);
      //   break;
      // case 'Luft':
      //   SaveLicence(air);
      //   break;
       case 'Sonstige':
         SaveLicence(other);
         break;
      case 'Sprachen':
        SaveLanguage();
        break;
      case 'Zusatzqualifikationen / Kenntnisse':
        SaveQualifications();
        break;
    }
  };

  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });

  return (
    <Fragment>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="error"
      >
        {snackbarMessage}
      </Snackbar>

      <ModalChip
        open={open}
        modalTitle={modalTitle}
        setOpen={setOpen}
        loadedChips={loadedChips}
        selectedChips={selectedChips}
        handleClick={handleClick}
        removeSelected={removeSelected}
        loading={loading}
        SaveChanges={SaveChanges}
      />

      <ModalAutocomplete
        openSeconModal={openSeconModal}
        modalTitle={modalTitle}
        setSelectedOptions={setSelectedChips}
        setOpenSeconModal={setOpenSeconModal}
        autocompleteOptions={autocompleteOptions}
        selectedOptions={selectedOptions}
        loading={loading}
        SaveChanges={SaveChanges}
        handleOptions={handleOptions}
        levels={levels}
        textLabel={textLabel}
      />

      <ModalBranch
        title={modalTitle}
        open={openThirdModal}
        onClose={() => setOpenThirdModal(false)}
        firstData={branchOptions}
        secondOption="Profession/Paging"
        selectedData={
          modalTitle == 'In welcher Branche möchtest du arbeiten?'
            ? branche
            : profession
        }
        save={
          modalTitle == 'In welcher Branche möchtest du arbeiten?'
            ? SaveBranch
            : SaveProfession
        }
        deleteData={
          modalTitle == 'In welcher Branche möchtest du arbeiten?'
            ? DeleteBranch
            : DeleteProfession
        }
        loading={loading}
        firstParagraph="In welcher Branche hast du Berufserfahrung/en ?"
        secondParagraph="Wähle deinen Beruf"
        thirdParagraph="Wie lange du hast diesen Beruf ausgeübt?"
        branch={
          modalTitle == 'In welcher Branche möchtest du arbeiten?'
            ? true
            : false
        }
      />

      {/* <HobbieModal
        open={openHobbieModal}
        setOpen={setOpenHobbieModal}
        getHobbies={GetHobbies}
        loadedHobbies={hobbies}
      /> */}

      <ModalVocational
        open={openVocationalModal}
        onClose={() => setopenVocationalModal(false)}
        selectedItems={vocational}
        update={updateVocational}
      />

      <ModalQualifications
        open={openQualificationModal}
        selectedQualifications={qualification}
        skills={levels}
        onClose={() => {
          setopenQualificationModal(false);
        }}
        saveQualifications={SaveQualifications}
      />
      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        <StrasseCard
          title="Führerschein"
          columns={['name', 'kategorie']}
          rows={street.map(({ License }) => {
            return {
              id: License.LicenseId,
              name: License.Name,
              kategorie: License.Category
            };
          })}
          onClick={EditStrasseLicence}
        />
        <StrasseCard
          title="Sonstige"
          columns={['name', '']}
          rows={other.map(({ License }) => {
            return {
              id: License.LicenseId,
              name: License.Name,
              category: License.Category
            };
          })}
          onClick={EditSpecialLicence}
        />
      </ComponentWrapper>
      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        {/* <StrasseCard
          title="Luft"
          columns={['name', 'kategorie']}
          rows={air.map(({ License }) => {
            return {
              id: License.LicenseId,
              name: License.Name,
              kategorie: License.Category
            };
          })}
          onClick={EditAirLicence}
        />
        <StrasseCard
          title="Sonstige"
          columns={['name', '']}
          rows={other.map(({ License }) => {
            return {
              id: License.LicenseId,
              name: License.Name,
              category: License.Category
            };
          })}
          onClick={EditSpecialLicence}
        /> */}
      </ComponentWrapper>
      <ComponentWrapper displayFlex noPaddingHorizontal>
        <BrancheCard
          title="Berufserfahrung/en"
          data={profession.map((prof) => [
            prof.Branch.BranchType,
            prof.Profession?.ProfesionName,
            prof.MoreThan3Year ? 'mehr als 3 Jahre' : 'weniger als 3 Jahre'
          ])}
          onClick={EditProfession}
        />
      </ComponentWrapper>
      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        <div
          style={{
            width: '45%',
            flexGrow: '1',
            margin: isExtraSmall ? '0' : '15px'
          }}
        >
          <DegreeCard
            classes={'fullWidth marginFirstCard'}
            title="Hochschulabschluss"
            data={college.map(({ CollegeDegree }) => CollegeDegree.DegreeName)}
            onClick={EditCollegeDegree}
          />
          <JobRegion
            classes={'fullWidth marginSecondCard'}
            jobRegionId={jobRegionId}
          />
        </div>
        {/* <DegreeCard
          classes="hobbysCard"
          title="Interessen & Hobbys"
          data={hobbies.map((hobbie) => hobbie.Hobbie)}
          onClick={() => setOpenHobbieModal(true)}
        /> */}
      </ComponentWrapper>
      <ComponentWrapper displayFlex noPaddingHorizontal>
        <BrancheCard
          title="Die Branche, in der Du arbeiten möchtest"
          data={branche.map((branch) => [
            branch.Branch.BranchType,
            branch.Profession?.ProfesionName
          ])}
          onClick={EditBranch}
        />
      </ComponentWrapper>

      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        <StrasseCard
          title="Sprachen"
          columns={['sprache', 'sprachniveau']}
          rows={language.map((lan) => {
            return {
              id: lan.LanguageLevelId,
              sprache: lan.Language?.Name,
              sprachniveau: lan.Level?.LevelType
            };
          })}
          onClick={EditLanguage}
        />
        <StrasseCard
          title="Zusatzqualifikationen / Kenntnisse"
          columns={['name', 'niveau']}
          rows={qualification.map((lan) => {
            return {
              id: lan.ApplicantQualificationsId,
              name: lan.Qualifications.Name,
              niveau: lan.Skills?.Skill
            };
          })}
          onClick={EditQualifications}
        />
      </ComponentWrapper>
      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        <StrasseCard
          title="Anstellungsart"
          columns={['name']}
          rows={employment.map(({ Employment }) => {
            return {
              id: Employment.EmploymentId,
              name: Employment.EmploymentType
            };
          })}
          onClick={EditEmployment}
        />
        <StrasseCard
          title="Benefits"
          columns={['name']}
          rows={benefit.map(({ Benefits }) => {
            return { id: Benefits.BenefitsId, name: Benefits.Name };
          })}
          onClick={EditBenefit}
        />
      </ComponentWrapper>
      <ComponentWrapper fluid displayFlex justifyBetween flexWrap noPadding>
        <StrasseCard
          title="Schulabschluss"
          columns={['name']}
          rows={certificate.map(({ SchoolCertificate }) => {
            return {
              id: SchoolCertificate.SchoolCertificateId,
              name: SchoolCertificate.CertificateType
            };
          })}
          onClick={EditSchoolCertificate}
        />
        <StrasseCard
          title="Ausbildung"
          columns={['name']}
          rows={vocational.map(({ Profession }) => {
            return {
              id: Profession.ProfessionId,
              name: Profession?.ProfesionName
            };
          })}
          onClick={() => {
            setopenVocationalModal(true);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper
        halfFluid={!isSmall}
        fluid={isSmall}
        displayFlex
        justifyBetween
        newSmallflexWrap
        noPadding
      >
        <JobDetails
          travelingId={travelingId}
          startingDateId={startingDateId}
          shiftId={shiftId}
        />
      </ComponentWrapper>
    </Fragment>
  );
};

export default ProfileMatchingData;
