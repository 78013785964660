import React from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';

import styles from './Job.module.scss';

const JobDetailsItem = ({ icon, title, content }) => {
  return (
    <>
      <ComponentWrapper>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img src={icon} alt="icon" className={styles.itemImage} />
          <Paragraph title> {title}</Paragraph>
        </div>
        <Paragraph style={{ paddingLeft: 38 }}> {content}</Paragraph>
      </ComponentWrapper>
    </>
  );
};

export default JobDetailsItem;
