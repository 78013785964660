import React from 'react';
import { useHistory } from 'react-router';
import SubmitButton from '../Buttons/submitButton';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import styles from './ErrorMessageModal.module.scss';
import Modal from './Modal';

const DeleteModal = ({ open, onClose, onDelete, text}) => {
  const history = useHistory();
  return (
    <>
      <Modal width="500px" height="200px" open={open} onClose={onClose}>
        <div className={styles.innerDiv}>
          <p>{text ? text : "Willst Du dieses Konto wirklich löschen?"}</p>
          <ComponentWrapper displayFlex fluid justifyCenter>

          <SubmitButton
            small
            inversed={true}
            onClick={onDelete}
            style={{marginRight:'15px'}}
            >
            Ja
          </SubmitButton>

          <SubmitButton
                small
                inversed={true}
                onClick={onClose}
                style={{marginLeft:'15px'}}
                >
                Nein
              </SubmitButton>
                  </ComponentWrapper>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
