import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import FirstStep from '../components/Bewerber/FirstStep';
import SecondStep from '../components/Bewerber/SecondStep';
import ThirdStep from '../components/Bewerber/ThirdStep';
import FourthStep from '../components/Bewerber/FourthStep';
import FifthStep from '../components/Bewerber/FifthStep';
import "../components/Bewerber/BewerberSteps.scss";
import { ReactComponent as Step} from '../assets/images/bewerber-step.svg'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
 overflow:hidden;
`;

export default function Aplicants() {
  const [active, setActive] = useState('bewerber-first');
  const steps = ['bewerber-first', 'bewerber-second', 'bewerber-third', 'bewerber-fourth', 'bewerber-fifth'];

  return (
    <div>
      <Helmet>
        <title>spiderjob - Bewerber</title>
      </Helmet>
      <div className='bewerber-steps'>
          {steps.map((el, index) => <Link key={el} to={el} smooth spy offset={index === 4? 0 : (index === 3? -180 :-90)} duration={80} activeClass="active-step" onSetActive = {() => setActive(el)} > <Step  onClick={(e) => {e.preventDefault();setActive(el)}}/></Link>)}
      </div>
      <Header/>
        <MainContainer  className='maxWidth'>
          <FirstStep isVisible = {active === 'bewerber-first' ? true : false}/>
          {/* <SecondStep isVisible = {active === 'bewerber-second' ? true : false}/> */}
          <ThirdStep isVisible = {active === 'bewerber-third' ? true : false} setActive={setActive}/>
          <FourthStep isVisible = {active === 'bewerber-fourth' ? true : false}/>
          <FifthStep isVisible = {active === 'bewerber-fifth' ? true : false}/>
        </MainContainer>
      <Footer />
    </div>
  );
}
