import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Paragraph } from '../StepSixSubcomponents/Region';

export default function RadioButtons(props) {
  const [value, setValue] = React.useState(props.value || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    props.dodajGodinu(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="gender"
        name={props.name}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="3-"
          control={<Radio />}
          label={<Paragraph>weniger als 3 Jahre</Paragraph>}
        />
        <FormControlLabel
          value="3+"
          control={<Radio />}
          label={<Paragraph>mehr als 3 Jahre</Paragraph>}
        />
        {!props.createProfile && (
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={<Paragraph>egal</Paragraph>}
          />
        )}
      </RadioGroup>
    </FormControl>
  );
}
