import React from 'react';
import CustomStepper from '../CreateProfile/SideBar/Koraci2';
import { StepOne } from '../CreateJobAd/Steps';
import { StepTwo, StepThree, StepFour, StepFive } from '../CreateProfile/Steps';
import Loader from '../Loader/loader';

const MatchingSteps = () => {
  const stepsComponent = [
    <StepOne />,
    <StepTwo />,
    <StepThree />,
    <StepFour />,
    <StepFive />,
    <Loader JobAd />
    //<Done/>
  ];

  const stepsLabel = [
    'Persönliche Daten',
    'Berufserfahrung',
    'Schulische Ausbildung',
    'Zusatzqualifikationen / Kenntnisse',
    'FAST GESCHAFFT !'
  ];

  return (
    <>
      <CustomStepper
        stepsLabel={stepsLabel}
        stepsComponent={stepsComponent}
        header={'Matching für Unternehmen'}
      />
    </>
  );
};

export default MatchingSteps;
