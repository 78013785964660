import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import styled from 'styled-components';
import GetDataPublic from '../api/GetDataPublic';
import { Pagination } from '@mui/material';
import { EmptyBox } from './CompanyDashboard/Applications';
import EmptyList from '../assets/images/empty-list.svg';
import CircularLoader from '../components/Loader/CircularLoader';
import SearchBar from '../components/SearchBar/SearchBar';
import PartnerJobCard from '../components/PartnerJobCard/PartnerJobCard';
import styles from '../components/Jobs/Jobs.module.scss';
import Slider from 'react-slick';
import { ReactComponent as LeftArrow } from '../assets/images/left-arrow.svg';
import { ReactComponent as RightArrow } from '../assets/images/right-arrow.svg';
import { ReactComponent as VolunteringTitleImg } from '../assets/images/job-title.svg';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../src/components/responsive';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    overflow: hidden;
  }
`;

const SubContainer = styled.div`
  width: 95%;
  margin-bottom: 30px;
`;

const CardsContainer = styled.div`
  margin: 10px 30px;
  padding: 20px 5px;
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
@media (min-width: 1600px) {
  gap: 100px 45px
}

  @media (max-width: 1024px) {
    gap: 50px 45px;
  }

  @media (max-width: 650px) {
    grid-template-columns: none;
  }
`;

export default function Voluntering() {
  const [jobAds, setjobAds] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [allJobs, setAllJobs] = useState(false);

  var baseJobbQuery = `JobAdvertisement/Paging?PageNumber=${currentPage}&PageSize=6&IncludePhoto=true&IncludeOffice=true&IncludeBranch=true&IncludeStartingDate=true&VolunteeringOnly=true`;

  const [settings, setSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll:3,
    initialSlide: 0,
    centerMode: true,
    centerPadding: '5px',
    className: 'center',
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
  });
  /*const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    centerMode:true,
    centerPadding: "5px",
    className:'center',
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
  };
*/
  const getUser = useCallback(async (jobAdsData) => {
    let updatedJobs = [];
    for (const job of jobAdsData?.list) {
      var user = await GetDataPublic(`User/${job.ApplicationUserId}`);
      updatedJobs.push({ ...job, ApplicationUser: user });
    }

    return { ...jobAdsData, list: updatedJobs };
  }, []);
  async function getJobAds(jobQuery) {
    setloading(true);
    const response = await GetDataPublic(jobQuery);
    setcurrentPage(response.CurrentPage);
    const data = await getUser(response);
    setjobAds(data);
    setloading(false);
  }

  useEffect(() => {
    getJobAds(baseJobbQuery);
  }, [currentPage]);

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  const searchJobs = (title, city, employment, branch) => {
    var newQuery = `${baseJobbQuery}${title && `&JobTitle=${title}`}${
      city && `&Location=${city}`
    }${employment && `&EmploymentId=${employment}`}${
      branch && `&BranchId=${branch}`
    }`;
    getJobAds(newQuery);
  };

  const isLarge = useMediaQuery({ maxWidth: DeviceSize.newLarge });
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.iPadPro });
  const isMobileSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  const isMobileSmall2 = useMediaQuery({ maxWidth: DeviceSize.mobile });
  useEffect(() => {
  
    var number = 3;
      if (isMobile) number = 2;
    
      if (isMobileSmall) number = 1;
      if(isMobileSmall2) number = 1;
      console.log(number);
      setSettings({
        ...settings,
        slidesToShow: number,
        slidesToScroll: number
      });
   
  }, [ isMobile, isMobileSmall, isMobileSmall2]);
  const history = useHistory();

  return (
    
    <div >
      <Header />
      <Helmet>
        <title>spiderjob - Ehrenamt</title>
      </Helmet>
      <Container className='maxWidth'>
        {!allJobs && jobAds?.list.length > 3 && !isLarge && (
          <VolunteringTitleImg className={styles.jobTitle} />
        )}
        <SubContainer>
          <SearchBar getJobs={searchJobs} voluntering={true} />
        </SubContainer>

        {loading ? (
          <CircularLoader small />
        ) : (
          <>
            {!allJobs && jobAds?.list.length > 3 ? (
              <>
                <Slider {...settings}>
                  {jobAds.list.map((job) => (
                    <PartnerJobCard
                      key={job.JobAdvertisementId}
                      job={job}
                      noButton={true}
                    />
                  ))}
                </Slider>
                <p
                  className={styles.allJobsButton}
                  onClick={() => setAllJobs(true)}
                >
                  ALLE STELLEN ANZEIGEN
                </p>
              </>
            ) : jobAds?.list.length > 0 ? (
              <>
                <div className="w-100 mt-5">
                  <CardsContainer>
                    {jobAds.list.map((job) => (
                      <PartnerJobCard
                        key={job.JobAdvertisementId}
                        job={job}
                        noButton={true}
                        alljobs ={true}
                      />
                    ))}
                  </CardsContainer>
                </div>
                {jobAds && jobAds.TotalPages > 1 && (
                  <Pagination
                    count={jobAds && jobAds.TotalPages}
                    page={currentPage}
                    onChange={onPageChange}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                  />
                )}
              </>
            ) : (
              <EmptyBox
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              >
                <div className="mb-5">
                  <img src={EmptyList} alt="Empty"></img>
                </div>
                <h4 className="fw-bold">Keine Ehrenämter gefunden</h4>
              </EmptyBox>
            )}
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
}
