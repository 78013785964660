import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import { Button } from '@mui/material';

import DashboardLayout from '../CompanyDashboard/DashboardLayout';
import { DashboardTitle } from '../CompanyDashboard/Home';
import { Row } from '../../components/CreateProfile/Steps';
import OptionButton from '../../components/Buttons/OptionButton';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import TableWrapper from '../../components/DataTable/TableWrapper';
import CircularLoader from '../../components/Loader/CircularLoader';
import NoApplications from '../../assets/images/noApplications.png';
import Snackbar from '../../components/snackbar/snackbar';

import config from '../../config/api.config';
import auth from '../../auth';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import DeletePrompt from '../CompanyDashboard/DeletePrompt';

const Container = styled.div`
  padding: 6.5em 3.5em;
  width: 100%;
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    color: #3a3a3a;
    font-size: 19px;
    width: 50%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
  }
  > div {
    margin-top: 50px;
  }
  > div > button {
    border-radius: 5px !important;
  }
`;

const Applications = () => {
  const history = useHistory();
  const [snackbar, showSnackbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [appNum, setAppNum] = useState(-1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  let temp = [];

  const GetApplications = async () => {
    await axios
      .get(`${config.apiUrl}/JobApplicant`, {
        params: {
          ApplicantId: auth.getId(),
          IncludeJobAdv: true
        }
      })
      .then(async (res) => {
        if (res.data.length) {
          setAppNum(res.data.length);
          res.data.forEach(
            async (app) => await GetAppUser(app, app.JobAdvertisement)
          );
        } else {
          setApplications([]);
          setLoading(false)}
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const DeleteApplication = async (ids) => {
    Promise.allSettled(
      ids.map(async (id) => {
        await axios.delete(`${config.apiUrl}/JobApplicant/${id}`);
      })
    )
      .then(async (res) => {
        showSnackbar(true);
        GetApplications();
      })
      .catch((err) => console.error(err));
  };

  const GetAppUser = async (applicant, adv) => {
    await axios
      .get(`${config.apiUrl}/User/${adv.ApplicationUserId}`)
      .then(async (res) => {
        const user = res.data;
        await axios
          .get(`${config.apiUrl}/File`, {
            params: {
              UserId: adv.ApplicationUserId,
              Type: 'Profile'
            }
          })
          .then((res) => {
            temp = Object.assign([], temp);
            console.log(applicant)
            temp.push({
              jobAdId: applicant.JobAdvertisementId,
              Company: {
                Name: user.Name,
                Email: user.Email,
                Profile: res.data[0]?.Url
              },
              Job: adv.Profession?.ProfesionName || adv.ProfessionText,
              JobApplicantIds: applicant.JobApplicantIds,
              Ort: adv.JobLocation.City,
              Passend: applicant.ApplicationType === "quick-apply"? "quick-apply" : applicant.Result,
              Handlung: { text: 'Ansehen', blue: true }
            });
            setApplications(temp);
         
          })
          .catch((err) => {
        
            setLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
      
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    GetApplications();
  }, []);

 useEffect(() => {
    if (appNum == applications.length) setLoading(false);
  }, [applications]);

  return (
    <DashboardLayout noPadding>
      <ComponentWrapper
        fluid
        padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
      >
        <Snackbar
          open={snackbar}
          onClose={() => showSnackbar(false)}
          severity="success"
        >
          Sie haben die Bewerbung erfolgreich gelöscht
        </Snackbar>
        <Row style={{ justifyContent: 'initial' }}>
          <DashboardTitle red> Bewerbungen</DashboardTitle>
        </Row>
        <ComponentWrapper noPaddingHorizontal>
          {loading ? (
            <Container>
              <CircularLoader />
            </Container>
          ) : applications.length != 0 ? (
            <>
              <TableWrapper
                tableTitle={''}
                columns={['Company', 'Job', 'Ort', 'Passend', 'Handlung']}
                rows={applications.map((ap, index) => {
                  return { ...ap, id: index };
                })}
                openOption={(data) =>
                  history.push(
                    `/job/${encodeURIComponent(
                      data.row.Job.replace('+', '')
                    )}-${data.row.jobAdId}`
                  )
                }
                deleteOption={async (data) => {
                  setDeleteModal(true);
                  setEditData(data);
                  //await DeleteApplication(data.row.JobApplicantIds)
                }}
              />

              <DeletePrompt
                text = "Bist Du dir sicher, dass du diese Bewerbung zurückziehen möchtest?"
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                onDelete={async () => {
                  await DeleteApplication(editData.row.JobApplicantIds);
                  setDeleteModal(false);
                }}
              />
            </>
          ) : (
            <Container>
              <p>
                Du hast dich anscheinend noch nicht auf eine Stelle beworben,
                wir helfen Dir!
              </p>
              <img src={NoApplications} />

              <div className={isMobile && 'applications__buttons'}>
                <OptionButton
                  style={{ height: '35px', marginRight: '15px' }}
                  outlined
                  text="Passende Jobs ansehen"
                  onClick={() => history.push('/bewerber-dashboard')}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ height: '35px' }}
                  onClick={() => history.push('/jobs')}
                >
                  Alle Jobs ansehen
                </Button>
              </div>
            </Container>
          )}
        </ComponentWrapper>{' '}
      </ComponentWrapper>
    </DashboardLayout>
  );
};

export default Applications;
