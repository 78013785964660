import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import PropTypes from 'prop-types';
import { DashboardTitle } from '../../Pages/CompanyDashboard/Home';
import { Row } from '../CreateProfile/Steps';
import close from '../../assets/images/close-icon.svg';
import CustomModal from './Modal';

export const ModalHeader = styled.div`
  height: 108px;
  width: 100%;
  box-shadow: 5px 0px 10px rgba(225, 225, 225, 0.25);
  border-radius: 30px 30px 10px 10px;

  @media (max-width: 590px) {
    height: auto;
  }
`;

export const ModalInner = styled(ComponentWrapper)`
  scroll-behavior: auto;
  height: 80%;
  overflow-y: auto;
  padding: 20px 20px 10px;

  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    background: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background: #c9333c;
    border-radius: 8px;
  }
`;

export function ModalOuter({
  open,
  title,
  option,
  onClose,
  children,
  small,
  smallHeight,
  large,
  showDot = true,
  header = null
}) {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      height= {'90%'}
      small={small}
      smallHeight={smallHeight}
      large={large}
    >
      <ModalHeader>
        <ComponentWrapper style={{padding: '20px 30px'}}>
          <Row>
            <DashboardTitle red>
              {title}
              {showDot ? (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: '#C1C1C1',
                    display: 'inline-flex',
                    margin: '0em 1em 3px'
                  }}
                ></div>
              ) : null}
              <span
                style={{
                  color: '#c1c1c1',
                  fontSize: 20,
                  fontWeight: 400
                }}
              >
                {option}
              </span>
            </DashboardTitle>
            <img
              src={close}
              alt="close"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </Row>
          <Row>
            {header}
          </Row>
        </ComponentWrapper>
      </ModalHeader>
      <ModalInner>{children}</ModalInner>
    </CustomModal>
  );
}

ModalOuter.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

export default ModalOuter;
