import React, { useState, useEffect } from 'react';

import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';

import DashboardLayout from '../CompanyDashboard/DashboardLayout';

import { DeviceSize } from '../../components/responsive';

import auth from '../../auth';
import { useMediaQuery } from 'react-responsive';


import SearchBar from '../../components/SearchBar/SearchBar';

import GetDataPublic from '../../api/GetDataPublic';
import { useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import '../../assets/css/MuiOverride.css';
import { EmptyBox } from '../CompanyDashboard/Applications';
import EmptyList from '../../assets/images/empty-list.svg';


import CircularLoader from '../../components/Loader/CircularLoader';
import styles from '../../components/Jobs/Jobs.module.scss';
import PartnerJobCard from '../../components/PartnerJobCard/PartnerJobCard';

import { ReactComponent as LeftArrow } from '../../assets/images/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/images/right-arrow.svg';



export default function JobsDashboard() {
    const location = useLocation();
    const [jobAds, setjobAds] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [title, setTitle] = useState(location?.state?.title || '');
    const [city, setCity] = useState(location?.state?.city || '');
    const [latitude, setLatitude] = useState(location?.state?.latitude || '');
    const [longitude, setLongitude] = useState(location?.state?.longitude || '');
    const [branchId, setbranchId] = useState(location?.state?.branch ?? '');
    const [employmentId, setemploymentId] = useState(
      location?.state?.employment ?? ''
    );
    const [loading, setloading] = useState(true);
    const [allJobs, setAllJobs] = useState(false);
    const [sliderRef, setSliderRef] = useState();
    const isLarge = useMediaQuery({ maxWidth: DeviceSize.newLarge });
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.iPadPro });
    const isMobileSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
    const isMobileSmall2 = useMediaQuery({ maxWidth: DeviceSize.mobile });
    const iMac = useMediaQuery({ maxWidth: 1600 });
    const [settings, setSettings] = useState({
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: isMobile ? 2 : 3,
      initialSlide: 0,
      centerMode: true,
      centerPadding: '5px',
      className: 'center',
      nextArrow: <RightArrow />,
      prevArrow: <LeftArrow />
    });
  
    const [baseJobQuery] = useState(
      `JobAdvertisement/Paging?&PageSize=6&CheckIsActive=true&IncludePhoto=true&IncludeOffice=true&IncludeStartingDate=true&IncludeBranch=true&
      Random=${!auth.isAuthenticated()}$
      }`
    );
  
    const getJobAds = async () => {
      const res = await GetDataPublic(baseJobQuery, {
        PageNumber: currentPage,
        VolunteeringOnly:  false,
        JobTitle: title,
        //Location: city,
        Latitude: latitude,
        Longitude: longitude,
        Radius: 50,
        BranchId: branchId,
        EmploymentId: employmentId
      });
  
      setcurrentPage(res.CurrentPage);
      setjobAds(res);
      setloading(false);
    };
    useEffect(() => {
      setloading(true);
      setIsLoggedIn(!!auth.getId());
  
      getJobAds();
    }, [currentPage, title, city, branchId, employmentId]);
  
    const onPageChange = (e, page) => {
      setcurrentPage(page);
    };
  
    const searchJobs = async (title, city, employment, branch, latitude, longitude) => {
      setTitle(title);
      setCity(city);
      setemploymentId(employment);
      setbranchId(branch);
      setLatitude(latitude);
      setLongitude(longitude);
    };
  
    useEffect(() => {
      if (sliderRef) {
        var number =
          sliderRef?.props.children?.length >= 3
            ? 3
            : sliderRef?.props.children?.length === 2
            ? 2
            : 1;
        if (isMobile) number = 2;
       if(isMobileSmall2) number = 1;
        if (isMobileSmall) number = 1;
        console.log(number);
        setSettings({
          ...settings,
          slidesToShow: number,
          slidesToScroll: number
        });
      }
    }, [sliderRef, isMobile, isMobileSmall, isMobileSmall2]);
  
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  

  return (
    <DashboardLayout noPadding style = {{overflowX: 'hidden'}}>
      <ComponentWrapper style={{ display: 'flex', justifyContent: 'center'}}
        fluid
        padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
      >
        <div style={isExtraSmall ? {width: '80%', }: {}}>
        {(
          <SearchBar
            getJobs={searchJobs}
            allJobs={true}
            title={title}
            city={city}
            employmentId={employmentId}
            branchId={branchId}
          />
        )}
        <>
          {loading ? (
            <CircularLoader small />
          ) : (
            <>
              { jobAds && jobAds.list.length > 0 ? (
                <>
                  <div className="w-100" style={{marginTop: '100px'}}> 
                    <div className={styles.jobsList} >
                      {jobAds.list.map((job) => (
                        <PartnerJobCard
                          key={job.JobAdvertisementId}
                          job={job}
                          noButton={true}
                          alljobs = {true}
                        />
                      ))}
                    </div>
                  
                  </div>
                  {isLoggedIn && jobAds && jobAds.TotalPages > 1 && (
                    <Pagination
                    className='pagCenter'
                      count={jobAds && jobAds.TotalPages}
                      page={currentPage}
                      onChange={onPageChange}
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  )}
                </>
              ) : (
                <EmptyBox
                  style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
                >
                  <div className="mb-5">
                    <img src={EmptyList} alt="Empty"></img>
                  </div>
                  <h4 className="fw-bold">
                    {jobAds && jobAds.TotalCount === 0
                      ? 'Keine Ergebnisse verfügbar'
                      : 'Du hast noch keine Jobs'}
                  </h4>
                </EmptyBox>
              )}
            </>
               )}{' '}
               </>
               </div>
      </ComponentWrapper>
    </DashboardLayout>
  );
}