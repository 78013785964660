import React, { useEffect, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import styles from '../Job/Job.module.scss';
import stylesCompany from './CompanyProfile.module.scss';
import correctLink from '../../utils/correctLink';

import { ReactComponent as BusinessIcon } from '../../assets/images/icon-business.svg';
import { ReactComponent as PersonIcon } from '../../assets/images/icon-person.svg';
import { ReactComponent as PaperclipIcon } from '../../assets/images/icon-paperclip.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';
import { ReactComponent as CenterIcon } from '../../assets/images/icon-business-center.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/icon-job-location.svg';
import Linkify from 'linkify-react';

const CompanyMain = ({
  company,
  branches,
  profilePhoto,
  jobs,
  setactiveSubpage
}) => {
  const [contact, setContact] = useState(null);

  const renderParagraphs = () => {
    // Razdvajanje teksta na paragrafe
    const paragraphs = company?.AboutMe?.split('\n');
    //console.log(paragraphs);
    return paragraphs?.map((paragraph, index) => (
      paragraph=="" ? <br></br> : 
        
      <p key={index}>
        <Linkify>{paragraph}</Linkify>
      </p>
    ));
  };

  useEffect(() => {
    let mainIsSet = branches?.find(
      (branche) => branche.ContactPerson !== null && branche.IsMain === true
    )
      ? true
      : false;
    if (mainIsSet) {
      setContact(
        branches?.find(
          (branche) => branche.ContactPerson !== null && branche.IsMain === true
        )?.ContactPerson
      );
    } else {
      setContact(null);
    }
  }, [branches, contact]);

  return (
    <ComponentWrapper marginTop={'40px'}>
      <div className={`${stylesCompany.companyInfo} ${styles.companyContent}`}>
        <div className={styles.contentCard}>
          <div className={styles.cardIcon}>
            <BusinessIcon />
          </div>
          <img src={profilePhoto} />
          <p className={styles.companyName}>{company?.Name}</p>
          <p className={styles.companyLocation}> {company?.LocationAddress}</p>
          <button
            onClick={() => window.open(correctLink(company.Website), '_blank')}
          >
            Webseite
          </button>
        </div>

        <div className={styles.contentCard}>
          <div className={styles.cardIcon}>
            <PaperclipIcon />
          </div>

          <div style={{ overflowY: 'auto' }}>
            <div className={styles.cardList}>
              <div>
                <ClockIcon />
              </div>
              <div>
                <p className={styles.listTitle}>Jobs online:</p>
                <p className={styles.listName}>{jobs?.TotalCount}+</p>
              </div>
            </div>
            <div className={styles.cardList}>
              <div>
                <LocationIcon />
              </div>
              <div>
                <p className={styles.listTitle}>Standorte:</p>
                <p className={styles.listName}>
                  {[
                    ...new Set(
                      branches?.map((branche) => branche.Office?.BranchType)
                    )
                  ].join(', ')}
                </p>
              </div>
            </div>
            <div className={styles.cardList}>
              <div>
                <CenterIcon />
              </div>
              <div>
                <p className={styles.listTitle}>Branche:</p>
                <p className={styles.listName}>
                  {jobs?.list[0]?.Branch.BranchType}
                </p>
              </div>
            </div>
          </div>
          <button onClick={() => setactiveSubpage('Stellenangebote')}>
            ZU DEN STELLEN
          </button>
        </div>

        <div className={styles.contentCard}>
          {contact?.File?.Url ? (
            <img
              src={contact?.File?.Url}
              alt="company image"
              className={styles.cardIcon}
            />
          ) : (
            <div className={styles.cardIcon}>
              <PersonIcon />
            </div>
          )}
          <p className={styles.contact}>Ansprechpartner/in</p>
          <p className={styles.contactName}>
            {contact?.FirstName} {contact?.LastName}
          </p>
          <p className={styles.contactTitle}>{contact?.Position}</p>
          <p className={styles.contactPhone}>
            {' '}
            {contact ? contact.Phone : company?.Phone}
          </p>
          <p className={styles.contactPhone}>
            {' '}
            {contact ? contact.Email : company?.ContactEmail}
          </p>
          <button
            onClick={() =>
              (window.location = `mailto:${
                contact ? contact.Email : company?.ContactEmail
              }`)
            }
          >
            Mail senden
          </button>
        </div>
      </div>

      <div className={stylesCompany.companyDesc}>
        <img src={company.Image} />
        <div style={{width: '100%', overflow: 'auto', paddingLeft: '20px'}}>  <Linkify>{renderParagraphs()}</Linkify></div>
      
        {/* <p>{company.AboutMe}</p> */}
      </div>
    </ComponentWrapper>
  );
};

export default CompanyMain;
