import React, { Fragment, useEffect, useState } from 'react';
import SelectedSkill from '../../../components/CreateProfile/Steps/SetFourSubcomponents/SelectedSkill';
import { ModalOuter } from '../../../components/modal';
import SelectAsync from '../../../components/select/selectAsync';
import SelectedInput from '../../../components/CreateProfile/Steps/SetFourSubcomponents/SelectedInput';
import { Button } from '@mui/material';
const ModalQualifications = ({
  selectedQualifications,
  open,
  skills,
  onClose,
  saveQualifications
}) => {
  const [selectAsyncArr, setselectAsyncArr] = useState([]);
  const [qualifications, setqualifications] = useState([]);

  useEffect(() => {
    setqualifications(
      selectedQualifications.map((item) => ({
        Name: item.Qualifications.Name,
        QualificationsId: item.QualificationsId,

        secondId: item.SkillsId,
        ApplicantQualificationsId: item.ApplicantQualificationsId
      }))
    );
    setselectAsyncArr(
      selectedQualifications.map((item) => ({
        label: item.Qualifications.Name,
        value: item.QualificationsId,

        secondId: item.SkillsId,
        ApplicantQualificationsId: item.ApplicantQualificationsId
      }))
    );
  }, [selectedQualifications]);

  const handleQualifications = (event, inputArr, setLevel) => {
    let values = inputArr;
    if (!setLevel) {
      values = inputArr.map((val) => ({
        QualificationsId: val.value,
        Name: val.label,
        secondId: val?.secondId
      }));
    }

    let newValues = [];
    if (setLevel) {
      newValues = values;
    } else {
      let deleteOption = qualifications?.length > values?.length;

      if (deleteOption) {
        for (let index = 0; index < qualifications.length; index++) {
          if (
            values.find(
              (item) =>
                item.QualificationsId === qualifications[index].QualificationsId
            )
          ) {
            newValues.push(qualifications[index]);
          }
        }
      } else {
        for (let index = 0; index < values.length; index++) {
          if (
            qualifications?.find(
              (item) => item.QualificationsId === values[index].QualificationsId
            )
          ) {
            newValues.push(qualifications[index]);
          } else {
            newValues.push(values[index]);
          }
        }
      }
    }
    setqualifications(newValues);
    let newSelectAsyncArr = [];

    //SelectAsync needs Name and QualificationsId properties nemed label and value
    if (newValues.length > 0) {
      newSelectAsyncArr = newValues.map((item) => ({
        label: item.Name,
        value: item.QualificationsId,
        secondId: item.secondId,
        ApplicantQualificationsId: item.ApplicantQualificationsId
      }));
    }
    setselectAsyncArr(newSelectAsyncArr);
  };

  return (
    <>
      <ModalOuter
        open={open}
        title={'Zusatzqualifikationen / Kenntnisse'}
        showDot={false}
        onClose={() => {
          //   update(itemsArray);
          saveQualifications(qualifications);
          onClose();
        }}
      >
        <>
          <div>
            <SelectAsync
              value={selectAsyncArr}
              option={'Qualifications/Paging'}
              onChange={(newValues, e) => {
                handleQualifications(e, newValues, false);
              }}
              isMulti={true}
              placeholder={'Mehr hinzufügen'}
              searchParam='Name'
            />
          </div>
        </>
        {qualifications.map((industry, index) => (
          <Fragment key={industry.QualificationsId}>
            <SelectedInput language={industry} vehicleType={'additional'} />
            <SelectedSkill
              data={skills}
              values={industry}
              allValues={qualifications}
              index={index}
              setValue={handleQualifications}
              selectedValue={industry.secondId || ''}
            />
          </Fragment>
        ))}
        <Button
          variant="contained"
          color="primary"
          style={{ position: 'absolute', margin: '0 auto', bottom: '0px', left: '0px', right: '0px'}}
          onClick={() => {
            saveQualifications(qualifications);
            onClose();
          }}
          disabled={false}
        >
          Speichern
        </Button>
      </ModalOuter>
    </>
  );
};

export default ModalQualifications;
