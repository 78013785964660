import React from 'react';
import {Div} from './HomeIcon'

export const LocationIcon = (props) => {
  const [hover, sethover] = React.useState('#A7A7A7');
  return (
    <Div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      onMouseEnter={() => sethover('#E83741')}
      onMouseLeave={() => sethover('#A7A7A7')}
    >
      <path
        d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
        stroke={props.stroke || hover}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.657 16.657L13.414 20.9C13.039 21.2746 12.5306 21.485 12.0005 21.485C11.4704 21.485 10.962 21.2746 10.587 20.9L6.343 16.657C5.22422 15.5381 4.46234 14.1127 4.15369 12.5608C3.84504 11.009 4.00349 9.40047 4.60901 7.93868C5.21452 6.4769 6.2399 5.22749 7.55548 4.34846C8.87107 3.46943 10.4178 3.00024 12 3.00024C13.5822 3.00024 15.1289 3.46943 16.4445 4.34846C17.7601 5.22749 18.7855 6.4769 19.391 7.93868C19.9965 9.40047 20.155 11.009 19.8463 12.5608C19.5377 14.1127 18.7758 15.5381 17.657 16.657V16.657Z"
        stroke={props.stroke || hover}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {props.hoverForParagraph && <p>Standorte</p>}</Div>
  );
};
