import styled, { css } from 'styled-components/macro';

const ComponentWrapper = styled.div`
  position: relative;
  padding: 10px;
  
  @media (max-width: 770px) {
    ${(props) =>
      props.marginTop &&
      css`
        margin-top: ${props.marginTop};
      `}
  
  }

  @media (max-width: 500px) {
    ${(props) =>
      props.marginTop &&
      css`
        margin-top: 80px;
      `}
  
  }

  
  @media (max-width: 500px) {
    ${(props) =>
      props.marginTop &&
      css`
        margin-top: 120px;
      `}
  
  }


  
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${(props) =>
    props.noPaddingLeft &&
    css`
      padding-left: 0 !important;
    `}

 

  ${(props) =>
    props.noPaddingRight &&
    css`
      padding-right: 0 !important;
    `}

  ${(props) =>
    props.noPaddingTop &&
    css`
      padding-top: 0 !important;
    `}

  ${(props) =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.noPaddingVertical &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.horizontalPaddingHalf &&
    css`
      padding-left: 18px !important;
      padding-right: 18px !important;
    `}

  ${(props) =>
    props.noPaddingHorizontal &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}

  ${(props) =>
    props.paddingVeticalHalf &&
    css`
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    `}

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

    ${(props) =>
    props.right &&
    css`
      text-align: right;
    `}

  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme.cGrey};
    `}

    ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

    ${(props) =>
    props.fluidHeight &&
    css`
      height: 100%;
    `}
    ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
  ${(props) =>
    props.fluid &&
    css`
      width: 100%;
    `}
     ${(props) =>
    props.halfFluid &&
    css`
      width: 50%;
    `}

    ${(props) =>
    props.flex &&
    css`
      flex: 1;
    `}
       ${(props) =>
    props.flexWrap &&
    css`
      flex-wrap: wrap;
    `}

      ${(props) =>
    props.displayFlex &&
    css`
      display: flex;
    `}
    ${(props) =>
    props.flexColumn &&
    css`
      flex-direction: column;
    `}
    
  ${(props) =>
    props.justifyCenter &&
    css`
      justify-content: center;
    `}
    ${(props) =>
    props.justifyBetween &&
    css`
      justify-content: space-between;
    `}
    ${(props) =>
    props.justifyStart &&
    css`
      justify-content: flex-start;
    `}
    ${(props) =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `}
   ${(props) =>
    props.justifyAround &&
    css`
      justify-content: space-around;
    `}
     ${(props) =>
    props.alignCenter &&
    css`
      align-items: center;
    `}
    ${(props) =>
    props.alignFlexEnd &&
    css`
      align-items: flex-end;
    `}
    ${(props) =>
    props.alignStart &&
    css`
      align-items: flex-start;
    `}
`;

export default ComponentWrapper;
