import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 0 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: #818181;
  margin-bottom: 1px !important;
`;

export default function SelectedLanguages(props) {
  //console.log(props);
  return (
    <Container>
      <Paragraph>{props.language.Name}</Paragraph>
      {props.vehicleType === 'car' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-car"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="7" cy="17" r="2" />
          <circle cx="17" cy="17" r="2" />
          <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
        </svg>
      ) : null}

      {props.vehicleType === 'boat' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-sailboat"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1" />
          <path d="M4 18l-1 -3h18l-1 3" />
          <path d="M11 12h7l-7 -9v9" />
          <line x1="8" y1="7" x2="6" y2="12" />
        </svg>
      ) : null}
      {props.vehicleType === 'plane' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-plane"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z" />
        </svg>
      ) : null}

      {props.vehicleType === 'special' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-backhoe"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="4" cy="17" r="2" />
          <circle cx="13" cy="17" r="2" />
          <line x1="13" y1="19" x2="4" y2="19" />
          <line x1="4" y1="15" x2="13" y2="15" />
          <path d="M8 12v-5h2a3 3 0 0 1 3 3v5" />
          <path d="M5 15v-2a1 1 0 0 1 1 -1h7" />
          <path d="M21.12 9.88l-3.12 -4.88l-5 5" />
          <path d="M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z" />
        </svg>
      ) : null}

      {props.vehicleType === 'additional' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-windows"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="4" y1="12" x2="20" y2="12" />
        </svg>
      ) : null}
      {props.vehicleType === 'interest' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-confetti"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#E83741"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 5h2" />
          <path d="M5 4v2" />
          <path d="M11.5 4l-.5 2" />
          <path d="M18 5h2" />
          <path d="M19 4v2" />
          <path d="M15 9l-1 1" />
          <path d="M18 13l2 -.5" />
          <path d="M18 19h2" />
          <path d="M19 18v2" />
          <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1.003 1.003 0 0 0 1.329 1.329l9.579 -4.39z" />
        </svg>
      ) : null}
    </Container>
  );
}
