import React from 'react';
import { useHistory } from 'react-router';
import SubmitButton from '../Buttons/submitButton';
import styles from './ErrorMessageModal.module.scss';
import CustomModal from './Modal';

const ErrorMessageModal = ({ open, onClose, message }) => {
  const history = useHistory();
  return (
    <>
      <CustomModal width="500px" height="200px" open={open} onClose={onClose}>
        <div className={styles.innerDiv}>
          <p>{message}</p>
          <SubmitButton
            small
            onClick={() => {
              history.push('/arbeitgeber', {data: 'scroll'});
            }}
          >
            Verfügbare Pakete anzeigen
          </SubmitButton>
        </div>
      </CustomModal>
    </>
  );
};

export default ErrorMessageModal;
