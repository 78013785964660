import React, { useEffect, useState } from 'react';
import DashboardLayout from './DashboardLayout';
import SubSidebar from '../../components/Dashboard/SubSidebar';
import { DashboardTitle } from './Home';
import { Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import TableWrapper from '../../components/DataTable/TableWrapper';
import EmptyList from '../../assets/images/empty-list.svg';
import styled from 'styled-components';
import { DeviceSize } from '../../components/responsive';
import GetData from '../../api/GetData';
import auth from '../../auth';
import ApplicationModal from '../../components/modal/applicationModal';
import QuickApplyModal from '../../components/modal/QuickApplyModal';
import { useMediaQuery } from 'react-responsive';
import CircularLoader from '../../components/Loader/CircularLoader';

export const EmptyBox = styled.div`
  flex: 1;
  position:relative;
  z-index:2;
  img {
    height: 350px;
    width: auto;
  }
  h4{
    color:#e71e2c;
    font-size:20px;
    margin-bottom:50px;
    text-transform: uppercase;
    font-weight: bold;
  }

  @media only screen and (max-width: ${DeviceSize.newSmall}px) {
    img {
      height: 100px;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Applications = (props) => {
  const [jobApplications, setjobApplications] = useState([]);
  const [applicationModal, setapplicationModal] = useState(false);
  const [quickApplyModal, setQuickApplyModal] = useState(false);
  const [EditData, setEditData] = useState(null);
  const [loadingData, setloadingData] = useState(true);

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const Userid = auth.getId();

  const getJobApplication = async () => {
    const response = await GetData(
      `JobApplicant?CompanyId=${Userid}&IncludeUser=true&IncludeJobAdv=true`
    );
    setjobApplications(response);
  };

  useEffect(() => {
    (async () => {
      setloadingData(true);
      await Promise.allSettled([getJobApplication()]);
      setloadingData(false);
    })();
  }, []);

  return (
    <DashboardLayout noPadding sidebar style={{ flex: 1 }}>
      <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
        <SubSidebar
          title={'Inhalt'}
          list={
            props.isOrganization
              ? [
                  { Name: 'Ehrenamt', Link: 'jobs' },
                  { Name: 'Bewerber', Link: 'bewerber' }
                ]
              : [
                  { Name: 'Arbeitsplätze', Link: 'jobs' },
                  { Name: 'Bewerber', Link: 'bewerber' },
                  { Name: 'Blogeinträge', Link: 'blogeinträge' }
                ]
          }
          storage={'bewerber'}
        />
        <ComponentWrapper
          fluid
          padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
        >
          <Row>
            <DashboardTitle red> Bewerber</DashboardTitle>
          </Row>

          <ComponentWrapper flex displayFlex noPaddingHorizontal>
            {loadingData ? (
              <ComponentWrapper fluid>
                <CircularLoader small />
              </ComponentWrapper>
            ) : (
              <>
                {jobApplications?.length ? (
                  <TableWrapper
                    tableTitle={
                      props.isOrganization
                        ? 'Ehrenamtliche Bewerbungen'
                        : 'Bewerbungen'
                    }
                    pageSize={10}
                    openOption={(data) => {
                      data.row.ApplicationType === "quick-apply"? setQuickApplyModal(true) :  setapplicationModal(true);
                      setEditData(data.row);
                    }}
                    columns={['Name', 'Job', 'Standort', 'Passend', 'Handlung']}
                    rows={generateApplicationsRows(jobApplications)}
                  />
                ) : (
                  <EmptyBox>
                    <div className="mb-5">
                      <img src={EmptyList} alt="Empty"></img>
                    </div>
                    <h4 className="fw-bold">Sie haben keine Bewerbungen</h4>
                    <p className="mb-0">
                      Niemand hat sich auf Ihre Jobs beworben
                    </p>
                  </EmptyBox>
                )}
              </>
            )}
          </ComponentWrapper>

          <ApplicationModal
            open={applicationModal}
            onClose={() => {
              setapplicationModal(false);
              setEditData(null);
            }}
            userId={EditData && EditData.UserId}
            jobId={EditData && EditData.JobId}
            match={EditData && EditData.Passend}
            userName={EditData && EditData.Name}
            email={EditData && EditData.Email}
            job={EditData && EditData.JobAdv}
            matchingId={EditData && EditData.MatchingId}
            officeApplications={EditData && EditData.OfficeApplications}
            title="Application"
            option="View application"
          />

          <QuickApplyModal
          openModal= {quickApplyModal}
          setOpenModal= {setQuickApplyModal}
          data={EditData}
          />
        </ComponentWrapper>
      </ComponentWrapper>
    </DashboardLayout>
  );
};

const generateApplicationsRows = (applications) => {
  let newRows = [];
  applications &&
    applications.map(async (element, index) => {
      newRows.push({
        id: index,
        Name: element.Applicant? `${element.Applicant?.FirstName} ${element.Applicant?.LastName}` : `${element.QuickApplyUser?.Name} ${element.QuickApplyUser?.LastName}`,
        Job:
          element?.JobAdvertisement?.Profession?.ProfesionName ||
          element?.JobAdvertisement?.ProfessionText,
          Standort: element.JobAdvertisement?.JobLocation?.City,
          Passend: element.ApplicationType === "quick-apply"? "quick-apply" : element.Result,
          Handlung: { text: 'Siehe Bewerbung', outlined: true },
          Email: element.Applicant? element.Applicant?.Email : element.QuickApplyUser?.Email,
        UserId: element.ApplicantId,
        JobId: element.JobAdvertisementId,
        MatchingId: element.JobAdvertisement?.MatchingId,
        JobAdv: element.JobAdvertisement,
        OfficeApplications: element.OfficeApplications,
        ApplicationType: element.ApplicationType,
        PhoneNumber: element.QuickApplyUser?.PhoneNumber
      });
    });
  return newRows;
};

export default Applications;
