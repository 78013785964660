import { ReactComponent as ListCheckmark } from '../../assets/images/list-checkmark.svg';
import ManImage from '../../assets/images/bewerber-third-img.png';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {Animated} from "react-animated-css";
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';

const ThirdStep = ({ isVisible, setActive }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({maxWidth: DeviceSize.iPadPro});
    return(
        <section className="bewerber-section bewerber-section-third" id='bewerber-third'>
            <div 
          /*  animationIn="bounceInRight" 
             animationOut="bounceOutRight" 
               animationInDuration={1600} 
             animationOutDuration={1600}
             isVisible={isVisible} */
            className="section-card">
                <div 
                 /*animationIn="bounceInLeft" 
                 animationOut="bounceOutLeft" 
                   animationInDuration={1600} 
                 animationOutDuration={1600}
                 isVisible={isVisible} */
                className="section-text">
                    <h3>Warum suchen?<br/>Lass dich vernetzen!</h3>
                    <p className="first-p-B first-p-B--bold">Bei spiderjob läuft die Jobsuche einfach und unkompliziert.</p>
                        <p className="first-p-B">Mit nur einem Profil kannst du dich auf alle Stellenanzeigen,<br/> bewerben, die definitiv zu dir passen. </p>

                      <p className='list-p'>Und das beste: </p>
                    <ul>
                           <li ><ListCheckmark style={{marginBottom: '5px'}}/>Mit nur einem Klick, ohne Anschreiben.</li>
                           <li><ListCheckmark style={{marginBottom: '5px'}}/>100% Kostenlos.</li>
                           <li><ListCheckmark style={{marginBottom: '5px'}}/>Schnell, einfach und überall.</li>
                    </ul>
                </div>
               {!isMobile &&<img src={ManImage}/>} 
            </div>
            <div 
            /* animationIn="bounceInRight" 
             animationOut="bounceOutRight" 
               animationInDuration={1600} 
             animationOutDuration={1600}
             isVisible={isVisible} */
            className='section-buttons-B'>
                <Link key='bewerber-fourth' to='bewerber-fourth' smooth spy offset={ -180} duration={80} activeClass="active-step" onSetActive = {() => setActive('bewerber-fourth')} >
                    <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); setActive('bewerber-fourth')}}>
                    Deine Vorteile
                    </Button>
                </Link>
                <Link key='bewerber-fifth' to='bewerber-fifth' smooth spy offset={80} duration={100} activeClass="active-step" onSetActive = {() => setActive('bewerber-fifth')} >
                        <Button style = {{textAlign: 'center', display: 'flex',  background: 'white'}}variant="contained" color="primary" onClick={(e) => {e.preventDefault(); setActive('bewerber-fifth')}}>
                        So funktioniert’s  
                        </Button>
            </Link>
            <Link>
            <Button variant="contained" color="primary" onClick={() => history.push('/profil-anlegen')}>
              Profil anlegen
            </Button>
            </Link>
            </div>
        </section>
    )
}

export default ThirdStep;
