import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { DeviceSize } from '../components/responsive';
import { useMediaQuery } from 'react-responsive';
import Company from '../components/Auth/Register/Company';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import styles from '../components/Auth/Login.module.scss';
import logo from '../assets/images/loginLogo.jpg';
import RegisterNew from './RegisterNew';

//ova stranica se vise ne koristi

const Container = styled.div`

    .register-form {
        margin: 0 auto;
        width: 700px;
      
      }

      @media only screen and (min-width: 1600px) {
       .register-form {
        margin: 150px auto 0 auto;
       }
      }
      
      @media only screen and (max-width: ${DeviceSize.mobile}px) {
        .register-form {
          width: 650px;
          padding-top: 20%;        }

        @media only screen and (max-width: ${DeviceSize.newExtraSmall}px) {
          .register-form {
            width: 100%;
            margin-top: 4%;
          }

       
          }
         
`;

const Subcontainer = styled.div`
  padding: 50px;
  border-radius: 10px;
  margin-top: 2px;
  .MuiChip-colorSecondary {
    width: 100%;
    background-color: #e83741 !important;
    height: 40px;
    margin: 0px !important;
    font-size: 15px !important;
    border-radius: 10px !important;
  }
  .MuiChip-colorSecondary > span {
    padding: 0 14px;
  }
  .MuiChip-colorPrimary {
    background-color: #ffffff !important;
    color: #c1c1c1 !important;
    font-size: 15px !important;
  }
  > .chips__parent {
    margin-bottom: 10px;
    border-radius: 10px !important;
  }
  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    padding: 25px;
  }
  @media only screen and (max-width: ${DeviceSize.newExtraSmall}px) {
    padding: 10px 10px 50px;
    margin: 0;
    box-shadow: none;
  }
`;

export default function Register({ companyRegister }) {
  const history = useHistory();
  const location = useLocation();
  const [active, setActive] = useState(0);
  const [urlIds, seturlIds] = useState({
    orderId: null,
    buyerId: null
  });

  const handleClick = (index) => {
    if (index === 2) {
      history.push('/kontakt');
    }
    setActive(index);
  };

  var chipData = [
    {
      key: 0,
      label: 'Bewerber',
      color: active === 0 ? 'secondary' : 'primary',

      handleClick
    },
    // {
    //   key: 2,
    //   label: 'Ehrenamt',
    //   color: active === 2 ? 'secondary' : 'primary',
    //   handleClick
    // },
    {
      key: 2,
      label: 'Partner',
      color: active === 2 ? 'secondary' : 'primary',
      handleClick
    }
  ];

  useEffect(() => {
    if (location.state?.company) setActive(1);
  }, []);

  useEffect(() => {
    if (companyRegister) {
      const params = new URLSearchParams(location.search);
      seturlIds({
        orderId: params.get('orderId'),
        buyerId: params.get('buyerId')
      });
    }
  }, [companyRegister]);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

 
  return companyRegister ? (
    <div>
      <div className="maxWidth">
        <div className={styles.loginParent}>
          <Helmet>
            <title>spiderjob - Registrieren</title>
          </Helmet>
          <img
            src={logo}
            alt="help"
            style={
              !isMobile
                ? {
                    marginLeft: '39%',
                    marginTop: '30px',
                    width: '22%',
                  }
                : {
                    marginLeft: '35%',
                    position: 'absolute',
                    top: '30px',
                    width: '28%',
                  }
            }
          />
  
          <Container className="main-container">
            <div className="register-form">
              <Subcontainer>
                <>
                  <ComponentWrapper noPaddingHorizontal>
                    <Company ids={urlIds} />
                  </ComponentWrapper>
                </>
              </Subcontainer>
            </div>
          </Container>
        </div>
      </div>
    </div>
  ) : (
    <RegisterNew></RegisterNew>
  );
 
}
