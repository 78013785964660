import CustomModal from './Modal';
import { Flex } from '../Common/Input';
import './quickApplyModal.scss';

const QuickApplyModal = ({ data, openModal, setOpenModal }) => {
  return (
    <CustomModal
      maxWidth="400px"
      height="360px"
      open={openModal}
      onClose={() => setOpenModal(false)}
      className="quick-apply-modal"
    >
      <Flex column jCenter fitContent marginAuto style={{ height: '100%' }}>
        <p className="quick-apply-modal__header">Bewerberinformationen</p>

        <p className="quick-apply-modal__info">
          <label>Name</label>
          <label>{data?.Name}</label>
        </p>

        <p className="quick-apply-modal__info">
          <label>Telefon</label>
          <label>{data?.PhoneNumber}</label>
        </p>

        <p className="quick-apply-modal__info">
          <label>E-Mail</label>
          <label>{data?.Email}</label>
        </p>
      </Flex>
    </CustomModal>
  );
};

export default QuickApplyModal;
