import React from 'react';
import '../../assets/scss/App.scss';
import { Navbar } from '../navbar/index';

function Header() {
  return (
      <Navbar />
  );
}

export default Header;
