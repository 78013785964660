import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SideBar from '../../components/Sidebar/Sidebar';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../components/responsive';
import Line from '../../assets/images/LineDash.png';
import menuItem from '../../assets/images/menuItem.svg';
import logoutIcon from '../../assets/images/logoutIcon.svg';
import auth from '../../auth';
import GetData from '../../api/GetData';
import { Tooltip } from '@mui/material';

import { Menu, MenuItem } from '@mui/material';

const Layout = styled.div`
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  .dash-mobile {
    // margin-left: 70px;
  }
`;
export const DashboardContainer = styled.div`
  width: 100%;
  padding: 50px;
  scroll-behavior: auto;
  min-height: 100vh;
  overflow: auto;
  //margin-left: 100px;
  ${(props) =>
    props.noPadding &&
    `
  padding:0;
  `}

  ${(props) =>
    props.subSidebar &&
    `
 margin-left: 15%;
 display: flex;
 flex-direction: column;
  `}
`;

const Bar = styled.div`
  height: 70px;
  width: 100%;
`;

const ProfilePic = styled.img`
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 1px solid #fbfbfb;
  background-color: #f2f2f2;
  margin-left: 20px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 25px 80px;
`;

export function DashboardLayout(props) {
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const history = useHistory();
  const [userName, setUserName] = useState();

  const [profilePic, setProfilePic] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const GetProfileImage = async () => {
    const response = await GetData(`File?UserId=${auth.getId()}`);
    const response1 = await GetData(`User/${auth.getId()}`);
    setUserName(response1.FirstName + ' ' + response1.LastName);
    if (!response)
      setProfilePic('https://api.spiderjob.de/Resources/ProfileImage.png');
    else setProfilePic(response[0]?.Url);
  };

  useEffect(() => {
    auth.isApplicant() && GetProfileImage() 
  }, []);

  return (
    <Layout>
      <SideBar />

      <DashboardContainer
        className={
          isMedium ? 'dash-mobile dashboard-container' : 'dashboard-container'
        }
        {...props}
      >
        {auth.isApplicant() ? (
          <div>
            {' '}
            <Bar>
              <Wrapper>
                {/* <img src={NotificationIcon} alt="Notification icon" /> */}
                <Tooltip title="Account settings">
                  <ProfilePic
                    src={profilePic}
                    alt="Profile icon"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  />
                </Tooltip>
              </Wrapper>
            </Bar>
            <img style={{ width: '100%' }} src={Line} alt="Line"></img>
            <div
              style={{
                marginLeft: '40%'
              }}
            >
              <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem style={{ pointerEvents: 'none' }}>
                  Hi, {userName}
                </MenuItem>
                {auth.isApplicant() && (
                  <MenuItem
                    onClick={() => {
                      history.push('/bewerber-dashboard/account-einstellungen');
                    }}
                  >
                    <div>
                      <img
                        style={{ marginRight: '10px' }}
                        src={menuItem}
                        alt="Notification icon"
                      />
                      Einstellungen
                    </div>
                  </MenuItem>
                )}
                <MenuItem onClick={() => auth.logout((_) => history.push('/'))}>
                  {' '}
                  <div>
                    <img
                      style={{ marginRight: '10px' }}
                      src={menuItem}
                      alt="Notification icon"
                    />
                    Ausloggen
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ) : (
          <div>
            <Bar>
              <Wrapper>
                {' '}
                <img
                  style={{ width: '25px', height: '25px' , cursor: 'pointer' }}
                  src={logoutIcon}
                  alt="logout"
                  onClick={() => auth.logout((_) => history.push('/'))}
                ></img>
              </Wrapper>
              <img style={{ width: '100%' }} src={Line} alt="Line"></img>
            </Bar>
          </div>
        )}

        {props.children}
      </DashboardContainer>
    </Layout>
  );
}

export default DashboardLayout;
