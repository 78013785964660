/* Libraries */
import patchdata from '../api/patchdata';
import PostData from '../api/PostData';
import auth from '../auth';

import axios from 'axios';
import config from '../config/api.config';
import LocalStorageService from '../LocalStorageService';

export async function submitCandidate(steps) {
  console.log('🚀 ~ steps', steps);
  var UserId = parseInt(auth.getId());

  const { stepOne, stepTwo, stepThree, stepFour, stepFive } = steps;

  await axios
    .post(`${config.apiUrl}/Location`, {
      streetNumber: stepOne.region.streetNumber?.toString(),
      streetName: stepOne.region.streetName,
      district: stepOne.region.district,
      city: stepOne.region.city,
      country: stepOne.region.country,
      postalCode: stepOne.region.postalCode,
      latitude: stepOne.region.latitude?.toString() ?? '0',
      longitude: stepOne.region.longitude?.toString() ?? '0',
      formattedAddress: stepOne.region.formattedAddress
    })
    .then(async (res) => {
      const location = res.data.LocationId;

      await axios
        .post(`${config.apiUrl}/Location`, {
          streetNumber: stepFive.region.streetNumber?.toString(),
          streetName: stepFive.region.streetName,
          district: stepFive.region.district,
          city: stepFive.region.city,
          country: stepFive.region.country,
          postalCode: stepFive.region.postalCode,
          latitude: stepFive.region.latitude?.toString() ?? '0',
          longitude: stepFive.region.longitude?.toString() ?? '0',
          formattedAddress: stepFive.region.formattedAddress,
          radius: stepFive.region.radius
        })
        .then(async (res) => {
          const UserData = [
            {
              path: '/Mobile',
              op: 'replace',
              value: stepOne.personalData.mobile
            },
            {
              path: '/Phone',
              op: 'replace',
              value: stepOne.personalData.telefon
            },
            {
              path: '/TitleID',
              op: 'replace',
              value: stepOne.personalData.title
            },
            {
              path: '/BirthDate',
              op: 'replace',
              value: stepOne.personalData.birthday
            },
            {
              path: '/BirthdayPlace',
              op: 'replace',
              value: stepOne.personalData.birthPlace
            },
            {
              path: '/NationalityID',
              op: 'replace',
              value: stepOne.personalData.nationality
            },
            {
              path: '/GenderId',
              op: 'replace',
              value: parseInt(stepOne.personalData.gender)
            },
            {
              path: '/MinAnnualSalary',
              op: 'replace',
              value: parseInt(stepOne.personalData.minAnnualSalary)
            },
            {
              path: '/MaxAnnualSalary',
              op: 'replace',
              value: parseInt(stepOne.personalData.maxAnnualSalary)
            },
            {
              path: '/LocationId',
              op: 'replace',
              value: location
            },
            {
              path: '/CompletedTraining',
              op: 'replace',
              value: stepTwo.completedApprenticeship ?? false
            },
            {
              path: '/ProfessionalExperience',
              op: 'replace',
              value: stepTwo.hasWorkExperience ?? false
            },
            {
              path: '/PersonnelResponsibility',
              op: 'replace',
              value: stepTwo.hasPersonalExperience ?? false
            },
            {
              path: '/SchoolDiploma',
              op: 'replace',
              value: stepThree.completedHighSchool ?? false
            },
            {
              path: '/CollegeDegree',
              op: 'replace',
              value: stepThree.completedCollege ?? false
            },

            {
              path: '/StartingDateId',
              op: 'replace',
              value: stepFive.startingDateId
            },
            {
              path: '/LoveTraveling',
              op: 'replace',
              value: stepFive.wouldTravel ?? false
            },
            {
              path: '/TravelingId',
              op: 'replace',
              value: stepFive.travelRegionId
            },
            {
              path: '/WorkInShifts',
              op: 'replace',
              value: stepFive.workInShifts
            },
            {
              path: '/ShiftId',
              op: 'replace',
              value: stepFive.shiftId
            },
            {
              path: '/JobRegionId',
              op: 'replace',
              value: res.data.LocationId
            }
          ];

          await patchdata('User', UserData);
        })
        .catch((err) => console.error(err));
    })
    .catch((err) => console.error(err));

  //VOCATIONAL // APPRENTICESHIP
  stepTwo.completedApprenticeship &&
    stepTwo.vocationalArray &&
    (await Promise.all(
      stepTwo.vocationalArray.map(async (step) => {
        let userApprenticeship = {
          applicantId: UserId,
          professionId: step.value,
          isDesired: false
        };
        await PostData('ApplicantDesiredProfession', userApprenticeship);
      })
    ));

  //Aplikant Industry
  //Matching employment
  stepTwo.hasWorkExperience &&
    stepTwo.workExperience &&
    (await Promise.all(
      stepTwo.workExperience.map(async (step) => {
        let data = {
          applicantId: UserId,
          branchId: step.industryId,
          professionId: step.proffesionId,
          moreThan3Year: step.years === '3+' ? true : false
        };
        await PostData('ApplicantProfession', data);
      })
    ));

  //AplikantCertifikat

  stepThree.completedHighSchool &&
    stepThree.highschoolArray &&
    (await Promise.all(
      stepThree.highschoolArray.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.SchoolCertificateId
        };
        await PostData('ApplicantCertificate', data);
      })
    ));

  //College //
  stepThree.completedCollege &&
    stepThree.collegeArray &&
    (await Promise.all(
      stepThree.collegeArray.map(async (step) => {
        let userCollege = {
          firstId: UserId,
          secondId: step.CollegeDegreeId
          //customTypeId: null
        };
        await PostData('ApplicantCollege', userCollege);
      })
    ));

  //Languages

  stepFour.languages &&
    (await Promise.all(
      stepFour.languages.map(async (step) => {
        let data = {
          levelId: parseInt(step.secondId),
          languageId: step.LanguageId,
          applicantId: UserId
        };
        await PostData('LanguageLevel', data);
      })
    ));

  //Vozacke
  //Zrak
  stepFour.airLicences &&
    (await Promise.all(
      stepFour.airLicences.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.LicenseId
        };
        await PostData('ApplicantLicense', data);
      })
    ));
  //Zemlja

  stepFour.landLicences &&
    (await Promise.all(
      stepFour.landLicences.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.LicenseId
        };
        await PostData('ApplicantLicense', data);
      })
    ));
  //Zrak

  stepFour.specialLicences &&
    (await Promise.all(
      stepFour.specialLicences.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.LicenseId
        };
        await PostData('ApplicantLicense', data);
      })
    ));

  stepFour.waterLicences &&
    (await Promise.all(
      stepFour.waterLicences.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.LicenseId
        };
        await PostData('ApplicantLicense', data);
      })
    ));

  //Qualifications
  stepFour.additionalQualifications &&
    (await Promise.all(
      stepFour.additionalQualifications.map(async (step) => {
        let data = {
          applicantId: UserId,
          qualificationsId: step.QualificationsId,
          skillsId: step.secondId
        };
        await PostData('ApplicantQualifications', data);
      })
    ));
  //AplicantEmployment

  stepFour.hobbies &&
    (await Promise.all(
      stepFour.hobbies.map(async (step) => {
        let data = {
          applicantId: UserId,
          hobbie: step
        };
        await PostData('ApplicantHobbies', data);
      })
    ));

  //Benefits
  stepFive.benefitsArray &&
    (await Promise.all(
      stepFive.benefitsArray.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.BenefitsId
        };
        await PostData('ApplicantBenefits', data);
      })
    ));

  //Employment type

  stepFive.employmentArray &&
    (await Promise.all(
      stepFive.employmentArray.map(async (step) => {
        let data = {
          firstId: UserId,
          secondId: step.EmploymentId
        };
        await PostData('ApplicantEmployment', data);
      })
    ));

  stepFive.branchArray &&
    (await Promise.all(
      stepFive.branchArray.map(async (step) => {
        let data = {
          applicantId: UserId,
          branchId: step.industryId,
          professionId: step.proffesionId,
          isDesired: true
        };
        await PostData('ApplicantDesiredProfession', data);
      })
    ));

  await patchdata('User', [
    {
      path: '/VerifiedAccount',
      op: 'replace',
      value: true
    }
  ]).then((res) => {
    auth.setVerifiedTrue();
    LocalStorageService.set({ verified: true });
  });
}

export default submitCandidate;
