import React from 'react';
import picture from '../assets/images/unsubscribe.png';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import { useEffect, useState } from 'react';
import axios from '../api/axios';
import config from '../config/api.config';
import CircularLoader from '../components/Loader/CircularLoader';
import Alert from '@mui/material/Alert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const Text = styled.p`
  font-family: Poppins;
  color: ${(props) => (props.color ? props.color : '#575757')};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  text-align: center;
`;

const Img = styled.img`
  height: 160px;
  width: 230px;
  margin-bottom: 50px;
`;

const Row = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
`;

const notificationsOff = (isActive) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get('email');
  email &&
    axios
      .put(
        `${config.apiUrl}/api/emailnotification/change-notification-settings?email=${email}&isActive=${isActive}`
      )
      .then((response) => console.log(response.data))
      .catch((error) => console.error(error));
};

export default function Unsubscribe() {
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    notificationsOff(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    let timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // alert će se sakriti nakon 3 sekunde
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  const history = useHistory();
  return loading ? (
    <CircularLoader small />
  ) : (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh', justifyContent: 'space-between'}}>
      {showAlert && (
        <Alert severity="success" style={{width: '300px', margin: '0 auto', justifyContent: 'center'}}>
          Du hast dich erneut angemeldet!
        </Alert>
      )}
      <Wrapper>
      <Img src={picture}></Img>
      <Text size={'40px'} weight={'600'}>
        Du hast dich erfolgreich abgemeldet!
      </Text>
      <Text size={'25px'} weight={'500'}>
        Du wirst keine E-Mail-Benachrichtigungen mehr erhalten.
      </Text>
      <Row>
        <Text size={'18px'} weight={'500'} color={'#A7A7A7'}>
          Versehentlich abgemeldet?
        </Text>
        <Text
          size={'18px'}
          weight={'500'}
          color={'#E83741'}
          onClick={() => {
            setLoading(true);
            notificationsOff(true);
            setLoading(false);
            setShowAlert(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          Erneut abbonieren.
        </Text>
      </Row>

      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/')}
      >
        Zurück zu Spiderjob
      </Button>

   
    </Wrapper>
    <Footer style={{margin: '0 auto'}}></Footer>
    </div>
  );
}
