import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { Input, Label, Flex } from '../../components/Common/Input';
import Snackbar from '../../components/snackbar/snackbar';
import { DeviceSize } from '../../components/responsive';
import config from '../../config/api.config';

import SubmitButton from '../Buttons/submitButton';
import { Link } from 'react-router-dom';

const Container = styled.div`
  background-color: white;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(234, 234, 234);
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 50px;
  h3 {
    font-size: 26px;
  }
  @media only screen and (max-width: ${DeviceSize.newSmall}px) {
    padding: 25px;
  }
`;

const P = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: rgba(73, 73, 73, 1);
  opacity: 0.8;
`;

const Marker = styled.marker`
  color: #e83741;
`;

export default function ContactForm() {
  const location = useLocation();
  const [values, setValues] = useState({
    lastname: '',
    name: '',
    position: '',
    email: '',
    mobile: '',
    companies: '',
    package: '',
    packageText: ''
  });
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${config.apiUrl}/Email/SendConnMail`, {
        firstName: values.name,
        lastName: values.lastname,
        email: values.email,
        position: values.position,
        mobile: values.mobile,
        company: values.companies,
        ...(values.package && { package: values.package }),
        ...(values.packageId && { packageId: values.packageId })
      })
      .then((res) => {
        setLoading(false);
        setSeverity('success');
        showSnackbar(true);
        setSnackbarMessage(res.data.Message);
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          setSeverity('error');
          showSnackbar(true);
          setSnackbarMessage(
            error.response.data.ERROR
              ? error.response.data.ERROR[0]
              : error.response.data.title
          );
        }
      });
  };

  useEffect(() => {
    if (
      values.name &&
      values.lastname &&
      values.email &&
      values.mobile &&
      values.companies
    )
      setValid(true);
    else setValid(false);
  }, [
    values.name,
    values.lastname,
    values.email,
    values.mobile,
    values.companies
  ]);

  useEffect(() => {
    if (location.state)
      setValues({
        ...values,
        package: location.state.package,
        packageText: location.state.packageText
      });
  }, [location]);

  return (
    <Container>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity={severity}
      >
        {snackbarMessage}
      </Snackbar>
      <Helmet>
        <title>spiderjob - Apply Now!</title>
      </Helmet>
      <h3 className="mb-4">Ihre Kontaktdaten</h3>
      <div>
        <div>
          <Label>Unternehmen*</Label>
          <Input
            type="text"
            name="companies"
            value={values.companies}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label>Vorname*</Label>
          <Input
            type="text"
            name="lastname"
            value={values.lastname}
            onChange={handleChange}
          />
        </div>

        <div>
          <Label>Name*</Label>
          <Input
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <Label>Position*</Label>
          <Input
            type="text"
            name="position"
            value={values.position}
            onChange={handleChange}
          />
        </div>

        <div>
          <Label>Geschaftliche E-Mail*</Label>
          <Input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <Label>Telefonnummer*</Label>
          <Input
            type="text"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^\+?\d+$]/g, '');
              e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
            }}
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
          />
        </div>

        {values.package && (
          <div style={{ marginBottom: '2rem' }}>
            <Label
              style={{
                alignItems: 'center',
                border: '1px solid rgb(232 55 65 / 37%)',
                padding: '2px 8px',
                borderRadius: '10px',
                justifyContent: 'center'
              }}
            >
              <span>Ausgewähltes Paket -</span>{' '}
              <span
                style={{
                  color: '#e83741',
                  fontSize: '1.12rem',
                  fontWeight: '500',
                  marginLeft: '0.3rem'
                }}
              >
                {values.package}
              </span>
            </Label>
          </div>
        )}

        <P>
          Ich habe die Bestimmungen der
          <Link
            to="/datenschutz-bestimmungen"
            style={{
              textDecoration: 'none',
              color: '#E30613',
              marginLeft: '3px',
              marginRight: '3px'
            }}
          >
            Datenschutzerklärung
          </Link>{' '}
          zur Kenntnis genommen und stimme den{' '}
          <Link
            to="/agb"
            style={{
              textDecoration: 'none',
              color: '#E30613'
            }}
          >
            Allgemeinen Geschäftsbedingungen
          </Link>{' '}
          zu.
        </P>

        <Flex jCenter>
          <SubmitButton
            buttonClicked={loading}
            onClick={onSubmit}
            valid={valid}
            type="submit"
          >
            Absenden
          </SubmitButton>
        </Flex>
      </div>
    </Container>
  );
}
