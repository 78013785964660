import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import Snackbar from '../../snackbar/snackbar';
import { DeviceSize } from '../../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import SubmitButton from '../../Buttons/submitButton';
import Logo from '../../../assets/images/newLogo.png';
import { useHistory } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;

  .MuiStepLabel-root {
    padding: 0;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .MuiStepLabel-label {
    font-size: 12px !important;
    padding: 0;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiStepLabel-label.Mui-active {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiFormControlLabel-label {
    font-size: 16px !important;
    opacity: 0.8;
    letter-spacing: 0.01071em;
  }

  .MuiStepConnector-vertical {
    padding: 0;
  }

  .left-header {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 15px;
  }

  @media (max-width: 992px) {
    margin-top: 0px;
  }
`;

export const LeftSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #F8F8F8;
  height: 100px;
  .MuiStepper-root {
    background: transparent;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 0;

    svg {
      width: 25px;
      height: 25px;
      margin-bottom: 5px;
    }
  }
  .MuiStepLabel-labelContainer {
    margin-left: 0;
  }

  .MuiStep-root {
    padding: 0 25px;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    width: 69px;
    display: flex;
    align-items: center;
    height: auto;

    .MuiStepper-root {
      height: 100%;
      padding: 12px;
    }

    margin: 50px auto 0 auto;
  }

  @media (min-width: 1600px) {
    margin-top: 200px;
  }
`;

export default function CustomStepper(props) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [lastFinishedStep, setlastFinishedStep] = useState(0);
  const [snackbar, showSnackbar] = useState(false);
  const valid = useSelector((state) => state.steps.valid);

  const steps = props.stepsLabel;
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setlastFinishedStep(activeStep + 1);
  };
  const handleBack = () => {
    if(activeStep === 0) {
      history.goBack();
    }
    else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
   
  const SwitchStep = () => {
    let stepToReturn;
    props.stepsComponent.forEach((step, index) => {
      if (activeStep === index) {
        return (stepToReturn = step);
      }
    });
    return stepToReturn;
  };

  const onStepClick = (label) => {
    //change step after step number click
    let redirectToStep;
    switch (label) {
      case 'Persönliche Daten':
        redirectToStep = 0;
        break;
      case 'Berufserfahrung':
        redirectToStep = 1;
        break;
      case 'Schulische Ausbildung':
        redirectToStep = 2;
        break;
      case 'Zusatzqualifikationen & Kenntnisse':
        redirectToStep = 3;
        break;
      case 'Fast geschafft!':
        redirectToStep = 4;
        break;
      default:
        redirectToStep = 0;
    }
    //prevent user to skip steps
    if (redirectToStep <= lastFinishedStep) {
      setActiveStep(redirectToStep);
    }
  };

  return (
    <div className="maxWidth">
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="success"
      >
        Your data have been successfully saved!
      </Snackbar>
      <div className="create-profile">
        <div className="create-profile-container">
          <img src={Logo} className="logo" />
          <Container>
            {/* <button
              onClick={handleBack}
              style={{ visibility: activeStep === 0 ? 'hidden' : 'initial' }}
              className="create-profile-button--back"
            >
              <BackIcon /> Zurück
            </button> */}
            <LeftSide>
              <Stepper
                className="stepper"
                orientation="horizontal"
                activeStep={activeStep}
                connector={<></>}
                style={{pointerEvents: 'none'}}
              >
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    onClick={() => {
                      onStepClick(label);
                    }}
                    classes={{
                      root:
                        index <= lastFinishedStep ? classes.stepContainer : ''
                    }}
                  >
                    <StepLabel
                      classes={{
                        iconContainer: classes.icon,
                        labelContainer: classes.labelContainer,
                        disabled: classes.disabledLabel
                      }}
                    >
                      {isMobile ? null : label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </LeftSide>
            <SwitchStep />
            {activeStep === steps.length ? null : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '75%',
                  margin: '0 auto',
                  padding: '40px'
                }}
              >
                <SubmitButton
                  className="create-profile-back"
                  onClick={() => handleBack()}
                >
                  {'Zurück'}
                </SubmitButton>
                <SubmitButton
                  variant="contained"
                  color="primary"
                  className="create-profile-next"
                  valid={valid}
                  onClick={() => handleNext()}
                >
                  {activeStep === steps.length - 1 ? 'Speichern' : 'WEITER'}
                </SubmitButton>
              </div>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

CustomStepper.propTypes = {
  stepsLabel: PropTypes.array.isRequired,
  stepsComponent: PropTypes.array.isRequired
};

//add new styles here
export const useStyles = makeStyles((theme) => ({
  stepBtn: {
    textTransform: 'none',
    width: 111,
    borderRadius: 8
  },
  stepFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1)
  },
  stepContainer: {
    cursor: 'pointer'
  },
  stepLabel: {
    fontSize: 31.27,
    color: 'black',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  disabledLabel: {
    cursor: 'inherit !important'
  },
  labelContainer: {
    fontWeight: 600,
    color: 'black',
    marginLeft: theme.spacing(3),
    fontSize: 20
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  icon: {
    'fontSize': 25,
    '& svg': {
      'zIndex': 2,
      'color': '#e8e8e8',
      '& text': {
        display: 'none'
      }
    }
  }
}));
