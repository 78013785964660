import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Paragraph } from '../StepSixSubcomponents/Region';

export default function RadioButtons(props) {
  const [value, setValue] = useState(props.value || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    props.naPromjenu(props.tip, event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="gender"
        name={props.name}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="1"
          control={<Radio />}
          label={<Paragraph>JA</Paragraph>}
          onChange={props.setTrue}
        />
        <FormControlLabel
          value="0"
          control={<Radio />}
          label={<Paragraph>NEIN</Paragraph>}
          onChange={props.setFalse}
        />
      </RadioGroup>
    </FormControl>
  );
}
