import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import SearchBar from '../components/SearchBar/SearchBar';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import GetDataPublic from '../api/GetDataPublic';
import '../assets/scss/partnerDetails.scss';
import '../assets/scss/partner.scss';
import { useEffect } from 'react';
import CompanyMap from '../components/CompanyProfile/CompanyMap';
import { ReactComponent as IconArrow } from '../assets/images/icon-arrow-dropdown.svg';
import PartnerJobCard from '../components/PartnerJobCard/PartnerJobCard';
import { Pagination } from '@mui/material';
import CircularLoader from '../components/Loader/CircularLoader';
import Partner1 from '../assets/images/Partner1.jpg';
import Partner22 from '../assets/images/Partner22.jpeg';
import Partner29 from '../assets/images/Marktplatz.jpg';
import Partner22mobile from '../assets/images/Partner22Mobile.jpeg';
import { Button } from '@mui/material';
import axios from '../api/axios';

import styles from '../components/Jobs/Jobs.module.scss';

import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../components/responsive';


import config from '../config/api.config';
import { useLocation } from 'react-router-dom';
const PartnerDetail = () => {
  const location = useLocation();
  const params = useParams();
  const ref = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [jobAds, setjobAds] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setloading] = useState(true);
  const [btnAlleJob, setBtnAlleJob] = useState(false);
  const [btnStudien, setBtnStudien] = useState(false);
  const [btnAusbildung, setBtnAusbildung] = useState(false);
  const [title, setTitle] = useState(location?.state?.title || '');
  const [company, setCompany] = useState(location?.state?.company || '');
 
  const [branchId, setbranchId] = useState(location?.state?.branch ?? '');
  const [employmentId, setemploymentId] = useState(
    location?.state?.employment ?? ''
  );
  const getCompanies = async () => {
    const companies = await GetDataPublic(
      `User/get-companies-by-partner/${params.name.split('-').pop()}`
    );
    setCompanies(companies);
    let tempLocation = [];
    await companies.forEach(async (element) => {
      await GetDataPublic(`Location/${element.LocationId}`).then((location) => {
        tempLocation = [...tempLocation, location];
        setLocations(tempLocation);
      });
    });
  };

  const [baseJobQuery] = useState(
    `/jobadvertisement/partner-jobs/paging?&PageSize=9&CheckIsActive=true&IncludePhoto=true&IncludeOffice=true&IncludeStartingDate=true&IncludeBranch=true&Random=false`
  );

  const searchJobs = async (title,company, employment, branch ) => {
    setTitle(title);
    setCompany(company);
    setemploymentId(employment);
    setbranchId(branch);
    
  };

  useEffect(() => {
    setloading(true);
   
    getJobAds();
  }, [ title, branchId, employmentId, company]);


  const getJobAds = async (
    employmentIdArray = [],
    includeAllJobs = true
  ) => {
    const niz = [];
    setCompany(company);

    if (employmentId != '') {
      niz.push(employmentId);
    }

    setloading(true);
    await axios
      .post(
        `${config.apiUrl}${baseJobQuery}`,
        employmentId ? niz : employmentIdArray,
        {
          params: includeAllJobs ? {
            PartnerId: params.name.split('-').pop(),
           //PageNumber: currentPage,
            VolunteeringOnly: false,
            JobTitle: title,
            CompanyName: companies.find((value) => value.Id == company)?.Name,
            BranchId: branchId
            //EmploymentId: employmentId
          } : {
            PartnerId: params.name.split('-').pop(),
            PageNumber: currentPage,
            VolunteeringOnly: false,
            JobTitle: title,
            CompanyName: companies.find((value) => value.Id == company)?.Name,
            BranchId: branchId
            //EmploymentId: employmentId
          }
        }
      )
      .then((res) => {
        setloading(false);
        setjobAds(res.data.list);
        setTotalPages(res.data.TotalPages);

        //setcurrentPage(res.CurrentPage);
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
    setloading(false);
  };

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newSmall });

  useEffect(() => {
    getCompanies();
    getJobAds([], false);
  }, [currentPage]);

  return (
    <>
    <div className='maxWidth'>
      <ComponentWrapper displayFlex flexColumn noPadding minHeight="130vh">
        <Header />

        {loading ? (
          <CircularLoader small />
        ) : (
          
          <div className="partner partner-details">
            <div className="companies-map">
              <div className="map-title">
                <p className="first-p-p">Partner & Kooperation</p>
                <p className="second-p-p">{params?.name.split('-')[0]}</p>
              </div>

              {params.name.split('-').pop() == 1 ||
              params.name.split('-').pop() == 25 || params.name.split('-').pop() == 29? (
                <div className="partner1Wrap">
                  {isMobile ? (
                    <img
                      className="partner1Img"
                      src={
                        params.name.split('-').pop() == 1 ? Partner1 : 
                        params.name.split('-').pop() == 25 ?
                        Partner22mobile : Partner29
                      }
                    ></img>
                  ) : (
                    <img
                      className="partner1Img"
                      src={
                        params.name.split('-').pop() == 1 ? Partner1 :  params.name.split('-').pop() == 25 ?
                        Partner22 : Partner29
                      }
                    ></img>
                  )}
                  <div className="partner1Buttons">
                    <Button
                      variant="contained"
                      color="primary"
                      className="partner1Button"
                      onClick={() => {
                        setBtnStudien(false);
                        setBtnAusbildung(false);
                        getJobAds([]);
                        ref.current?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      ALLE JOBS
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="partner1Button"
                      onClick={() => {
                        setBtnStudien(true);
                        getJobAds([10, 12, 13]);
                        ref.current?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      STUDIENPLÄTZE
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="partner1Button"
                      onClick={() => {
                        setBtnStudien(false);
                        setBtnAusbildung(true);
                        getJobAds([7]);
                        ref.current?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      AUSBILDUNGSPLÄTZE
                    </Button>
                  </div>
                </div>
              ) : (
                <CompanyMap location={locations} _zoom={15} />
              )}
            </div>

            <button
              onClick={() =>
                ref.current?.scrollIntoView({ behavior: 'smooth' })
              }
              className="partner-job-preview"
            >
              Alle Jobs im {params?.name.split('-')[0]}
              <IconArrow />
            </button>

            <div className="partner-content">
              <p className="second-p">suchen & finden!</p>
              <SearchBar
                job={true}
                company = {company}
                partnerName = {params?.name.split('-')[0]}
                allCompanies={companies}
                getJobs={searchJobs}
                title={title}
                employmentId={employmentId}
                branchId={branchId}
                btnAlleJob={btnAlleJob}
                btnStudien={btnStudien}
                btnAusbildung={btnAusbildung}
              />

              <div style= {{marginTop: '20px', marginBottom: '40px'}} ref={ref}>
          
                  <div className={styles.jobsList}>
                    {jobAds.map((job) => (
                      <PartnerJobCard key={job.JobAdvertisementId} job={job} />
                    ))}
                  </div>
       
              </div>

              {totalPages > 1 && (
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={onPageChange}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              )}
            </div>
          </div>
        
        )}
      </ComponentWrapper>
      <Footer />
      </div>
    </>
  );
};

export default PartnerDetail;
