import PostData from '../api/PostData';
import auth from '../auth';
import UploadBranch from './uploadBranch';
import axios from 'axios';
import config from '../config/api.config';

export async function UploadCompanyProfile(
  profileData,
  links,
  addLocation
) {
  const UserId = auth.getId();

  if (links) {
    const reqData = {
      organisationId: Number(UserId),
      facebookUrl: links.facebook,
      instagramUrl: links.instagram,
      linkedinUrl: links.linkedin,
      twitterUrl: links.twitter,
      youtubeUrl: links.youtube
    };
    if (links.request) {
      await PostData(`organisationsocialnetwork/${UserId}`, reqData, 'PUT');
    } else {
      await PostData('organisationsocialnetwork', reqData);
    }
  }

  let locationData = {
    streetNumber: profileData.streetNumber || null,
    streetName: profileData.streetName || null,
    city: profileData.location || null,
    country: profileData.country || null,
    postalCode: profileData.zipcode || null,
    latitude: profileData.latitude || null,
    longitude: profileData.longitude || null,
    formattedAddress: profileData.address || null
  };
  var location;

  if (addLocation) {
    const branchData = { ...locationData, poslovnica: profileData.location };
    await UploadBranch(branchData);
  }

  if (profileData.locationId) {
    location = await PostData(
      `Location/${profileData.locationId}`,
      locationData,
      'PUT'
    );
  } else {
    location = await PostData('Location', locationData);
  }

  const userData = {
    id: Number(UserId),
    name: profileData.companyName,
    locationPostcode: profileData.zipcode,
    locationCity: profileData.location,
    contactEmail: profileData.email,
    phone: profileData.telefon,
    website: profileData.website,
    aboutMe: profileData.companyText,
    locationAddress: profileData.address,
    locationId:location.LocationId,
    verifiedAccount: true

  }

  await PostData(`user/company-put/${UserId}`, userData, 'PUT');
}
export default UploadCompanyProfile;
