import React, { useEffect, useState, useRef } from 'react';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import styled from 'styled-components';
import SearchBar from '../components/Beitrage/SearchBar';
import GetDataPublic from '../api/GetDataPublic';
import BlogCard from '../components/Beitrage/BlogCard';
import { Pagination } from '@mui/material';
import { EmptyBox } from './CompanyDashboard/Applications';
import EmptyList from '../assets/images/empty-list.svg';
import { useHistory } from 'react-router-dom';
import auth from '../auth';
import CircularLoader from '../components/Loader/CircularLoader';
import '../assets/scss/blogs.scss'
import { useMediaQuery } from 'react-responsive';
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.padding &&
    `
  padding: ${props.padding};
  `}
`;

const CardsContainer = styled.div`
  margin: 10px auto;
  margin-top:80px;
  padding: 20px;
  display: grid;
  gap: 60px 20px;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  width: 100%;
  margin-bottom:50px;
  @media (max-width: 650px) {
    grid-template-columns: none;
  }
`;

export default function Beiträge({ companyProfile, userId }) {
  const [blogs, setblogs] = useState();
  const [searchText, setsearchText] = useState('');
  //loading state used for searchBar icon (when user search for certain query)
  const [loading, setisLoading] = useState(false);

  //loading state used for fetching Blogs (when blogs are loading at first render)
  const [loadingBlogs, setloadingBlogs] = useState(true);

  const [currentPage, setcurrentPage] = useState(1);
  const [order, setorder] = useState('descdate');

  const isMobileForCompany = useMediaQuery({ maxWidth: 500 });

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `spiderjob - Beiträge`;
  });
  const blogsRef = useRef(null);
  const history = useHistory();

  const getBlogs = async () => {
    setloadingBlogs(true);
    const response = await GetDataPublic(
      `Blogs/Paging?&PageSize=6&IncludePicture=true` +
        (companyProfile ? `&UserId=${userId}` : ''),
      {
        PageNumber: currentPage,
        Title: searchText,
        OrderBy: order
      }
    );
    blogsRef.current = response;

    setcurrentPage(response.CurrentPage);
    setblogs(response);
    setloadingBlogs(false);
  };

  useEffect(() => {
    getBlogs();
  }, [currentPage, searchText, order]);

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  const sortBlogs = (e) => {
    switch (e) {
      case 'a-z':
        setorder('asc');
        break;
      case 'z-a':
        setorder('desc');
        break;
      case 'oldest':
        setorder('ascdate');
        break;
      default:
        setorder('descdate');
        break;
    }
  };

  return (
    <div className='blogs'>
        {!companyProfile && <Header />}
      <MainContainer
       style={companyProfile && isMobileForCompany ? {marginTop: 100}: {}}
        className="main-container"
        padding={companyProfile ? '60px 15px 40px' : '0px 15px 0'}
      >
        {blogs?.list.length > 0 && (
          <SearchBar  style={companyProfile && isMobileForCompany ? {marginTop: '50px'}: {}}
            searchText={searchText}
            onChange={(e) => {
              setisLoading(true);
              setTimeout(() => {
                setsearchText(e);
                setisLoading(false);
              }, 300);
            }}
            loading={loading}
            onSortChange={sortBlogs}
          />
        )}
        {loadingBlogs ? (
          <CircularLoader small />
        ) : (
          <>
            {blogs?.list.length > 0 ? (
              <>
                <CardsContainer>
                  {blogs.list.map((blog, index) => (
                    <BlogCard
                      key={index}
                      value={{
                        text: blog.BlogText,
                        title: blog.Title,
                        src: blog.File.Url,
                        id: blog.BlogsId,
                        userId: blog.UserId,
                        file: blog.File,
                        closeModal: () => {
                          //getCompanyBlogs();
                        }
                      }}
                      isOwner={
                        auth.isAuthenticated()
                          ? auth.getId() == blog.UserId
                          : false
                      }
                      blog={blog}
                      onClick={() => {
                        history.push({
                          pathname: `/blogeintrag/${encodeURIComponent(blog.Title.replace("+", ""))}-${blog.BlogsId}`,
                          state: {
                            blog: blog
                          }
                        });
                      }}
                      onDelete={() => {
                        history.push({
                          pathname: `/arbeitgeber-dashboard/blogeinträge`,
                          state: {
                            blog: blog,
                            delete: true
                          }
                        });
                      }}
                      oneThirdWidth
                    />
                  ))}
                </CardsContainer>
              </>
            ) : (
              <EmptyBox
              style={companyProfile && isMobileForCompany ? {marginBottom: 20, marginTop: 100, width: '100%'}: {marginBottom: 20, marginTop: 20, width: '100%'}}
            
              >
                <h4>
                  {blogs && blogs.list !== blogsRef.current.list
                    ? 'Keine Ergebnisse verfügbar'
                    : companyProfile
                    ? 'Es gibt noch keine Beiträge'
                    : 'Du hast noch keine Blogs'}
                </h4>
                <div className="mb-5">
                  <img src={EmptyList} alt="Empty"></img>
                </div>
              </EmptyBox>
            )}

            {blogs && blogs.TotalPages > 1 && (
              <Pagination
                count={blogs && blogs.TotalPages}
                page={currentPage}
                onChange={onPageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            )}
          </>
        )}
      </MainContainer>
      {!companyProfile && <Footer />}
    </div>
  );
}
