/* Libraries */
import axios from 'axios';
import config from '../config/api.config';

const GetDataPublic = async (url, params = {}) =>
  new Promise(async (resolve, reject) => {
    try {
      let urlNew = config.apiUrl +"/"+ url;
      const result = await axios.get(urlNew, {
        params: params
      })
      // const result = await axios({
      //   method: 'GET',
      //   urlNew,
      //   params
      // });

      resolve(result.data);
    } catch (error) {
      console.log(`Error: ${error}`);

      reject(error);
    }
  });

export default GetDataPublic;
