import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import DashboardLayout from '../CompanyDashboard/DashboardLayout';
import { DashboardTitle } from '../CompanyDashboard/Home';
import { Input, Row } from '../../components/CreateProfile/Steps';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import styled from 'styled-components';
import SubmitButton from '../../components/Buttons/submitButton';
import SubSidebar from '../../components/Dashboard/SubSidebar';

import Snackbar from '../../components/snackbar/snackbar';
import config from '../../config/api.config';
import auth from '../../auth';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import DeleteModal from '../../components/modal/DeleteModal';
import Switch from '@mui/material/Switch';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const Links = styled.a`
  font-size: 14px;
  color: #717171;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #e83741;
  }
`;

const WrapperFlex = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Notifications = styled.p`
  width: auto;
  font-size: 15px;
  margin-bottom: 0;
  overflow-wrap: break-word;
  margin-top: 6px;
  font-size: 16px;
  color: #929292;
`;

const InputEye = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  position: relative;
  outline: none;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #d6d6d6;
  padding-left: 20px;
  padding-right: 20px;
  color: #7c7c7c;
`;

const Account = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [accountData, setaccountData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    newEmail: ''
  });
  const [deleteModal, openDeleteModal] = useState(false);
  const [deactivateModal, openDeactivateModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [seeOldPass, setSeeOldPass] = useState(false);
  const [seeNewPass, setSeeNewPass] = useState(false);
  const [seeConfirmPass, setSeeConfirmPass] = useState(false);
  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const handleInputChange = (e) => {
    setaccountData({ ...accountData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${config.apiUrl}/Authentication/change-email/${auth.getId()}`, {
        newEmail: accountData.newEmail
      })
      .then((res) => {
        setLoading(false);
        setSeverity('success');
        showSnackbar(true);
        setSnackbarMessage(
          'Sie haben die E-Mail-Adresse erfolgreich geändert. Bitte überprüfen Sie Ihre E-Mail zur Bestätigung.'
        );
      })
      .catch((error) => {
        setLoading(false);
        setSeverity('error');
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );
      });
  };

  const changePassword = async () => {
    setLoadingPassword(true);
    await axios
      .post(
        `${
          config.apiUrl
        }/Authentication/change-password-from-settings/${auth.getId()}`,
        {
          currentPassword: accountData.oldPassword,
          newPassword: accountData.newPassword,
          confirmPassword: accountData.confirmPassword
        }
      )
      .then((res) => {
        setLoadingPassword(false);
        setSeverity('success');
        showSnackbar(true);
        setSnackbarMessage('Sie haben das Passwort erfolgreich geändert.');
        setaccountData({
          ...accountData,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      })
      .catch((error) => {
        setLoadingPassword(false);
        setSeverity('error');
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );

        // console.log('Catch blok response', error.response);
      });
  };

  const GetUser = async () => {
    await axios
      .get(`${config.apiUrl}/User/${auth.getId()}`)
      .then((res) =>
        setaccountData({ ...accountData, newEmail: res.data.Email })
      )
      .catch((err) => console.error(err));
  };

  const GetNotification = async () => {
    await axios
      .get(`${config.apiUrl}/api/emailnotification/${auth.getId()}`)
      .then((res) => {
        setChecked(res.data.IsActive);
      })
      .catch((err) => console.error(err));
  };

  const DeactivateProfile = async () => {
    //  e.preventDefault();
    await axios
      .post(
        `${config.apiUrl}/authentication/deactivate-account?id=${auth.getId()}`
      )
      .then((res) => {
        setSeverity('success');
        showSnackbar(true);
        setSnackbarMessage('Sie haben Ihr Konto erfolgreich deaktiviert.');
        setTimeout(() => auth.logout((_) => history.push('/')), 2000);
      })
      .catch((err) => console.error(err));
  };

  const DeleteProfile = async () => {
    await axios
      .delete(`${config.apiUrl}/user/applicant/${auth.getId()}`)
      .then((res) => {
        setSeverity('success');
        showSnackbar(true);
        setSnackbarMessage('Sie haben Ihr Konto erfolgreich gelöscht.');
        setTimeout(() => auth.logout((_) => history.push('/')), 2000);
      })
      .catch((err) => console.error(err));
  };
  const handleChange = async () => {
    setChecked(!checked);
    await axios
      .put(`${config.apiUrl}/api/emailnotification/${auth.getId()}`, {
        userId: auth.getId(),
        isActive: !checked
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    GetUser();
    GetNotification();
  }, []);

  const content = (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity={severity}
      >
        {snackbarMessage}
      </Snackbar>
      <ComponentWrapper
        fluid
        noPaddingTop
        noPaddingLeft
        noPaddingHorizontal={isMedium}
      >
        <div className="mb-3">
          <DashboardTitle red> Passwort ändern</DashboardTitle>
        </div>
        <ComponentWrapper noPaddingHorizontal>
          <Paragraph grey small>
            Aktuelles Passwort
          </Paragraph>

          <InputEye>
            <input
              name="oldPassword"
              id="oldPassword"
              type={seeOldPass ? 'text' : 'password'}
              required
              value={accountData.oldPassword}
              noFill
              onChange={handleInputChange}
              style={{ border: 'none', outline: 'none', width: '100%' }}
            />
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() => setSeeOldPass(!seeOldPass)}
            >
              {seeOldPass ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
          </InputEye>
        </ComponentWrapper>
        <ComponentWrapper noPaddingHorizontal noPaddingTop>
          <Paragraph grey small>
            Neues Passwort
          </Paragraph>
          <InputEye>
            <input
              name="newPassword"
              id="newPassword"
              type={seeNewPass ? 'text' : 'password'}
              required
              value={accountData.newPassword}
              noFill
              onChange={handleInputChange}
              style={{ border: 'none', outline: 'none', width: '100%' }}
            />

            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() => setSeeNewPass(!seeNewPass)}
            >
              {seeNewPass ? (
                <EyeOutlined></EyeOutlined>
              ) : (
                <EyeInvisibleOutlined></EyeInvisibleOutlined>
              )}
            </div>
          </InputEye>
        </ComponentWrapper>
        <ComponentWrapper noPaddingHorizontal noPaddingTop>
          <Paragraph grey small>
            Passwort bestätigen
          </Paragraph>
          <InputEye>
          <input
            name="confirmPassword"
            id="confirmPassword"
            type={seeConfirmPass ? 'text' : 'password'}
            required
            value={accountData.confirmPassword}
            noFill
            onChange={handleInputChange}
            style={{ border: 'none', outline: 'none', width: '100%' }}
          />

          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => setSeeConfirmPass(!seeConfirmPass)}
          >
            {seeConfirmPass ? (
              <EyeOutlined></EyeOutlined>
            ) : (
              <EyeInvisibleOutlined></EyeInvisibleOutlined>
            )}
          </div>
          </InputEye>
        </ComponentWrapper>
        <SubmitButton
          valid={
            accountData.newPassword &&
            accountData.oldPassword &&
            accountData.confirmPassword
          }
          onClick={changePassword}
          buttonClicked={loadingPassword}
        >
          Passwort ändern
        </SubmitButton>
        <ComponentWrapper noPaddingHorizontal>
          <Row noJustify>
            <Links onClick={(e) => openDeactivateModal(true)}>
              Account deaktivieren
            </Links>
            <Links onClick={(_) => openDeleteModal(true)}>
              Account löschen
            </Links>
          </Row>
        </ComponentWrapper>
      </ComponentWrapper>

      <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isMedium}>
        <div className="mb-3">
          <DashboardTitle red> E-Mail ändern</DashboardTitle>
        </div>

        <ComponentWrapper noPaddingHorizontal>
          <Paragraph grey small>
            Neue E-Mail
          </Paragraph>
          <Input
            type="text"
            name="newEmail"
            id="newEmail"
            required
            value={accountData.newEmail}
            noFill
            onChange={handleInputChange}
          />
        </ComponentWrapper>
        <ComponentWrapper noPaddingHorizontal>
          <Paragraph grey small>
            Nachdem Du eine neue E-Mail angegeben hast, erhältst Du eine Mail,
            in der Du deine neue E-Mail bestätigen musst. Im Nachgang musst du
            dich erneut anmelden.
          </Paragraph>
        </ComponentWrapper>
        <SubmitButton
          buttonClicked={loading}
          onClick={onSubmit}
          valid={accountData.newEmail}
        >
          E-Mail ändern
        </SubmitButton>
        <WrapperFlex>
          <Notifications>E-Mail Benachrichtigungen</Notifications>

          <Switch checked={checked} onChange={handleChange} />
        </WrapperFlex>
      </ComponentWrapper>
      <DeleteModal
        open={deleteModal}
        onClose={() => openDeleteModal(false)}
        onDelete={() => DeleteProfile()}
      />

      <DeleteModal
        open={deactivateModal}
        onClose={() => openDeactivateModal(false)}
        onDelete={() => DeactivateProfile()}
        text="Sind Sie sicher, dass Sie das Konto deaktivieren möchten?"
      />
    </>
  );

  return (
    <>
      {!auth.isApplicant() ? (
        <DashboardLayout noPadding sidebar>
          <ComponentWrapper displayFlex noPadding flexColumn={isMedium}>
            <SubSidebar
              title={'Ihr Profil'}
              list={[
                { Name: 'Basisdaten', Link: 'profil' },
                { Name: 'Logo & Header-Grafik', Link: 'logo' },
                { Name: 'Galerie', Link: 'galerie' },
                { Name: 'Passwort und Konto', Link: 'konto' }
              ]}
              storage={'konto'}
            />

            <ComponentWrapper
              fluid
              padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
              displayFlex
              flexWrap={isMedium}
            >
              {content}
            </ComponentWrapper>
          </ComponentWrapper>
        </DashboardLayout>
      ) : (
        <DashboardLayout noPadding>
          <ComponentWrapper
            fluid
            padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
            displayFlex
            flexWrap={isMedium}
          >
            {content}
          </ComponentWrapper>
        </DashboardLayout>
      )}
    </>
  );
};

Account.propTypes = {};

export default Account;
