import styles from './SocialShare.module.scss';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon } from 'react-share';

const SocialShare = ({ style }) => {
  return (
    <div className={styles.socialShare} style={style}>
      <span>Teile via</span>
      <div className={`${styles.socialButtons} social-buttons`}>
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton url={window.location.href}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
