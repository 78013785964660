import React, { useEffect, useRef, useState } from 'react';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../CreateProfile/Steps/StepSixSubcomponents/Region';
import { Input } from '../CreateProfile/Steps';
import TextEditor from '../Dashboard/TextEditor';
import GetData from '../../api/GetData';
import { FormControl, MenuItem, Select } from '@mui/material';
import upload from '../../assets/images/upload.svg';
import ImageWithDelete from '../Dashboard/ImageWithDelete';
import { UploadJobAd } from '../../utils/uploadJobAd';
import SubmitButton from '../Buttons/submitButton';
import auth from '../../auth';
import ModalOuter from './ModalOuter';
import { LocationModal } from './locationModal';
import Gallery from 'react-photo-gallery';
import { ContactPersonModal } from './contactPersonModal';
import { ErrorText } from '../Common/Input';
import SelectAsync from '../select/selectAsync';
import ErrorMessageModal from './ErrorMessageModal';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import Slider from '../slider/slider';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioButtons from '../CreateProfile/Steps/StepOneSubcomponents/StepOne';
import { Button } from '@mui/material';

export const JobAdModal = (props) => {
  const [jobValues, setjobValues] = useState({
    jobId: props.edit.JobAdvertisementId,
    heading:
      props.edit && !auth.isOrganization()
        ? {
            label: props.edit.Profession.ProfesionName,
            value: props.edit.Profession.ProfessionId
          }
        : props.edit
        ? props.edit.ProfessionText
        : '',
    employmentType:
      (props.edit.Employments?.length &&
        props.edit.Employments.map((e) => e.EmploymentId)) ||
      props.edit.EmploymentText ||
      (auth.isOrganization() ? 'Ehrenamt' : []),
    startEmployment: props.edit.StartingDateId || '',
    branch:
      (props.edit &&
        [
          props.edit.Office?.OfficeId,
          props.edit.Office2?.OfficeId,
          props.edit.Office3?.OfficeId
        ].filter((b) => b !== null && b !== undefined)) ||
      [],
    branchData:
      [props?.edit.Office, props?.edit.Office2, props?.edit.Office3].filter(
        (b) => b !== null && b !== undefined
      ) || [],
    placeZIP: '',
    city: '',
    country: '',
    contactId: props.edit.ContactId || '',
    industry: props.edit.BranchId || '',
    introduction: props.edit.IntroductionText || '',
    yourTasks: props.edit.Tasks || '',
    yourProfile: props.edit.Profil || '',
    weOffer: props.edit.Offer || '',
    closingText: props.edit.ClosingText || '',
    matching: props.edit.MatchingId || '',
    jobAdSpecification: props.edit.JobAdSpecification || '',
    applicantAddressing: props.edit.ApplicantAddressing ?? 'Ihre',
    minAnnualSalary: props.edit.MinAnnualSalary ?? 50000,
    maxAnnualSalary: props.edit.MaxAnnualSalary ?? 150000,
    shouldRedirectExternal: props.edit ? (props.edit.ShouldRedirectExternal ? 'careerLink' : 'spiderjob') : 'spiderjob',
    externalCareerLink: props.edit.RedirectExternalUrl ?? ''
  })
  const [errors, seterrors] = useState({
    heading: '',
    employmentType: '',
    startEmployment: '',
    contactPerson: '',
    branch: '',
    industry: '',
    jobAdSpecification: ''
  });
  const [employment, setemployment] = useState();
  const [industry, setindustry] = useState();
  const [files, setfiles] = useState(
    (props.edit && {
      url: props.edit.Photo.Url,
      FileId: props.edit.Photo.FileId
    }) ||
      null
  );
  const [isLoading, setisLoading] = useState(false);
  const [locations, setlocations] = useState();
  const [allContactPersons, setallContactPersons] = useState(null);
  const [contactPersons, setcontactPersons] = useState(null);
  const [startingDate, setstartingDate] = useState();
  const [branchModal, setbranchModal] = useState(false);
  const [contactModal, setcontactModal] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [galleryModal, setgalleryModal] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [salary, setSalary] = useState([50000, 150000]);

  const [errorMessageModal, seterrorMessageModal] = useState(false);
  const [errMess, seterrMess] = useState('');

  var fileRef = useRef();

  const UserId = auth.getId();

  async function getLocations() {
    const response = await GetData(
      `UsersBranches?IncludeOffice=true&IncludeContactPerson=true&UserId=${UserId}`
    );
    setallContactPersons(response.filter((x) => x.ContactPerson !== null));

    const uniqueRes = [
      ...new Map(response.map((item) => [item['OfficeId'], item])).values()
    ];
    let branchesWithLocation = [];

    await Promise.all(
      uniqueRes.map(async (element) => {
        //need to get country data in location
        const Location = await GetData(`Location/${element.Office.LocationId}`);
        branchesWithLocation.push({ ...element, Location });
      })
    );
    setlocations(branchesWithLocation);
  }
  useEffect(() => {
    async function getGallery() {
      const response = await GetData(`File?UserId=${UserId}&Type=Gallery`);

      let newGallery = [];
      response.forEach((element) => {
        newGallery.push({
          FileId: element.FileId,
          filename: element.FileName,
          url: element.Url,
          width: 1,
          height: 1,
          src: element.Url
        });
      });
      setGallery(newGallery);
    }

    async function getEmployment() {
      let result = await GetData('Employment');
      setemployment(result);
    }

    async function getBranch() {
      let result = await GetData(
        `Branch${auth.isOrganization() ? '?IsJobs=false' : ''}`
      );
      setindustry(result);
    }
    async function getStartingDate() {
      let result = await GetData('StartingDate');
      setstartingDate(result);
    }

    getEmployment();
    getStartingDate();
    getLocations();
    getBranch();
    getGallery();

    return () => {};
  }, []);

  useEffect(() => {
    if (jobValues.branch && allContactPersons) {
      setcontactPersons(
        allContactPersons.filter(
          (x) =>
            jobValues.branch.includes(x.OfficeId) && x.ContactPerson !== null
        )
      );
    }
  }, [jobValues.branch, allContactPersons]);

  const handleInputChange = (name, value) => {
    setjobValues({ ...jobValues, [name]: value });
  };

  const handleInputChangeLink = (event) => {
    setjobValues({ ...jobValues, ['externalCareerLink']: event.target.value });
  };

  const onFileChange = (e) => {
    //remove old image/object from browser memory
    if (files?.url) {
      URL.revokeObjectURL(files.url);
    }
    if (e.target.files.length > 0) {
      setfiles({
        file: e.target.files[0],
        name: e.target?.files[0].name,
        url: URL.createObjectURL(e.target.files[0])
      });
    }
  };
  const uploadFile = () => {
    fileRef.current.click();
  };

  const submitJobAd = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      let jobId = (props.edit && props.edit.JobAdvertisementId) || null;
      await UploadJobAd(jobValues, files, jobId);
      setisLoading(false);
      props.closeModal();
    } catch (error) {
      // crazy logic to check wheater error is bc company did not subscribed
      // definitely should not be implemented like this
      console.log(error);
      if (
        error.response?.status === 400 &&
        error.response?.data?.ERROR[0] &&
        (error.response?.data?.ERROR[0] ===
          'Sie müssen ein aktives Abonnement haben, um neue Stellenanzeigen zu veröffentlichen!' ||
          error.response.data.ERROR[0].includes('Das Limit von '))
      ) {
        seterrMess(error.response.data.ERROR[0]);
        seterrorMessageModal(true);
      } else {
        console.log('🚀 ~ error', error);
      }

      setisLoading(false);
    }
  };

  //Validation of the modal
  useEffect(() => {
    let newErrors = { ...errors };
    if (
      jobValues.heading &&
      jobValues.startEmployment &&
      jobValues.employmentType.length &&
      jobValues.branch.length &&
      jobValues.industry &&
      ((jobValues.shouldRedirectExternal === 'careerLink' &&
        jobValues.externalCareerLink != '') ||
        jobValues.shouldRedirectExternal === 'spiderjob')
    )
      setdisabled(false);
    else setdisabled(true);

    seterrors(newErrors);
  }, [
    jobValues.heading,
    jobValues.employmentType,
    jobValues.startEmployment,
    jobValues.branch,
    jobValues.industry,
    jobValues.shouldRedirectExternal,
    jobValues.externalCareerLink
  ]);

  useEffect(() => {
    props.setEditData({
      JobAdvertisementId: jobValues?.jobId,
      Branch: industry?.find((i) => i.BranchId == jobValues.industry),
      ClosingText: jobValues.closingText,
      ContactId: jobValues.contactId,
      Employments: !auth.isOrganization()
        ? jobValues?.employmentType
        : { EmploymentText: jobValues.employmentType },
      IntroductionText: jobValues.introduction,
      JobAdSpecification: jobValues.jobAdSpecification,
      JobLocation: {
        City: jobValues?.branchData[0]?.Location?.City,
        FormattedAddress: jobValues?.branchData[0]?.Location?.FormattedAddress,
        StreetName: jobValues?.branchData[0]?.Location?.StreetName
      },
      JobLocation2: {
        City: jobValues?.branchData[1]?.Location?.City,
        FormattedAddress: jobValues?.branchData[1]?.Location?.FormattedAddress,
        StreetName: jobValues?.branchData[1]?.Location?.StreetName
      },
      JobLocation3: {
        City: jobValues?.branchData[2]?.Location?.City,
        FormattedAddress: jobValues?.branchData[2]?.Location?.FormattedAddress,
        StreetName: jobValues?.branchData[2]?.Location?.StreetName
      },
      Offer: jobValues.weOffer,
      Office: jobValues.branchData,
      Photo: { FileId: files?.FileId, Url: files?.url },
      Profession: !auth.isOrganization()
        ? {
            ProfessionId: jobValues.heading?.value,
            ProfesionName: jobValues.heading?.label
          }
        : { ProfessionText: jobValues.heading },
      Profil: jobValues.yourProfile,
      MinAnnualSalary: jobValues.minAnnualSalary,
      MaxAnnualSalary: jobValues.maxAnnualSalary,
      StartingDate: startingDate?.find(
        (d) => d.StartingDateId == jobValues.startEmployment
      ),
      Tasks: jobValues.yourTasks,
      isActive: true,
      ApplicantAddressing: jobValues.applicantAddressing,
      ShouldRedirectExternal: jobValues.shouldRedirectExternal,
      RedirectExternalUrl: jobValues.externalCareerLink
    });
    setSalary([jobValues.minAnnualSalary, jobValues.maxAnnualSalary]);
  }, [jobValues, files, industry, employment, startingDate]);

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <>
      <ComponentWrapper noPadding displayFlex flexWrap={isMedium}>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Anzeigenüberschrift/Gesucht*
          </Paragraph>
          {auth.isOrganization() ? (
            <Input
              type="text"
              name="heading"
              id="heading"
              required
              value={jobValues.heading}
              noFill
              onChange={(e) => handleInputChange('heading', e.target.value)}
            />
          ) : (
            <FormControl fullWidth>
              <SelectAsync
                required
                option="Profession/Paging"
                value={jobValues.heading}
                onChange={(item) => {
                  handleInputChange('heading', item);
                }}
              />
            </FormControl>
          )}

          <ErrorText>{errors.heading}</ErrorText>
        </ComponentWrapper>

        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Anzeigenspezifikation
          </Paragraph>
          <Input
            type="text"
            name="jobAdSpecification"
            id="jobAdSpecification"
            value={jobValues.jobAdSpecification}
            noFill
            onChange={(e) =>
              handleInputChange('jobAdSpecification', e.target.value)
            }
          />
          <ErrorText>{errors.jobAdSpecification}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper noPadding displayFlex flexWrap={isMedium}>
        <ComponentWrapper
          halfFluid
          noPaddingTop
          noPaddingHorizontal={isExtraSmall}
        >
          <Paragraph grey small>
            Anstellungsart*
          </Paragraph>
          {auth.isOrganization() ? (
            <>
              <Input
                type="text"
                name="employmentType"
                id="employmentType"
                required
                defaultValue="Ehrenamt"
                disabled
                value={jobValues.employmentType}
                noFill
                onChange={(e) =>
                  handleInputChange('employmentType', e.target.value)
                }
              />
            </>
          ) : (
            <>
              <FormControl fullWidth>
                <Select
                  multiple
                  required
                  color="secondary"
                  value={jobValues.employmentType}
                  onChange={(e) => {
                    handleInputChange('employmentType', e.target.value);
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (s) =>
                          employment?.find((e) => e.EmploymentId === s)
                            ?.EmploymentType
                      )
                      .join(', ')
                  }
                >
                  {employment &&
                    employment.map((item, index) => (
                      <MenuItem
                        value={item.EmploymentId}
                        key={item.EmploymentId}
                      >
                        <Checkbox
                          checked={jobValues.employmentType?.includes(
                            item.EmploymentId
                          )}
                        />
                        <ListItemText primary={item.EmploymentType} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}

          <ErrorText>{errors.employmentType}</ErrorText>
        </ComponentWrapper>
        <ComponentWrapper
          halfFluid
          noPaddingTop
          noPaddingHorizontal={isExtraSmall}
        >
          <Paragraph grey small>
            Zweigstelle / Niederlassung*
          </Paragraph>
          <FormControl fullWidth>
            <Select
              multiple
              required
              color="secondary"
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected
                  .map(
                    (s) =>
                      jobValues.branchData?.find((b) => b?.OfficeId === s)
                        ?.BranchType
                  )
                  .join(', ')
              }
              value={jobValues.branch}
              onChange={(e) => {
                if (e.target.value.includes('createBranch')) {
                  setbranchModal(true);
                } else {
                  e.target.value.length <= 3 &&
                    setjobValues({
                      ...jobValues,
                      branch: e.target.value,
                      branchData: locations
                        .filter((x) => e.target.value.includes(x.OfficeId))
                        .map((x) => x.Office),
                      contactId: null
                    });
                }
              }}
            >
              <MenuItem value="createBranch" key="createBranch">
                <SubmitButton small>Erstelle neu</SubmitButton>
              </MenuItem>
              {locations &&
                locations.map((item, index) => (
                  <MenuItem value={item.OfficeId} key={item.Office.BranchType}>
                    <Checkbox
                      checked={jobValues.branch?.includes(item.OfficeId)}
                    />
                    <ListItemText primary={item.Office.BranchType} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <ErrorText>{errors.branch}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>

      <ComponentWrapper noPadding displayFlex flexWrap={isMedium}>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Branche*
          </Paragraph>
          <FormControl fullWidth>
            <Select
              required
              color="secondary"
              value={jobValues.industry}
              onChange={(e) => {
                handleInputChange('industry', e.target.value);
              }}
            >
              {industry &&
                industry.map((index) => (
                  <MenuItem value={index.BranchId} key={index.BranchType}>
                    {index.BranchType}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <ErrorText>{errors.industry}</ErrorText>
        </ComponentWrapper>
        <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
          <Paragraph grey small>
            Ansprechpartner
          </Paragraph>
          <FormControl fullWidth>
            <Select
              disabled={!jobValues.branch}
              color="secondary"
              value={jobValues.contactId}
              onChange={(e) => {
                if (e.target.value === 'createContact') {
                  setcontactModal(true);
                } else
                  setjobValues({ ...jobValues, contactId: e.target.value });
              }}
            >
              <MenuItem value="createContact" key="createContact">
                <SubmitButton small>Erstelle neu</SubmitButton>
              </MenuItem>
              {contactPersons?.map((item) => (
                <MenuItem
                  value={item.ContactPerson.ContactPersonId}
                  key={item.ContactPerson.ContactPersonId}
                >
                  {item.ContactPerson.FirstName} {item.ContactPerson.LastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ErrorText>{errors.contactPerson}</ErrorText>
        </ComponentWrapper>
      </ComponentWrapper>
      {/* <ComponentWrapper noPadding displayFlex flexWrap={isMedium}>
        <ComponentWrapper
          noPaddingTop
          displayFlex
          alignCenter
          halfFluid={!isMedium}
          fluid={isMedium}
          noPaddingHorizontal={isExtraSmall}
        >
          <ComponentWrapper padding="0 20px 0 0">
            <Paragraph grey small autoWidth>
              Neue Zweigstelle
            </Paragraph>
          </ComponentWrapper>

          <SubmitButton small onClick={() => setbranchModal(true)}>
            Erstelle neu
          </SubmitButton>
        </ComponentWrapper>
        <ComponentWrapper
          noPaddingTop
          displayFlex
          alignCenter
          halfFluid={!isMedium}
          fluid={isMedium}
          noPaddingHorizontal={isExtraSmall}
        >
          <ComponentWrapper padding="0 20px 0 0">
            <Paragraph grey small autoWidth>
              Neuer Ansprechpartner
            </Paragraph>
          </ComponentWrapper>
          <SubmitButton small onClick={() => setcontactModal(true)}>
            Erstelle neu
          </SubmitButton>
        </ComponentWrapper>
      </ComponentWrapper> */}
      <ComponentWrapper noPadding displayFlex flexWrap={isMedium}>
        <ComponentWrapper
          fluid
          noPaddingVertical
          noPaddingHorizontal={isExtraSmall}
        >
          <Paragraph grey small>
            Beschäftigungsbeginn*
          </Paragraph>

          <FormControl fullWidth>
            <Select
              required
              color="secondary"
              value={jobValues.startEmployment}
              onChange={(e) => {
                handleInputChange('startEmployment', e.target.value);
              }}
            >
              {startingDate &&
                startingDate.map((item, index) => (
                  <MenuItem value={item.StartingDateId} key={item.Type}>
                    {item.Type}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <ErrorText>{errors.startEmployment}</ErrorText>
        </ComponentWrapper>
        <ComponentWrapper
          fluid
          noPaddingVertical
          noPaddingHorizontal={isExtraSmall}
        >
          <Paragraph grey small autoWidth>
            {' '}
            Jahresbruttogehalt{' '}
          </Paragraph>
          <ComponentWrapper displayFlex alignCenter noPaddingHorizontal>
            <Slider
              onChange={(e, value) => {
                setSalary(value);
              }}
              onChangeCommitted={(e, value) => {
                setjobValues({
                  ...jobValues,
                  minAnnualSalary: value[0],
                  maxAnnualSalary: value[1]
                });
              }}
              value={salary}
              style={{ maxWidth: '350px', marginRight: '10px' }}
              step={10000}
              min={0}
              max={200000}
              valueLabelDisplay="on"
            />
            <Paragraph grey small autoWidth>
              {' '}
              €{' '}
            </Paragraph>
          </ComponentWrapper>
        </ComponentWrapper>
      </ComponentWrapper>
      <ComponentWrapper fluid noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <RadioButtons
          napromjenu={(value) => {
            handleInputChange('applicantAddressing', value);
          }}
          selectedValue={jobValues.applicantAddressing}
          style={{ margin: '0' }}
          label1="Ihre"
          label2="Deine"
          value1="Ihre"
          value2="Deine"
          name="options"
        />
      </ComponentWrapper>

      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          Einleitungstext
        </Paragraph>
        <TextEditor
          value={jobValues.introduction}
          onChange={(value) => {
            handleInputChange('introduction', value);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          {jobValues.applicantAddressing} Aufgaben
        </Paragraph>
        <TextEditor
          value={jobValues.yourTasks}
          onChange={(value) => {
            handleInputChange('yourTasks', value);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          {jobValues.applicantAddressing?.slice(0, -1)} Profil
        </Paragraph>
        <TextEditor
          value={jobValues.yourProfile}
          onChange={(value) => {
            handleInputChange('yourProfile', value);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          Wir bieten
        </Paragraph>
        <TextEditor
          value={jobValues.weOffer}
          onChange={(value) => {
            handleInputChange('weOffer', value);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph grey small>
          Schlusstext
        </Paragraph>
        <TextEditor
          value={jobValues.closingText}
          onChange={(value) => {
            handleInputChange('closingText', value);
          }}
        />
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal={isExtraSmall}>
        <Paragraph>Titelbild</Paragraph>
        <Paragraph grey small>
          Hier können Sie ein Titelbild hachladen oder aus der Liste auswählen
        </Paragraph>
        <div
          style={{
            height: '11em',
            backgroundColor: files ? 'white' : '#E5C2C2',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {files ? (
            <ImageWithDelete
              src={files.url}
              key={files.name}
              handleDelete={() => {
                URL.revokeObjectURL(files.url);
                setfiles();
              }}
            />
          ) : (
            <img
              src={upload}
              alt="upload"
              style={{ margin: 'auto', display: 'block' }}
            />
          )}
        </div>
        <ComponentWrapper noPaddingHorizontal displayFlex flexWrap justifySpace>
          <input
            type="file"
            hidden
            onChange={onFileChange}
            ref={fileRef}
            accept="image/*"
          />
          <ComponentWrapper padding={'10px 10px 10px 0'}>
            <SubmitButton small inversed={true} onClick={uploadFile}>
              Hochladen
            </SubmitButton>
          </ComponentWrapper>
          <ComponentWrapper padding={'10px 10px 10px 0'}>
            <SubmitButton
              small
              inversed={true}
              onClick={() => setgalleryModal(true)}
            >
              Aus Galerie hochladen
            </SubmitButton>
          </ComponentWrapper>
        </ComponentWrapper>
        <RadioButtons
          style={{ margin: '0' }}
          napromjenu={(value) => {
            handleInputChange('shouldRedirectExternal', value);
          }}
          label1="Verwendung von spiderjob-Matching-Software"
          label2="Nutzen von eigener Karriereseite"
          value1="spiderjob"
          value2="careerLink"
          isColumn={true}
          selectedValue={jobValues.shouldRedirectExternal}
          info = {true}
        />
        {jobValues.shouldRedirectExternal === 'careerLink' && (
          <div style={{ marginLeft: '30px' }}>
            <div className="linkText">Link zum eigenen Stellenportal</div>
            <div>
              <input
                type="text"
                className="single-line-input"
                placeholder=""
                value={jobValues.externalCareerLink}
                onChange={handleInputChangeLink}
              />
            </div>
          </div>
        )}
      </ComponentWrapper>
      <ComponentWrapper displayFlex justifyCenter alignCenter noPadding fluid>
        <SubmitButton
          onClick={submitJobAd}
          disabled={disabled}
          buttonClicked={isLoading}
        >
          {props.edit
            ? 'Änderungen speichern'
            : auth.isCompany()
            ? 'Stellenanzeige hochladen'
            : 'Ehrenamt hochladen'}
        </SubmitButton>
      </ComponentWrapper>

      <ModalOuter
        open={branchModal}
        onClose={() => {
          setbranchModal(false);
        }}
        title="Standorte"
        option="Neue hinzufügen"
        small
      >
        <LocationModal
          data={''}
          //edit={editData ? true : false}
          closeModal={() => {
            setbranchModal(false);
            getLocations();
          }}
        />
      </ModalOuter>

      <ModalOuter
        open={contactModal}
        onClose={() => {
          setcontactModal(false);
        }}
        title="Ansprechpartner"
        option="Neue hinzufügen"
        small
      >
        <ContactPersonModal
          data={''}
          locations={locations}
          closeModal={() => {
            setcontactModal(false);
            getLocations();
          }}
        />
      </ModalOuter>

      <ModalOuter
        open={galleryModal}
        onClose={() => setgalleryModal(false)}
        title="Galerie"
        option="Wählen Sie ein Foto aus"
        small
      >
        <Gallery
          photos={gallery}
          onClick={(e, object) => {
            setfiles(object.photo);
            setgalleryModal(false);
          }}
        />
      </ModalOuter>

      <ErrorMessageModal
        message={errMess}
        open={errorMessageModal}
        onClose={() => {
          seterrorMessageModal(false);
        }}
      />
    </>
  );
};

JobAdModal.propTypes = {};
