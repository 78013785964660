import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { updateStepFour } from '../../../../store/stepsSlice';
import { useDispatch, useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApplicantLandLicences(props) {
  const dispatch = useDispatch();
  const selectedLand = useSelector(
    (state) => state.steps.stepFour.landLicences
  );

  const [search, setSearch] = useState('');

  const [landLicence, setLandLicence] = useState([]);

  const handleLand = (_, values) => {
    dispatch(updateStepFour({ landLicences: values }));
  };

  useEffect(() => {
    if (props.values)
      setLandLicence(props.values.filter((item) => item.LicenseTypeId === 1));
  }, [props.values]);

  return (
    <>
      <Autocomplete
        className='create-profile-select'
        filterOptions={(x) =>
          x.filter((l) => l.Name.toLowerCase().startsWith(search))
        }
        multiple
        id="vozacke"
        noOptionsText="Keine Option"
        options={landLicence}
        value={selectedLand}
        disableCloseOnSelect
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) => {
          return option.LicenseId === value.LicenseId;
        }}
        onChange={handleLand}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{fontSize:"14px"}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
            {' ('}
            {option.Category}
            {') '}
          </li>
        )}
        style={{ width: '100%', margin: '10px 0' }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Straße"
          />
        )}
      />
    </>
  );
}
