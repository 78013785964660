import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import axios from 'axios';
import Check from './Check';
import { Container } from './Bewerber';
import PasswordExplain from './PasswordExplain';
import SubmitButton from '../../Buttons/submitButton';
import Snackbar from '../../snackbar/snackbar';

import config from '../../../config/api.config';

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  background-color: white;
  border-bottom: 1px solid #dddddd;
  margin: 0 auto 30px auto;
  font-size: 16px;
  &:focus {
    border-color: #0077b5;
  }
`;

const EmailInput = styled(StyledInput)``;
const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  ${EmailInput}:focus ~ & {
    color: #5264ae;
  }
`;

const RegisterButtonContainer = styled.div`
  margin: 30px 0 0 0;
  text-align: center;
`;

export default function Company({ ids }) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${config.apiUrl}/Authentication/Register?AppType=Web`, {
        name: values.name,
        userName: values.email,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        userType: 3,
        orderId: ids.orderId,
        buyerId: ids.buyerId
      })
      .then((response) => {
        setLoading(false);
        history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showSnackbar(true);
          setSnackbarMessage(
            error.response.data.ERROR
              ? error.response.data.ERROR[0]
              : error.response.data.title
          );
          console.log(error.response.data);
        }
      });
  };

  useEffect(() => {
    if (
      values.email &&
      values.name &&
      values.password &&
      values.confirmPassword &&
      checked
    )
      setValid(true);
    else setValid(false);
  }, [
    values.email,
    values.name,
    values.password,
    values.confirmPassword,
    checked
  ]);

  return (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="error"
      >
        {snackbarMessage}
      </Snackbar>
      <Container>
        <Label>Firma*</Label>
        <StyledInput
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
        />
        <Label>E-mail*</Label>
        <StyledInput
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
        />
        <Label>Passwort*</Label>
        <StyledInput
          name="password"
          type="password"
          autoComplete="new-password"
          value={values.password}
          onChange={handleChange}
        />
        <Label>Passwort - Wiederholung*</Label>
        <StyledInput
          name="confirmPassword"
          type="password"
          value={values.confirmPassword}
          onChange={handleChange}
        />
        <PasswordExplain />
        <Check setChecked={setChecked} />

        <RegisterButtonContainer>
          <SubmitButton
            buttonClicked={loading}
            onClick={onSubmit}
            valid={valid}
            type="submit"
          >
            Registrierung
          </SubmitButton>
        </RegisterButtonContainer>
      </Container>
    </>
  );
}
