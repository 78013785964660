import React, { useEffect, useState } from 'react';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import TableWrapper from '../../components/DataTable/TableWrapper';
import DashboardLayout from './DashboardLayout';
import {
  ModalOuter,
  LocationModal,
  ContactPersonModal
} from '../../components/modal';
import GetData from '../../api/GetData';
import auth from '../../auth';
import SubmitButton from '../../components/Buttons/submitButton';
import CustomModal from '../../components/modal/Modal';
import DeleteData from '../../api/DeleteData';
import { DeviceSize } from '../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import CircularLoader from '../../components/Loader/CircularLoader';

const Locations = () => {
  const [loading, setloading] = useState(true);
  const [modalLocationOpen, setmodalLocationOpen] = useState(false);
  const [modalContactOpen, setmodalContactOpen] = useState(false);
  const [editData, seteditData] = useState('');
  const [locations, setlocations] = useState();
  // const [contactPerson, setcontactPerson] = useState();
  const [deleteModal, setdeleteModal] = useState(false);

  //true for office deletion, false for contact person deletion
  const [deleteOption, setdeleteOption] = useState();

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const UserId = auth.getId();

  async function getLocations() {
    setloading(true);
    const response = await GetData(
      `UsersBranches?IncludeOffice=true&IncludeContactPerson=true&UserId=${UserId}`
    );
    setlocations(response);
    setloading(false);
  }

  useEffect(() => {
    getLocations();
  }, []);

  const onDelete = async () => {
    if (deleteOption) {
      await DeleteData('Office', editData.BranchId);
    } else {
      await DeleteData('ContactPerson', editData.ContactPersonId);
    }

    seteditData('');
    setdeleteOption(null);
    editData.function();
    setdeleteModal(false);
  };

  return (
    <DashboardLayout noPadding>
      {/* Tables */}
      {loading ? (
        <ComponentWrapper minHeight={'100vh'} displayFlex alignCenter>
          <CircularLoader small />
        </ComponentWrapper>
      ) : (
        <ComponentWrapper
          fluid
          padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
        >
          <>
            <ComponentWrapper noPaddingHorizontal>
              <TableWrapper
                red
                tableTitle="Standorte"
                tableFunction={
                  <SubmitButton
                    small
                    onClick={() => setmodalLocationOpen(true)}
                  >
                    Neue hinzufügen
                  </SubmitButton>
                }
                columns={['Name', 'Adresse', 'Stadt', 'Land', 'Handlung']}
                rows={generateLocationRows(locations)}
                openOption={(data) => {
                  seteditData(data.row);
                  setmodalLocationOpen(true);
                }}
                deleteOption={(data) => {
                  data.row.type = 'Location';
                  data.row.function = () => getLocations();
                  seteditData(data.row);
                  setdeleteOption(true);
                  setdeleteModal(true);
                }}
              />
            </ComponentWrapper>
            <ComponentWrapper noPaddingHorizontal>
              <TableWrapper
                tableTitle="Ansprechpartner"
                tableFunction={
                  <SubmitButton small onClick={() => setmodalContactOpen(true)}>
                    Neue hinzufügen
                  </SubmitButton>
                }
                columns={['Name', 'Standort', 'Position', 'Handlung']}
                rows={generateContactPersonRows(locations)}
                red
                openOption={(data) => {
                  seteditData(data.row);
                  setmodalContactOpen(true);
                }}
                deleteOption={(data) => {
                  data.row.type = 'ContactPerson';
                  data.row.function = () => getLocations();

                  seteditData(data.row);
                  setdeleteOption(false);
                  setdeleteModal(true);
                }}
              />
            </ComponentWrapper>
          </>

          {/* location modal */}
          <ModalOuter
            open={modalLocationOpen}
            onClose={() => {
              setmodalLocationOpen(false);
              seteditData('');
            }}
            title="Standorte"
            option={editData ? 'Bearbeiten' : 'Neue hinzufügen'}
            small
            // smallHeight
          >
            <LocationModal
              data={editData}
              edit={editData ? true : false}
              closeModal={() => {
                setmodalLocationOpen(false);
                getLocations();
              }}
            />
          </ModalOuter>

          {/* contact person modal */}
          <ModalOuter
            open={modalContactOpen}
            onClose={() => {
              setmodalContactOpen(false);
              seteditData('');
            }}
            title="Ansprechpartner"
            option={editData ? 'Bearbeiten' : 'Neue hinzufügen'}
            small
          >
            <ContactPersonModal
              data={editData}
              edit={editData ? true : false}
              locations={locations}
              closeModal={() => {
                setmodalContactOpen(false);
                getLocations();
              }}
            />
          </ModalOuter>

          {/* delete item modal */}
          <CustomModal
            // width="40%"
            height="auto"
            small
            open={deleteModal}
            onClose={() => {
              setdeleteModal(false);
              seteditData('');
            }}
          >
            <ComponentWrapper
              displayFlex
              justifyCenter
              alignCenter
              flexColumn
              center
            >
              <h3
                style={{
                  color: '#e83741',
                  fontWeight: 600,
                  marginBottom: 40,
                  marginTop: 40
                }}
              >
               {deleteOption ? " Möchten Sie dies wirklich löschen?" : "Möchten Sie den Ansprechpartner wirklich löschen?"}
              </h3>
              <p>
                {deleteOption
                  ? 'Mit dieser Aktion werden auch alle Stellenanzeigen und Ansprechpartner die mit diesem Standort verbunden sind gelöscht. Diese Aktion kann nicht rückgängig gemacht werden!'
                  : 'Mit dieser Aktion wird der Ansprechpartner aus allen live geschalteten Stellenanzeigen verschwinden. Diese Aktion kann nicht rückgängig gemacht werden.'}
              </p>
              <ComponentWrapper noPadding displayFlex>
                <ComponentWrapper>
                  <SubmitButton small onClick={onDelete}>
                    Ja
                  </SubmitButton>
                </ComponentWrapper>
                <ComponentWrapper>
                  <SubmitButton
                    small
                    inversed={true}
                    onClick={() => {
                      setdeleteModal(false);
                      seteditData('');
                    }}
                  >
                    Nein
                  </SubmitButton>
                </ComponentWrapper>
              </ComponentWrapper>
            </ComponentWrapper>
          </CustomModal>
        </ComponentWrapper>
      )}
    </DashboardLayout>
  );
};

const generateLocationRows = (data) => {
  let newRows = [];
  data &&
    data.forEach((element) => {
      if (
        !newRows.some((item) => item.id === element.Office.Location.LocationId)
      ) {
        newRows.push({
          id: element.Office.Location.LocationId,

          Name: element.Office.BranchType,
          Adresse: element.Office.Location.StreetName,
          Stadt: element.Office.Location.City,
          Land: element.Office.Location.Country,
          FormatedAddress: element.Office.Location.FormattedAddress,

          // Handlung: {
          //   text: 'Bearbeiten',
          //   outlined: true,
          //   icon: edit
          // },
          //below fields are just for the edit modal
          LocationId: element.Office.Location.LocationId,
          BranchId: element.OfficeId,
          Latitude: element.Office.Location.Latitude,
          Longitude: element.Office.Location.Longitude,
          UserBranchId: element.UserBranchId
        });
      }
    });
  return newRows;
};

const generateContactPersonRows = (data) => {
  let newRows = [];
  data &&
    data.forEach((element) => {
      if (element.ContactPerson)
        newRows.push({
          id: element.ContactPersonId,
          Name:
            element.ContactPerson.FirstName +
              ' ' +
              element.ContactPerson.LastName || 'No name',
          Standort: element.Office.BranchType,
          Position: element.ContactPerson.Position,
          // Handlung: {
          //   text: 'Bearbeiten',
          //   outlined: true,
          //   icon: edit
          // },
          //below fields are just for the edit modal
          Email: element.ContactPerson.Email,
          Title: element.ContactPerson.Title,
          LastName: element.ContactPerson.LastName,
          Phone: element.ContactPerson.Phone,
          UserBranchId: element.UserBranchId,
          FirstName: element.ContactPerson.FirstName,
          BranchId: element.OfficeId,
          UserId: element.UserId,
          ContactPersonId: element.ContactPersonId,
          FileId: element.ContactPerson.FileId, 
          IsMain: element.IsMain
        });
    });
  return newRows;
};

export default Locations;
