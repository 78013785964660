import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Button } from '@mui/material';
import SearchBar from '../components/SearchBar/SearchBar';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import GetDataPublic from '../api/GetDataPublic';
import PartnerCard from '../components/PartnerCard/PartnerCard';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../src/components/responsive';
import '../assets/scss/partner.scss';
import { useEffect } from 'react';
const Partner = () => {
  const [partners, setPartners] = useState([]);
  const [partnersFilter, setPartnersFilter] = useState([]);
  const ref = useRef(null);
  const history = useHistory();

  const getPartners = async () => {
    const partners = await GetDataPublic('Partner');
    setPartners(partners);
  };

  const getPartnersFilter = async (partnerId) => { //need backend here for refactoring
    const filtered = partners.filter(
      (partner) => partner.PartnerId === partnerId
    );

    setPartnersFilter(filtered);
  };
  useEffect(() => {
    getPartners();
  }, []);
  const isMobileSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });
  return (
    <>
      <ComponentWrapper
        displayFlex
        flexColumn
        noPadding
        className="partner-wrapper maxWidth"
      >
        <Header />

        <div className="partner ">
          <div className="header-wrapper">
            <div className="partner-header">
              <div className="header-content">
                <h2>
                  Unsere Kooperationen.
                  <br />
                  Deine Vorteile.
                </h2>
                <p>
                  Wir kooperieren auch mit Schulen, Gewerbeparks, <br />
                  Universitäten und Vereinen aus Ihrer Region und <br />
                  haben ein großes Netzwerk an potentiellen
                  <br />
                  Bewerbern für Ihr Unternehmen.
                </p>
                <div className="header-buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      ref.current?.scrollIntoView({ behavior: 'smooth' })
                    }
                  >
                    Zu den Partnern
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/kontakt')}
                  >
                    Partner werden
                  </Button>
                </div>
              </div>
            </div>

            {!isMobileSmall && <div class="shadow"></div>}
          </div>

          <div className="partner-content">
            <p className="first-partner">Partner & Kooperationen</p>
            <p className="second-p">suchen & finden!</p>
            <SearchBar partners={partners} getJobs={getPartnersFilter} />

            <div className="partners-list" ref={ref}>
              {partnersFilter.length !== 0
                ? partnersFilter.map((partner) => (
                    <PartnerCard key={partner.PartnerId} partner={partner} />
                  ))
                : partners.map((partner) => (
                    <PartnerCard key={partner.PartnerId} partner={partner} />
                  ))}
            </div>
          </div>
        </div>
      </ComponentWrapper>
      <Footer />
    </>
  );
};

export default Partner;
