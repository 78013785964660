import React, {useEffect, useState} from 'react';
import { Paragraph } from '../StepSixSubcomponents/Region';
import VocationalModal from './VocationalModal';
import { useSelector, useDispatch } from 'react-redux';
import {ReactComponent as Cancel} from "../../../../assets/images/icon-cancel.svg";
import { updateStepTwo } from '../../../../store/stepsSlice';
import {ReactComponent as Plus} from '../../../../assets/images/IconPlus.svg';

export default function Vocational(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const vocationalArray = useSelector((state) => state.steps.stepTwo.vocationalArray);
  const [selectedItems, setselectedItems] = useState(vocationalArray);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  }

  const remove = (id) =>  dispatch(updateStepTwo({ vocationalArray: selectedItems.filter(item => item.value !== id) }));

  useEffect(() => {
      setselectedItems(vocationalArray)
  }, [vocationalArray])

  return (
    <div className='vocational-wrapper'>
      <div className="vocational">
        <div className="vocational-left">
            <Paragraph autoWidth>
              {props.createProfile
                ? 'Berufsausbildung auswählen'
                : 'Was für eine abgeschlossene Ausbildung sollte der Bewerber haben?'}
            </Paragraph>
          {!open? <Plus style={{"margin-left": "8px", "transform" : 'scale(0.8)'}} onClick={handleOpen}></Plus>
          :
          <button className='vocational-button--plus vocational-button--minus' onClick={handleOpen}>-</button>
          }
        </div>
       <div className='vocational-selected'>
        {selectedItems.map(voc => <p key={voc.value}>{voc.label} <Cancel onClick={() => remove(voc.value)}/></p>)}
       </div>
      </div>
      {open && <VocationalModal
        createProfile
        endpoint="Profession/Paging"
        inputName={
          props.createProfile
            ? 'Wähle deinen Ausbildungsberuf'
            : 'In welchem Beruf benötigt der Bewerber Berufserfahrung/en?'
        }
      />}

    </div>
  );
}
