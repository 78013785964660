import PostData from '../api/PostData';

export async function submitMatching(steps) {
  const jobAd = JSON.parse(localStorage.getItem('JobAd'));
  const matchingIdLocStor = jobAd.MatchingId;

  const { stepOne, stepTwo, stepThree, stepFour, stepFive } = steps;

  //applicant Location
  const applicantRegion = {
    streetNumber: stepOne.region.streetNumber || null,
    streetName: stepOne.region.streetName || null,
    district: stepOne.region.district || null,
    city: stepOne.region.city || null,
    country: stepOne.region.country || null,
    postalCode: stepOne.region.postalCode || null,
    latitude: stepOne.region.latitude || null,
    longitude: stepOne.region.longitude || null,
    formattedAddress: stepOne.region.formattedAddress || null,
    radius: stepOne.region.radius || null
  };

  const jobRegion = {
    streetNumber: stepFive.region.streetNumber || null,
    streetName: stepFive.region.streetName || null,
    district: stepFive.region.district || null,
    city: stepFive.region.city || null,
    country: stepFive.region.country || null,
    postalCode: stepFive.region.postalCode || null,
    latitude: stepFive.region.latitude || 0,
    longitude: stepFive.region.longitude || 0,
    formattedAddress: stepFive.region.formattedAddress || null,
    radius: stepFive.region.radius || null
  };

  const matchingData = {
    ...(matchingIdLocStor && {
      jobRegionForApplicantId: stepOne.region.locationId
    }),
    ...(matchingIdLocStor && { fillingPositionId: stepFive.region.locationId }),
    startingDateId: Number(stepFive.startingDateId) || null,
    jobTitleId: null,
    // applicantOldMin: stepOne.applicantAge.ageMin,
    // applicantOldMax: stepOne.applicantAge.ageMax,
    completedApprenticeship: Boolean(stepTwo.completedApprenticeship),
    crofessionalExperience: Boolean(stepTwo.hasWorkExperience),
    personnelResponsibility:
      stepTwo.hasWorkExperience && stepTwo.hasPersonalExperience,
    schoolCertificate: Boolean(stepThree.completedHighSchool),
    universityDegree: Boolean(
      stepThree.completedCollege && stepThree.completedHighSchool
    ),
    travel: Boolean(stepFive.wouldTravel),
    travelRegionId: stepFive.wouldTravel
      ? Number(stepFive.travelRegionId)
      : null,
    shifts: Boolean(stepFive.workInShifts),
    shiftId: stepFive.workInShifts ? Number(stepFive.shiftId) : null
  };

  const reqObj = {
    ...(matchingIdLocStor && { matchingId: matchingIdLocStor }),
    applicantRegion,
    jobRegion,
    matching: matchingData,
    collegeId:
      (stepThree.completedHighSchool &&
        stepThree.completedCollege &&
        stepThree.collegeArray &&
        stepThree.collegeArray.map((item) => ({
          id: item.CollegeDegreeId
        }))) ||
      [],
    matchingDesiredProfessionFalse:
      (stepTwo.completedApprenticeship &&
        stepTwo.vocationalArray.map((item) => ({
          professionId: item.value
          // branchId: item?.branchId
        }))) ||
      [],
    certificateId:
      (stepThree.completedHighSchool &&
        stepThree.highschoolArray &&
        stepThree.highschoolArray.map((item) => ({
          id: item.SchoolCertificateId
        }))) ||
      [],
    employmentId:
      (stepFive.employmentArray &&
        stepFive.employmentArray.map((item) => ({
          id: item.EmploymentId
        }))) ||
      [],
    matchingLanguage:
      (stepFour.languages &&
        stepFour.languages.map((item) => ({
          languageId: item.LanguageId,
          levelId: Number(item.secondId) || null
        }))) ||
      [],

    matMatchingQualification:
      (stepFour.additionalQualifications &&
        stepFour.additionalQualifications.map((item) => ({
          matchingQualificationsId: item?.MatchingQualificationsId || null,
          qualificationsId: item.QualificationsId,
          skillsId: Number(item.secondId) || null
        }))) ||
      [],
    matchingLicenses:
      []
        .concat(
          stepFour.airLicences,
          stepFour.landLicences,
          stepFour.specialLicences,
          stepFour.waterLicences
        )
        .filter((item) => item)
        .map((item) => ({
          id: item.LicenseId
        })) || [],

    matchingProfession:
      (stepTwo.hasWorkExperience &&
        stepTwo.workExperience.map((item) => ({
          professionId: item.proffesionId,
          branchId: item.industryId,
          moreThan3Year:
            item.years == '0' ? null : item.years === '3+' ? true : false
        }))) ||
      [],
    benefitsId:
      (stepFive.benefitsArray &&
        stepFive.benefitsArray.map((item) => ({
          id: item.BenefitsId
        }))) ||
      [],
    matchingDesiredProfessionTrue:
      (stepFive.branchArray &&
        stepFive.branchArray.map((item) => ({
          branchId: item.industryId,
          professionId: item.proffesionId
        }))) ||
      []
  };


  let res;
  if (matchingIdLocStor) {
    res = await PostData('matching/matchingupdate', reqObj, 'PUT');
  } else {
    res = await PostData('matching/matchinginsert', reqObj);
  }

  //add matching to jobad
  const job = localStorage.getItem('JobAd');
  if (job) {
    let parsedJob = JSON.parse(job);
    parsedJob = {
      ...parsedJob,
      MatchingId: res.MatchingId,
      IncludeMatching: true
    }
    await PostData(
      `JobAdvertisement/${parsedJob.JobAdvertisementId}`,
      parsedJob,
      'PUT'
    );
  }
}

export default submitMatching;
