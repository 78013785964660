import React from 'react';
import SubmitButton from '../../components/Buttons/submitButton';
import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';
import CustomModal from '../../components/modal/Modal';

const DeletePrompt = ({ open, onClose, onDelete , text}) => {
  return (
    <>
      <CustomModal
        maxWidth="550px"
        width="95%"
        height="fit-content"
        open={open}
        onClose={onClose}
      >
        <ComponentWrapper
          displayFlex
          justifyCenter
          alignCenter
          flexColumn
          fluidHeight
          padding="20px 20px 0"
        >
          <h3
            style={{
              color: '#e83741',
              fontWeight: 600,
              marginTop: '20px',
              textAlign: 'center'
            }}
          >
            {text ?? "Möchten Sie dies wirklich löschen?"}
          </h3>
          <ComponentWrapper
            displayFlex
            justifyCenter
            flexWrap
            noPaddingHorizontal
          >
            <ComponentWrapper padding="10px">
              <SubmitButton small onClick={onDelete}>
                Ja
              </SubmitButton>
            </ComponentWrapper>
            <ComponentWrapper padding="10px">
              <SubmitButton small inversed={true} onClick={onClose}>
                Nein
              </SubmitButton>
            </ComponentWrapper>
          </ComponentWrapper>
        </ComponentWrapper>
      </CustomModal>
    </>
  );
};

export default DeletePrompt;
