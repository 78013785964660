import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import ResetPassSucc from '../../assets/images/passchangedsucc.png';

import auth from '../../auth';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  img {
    width: 100%;
    max-width: 300px;
  }
`;

const Heading = styled.h1`
  color: rgba(232, 55, 65, 1);
  font-size: 26px;
`;

const EmailChanged = () => {
  const history = useHistory();

  return (
    <>
      <Header />
    <Container className="main-container">
      <Heading className="mb-0 text-center">
      Sie haben Ihre E-Mail erfolgreich geändert!
      </Heading>
      <div className="my-5">
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(auth.isApplicant()? '/bewerber-dashboard' : 'arbeitgeber-dashboard')}
        >
          Zurück zur Anmeldung
        </Button>
      </div>
      <img src={ResetPassSucc} alt="Email changed"></img>
    </Container>
    <Footer />
    </>
  );
};

export default EmailChanged;
