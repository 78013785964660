import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import './Register.scss'

const CheckContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #929292;
  .MuiTypography-body1 {
    font-size: 0.85rem !important;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #e83741 !important;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Marker = styled.span`
  color: #e83741;
  padding: 0 !important;
  font-size: 11px;
`;
export default function Check({ setChecked = () => {} }) {
  const [state, setState] = useState({
    agb: false,
    datenschutzbestimmungen: false
  });
  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.checked });

  useEffect(() => {
    if (state.agb && state.datenschutzbestimmungen) setChecked(true);
    else setChecked(false);
  }, [state.agb, state.datenschutzbestimmungen]);

  return (
    <CheckContainer>
      <FormControlLabel className='checkMobile'
        onChange={handleChange}
        control={<Radio name="agb" />}
        label={
          <span>
            Ich stimme <Marker>AGB</Marker> den zu.
          </span>
        }
      />
      <FormControlLabel className='checkMobile'
        onChange={handleChange}
        control={<Radio name="datenschutzbestimmungen" />}
        label={
          <span>
            Ich akzeptiere die <Marker>Datenschutzbestimmungen</Marker> zu.
          </span>
        }
      />
    </CheckContainer>
  );
}
