import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';


import Snackbar from '../../snackbar/snackbar';

import config from '../../../config/api.config';

import { hash } from '../../../utils/hash';

import { Button } from '@mui/material';
export const StyledInput = styled.input`
 
  text-align: center;
  width: 292px;
  height: 45px;
  outline: none;
  border: solid 1px #d6d6d6;
  border-radius: 10px;
  margin: 0 0 10px 0;
  font-size: 16px;
  &:focus {
    border-color: #e30613;
  }
  ::placeholder {
    font-size: 14px;
  }
  :focus::placeholder {
    color: transparent;
  }
`;
export const StyledInputEmail = styled.input`
  text-align: center;
  width: 100%;
  height: 45px;
  outline: none;
  border: solid 1px #d6d6d6;
  border-radius: 10px;
  margin: 0 0 10px 0;
  font-size: 16px;
  &:focus {
    border-color: #e30613;
  }
  ::placeholder {
    font-size: 14px;
  }
  :focus::placeholder {
    color: transparent;
  }

  @media (max-width: 767px) {
    width: 292px;
  }
`;
export const InputContainer = styled.div`
  margin: 0 0px 0px 0;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
`;
const Container = styled.div`
  width: 100%;
 
`;
export const EmailInput = styled(StyledInput)``;
export const Label = styled.label`
  color: #929292;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  ${EmailInput}:focus ~ & {
    color: #5264ae;
  }
`;

const Span = styled.p`
  color: #929292;
  font-size: 16px;
  margin-bottom: 5%;
`;
export const Error = styled.span`
  color: rgb(232, 55, 65);
  font-size: 0.6rem;
  padding: 0 5px 7px 5px;
  display: block;
`;

const RegisterButtonContainer = styled.div`
  text-align: center;
  margin: 20px auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }`;

export { Span, Container };

const access_token =
  'EAAHVkjGN0ZA0BAHxgilcj3WOua1Ea2iuZBEbzHsp0mio6iIvmwXZAtxL2ReT15e1LspKNlb3mDZBsfej2CTqKCS2bDwOPJHx1jpZAFEWfns6Tl0LJukoMxSzi8mOeZAIO3iHIZC8iOQr11TEfzBwsmWIrQZC4EBRzxYsRbFClDmOShVIjlvLqYJF';
const pixel_id = '400768238535269';

export default function Bewerber(props) {
  const current_timestamp = Math.floor(new Date() / 1000);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [valid, setValid] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState({
  
    email: '',
    password: '',
  
  });
 
  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    setLoading(true);
    await axios.post(
      `https://graph.facebook.com/v12.0/${pixel_id}/events?access_token=${access_token}`,
      {
        data: [
          {
            event_name: 'Registration',
            event_time: current_timestamp,
            action_source: 'website',
            event_source_url: 'https://spiderjob.de/profil-anlegen',
            user_data: {
              em: [hash(values.email)],
              fn: [hash(values.firstName)],
              ln: [hash(values.lastName)]
            },
            custom_data: {
              user_type: 'Applicant'
            }
          }
        ]
      }
    );
    await axios
      .post(`${config.apiUrl}/Authentication/Register?AppType=Web`, {
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.email,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        userType: 1
      })
      .then((res) => {
        setLoading(false);
        history.push('/login');
        //history.push('/erfolgreich-registriert');
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );
        //if (error.response.data.errors) setErrors(error.response.data.errors);
      });
  };


  useEffect(() => {
    setError('');

    if (values.email != '' && values.password != '') setValid(true);
    else setValid(false);

    if (
      values.password != '' &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(values.password)
    ) {
      setError('Passwort entspricht nicht den Anforderungen');
      setValid(false);
    }
  }, [values.email, values.password]);

  return (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity="error"
      >
        {snackbarMessage}
      </Snackbar>

      
      <p className="bolded-p">Neuen Account erstellen</p>
          <p className="simple-p">
            Jetzt bei spiderjob registrieren und den richtigen Job finden
          </p>

          <p className="email">Email</p>
          <input
            className="input-e"
            onChange={handleChange}
            name="email"
            value={values.email}
          ></input>
          <p className="email">Passwort festlegen</p>
          <input
            className="input-e"
            onChange={handleChange}
            value={values.password}
            name="password"
            type="password"
            style={{ marginBottom: '0px' }}
          ></input>

          <Error key={'error'}>{error}</Error>

          <p className="pass">
            Das Passwort muss mindestens 8 Zeichen, Groß- und Kleinbuchstaben
            sowie eine Mischung aus Buchstaben und Zahlen enthalten.
          </p>

          <Button
            variant="contained"
            color="primary"
            className="register-b"
            onClick={onSubmit}
            disabled={!valid}
          >
            Registrieren
          </Button>

          <p className="pass">
            Mit deiner Registrierung stimmst du unseren{' '}
            <span className="span-pass">Nutzungsbedingungen</span> zu. Lies dazu
            auch unsere{' '}
            <span
              className="span-pass"
              onClick={() => history.push('/datenschutz-bestimmungen')}
            >
              Datenschutzerklärung
            </span>
            .
          </p>
          <p className="pass">
            In Erfüllung des Nutzungsvertrages schicken wir dir passende Jobs
            und Infos zu ähnlichen Produkten und Services per E-Mail zu.
            Natürlich kannst du jederzeit von unseren einwilligungsfähigen
            E-Mails und Services abmelden oder dein Profil auf “nicht sichtbar”
            stellen.
          </p>
          <div >
            <p className="last-p">
              Du hast schon einen Account?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/login')}
              >
                {' '}
                Jetzt einloggen
              </span>
            </p>
            <p className="last-p">
              Sie sind Arbeitgeber?{' '}
              <span
                className="span-pass"
                onClick={() => history.push('/kostenloses-erstgespräch')}
              >
                {' '}
                Hier entlang
              </span>
            </p>
          </div>
    
    </>
  );
}
