import GetData from '../api/GetData';
import PostData from '../api/PostData';
import auth from '../auth';

export async function UploadBranch(data, edit = null) {
  // console.log('🚀 ~ data', data);
  let locationData = {
    //streetNumber: 'string',
    streetName: data.streetName || null,
    //district: 'string',
    city: data.city || null,
    country: data.country || null,
    postalCode: data.postalCode || null,
    latitude: data.latitude || null,
    longitude: data.longitude || null,
    formattedAddress: data.formattedAddress || null
    //radius: 'string'
  };

  let location;
  if (edit) {
    location = await PostData(
      `Location/${data.locationId}`,
      locationData,
      'PUT'
    );
  } else {
    location = await PostData('Location', locationData);
  }

  let branchData = {
    LocationId: location.LocationId,
    BranchType: data.poslovnica,
    Place: null,
    Mobile: null,
    Phone: null,
    Email: null,
    Website: null,
    Employee: null,
    Existing: null
  };

  let branch;
  if (edit) {
    branch = await PostData(`Office/${data.branchId}`, branchData, 'PUT');
  } else {
    branch = await PostData('Office', branchData);
  }

  const UserId = auth.getId();

  let userBranchData;
  if (edit) {
    let userBranch = await GetData(`UsersBranches/${data.userBranchId}`);
    userBranchData = {
      userId: userBranch.UserId,
      officeId: branch.OfficeId,
      contactPersonId: userBranch.ContactPersonId || null
    };
  } else {
    userBranchData = {
      userId: Number(UserId),
      officeId: branch.OfficeId,
      contactPersonId: null
    };
  }

  if (edit) {
    await PostData(`UsersBranches/${data.id}`, userBranchData, 'PUT');
  } else {
    await PostData('UsersBranches', userBranchData);
  }
}

export default UploadBranch;
