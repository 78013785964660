import React from 'react';
import styles from './ImageModal.module.scss';
import close from '../../assets/images/close-icon.svg';
import { Modal } from '@mui/material';

const ImageModal = ({ src, onClose }) => {
  return (
    <>
      <Modal open={Boolean(src)} onClose={onClose} className={styles.modal}>
        <div className={styles.container}>
        {src?.split(".").pop() !== "mp4" ? 
          <img src={src} alt="" className={styles.image} />
          :
          <video controls
          className={styles.image}
         >
            <source src={src} type="video/mp4" />
          </video>
    }
          <img
            src={close}
            alt="close"
            onClick={onClose}
            className={styles.closeBtn}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImageModal;
