import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import axios from 'axios';
import config from '../../config/api.config';
import './select.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, styled } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const defaultAdditional = {
  page: 1
};

const SelectAsync = ({
  option = '',
  url = config.apiUrl,
  onChange,
  value,
  searchParam = 'ProfesionName',
  isClearable = false,
  isMulti = false,
  placeholder = '',
  className
}) => {
  const loadPageOptions = async (q, prevOptions, { page }) => {
    const response = await axios.get(`${url}/${option}`, {
      params: { PageNumber: page, [searchParam]: q, PageSize: 30 }
    });

    return {
      options: response.data.list.map((item) => {
        return { value: Object.values(item)[0], label: Object.values(item)[1] };
      }),
      hasMore: response.data.HasNext,

      additional: {
        page: page + 1
      }
    };
  };

  return (
    <AsyncPaginate
      debounceTimeout={200}
      additional={defaultAdditional}
      hideSelectedOptions={false}
      loadOptions={loadPageOptions}
      className={`basic-select ${className}`}
      classNamePrefix="select"
      isSearchable={true}
      isClearable={isClearable}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder}
      noOptionsMessage={({}) => {
        return 'Keine Option';
      }}
      styles={colourStyles}
      components={{
        MultiValueRemove,
        Option: isMulti ? Option : components.Option
      }}
    />
  );
};

const CustomCancelIcon = styled(CancelIcon)(() => ({
  'color': 'rgba(0, 0, 0, 0.26)',
  'width': '20px',
  'cursor': 'pointer',
  'height': '20px',
  'margin': '0 5px 0 -6px',
  // '-webkit-tap-highlight-color': 'transparent',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.4)'
  }
}));

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CustomCancelIcon />
    </components.MultiValueRemove>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={props.isSelected}
        />
        {props.label}
      </components.Option>
    </>
  );
};

const colourStyles = {
  input: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      'cursor': isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      'backgroundColor': isFocused
        ? '#f5f5f5'
        : isSelected
        ? '#ebebeb'
        : '#fff',
      'color': 'inherit',
      'fontSize': '14px !important'
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      flexWrap: "nowrap",
      overflow:"auto"
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      minWidth: "fit-content"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '14px',
    whiteSpace: 'nowrap'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    'padding': '0',
    'margin': '0  0 0 10px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  })
};

export default SelectAsync;
