import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
  outline: none;
  padding: 0.5% 1em;

  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  background-color: #e83741;
  border: none;
  &:hover {
    background-color: #e64e57;
  }

  ${(props) =>
    props.outlined &&
    `
  border: 1px solid #e83741;
  color: #e83741;
  background-color: #fff;
 &:hover {
    background-color: #e83741;
    color:#fff;
  }`
}
${(props) =>
  props.blue &&
  `
  border: 1px solid  rgb(116, 173, 215);
  color:  rgb(116, 173, 215);
  background-color: #fff;
  &:hover {
    background-color: rgb(116, 173, 215);
    color:#fff;
  }`
}
  ${(props) =>
    props.disabled &&
    `
  cursor: not-allowed;
    opacity: 0.5;
 

  `}
    ${(props) =>
    props.withHeight &&
    `
     display: flex;
    height: 28px;
    align-items: center;
 

  `}
`;

const OptionButton = (props) => {
  const [invertIcon, setinvertIcon] = useState(false);
  return (
    <Button
      {...props}
      onMouseEnter={() => setinvertIcon(true)}
      onMouseLeave={() => setinvertIcon(false)}
    >
      {props.icon && (
        <img
          src={invertIcon ? props.icon2 : props.icon}
          alt="icon"
          style={{ marginRight: 5 }}
        />
      )}
      {props.text}
    </Button>
  );
};

OptionButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default OptionButton;
