import axios from 'axios';
import auth from '../auth';
import { Redirect } from 'react-router-dom';
import config from '../config/api.config';

const PatchData = async (url, UserData) => {
  //if (auth.isTokenExpired()) auth.logout(() => <Redirect to="/login" />);
  const accessToken = auth.getToken();
  if (!accessToken) {
    return <Redirect to="/" />;
  }

  return new Promise(async (resolve, reject) => {
    var UserId = auth.getId();
    let urlNew = config.apiUrl +"/"+ url;
    try {
      const result = await axios.patch(`${urlNew}/${UserId}`, UserData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          //Authorization: `Bearer ${accessToken}`
        }
      })
      // const result = await axios({
      //   method: 'PATCH',
      //   url: `${url}/${UserId}`,
      //   data: UserData,
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     //Authorization: `Bearer ${accessToken}`
      //   }
      // });
      // console.log(result);
      resolve(result.data);
    } catch (error) {
      console.log(error.response);
      console.log(`Error: ${error}`);
      reject(error);
    }
  });
};

export default PatchData;
