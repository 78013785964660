import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Paragraph } from './Region';
import PropTypes from 'prop-types';

export default function TravelHowFar(props) {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="w-100">
      {!props.createProfile && <Paragraph title>Wähle...</Paragraph>}

      <FormControl fullWidth style={{ marginTop: '10px' }}>
        <Select
                className='create-profile-select'
          required
          defaultValue=""
          color="secondary"
          name={props.name}
          value={Number(props.value) || value}
          onChange={props.setRegion || handleChange}
        >
          {props.data?.map((region) => (
            <MenuItem value={region.RegionId} key={region.RegionId}>
              {region.RegionName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

TravelHowFar.propTypes = {
  data: PropTypes.array.isRequired
};
