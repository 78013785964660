import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@mui/material';
import axios from 'axios';
import auth from '../../../auth';
import config from '../../../config/api.config';
import Snackbar from '../../../components/snackbar/snackbar';
import { GoogleAutocomplete } from '../../../components/GoogleAutocomplete';
import DashboardLayout from '../../CompanyDashboard/DashboardLayout';
import { DashboardTitle } from '../../CompanyDashboard/Home';
import { Input, Row } from '../../../components/CreateProfile/Steps';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import { Paragraph } from '../../../components/CreateProfile/Steps/StepSixSubcomponents/Region';
import SubmitButton from '../../../components/Buttons/submitButton';
import CircularLoader from '../../../components/Loader/CircularLoader';
import ProfileMatchingData from './profileMatchingData';
import patchdata from '../../../api/patchdata';
import GetData from '../../../api/GetData';
import UploadFile from '../../../api/UploadFile';
import './profileSettings.css';
import { ReactComponent as ProfileImg } from '../../../assets/images/editProfile.svg';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../../../components/responsive';
import 'react-datepicker/dist/react-datepicker.css';

export const ProfileInput = ({
  label,
  name,
  type = 'text',
  required,
  value,
  onChange,
  ...otherProps
}) => {
  const ref = useRef();

  return (
    <ComponentWrapper fluid {...otherProps}>
      <Paragraph grey small>
        {label}
      </Paragraph>
      <Input
        type={type}
        name={name}
        id={name}
        required={required}
        value={value}
        noFill
        onChange={onChange}
        ref={ref}
      />
    </ComponentWrapper>
  );
};

const Profile = () => {
  const [nationalities, setNationalities] = useState(null);
  const [titles, setTitles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [snackbar, showSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [valid, setValid] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [salary, setSalary] = useState([50000, 150000]);
  const [basicData, setbasicData] = useState({
    vorname: '',
    // geburtsort: '',
    //staatsangehörigkeit: null,
    //titel: null,
    nachname: '',
    // telefon: '',
    //geburtsdatum: new Date(),
    mobil: '',
    locationId: null,
    applicantId: null,
    jobRegionId: null
    // personalRespon: false,
    // minAnnualSalary: 50000,
    // maxAnnualSalary: 150000
    //gender: null
  });

  const [location, setLocation] = useState({
    streetNumber: '',
    streetName: '',
    district: '',
    city: '',
    country: '',
    postalCode: '',
    latitude: '',
    longitude: '',
    formattedAddress: ''
  });
  const [profile, setProfile] = useState(null);

  const dataRef = useRef(basicData);
  const fileRef = useRef();

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  const handleInputChange = (e) => {
    dataRef.current = { ...basicData, [e.target.name]: e.target.value };
    setbasicData({ ...basicData, [e.target.name]: e.target.value });
  };
  // const GetNationalities = async () => {
  //   await axios
  //     .get(`${config.apiUrl}/Nationality`)
  //     .then(function (response) {
  //       setNationalities(response.data);
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         console.log(
  //           error.response.data.ERROR ? error.response.data?.ERROR[0] : null
  //         );
  //       }
  //     });
  // };
  // const GetTitles = async () => {
  //   await axios
  //     .get(`${config.apiUrl}/Title`)
  //     .then(function (response) {
  //       setTitles(response.data);
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         console.log(
  //           error.response.data.ERROR ? error.response.data.ERROR[0] : null
  //         );
  //       }
  //     });
  // };

  const GetUser = async () => {
    await axios
      .get(`${config.apiUrl}/User/${auth.getId()}`)
      .then(async (res) => {
        const user = res.data;
        setbasicData({
          ...basicData,
          vorname: user.FirstName,
          nachname: user.LastName,
          //geburtsdatum: new Date(user.BirthDate),
          hausnummer: user.HouseNumber,
          // telefon: user.Phone,
          mobil: user.Mobile,
          //staatsangehörigkeit: user.NationalityID,
          //  geburtsort: user.BirthdayPlace,
          //  titel: user.TitleID,
          locationId: user.LocationId,
          applicantId: user.Id,
          jobRegionId: user.JobRegionId,
          travelingId: user.TravelingId,
          startingDateId: user.StartingDateId,
          shiftId: user.ShiftId
          // personalRespon: user.PersonnelResponsibility,
          // minAnnualSalary: user.MinAnnualSalary,
          // maxAnnualSalary: user.MaxAnnualSalary
          //gender: user.GenderId
        });

        // setSalary([user.MinAnnualSalary, user.MaxAnnualSalary]);

        if (user.LocationId)
          await axios
            .get(`${config.apiUrl}/Location/${user.LocationId}`)
            .then((res) => {
              setLocation({
                streetNumber: res.data.StreetNumber,
                streetName: res.data.StreetName,
                district: res.data.District,
                city: res.data.City,
                country: res.data.Country,
                postalCode: res.data.PostalCode,
                latitude: res.data.Latitude,
                longitude: res.data.Longitude,
                formattedAddress: res.data.FormattedAddress
              });

              setLoadingPage(false);
            })
            .catch((err) => {
              console.error(err);
              setLoadingPage(false);
            });
        else setLoadingPage(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingPage(false);
      });
  };

  const GetProfileImage = async () => {
    const response = await GetData(`File?UserId=${auth.getId()}`);
    console.log(response[0], auth.getId());
    setProfile(response[0]);
  };
  const ChangeProfileImage = async (event) => {
    setLoadingImage(true);
    const formData = new FormData();
    formData.append('File', event.target.files[0]);
    formData.append('Type', 'Profile');
    formData.append('UserId', auth.getId());

    try {
      await UploadFile(`File/${profile.FileId}`, formData, 'PUT');
      GetProfileImage();
      setLoadingImage(false);
    } catch (error) {
      setLoadingImage(false);
      console.error(error);
      setSeverity('error');
      showSnackbar(true);
      setSnackbarMessage(
        'Beim Hochladen des Bildes ist ein Fehler aufgetreten'
      );
    }
  };

  const PatchUser = async () => {
    setLoading(true);
    await axios
      .patch(`${config.apiUrl}/User/${auth.getId()}`, [
        {
          path: '/FirstName',
          op: 'replace',
          value: basicData.vorname
        },
        {
          path: '/LastName',
          op: 'replace',
          value: basicData.nachname
        },
        {
          path: '/Mobile',
          op: 'replace',
          value: basicData.mobil
        }
        // {
        //   path: '/Phone',
        //   op: 'replace',
        //   value: basicData.telefon
        // },
        // {
        //   path: '/BirthDate',
        //   op: 'replace',
        //   value: basicData.geburtsdatum
        // },
        // {
        //   path: '/BirthdayPlace',
        //   op: 'replace',
        //   value: basicData.geburtsort
        // },
        // {
        //   path: '/TitleID',
        //   op: 'replace',
        //   value: basicData.titel
        // },
        // {
        //   path: '/NationalityID',
        //   op: 'replace',
        //   value: basicData.staatsangehörigkeit
        // },
        // {
        //   path: '/PersonnelResponsibility',
        //   op: 'replace',
        //   value: basicData.personalRespon
        // },
        // {
        //   path: '/MinAnnualSalary',
        //   op: 'replace',
        //   value: basicData.minAnnualSalary
        // },
        // {
        //   path: '/MaxAnnualSalary',
        //   op: 'replace',
        //   value: basicData.maxAnnualSalary
        // }
        // {
        //   path: '/GenderId',
        //   op: 'replace',
        //   value: parseInt(basicData.gender)
        // }
      ])
      .then(async (res) => {
        if (!basicData.locationId)
          await axios
            .post(`${config.apiUrl}/Location`, {
              streetNumber: location.streetNumber?.toString(),
              streetName: location.streetName,
              district: location.district,
              city: location.city,
              country: location.country,
              postalCode: location.postalCode,
              latitude: location.latitude?.toString(),
              longitude: location.longitude?.toString(),
              formattedAddress: location.formattedAddress
            })
            .then(async (res) => {
              await patchdata('User', [
                {
                  path: '/LocationId',
                  op: 'replace',
                  value: res.data.LocationId
                }
              ]);
              setLoading(false);
              setSeverity('success');
              showSnackbar(true);
              setSnackbarMessage(
                'Du hast deine Daten erfolgreich aktualisiert!'
              );
            })
            .catch((error) => {
              setLoading(false);
              setSeverity('error');
              showSnackbar(true);
              setSnackbarMessage(
                error.response.data.ERROR
                  ? error.response.data.ERROR[0]
                  : error.response.data.title
              );
            });
        else
          await axios
            .put(`${config.apiUrl}/Location/${basicData.locationId}`, {
              streetNumber: location.streetNumber,
              streetName: location.streetName,
              district: location.district,
              city: location.city,
              country: location.country,
              postalCode: location.postalCode,
              latitude: location.latitude?.toString(),
              longitude: location.longitude?.toString(),
              formattedAddress: location.formattedAddress
            })
            .then((res) => {
              setLoading(false);
              setSeverity('success');
              showSnackbar(true);
              setSnackbarMessage(
                'Du hast deine Daten erfolgreich aktualisiert!'
              );
            })
            .catch((error) => {
              setLoading(false);
              setSeverity('error');
              showSnackbar(true);
              setSnackbarMessage(
                error.response.data.ERROR
                  ? error.response.data.ERROR[0]
                  : error.response.data.title
              );
            });
      })
      .catch((error) => {
        setLoading(false);
        setSeverity('error');
        showSnackbar(true);
        setSnackbarMessage(
          error.response.data.ERROR
            ? error.response.data.ERROR[0]
            : error.response.data.title
        );
      });
  };

  useEffect(() => {
    setLoadingPage(true);
    GetUser();
    GetProfileImage();
  }, []);

  useEffect(() => {
    if (basicData.vorname && basicData.nachname && location.formattedAddress) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [basicData.vorname, basicData.nachname, location.formattedAddress]);

  const onGoogleSelected = (place) => {
    setLocation({
      streetNumber: place?.streetNumber,
      streetName: place?.streetName,
      district: place?.district,
      city: place?.city,
      country: place?.country,
      postalCode: place?.postalCode,
      latitude: place?.latitude,
      longitude: place?.longitude,
      formattedAddress: place?.formattedAddress
    });
  };

  useEffect(() => {
    if (loadingPage)
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    else document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }, [loadingPage]);

  return (
    <>
      <Snackbar
        open={snackbar}
        onClose={() => showSnackbar(false)}
        severity={severity}
      >
        {snackbarMessage}
      </Snackbar>
      {loadingPage ? (
        <div className="loadingCover">
          <CircularLoader />
        </div>
      ) : null}
      <DashboardLayout noPadding>
        <ComponentWrapper
          fluid
          padding={isExtraSmall ? '20px 10px' : isMedium ? '30px' : '50px'}
        >
          <Row>
            <DashboardTitle red> Basisdaten</DashboardTitle>
          </Row>
          <ComponentWrapper noPadding displayFlex flexWrap={isSmall} width={'100%'} justifyBetween>
            <ComponentWrapper
              displayFlex
              flexColumn={isMedium}
              flexWrap={isMedium}
              alignCenter
              justifyBetween
              style={{flex: '1'}}
            >
              <div
                className="profile-settings__image"
                onClick={() => fileRef.current.click()}
              >
                {loadingImage ? (
                  <div className="loadingImage">
                    <CircularLoader />
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      hidden
                      ref={fileRef}
                      onChange={ChangeProfileImage}
                    />
                    <ProfileImg />
                    <img src={profile?.Url} />
                  </>
                )}
              </div>
              <p
                style={{
                  color: '#929292',
                  fontSize: '12px',
                  maxWidth: '200px',
                  textAlign: 'center'
                  // width: 'calc(90% - 193px)'
                }}
              >
                Dieses Bild wird verwendet, wenn Du dich auf Stellen bewirbst.
              </p>
            </ComponentWrapper>

            <ComponentWrapper displayFlex alignCenter justifyBetween   flexColumn={isMedium}
              flexWrap={isMedium}  style={{flex: '2'}}>
              {' '}
              <div style={{width: '100%'}}>
                <ProfileInput
                  label="Vorname*"
                  name="vorname"
                  required={true}
                  value={basicData.vorname}
                  onChange={handleInputChange}
                />
                <ProfileInput
                  label="Nachname*"
                  name="nachname"
                  required={true}
                  value={basicData.nachname}
                  onChange={handleInputChange}
                />
              </div>
              <div style={{width: '100%'}}>
                <ProfileInput
                  label="Mobil"
                  name="mobil"
                  required={true}
                  value={basicData.mobil}
                  onChange={handleInputChange}
                />

                <ComponentWrapper fluid>
                  <Paragraph grey small>
                    Standort*
                  </Paragraph>
                  <GoogleAutocomplete
                    onPlaceSelected={onGoogleSelected}
                    defaultValue={location.formattedAddress}
                    type='(regions)'
                  />
                </ComponentWrapper>
              </div>
          

              </ComponentWrapper>
          
          
         
          </ComponentWrapper>

          <ComponentWrapper>
            <SubmitButton
              style={{ maxWidth: '350px' }}
              buttonClicked={loading}
              onClick={PatchUser}
              valid={valid}
              specific={true}
            >
              Speichern
            </SubmitButton>
          </ComponentWrapper>

          {!loadingPage && (
            <ProfileMatchingData
              jobRegionId={basicData.jobRegionId}
              travelingId={basicData.travelingId}
              startingDateId={basicData.startingDateId}
              shiftId={basicData.shiftId}
            />
          )}
        </ComponentWrapper>
      </DashboardLayout>
    </>
  );
};

export default Profile;
