import React, { useEffect, useState } from 'react';
import Vocational from './Step2Subcomponents.js/Vocational';
import WorkExp from './Step2Subcomponents.js/WorkExp';
import RadioButtons from '../Steps/Step2Subcomponents.js/RadioButtons';
import GetData from '../../../api/GetData';
import { Paragraph } from './StepSixSubcomponents/Region';
import styled from 'styled-components';
import { DeviceSize } from '../../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import { updateStepTwo, updateValid } from '../../../store/stepsSlice';
import { useDispatch, useSelector } from 'react-redux';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
  padding: 40px;
  width:75%;

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    padding: 20px;
  }
`;

export function StepTwo({ createProfile }) {
  const dispatch = useDispatch();
  const stepTwo = useSelector((state) => state.steps.stepTwo);

  const [branch, setBranch] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });

  useEffect(() => {
    async function getBranches() {
      let result = await GetData('Branch');
      setBranch(result);
    }
    getBranches();
  }, []);

  useEffect(() => {
    if (
      ((stepTwo.completedApprenticeship &&
        stepTwo.vocationalArray.length > 0) ||
        !stepTwo.completedApprenticeship) &&
      ((stepTwo.hasWorkExperience && stepTwo.workExperience.length > 0) ||
        !stepTwo.hasWorkExperience)
    ) {
      dispatch(updateValid(true));
    } else {
      dispatch(updateValid(false));
    }
  }, [
    stepTwo.completedApprenticeship,
    stepTwo.vocationalArray,
    stepTwo.hasWorkExperience,
    stepTwo.workExperience.length
  ]);

  return (
    <form className='formTwo'>
      <Container>
        {isMobile ? (
          <h4 className="fw-bold fc-p mb-4 mt-3">2. Berufserfahrung</h4>
        ) : null}
        <Row>
          <Paragraph autoWidth>
            {createProfile
              ? 'Hast du eine abgeschlossene Berufsausbildung?'
              : 'Benötigt Ihr Bewerber eine abgeschlossene Ausbildung?'}
          </Paragraph>
          <RadioButtons
            setTrue={() =>
              dispatch(updateStepTwo({ completedApprenticeship: true }))
            }
            setFalse={() =>
              dispatch(updateStepTwo({ completedApprenticeship: false }))
            }
            naPromjenu={() => {}}
            tip={'finishedVocational'}
            value={stepTwo.completedApprenticeship ? '1' : '0'}
          />
        </Row>

        {stepTwo.completedApprenticeship && (
          <Vocational createProfile={createProfile} industry={branch} />
        )}
        <WorkExp createProfile={createProfile} industry={branch} />
      </Container>
    </form>
  );
}

export default StepTwo;
