import React, { useEffect, useState } from 'react';
import SubmitButton from '../../../Buttons/submitButton';
import ComponentWrapper from '../../../ComponentWrapper/ComponentWrapper';
import SelectAsync from '../../../select/selectAsync';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepTwo } from '../../../../store/stepsSlice';

const VocationalModal = ({endpoint, inputName }) => {
  const [valid, setValid] = useState(false);

  const dispatch = useDispatch();
  const vocationalArray = useSelector((state) => state.steps.stepTwo.vocationalArray);

  const [selectedItems, setselectedItems] = useState(vocationalArray);

  const onOptionSelected = (newArr) => {
    setselectedItems(newArr);
  };

  useEffect(() => {
    if (selectedItems?.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [selectedItems?.length]);

  const closeModalUpdateData = (e) => {
    e.preventDefault();
    dispatch(updateStepTwo({ vocationalArray: selectedItems }));
  };

  return (
        <ComponentWrapper fluid displayFlex noPaddingHorizontal padding="10px" className='divHif'>
              <SelectAsync
                value={selectedItems}
                option={endpoint}
                onChange={(e) => onOptionSelected(e)}
                isMulti={true}
                placeholder={inputName}
                className='create-profile-select'
              />

              <SubmitButton onClick={closeModalUpdateData} valid={valid} className = "buttonHif">
                Hinzufügen
              </SubmitButton>
        </ComponentWrapper>
  );
};

export default VocationalModal;
