import React, { useState } from "react";
import { Button } from "@mui/material";
import {ReactComponent as IconBusiness} from "../../assets/images/icon-business-grey.svg";
import {ReactComponent as IconPin} from "../../assets/images/icon-pin-fill.svg";
import {ReactComponent as IconBusinessCenter} from "../../assets/images/icon-business-center-fill.svg";
import './partnerJobCard.scss';
import { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';

const PartnerJobCard = (props) => {
    const [employment, setEmployment] = useState(null);

    useEffect(() => {
            setEmployment(props.job?.Employments?.length? props.job?.Employments : props.job?.EmploymentText)
    }, [props.job])
    const isMobileSmall = useMediaQuery({ maxWidth: 767 });
    const isMobileSmall2 = useMediaQuery({ maxWidth: DeviceSize.iPadPro });
    return (
        <div className="partner-job-card"
      onClick={_ => {window.open(`/job/${encodeURIComponent(props.job.Profession?.ProfesionName?.replace("+", "") ?? props.job.ProfessionText?.replace("+", ""))}-${props.job.JobAdvertisementId}`,"_blank") }}
        >
            <div className="card-type">{Array.isArray(employment)? `${employment[0].EmploymentType} ${employment.length > 1 ? `+${employment.length -1}` : ' '}`   : employment}</div>
            <div className="card-image">
<img src={props.job?.UserPhotoUrl}/>
            </div>
            <p className="card-name">{props.job?.ProfessionText ?? props.job?.Profession?.ProfesionName}</p>
            <p className="card-gender">m | w | d</p>
            <div className="card-establishment">
                <IconBusiness/>
                <p>{props.job?.ApplicationUser?.Name}</p>
            </div>
            <div className="card-location">
            <IconPin/>
            <p>{props.job?.JobLocation?.City} {(props.job?.JobLocation2?.City || props.job?.JobLocation3?.City) ? ' +1' : (props.job?.JobLocation2?.City && props.job?.JobLocation3?.City? '+2' : '')}</p>
            </div>
            <div className="card-location">
            <IconBusinessCenter/>
            <p>{props.job?.Branch?.BranchType}</p>
            </div>
            {/* {!props.noButton && <Button
            style={isMobileSmall ? {top: '90%'} : {}}
               variant="contained" 
               color="secondary"
            onClick={_ => {  window.open(`/job/${encodeURIComponent(props.job.Profession?.ProfesionName?.replace("+", "") ?? props.job.ProfessionText?.replace("+", ""))}-${props.job.JobAdvertisementId}`,"_blank") }}
                 >
                    Jetzt entdecken
             </Button>} */}
           
        </div>
    )
}

export default PartnerJobCard;