import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../components/responsive';
import Pricing from '../components/ForEmployer/Pricing';
import ContactPerson from '../components/ForEmployer/ContactPerson';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Kompanija1 from '../assets/images/kompanija1.png';
import Kompanija2 from '../assets/images/kompanija2.png';
import Kompanija3 from '../assets/images/kompanija3.png';
import Kompanija4 from '../assets/images/kompanija4.png';
import Kompanija5 from '../assets/images/kompanija5.png';
import PartnersImage from '../assets/images/logos_Kunden_neu.png';
import PartnersMobile from '../assets/images/logos_Kunden_Mobil.png';
import { ReactComponent as One } from '../assets/images/number-one.svg';
import { ReactComponent as Two } from '../assets/images/number-two.svg';
import { ReactComponent as Three } from '../assets/images/number-three.svg';
import { ReactComponent as Four } from '../assets/images/number-four.svg';
import { ReactComponent as Five } from '../assets/images/number-five.svg';
import '../assets/scss/arbeitgeber.scss';
import { useEffect } from 'react';

export default function Arbeitgeber() {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  var data = history.location.state?.data; //prebaciti u state ako zelim da se pri reloadu stranice postavi na null

  useEffect(() => {
    //ovaj data se postavlja u errormessagemodal i sluzi da se napravi da kada se redirecta na ovu stranicu bude automatski scroll
    //na paket&preise

    if (data == 'scroll') {
      const element = document.getElementById('package');
      if (element != null) element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="arbeitgeber">
      <Header />
      <div className="arbeitgeber-container maxWidth">
        <ContactPerson mobilna={isMobile} />

        <section
          className="bewerber-section bewerber-section-second bewerber-section-fifth"
          id="function"
        >
          <div />
          <div className="section-content">
            <div className="content-title">
              <p>In nur 5 Schritten zu neuen Mitarbeitern</p>
              <h4>So einfach funktioniert’s!</h4>
            </div>
            <div className="arbeitgeber-steps">
              <div>
                <div className="step-section">
                  <img src={Kompanija2} />
                  <div>
                    {' '}
                    <One />
                  </div>
                  <div>
                    <h5>
                      Unverbindliches & kostenloses Erstgespräch vereinbaren
                    </h5>
                    <p>
                      Vereinbaren Sie ein kostenloses Erstgespräch oder wählen
                      Sie direkt eines der vier Pakete.
                    </p>
                  </div>
                </div>
                <div className="step-section">
                  <img src={Kompanija1} />
                  <div>
                    {' '}
                    <Two />
                  </div>
                  <div>
                    <h5>Zahlungsabwicklung</h5>
                    <p>
                      Nachdem Sie das gewünschte Paket ausgewählt haben, werden
                      Sie zu unserem Zahlungsdienstleister digistore24
                      weitergeleitet. Nach Abschluss der Zahlungsabwicklung
                      können Sie direkt loslegen und neue Mitarbeiter finden!
                    </p>
                  </div>
                </div>
              </div>
              <div className="step-section">
                <img src={Kompanija3} />
                <div>
                  <Three />
                </div>
                <div>
                  <h5>Unternehmensprofil anlegen</h5>

                  <p>
                    Erstellen Sie ein aussagekräftiges Unternehmensprofil mit
                    Logo, Bildern, Videos und Ansprechpartner.
                  </p>
                </div>
              </div>
              <div>
                <div className="step-section">
                  <img src={Kompanija4} />
                  <div>
                    {' '}
                    <Four />
                  </div>
                  <div>
                    <h5>Stellen schalten</h5>
                    <p>
                      Schalten Sie jetzt Ihre Stellenanzeige/n. Gerne
                      unterstützen wir Sie bei der Anzeigenerstellung und
                      schalten Ihre offenen Stellen online.
                    </p>
                  </div>
                </div>
                <div className="step-section">
                  <img src={Kompanija5} />
                  <div>
                    {' '}
                    <Five />
                  </div>
                  <div>
                    <h5>Bewerbungen erhalten</h5>
                    <p>
                      In Ihrem Dashboard können Sie ab sofort alle Stellen
                      verwalten, neue Stellen hinzufügen und Bewerber
                      kontaktieren.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  'https://www.youtube.com/watch?v=qPebvcfbMcI',
                  '_blank'
                )
              }
            >
              Zum Video
            </Button>
            <Link to={'package'} smooth spy duration={100}>
              <Button variant="contained" color="primary">
                Pakete & Preise
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/profil-anlegen')}
            >
              Profil anlegen
            </Button>
          </div>
        </section>

        
        <Pricing id="package" />
        <p className="pricing-note">
          *Alle Preise verstehen sich zzgl. gesetzlicher MwSt.
        </p>

        <h4 className="partners-title">
          Innovative Unternehmen nutzen spiderjob
        </h4>
        <div className="partners-image">
          <img src={isMobile ? PartnersMobile : PartnersImage} alt="Partners" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
