import React from 'react';
import styled from 'styled-components';
import NotFoundImage from '../assets/images/not-found-image.png';
import NotFoundBackground from '../assets/images/not-found-background.png';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { DeviceSize } from '../components/responsive';

const Container = styled.div`
  height: 100%;
  width: 100%;

  .not-found-container {
    width: 100%;
    min-height: 700px;
    background-image: url(${NotFoundBackground});
    background-size: cover;
    background-position: center center;
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    h1 {
        color: white;
        font-weight: bold;
    }

    .image-container {
        text-align: center;
    }
}

  .not-found-container img {
      height: 350px;
  }

  .footer {
      margin-top: 0 !important;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    .not-found-container  {
        img  {
            height: 250px;
        }

        h1 {
            font-size: 26px;
        }
     }
  }

  @media only screen and (max-width: ${DeviceSize.newSmall}px) {
    .not-found-container  {
        img  {
            height: 150px;
        }

        h1 {
            font-size: 20px;
        }
     }
  }
`;

export default function NotFound() {
  return (
    <Container>
      <Header />
        <div className="not-found-container">
                <h1 className="text-center">OOPS...IT LOOKS LIKE YOU’RE LOST</h1>
            <div className="image-container">
            <img src={NotFoundImage} alt="Not found"></img>
            </div>
        </div>
        <Footer />
    </Container>
  );
}
