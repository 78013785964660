import React, { useEffect } from 'react';
import HighSchool from './Step3Subcomponents/HighSchool';
import College from './Step3Subcomponents/College';
import Radiobuttons from '../Steps/Step2Subcomponents.js/RadioButtons';
import { Container, Row } from './StepTwo';
import { Paragraph } from './StepSixSubcomponents/Region';
import { DeviceSize } from '../../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepThree, updateValid } from '../../../store/stepsSlice';

export function StepThree({ createProfile }) {
  const dispatch = useDispatch();
  const stepThree = useSelector((state) => state.steps.stepThree);

  const {
    completedHighSchool,
    highschoolArray,
    completedCollege,
    collegeArray
  } = stepThree;

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });

  useEffect(() => {
    if (
      !completedHighSchool ||
      (((completedHighSchool && highschoolArray.length > 0) ||
        !completedHighSchool) &&
        ((completedCollege && collegeArray.length > 0) || !completedCollege))
    ) {
      dispatch(updateValid(true));
    } else {
      dispatch(updateValid(false));
    }
  }, [
    completedHighSchool,
    highschoolArray.length,
    completedCollege,
    collegeArray.length
  ]);

  return (
    <form className='formTwo'>
    <Container>
      {isMobile ? (
        <h4 className="fw-bold fc-p mb-4 mt-3">3. Schulische Ausbildung</h4>
      ) : null}
      <Row>
        <Paragraph autoWidth>
          {createProfile
            ? 'Hast du einen Schulabschluss?'
            : 'Benötigt Ihr Bewerber einen Schulabschluss?'}
        </Paragraph>
        <Radiobuttons
          setTrue={() =>
            dispatch(updateStepThree({ completedHighSchool: true }))
          }
          setFalse={() =>
            dispatch(updateStepThree({ completedHighSchool: false }))
          }
          name="highschool"
          title="highSchoolDiploma"
          naPromjenu={() => {}}
          value={completedHighSchool ? '1' : '0'}
        />
      </Row>
      {completedHighSchool && (
        <HighSchool title={'highDiplomas'} createProfile={createProfile} />
      )}
      {completedHighSchool && (
        <Row>
          <Paragraph autoWidth>
            {createProfile
              ? 'Hast du einen Hochschulabschluss?'
              : 'Benötigt Ihr Bewerber einen Hochschulabschluss?'}
          </Paragraph>
          <Radiobuttons
            setTrue={() =>
              dispatch(updateStepThree({ completedCollege: true }))
            }
            setFalse={() =>
              dispatch(updateStepThree({ completedCollege: false }))
            }
            name="college"
            title="collegeDiploma"
            naPromjenu={() => {}}
            value={completedCollege ? '1' : '0'}
          />
        </Row>
      )}
      {completedHighSchool && completedCollege && (
        <College createProfile={createProfile} />
      )}
    </Container>
    </form>
  );
}

export default StepThree;
