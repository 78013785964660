import ComponentWrapper from '../../components/ComponentWrapper/ComponentWrapper';

import DashboardLayout from '../CompanyDashboard/DashboardLayout';

import { DeviceSize } from '../../components/responsive';

import auth from '../../auth';
import { useMediaQuery } from 'react-responsive';

import SearchBar from '../../components/SearchBar/SearchBar';

import GetDataPublic from '../../api/GetDataPublic';
import { useLocation } from 'react-router-dom';
import { Pagination } from '@mui/material';
import '../../assets/css/MuiOverride.css';
import { EmptyBox } from '../CompanyDashboard/Applications';
import EmptyList from '../../assets/images/empty-list.svg';

import CircularLoader from '../../components/Loader/CircularLoader';

import React, { useEffect, useState, useRef } from 'react';

import BlogCard from '../../components/Beitrage/BlogCard';

import { useHistory } from 'react-router-dom';

import '../../assets/scss/blogs.scss';
import styled from 'styled-components';

const CardsContainer = styled.div`
  margin: 10px auto;
  margin-top: 100px;
  padding: 20px;
  display: grid;
  gap: 60px 20px;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 650px) {
    grid-template-columns: none;
  }

  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70%;
  }
`;

export default function BlogsDashboard() {
  const [blogs, setblogs] = useState();
  const [searchText, setsearchText] = useState('');
  //loading state used for searchBar icon (when user search for certain query)
  const [loading, setisLoading] = useState(false);

  //loading state used for fetching Blogs (when blogs are loading at first render)
  const [loadingBlogs, setloadingBlogs] = useState(true);

  const [currentPage, setcurrentPage] = useState(1);
  const [order, setorder] = useState('descdate');

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `spiderjob - Beiträge`;
  });
  const blogsRef = useRef(null);
  const history = useHistory();

  const getBlogs = async () => {
    setloadingBlogs(true);
    const response = await GetDataPublic(
      `Blogs/Paging?&PageSize=6&IncludePicture=true`,

      {
        PageNumber: currentPage,
        Title: searchText,
        OrderBy: order
      }
    );
    blogsRef.current = response;

    setcurrentPage(response.CurrentPage);
    setblogs(response);
    setloadingBlogs(false);
  };

  useEffect(() => {
    getBlogs();
  }, [currentPage, searchText, order]);

  const onPageChange = (e, page) => {
    setcurrentPage(page);
  };

  const sortBlogs = (e) => {
    switch (e) {
      case 'a-z':
        setorder('asc');
        break;
      case 'z-a':
        setorder('desc');
        break;
      case 'oldest':
        setorder('ascdate');
        break;
      default:
        setorder('descdate');
        break;
    }
  };

  const isMedium = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const isNewSmall = useMediaQuery({ maxWidth: DeviceSize.newSmall });
  const isExtraSmall = useMediaQuery({ maxWidth: DeviceSize.newExtraSmall });

  return (
    <DashboardLayout noPadding style={{ overflowX: 'hidden' }}>
      <ComponentWrapper style={{display: 'flex', justifyContent: 'center'}}
        fluid
        padding={isExtraSmall ? '20px 15px' : isMedium ? '30px' : '50px'}
      >
      <div>
        {blogs?.list.length > 0 && (
          <SearchBar
            blogsDash={true}
            searchText={searchText}
            onChange={(e) => {
              setisLoading(true);
              setTimeout(() => {
                setsearchText(e);
                setisLoading(false);
              }, 300);
            }}
            loading={loading}
            onSortChange={sortBlogs}
          />
        )}
        {loadingBlogs ? (
          <CircularLoader small />
        ) : (
          <>
        
            {blogs?.list.length > 0 ? (
              <>
             
                <CardsContainer>
                  {blogs.list.map((blog, index) => (
                    <BlogCard
                      key={index}
                      value={{
                        text: blog.BlogText,
                        title: blog.Title,
                        src: blog.File.Url,
                        id: blog.BlogsId,
                        userId: blog.UserId,
                        file: blog.File,
                        closeModal: () => {
                          //getCompanyBlogs();
                        }
                      }}
                      isOwner={
                        auth.isAuthenticated()
                          ? auth.getId() == blog.UserId
                          : false
                      }
                      blog={blog}
                      onClick={() => {
                        window.open(
                          `/blogeintrag/${encodeURIComponent(
                            blog.Title.replace('+', '')
                          )}-${blog.BlogsId}?param=userDash`
                        );
                        /*history.push({
                          pathname: `/blogeintrag/${encodeURIComponent(blog.Title.replace("+", ""))}-${blog.BlogsId}`,
                          state: {
                            blog: blog
                          }
                        });*/
                      }}
                      onDelete={() => {
                        history.push({
                          pathname: `/arbeitgeber-dashboard/blogeinträge`,
                          state: {
                            blog: blog,
                            delete: true
                          }
                        });
                      }}
                      oneThirdWidth
                    />
                  ))}
                </CardsContainer>
               
              </>
              
            ) : (
              <EmptyBox
                style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
              >
                <h4>
                  {blogs && blogs.list !== blogsRef.current.list
                    ? 'Keine Ergebnisse verfügbar'
                    : 'Du hast noch keine Blogs'}
                </h4>
                <div className="mb-5">
                  <img src={EmptyList} alt="Empty"></img>
                </div>
              </EmptyBox>
            )} 

            {blogs && blogs.TotalPages > 1 && (
              <Pagination
                className="pagCenter"
                count={blogs && blogs.TotalPages}
                page={currentPage}
                onChange={onPageChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            )}
          </>
        )}
        </div>
      </ComponentWrapper>
    </DashboardLayout>
  );
}
