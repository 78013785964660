import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import { Container, Heading, Span, SubHeading } from './Privacy';

const Paragraph = styled.p`
  font-size: 16px;
  color: rgba(82, 80, 102, 1);
  margin: 1% 0;
  //   font-weight: 500;
`;

const AGB = () => {
  return (
    <div>
      <Helmet>
        <title>spiderjob - AGB</title>
      </Helmet>
      <Header />
      <Container className="main-container">
        <Heading className="heading">Allgemeine Geschäftsbedingungen</Heading>
        <Span>
          Nutzungsbedingungen für private Stellensuchende („Bewerber“)
        </Span>
        <div className="impressumHeader">1 Geltungsbereich</div>
        <div>
          {' '}
          1.1 Die nachfolgenden Bedingungen gelten für alle gewerblichen
          Unternehmen die sich auf der Plattform spiderjob.de darstellen und
          über das Portal Personal suchen wollen. Das Portal wird von spiderjob
          GmbH, Freiburger Straße 33, 79427 Eschbach, Geschäftsführer Philipp
          Goebel, Matthias Braun, Marco Sprengler betrieben.
        </div>
        <br></br>
        <div>
          1.2 Abweichungen von diesen Nutzungsbedingungen gelten nur dann als
          vereinbart, wenn sie vom Anbieter ausdrücklich schriftlich bestätigt
          wurden.
        </div>
        <div className="impressumHeader">2 Inhalte</div>
        <div>
          2.1 Das Portal bietet eine Plattform zur Entwicklung individueller
          Karrierechancen für Privatpersonen bzw. zur gezielten
          Personalrekrutierung und -entwicklung für Unternehmen. Im Rahmen des
          Angebotes haben Privatpersonen die Möglichkeit, sich auf
          Stellenausschreibungen zu bewerben oder von Unternehmen bezüglich zu
          besetzender Stellen angesprochen zu werden. Für die Nutzung dieses
          Service ist eine Regist- rierung erforderlich. Nutzung des Service ist
          für private Nutzer (Stellenbewerber) kostenlos. Auf dem Portal werden
          kostenpflichtig in Auftrag gegebene Stellenausschreibungen
          veröffentlicht.
        </div>
        <br></br>
        <div>
          2.2 Alle Inhalte auf dem Portal sind urheberrechtlich geschützt. Die
          Registrierung beinhaltet keine Übertra- gung von Eigentums- oder
          Nutzungsrechten, Lizenzen oder sonstigen Rechten an der Software auf
          den Nutzer. Alle Rechte an der genutzten Software, an Kennzeichen,
          Titeln, Marken und Urheber- und sonsti- gen Rechten verbleiben
          uneingeschränkt beim Anbieter. Gewerbsmäßige Adressbeschaffung sowie
          das Kopieren von Inhalten, insbesondere auch von Profilen oder
          Kontaktdaten der Nutzer, und deren ander- weitige Veröffentlichung
          ohne schriftliche Genehmigung des Anbieters ist nicht gestattet.
        </div>
        <div className="impressumHeader">
          3 Zugangsberechtigung / Registrierungspflicht
        </div>
        <div>3.1 Zugangsberechtigt sind Unternehmen aller Branchen.</div>
        <br></br>
        <div>
          3.2 Unternehmen können sich erst dann auf dem Portal darstellen,
          nachdem Sie sich als Kunde registriert haben. Dies erfolgt unter
          Angabe unternehmensbezogener und personenbezogener Daten (Firmenname,
          Anschrift, Name und E-Mail Adresse des zuständigen Ansprechpartners
          usw.) und setzt die Anerkennung dieser Allgemeinen
          Geschäftsbedingungen voraus. Der Benutzername entspricht der E-Mail
          Adresse, das Passwort kann vom Kunden im Nachhinein selbst geändert
          werden. Jeder Kunde darf sich nur einmal anmelden. Er muss sich bei
          jeder weiteren Nutzung mit den bei der Erstanmeldung zugeteilten
          Benutzer- daten (Benutzername und Passwort) einloggen. Die
          Berechtigung gilt nur für den vom Kunden angegebe- nen Ansprechpartner
          persönlich und ist nur an andere Mitarbeiter des Kunden übertragbar.
          Der Kunde ist zur Geheimhaltung seiner Zugangsdaten verpflichtet; er
          haftet insoweit uneingeschränkt für sämtliche Mitarbeiter. Sollte
          einem Kunden die missbräuchliche Nutzung seines Logins bekannt werden,
          hat er dies dem Anbieter unverzüglich mitzuteilen und sein Passwort
          unverzüglich zu ändern, um den Zugang nicht autorisierter Personen zu
          verhindern. Der Anbieter übernimmt keinerlei Haftung für Schäden, die
          aus der unberechtigten bzw. unsachgemäßen Verwendung
          passwortgeschützter Logins des Kunden resultieren.
        </div>
        <br></br>
        <div>
          3.3 Der Kunde ist verpflichtet, bei der Registrierung ausschließlich
          wahrheitsgemäße und vollständige Informationen / Daten zu übermitteln
          und diese bei späteren Änderungen unverzüglich zu aktualisieren.
          Dasselbe gilt für später vom Kunden in das Portal eingestellten
          Inhalte.
        </div>
        <br></br>
        <div>
          3.4 Der Kunde stellt sicher, dass er für den Anbieter jederzeit unter
          den im Rahmen der Registrierung angegebenen Kontaktdaten, insbesondere
          per E- Mail, erreichbar ist.
        </div>
        <br></br>
        <div>
          3.5 Der Anbieter behält sich vor, die Registrierung einzelner
          Unternehmen ohne Angabe von Gründen abzulehnen. Ferner behält sich der
          Anbieter vor, auch bereits aktive Kunden aus wichtigem Grund (z.B.
          wegen Verstoß gegen diese AGB) zu sperren.
        </div>
        <br></br>
        <div>
          3.6 Die Registrierung von Unternehmen als Kunden ist kostenpflichtig.
          Sämtliche Funktionalitäten des Portals können nur genutzt werden, wenn
          der Kunde kostenpflichtig ein Paket auswählt (spiderBASIC,
          spiderBUSINESS, spiderBUSINESSPLUS, , spiderFLAT) und in Auftrag
          gegeben hat.
        </div>
        <br></br>
        <div>
          3.7. Ein Vertrag kommt - soweit nicht anders ausdrücklich individuell
          vereinbart - erst mit erfolgreichem Abschluss des
          Registrierungsvorgangs zustande.
        </div>
        <div className="impressumHeader">
          {' '}
          4 Pflichten des Kunden und Leistungsstörungen
        </div>
        <div>
          4.1 Der Kunde darf den Service des Anbieters nur sachgerecht nutzen.
          Er wird insbesondere:
        </div>
        <br></br>
        <div>
          1 die Zugriffsmöglichkeiten auf den Service nicht
          rechtsmissbräuchlich, insbesondere nicht im Wider- spruch zu den
          Allgemeinen Geschäftsbedingungen nutzen und die Gesetze sowie die
          Rechte Dritter respektieren;
        </div>
        <br></br>
        <div>
          2 zum Schutz der Daten den anerkannten Grundsätzen der Datensicherheit
          Rechnung tragen und die Verpflichtungen der Datenschutzvereinbarung
          beachten;
        </div>
        <br></br>
        <div>
          3 gegebenenfalls an den Anbieter beziehungsweise Dritte ausgehende
          E-Mails und Abfragen mit größt- möglicher Sorgfalt auf Viren
          überprüfen;
        </div>
        <br></br>
        <div>
          4 gesetzliche, behördliche und technische Vorschriften einhalten.
        </div>
        <br></br>
        <div>
          4.2 Der Kunde hat dem Anbieter in dem zuvor geschilderten Fall den aus
          solchen schuldhaften Pflichtver- letzungen resultierenden Schaden zu
          ersetzen.
        </div>
        <br></br>
        <div>
          4.3 Der Kunde ist rechtlich allein verantwortlich für die von ihm
          eingestellten Inhalte und hat sicherzustel- len, dass keine rechts-
          oder sittenwidrigen Inhalte aufgenommen werden oder die Aufnahme
          solcher Inhalte ermöglicht wird, derartiger Inhalt gespeichert,
          verbreitet, zugänglich gemacht oder auf ein Ange- bot mit solchem
          Inhalt hingewiesen bzw. verlinkt wird. Der Kunde verpflichtet sich
          insbesondere, bei allen von ihm eingestellten Inhalten und bei
          jedweder Kommunikation über das Portal nach bestem Wissen und Gewissen
          nur wahrheitsgemäße Angaben zu machen und die Vorgaben des AGG
          (Allgemeines Gleichbehandlungsgesetz) zu beachten, insbesondere
          Stellenangebote nur geschlechtsneutral zu formu- lieren.
        </div>
        <br></br>
        <div>
          4.4 Ausdrücklich untersagt ist die Einstellung von rassistischen,
          pornographischen, menschenverachten- den, beleidigenden, zu Straftaten
          anleitenden und gegen die guten Sitten verstoßenden Inhalten.
          Ausdrücklich verboten ist die Verbreitung von Inhalten, mit denen zum
          Hass gegen Teile der Bevölkerung aufgerufen wird (Volksverhetzung)
          oder mit denen Propaganda für eine verfassungsfeindliche Organisati-
          on betrieben wird, sowie verleumderische, beleidigende oder ruf- oder
          geschäftsschädigende bzw. persön- lichkeitsrechtsverletzende
          Äußerungen sowie Junkmails, Spam, Kettenbriefe und andere Inhalte mit
          werbendem Charakter. Ebenfalls untersagt ist die Einstellung von
          Beiträgen, die gegen die Grundsätze der Datensicherheit verstoßen
          (z.B. mit Viren, Trojanern u.ä. behaftete Beiträge und E-Mails).
        </div>
        <br></br>
        <div>
          4.5 Die Entscheidung über die Einstellung von Inhalten des Kunden in
          das Portal steht im Ermessen des Anbieters. Dieser ist daher jederzeit
          berechtigt, die Veröffentlichung von Inhalten, insbesondere von
          Stellenangeboten, ohne Angabe von Gründen zu verweigern bzw. bereits
          eingestellte Inhalte wieder zu entfernen.
        </div>
        <br></br>
        <div>
          4.6 Der Kunde ist allein und umfassend für die von ihm auf dem Portal
          eingestellten Inhalte verantwortlich und verpflichtet, dem Anbieter
          sämtlichen Schaden, einschließlich aller Aufwendungen, zu ersetzen,
          welche diesem aufgrund schuldhafter Verstöße gegen diese
          Nutzungsbedingungen entstehen. Der Kunde stellt den Anbieter insoweit
          ausdrücklich auf erstes Anfordern auch von allen berechtigten
          Ansprüchen Dritter und den Kosten der damit verbundenen
          Rechtsverfolgung frei.
        </div>
        <br></br>
        <div>
          4.7 Der Anbieter behält sich darüber hinaus vor, bei Verdacht einer
          missbräuchlichen Nutzung oder wesentlicher Vertragsverletzungen diesen
          Vorgängen nachzugehen, entsprechende Vorkehrungen zu treffen und bei
          einem begründeten Verdacht gegebenenfalls den Zugang des Kunden -
          mindestens bis zu einer Verdachtsausräumung seitens des Kunden - zu
          sperren und/oder vom Kunden eingestellte Inhalte zu löschen und/oder
          gegebenenfalls bei besonders schwerwiegenden Verstößen auch das
          Vertragsverhältnis fristlos zu kündigen. Soweit der Kunde den Verdacht
          ausräumt, wird die Sperrung aufgehoben.
        </div>
        <div className="impressumHeader">5 Nutzungsumfang</div>
        <div>
          5.1 Soweit Inhalte, insbesondere Informationen über Stellensuchende,
          dem Kunden über das Portal oder per Mail zugesandt werden, dürfen
          diese vom Kunden nur für eigene interne Zwecke verwendet werden. Jede
          Nutzung von Inhalten für gewerbliche externe Zwecke ist untersagt.
          Eine Archivierung darf nur zu eigenen und internen Zwecken des Kunden
          dienen, also Dritten nicht zugänglich sein, nicht dazu dienen,
          Vervielfältigungen für Dritte herzustellen und die Archivnutzung darf
          nicht zu einer zusätzlichen Verwer- tung der Inhalte führen. Alle
          weiteren Nutzungen (z.B. Vervielfältigung zu gewerblichen externen
          Zwecken einschl. Archivierung, Überlassung an oder Verarbeitung durch
          Dritte für eigene oder fremde Zwecke oder zur öffentlichen Wiedergabe,
          Übersetzung, Bearbeitungen usw.) bedürfen der vorherigen schriftlichen
          Zustimmung des Anbieters.
        </div>
        <br></br>
        <div>
          5.2 Urheberrechtshinweise und/oder Markenbezeichnungen und/oder
          sonstige Rechtevorbehalte in den Inhalten dürfen weder verändert noch
          beseitigt werden.
        </div>
        <br></br>
        <div>
          5.3 Stellensuchenden bzw. sonstigen privaten Nutzern ist es nach
          Registrierung ausdrücklich gestattet, an den dafür vorgesehene Stellen
          des Portals eigene Inhalte einzustellen. Nutzergenerierte Inhalte
          stammen nicht vom Anbieter und werden auch nicht im Namen des
          Anbieters veröffentlicht. Der Anbieter macht sich deren Inhalt und
          deren Aussagen nicht zu eigen und distanziert sich ausdrücklich von
          allen nutzerge- nerierten oder sonst erkennbar fremden, d.h. nicht vom
          Anbieter stammenden Inhalten.
        </div>
        <br></br>
        <div>
          5.4 Der Kunde räumt dem Anbieter mit Einstellung seiner Inhalte das
          Recht ein, diese auf dem Portal zu veröffentlichen und/oder
          veröffentlichen zu lassen, zu verbreiten und/oder verbreiten zu
          lassen, sowie auf sonstige Weise Dritten öffentlich zugänglich zu
          machen. Von der Rechteinräumung umfasst ist die Mög- lichkeit,
          Beiträge zum Abruf durch Dritte zur Verfügung zu stellen und Beiträge
          zu archivieren. Mit Einstel- lung seiner Inhalte sichert der Kunde zu,
          dass er über die eingeräumten Rechte verfügen kann und dass über diese
          Rechte nicht bereits anderweitig in einer Art verfügt wurde, welche
          die vorstehenden Nutzungsrechte des Anbieters beeinträchtigten
          könnten. Beim Einstellen von Bildern oder Videos sichert der Kunde zu,
          dass alle darauf erkennbaren Personen der Veröffentlichung zugestimmt
          haben. Vom Kunden hochgeladene Dateien werden vom Anbieter ggf. in das
          für die Veröffentlichung auf dem Portal erforderliche Format
          umformatiert. Bei dieser Umformatierung wird lediglich die
          Datenstruktur, nicht aber der Dateninhalt verändert.
        </div>
        <br></br>
        5.5 Der Kunde räumt dem Anbieter darüber hinaus das Recht ein, zum
        Zwecke der Bewerbung des Portals bzw. des Anbieters die Tatsache zu
        veröffentlichen bzw. veröffentlichen zu lassen, dass der Kunde über das
        Portal des Anbieters Personal sucht bzw. gesucht hat. Der Kunde räumt
        dem Anbieter in diesem Zusam- menhang auch das Recht ein, die
        Firmenbezeichnung des Kunden und/oder dessen Unternehmenskenn- zeichen
        (Logo, Schriftzug usw.) zu nutzen.<br></br>
        <div>
          5.6 Kunden, die ein "spiderPAKET" gebucht haben, räumen dem Anbieter
          das Recht ein, während der Laufzeit der Stellenangebote, die der Kunde
          außerhalb des Portals (z.B. auf seiner Homepage oder in anderen
          Medien) bewirbt, unter Quellenangabe (s. Ziffer 2.1 am Ende) in das
          Portal aufzunehmen. Der Kunde hat jederzeit das Recht, der Aufnahme
          einzelner oder aller derartiger Stellenangebote zu wider- sprechen.
        </div>
        <div className="impressumHeader">6. Pflichten des Anbieters</div>
        <div>
          6.1 Der Kunde kann je nach vertraglich vereinbarter Leistung im Rahmen
          seines Unternehmensprofils Stellenangebote und weitere
          unternehmensbezogene Inhalte veröffentlichen. Der Kunde kann die
          Inhalte in einem vom Anbieter vorgegebenen Eingabeformular selbst
          eingeben. Der Anbieter wird die Inhalte des Kunden, Interessenten über
          das Internet oder per Mail zur Verfügung stellen. Des Weiteren kann
          der Kunde Kontakt zu Arbeitssuchenden aufnehmen die vom Anbieter
          zugesandt wurden, die seinen Wunschkriterien entsprechen und gezielt
          Unternehmensinformationen in sein Profil einpflegen.
        </div>
        <br></br>
        <div>
          6.2 Die vom Kunden eingestellten Inhalte, insbesondere
          Stellenangebote, sind zeitlich begrenzt auf dem Portal abrufbar. Die
          Dauer der Verfügbarkeit ergibt sich aus den jeweiligen, mit dem Kunden
          getroffenen Vereinbarungen.
        </div>
        <br></br>
        <div>
          6.3 Der Anbieter beteiligt sich inhaltlich nicht an der Kommunikation,
          dem Informationsaustausch oder der Stellenvermittlung zwischen
          Unternehmen und privaten Nutzern. Insofern übernimmt der Anbieter
          keinerlei Gewähr für die Richtigkeit und Vollständigkeit der von den
          privaten Nutzern über die Services verbreiteten Informationen und
          überprüft diese auch nicht.
        </div>
        <br></br>
        <div>
          6.4 Soweit Unternehmen und private Nutzer über das Portal
          untereinander Verträge abschließen, entste- hen daraus keinerlei
          vertragliche Verpflichtungen für den Anbieter.
        </div>
        <br></br>
        <div>
          6.5 Der Anbieter behält sich Änderungen seiner Leistungen vor, soweit
          solche Änderungen nicht die Kernleistungen verändern und unter
          Berücksichtigung der Interessen des Kunden für diesen zumutbar sind.
        </div>
        <br></br>
        <div>
          6.6 Der Anbieter ist berechtigt, die ihm obliegenden Leistungen ganz
          oder teilweise durch Dritte erbringen zu lassen.
        </div>
        <div className="impressumHeader">7 Personenbezogene Daten</div>
        <div>
          Die Verwendung, Verarbeitung und Nutzung personenbezogener Daten
          erfolgt gemäß den jeweils gülti- gen gesetzlichen Bestimmungen und den
          Datenschutzregelungen des Anbieters.
        </div>
        <div className="impressumHeader">8 Gewährleistung</div>
        <div>
          8.1 Der Anbieter wird versuchen, im Rahmen der technischen
          Möglichkeiten, mehr als unerhebliche Störungen und Fehler
          schnellstmöglich zu beseitigen und unerhebliche Beeinträchtigungen in
          angemes- sener Frist zu beseitigen. Der Anbieter wird sich bemühen,
          die angebotenen Leistungen auf dem Portal stets zugänglich zu halten.
          Der Kunde hat jedoch keinen Anspruch auf die ständige Verfügbarkeit
          und Störungsfreiheit der Leistungen.
        </div>
        <br></br>
        <div>
          8.2 Der Anbieter ist für den eventuellen Erfolg einer
          Stellenvermittlung in keiner Weise verantwortlich und haftet nicht,
          falls keine Stellenvermittlung zustande kommt.
        </div>
        <br></br>
        <div>
          8.3 Dem Kunden ist bekannt, dass es nach dem derzeitigen Stand der
          Technik nicht möglich ist, alle Risiken aus der Nutzung des Internet
          auszuschließen. Daher übernimmt der Anbieter keine Gewährleis- tung
          für technische Mängel, insbesondere für die ständige und
          ununterbrochene Verfügbarkeit der Datenbank und ihrer Inhalte oder für
          die vollständige und fehlerfreie Wiedergabe der vom Kunden einge-
          stellten Inhalte.
        </div>
        <br></br>
        <div>
          8.4 Sämtliche Downloads – gleich ob unmittelbar von dem Portal oder
          von mit dem Portal verlinkten Seiten – erfolgen auf eigenes Risiko.
        </div>
        <div className="impressumHeader">
          9 Haftungsbeschränkung des Anbieters
        </div>
        <div>
          9.1 Der Anbieter haftet bei Vorsatz oder grober Fahrlässigkeit für
          alle vom Anbieter im Zusammenhang mit der Erbringung der
          vertragsgemäßen Leistungen verursachten Schäden unbeschränkt.
        </div>
        <br></br>
        <div>
          9.2 Bei leichter Fahrlässigkeit haftet der Anbieter im Fall der
          Verletzung des Lebens, des Körpers oder der Gesundheit unbeschränkt.
        </div>
        <br></br>
        <div>
          9.3 Im Übrigen haftet der Anbieter nur, soweit er eine wesentliche
          Vertragspflicht verletzt hat. Als wesentli- che Vertragspflichten
          werden dabei abstrakt solche Pflichten bezeichnet, deren Erfüllung die
          ordnungsge- mäße Durchführung des Vertrages überhaupt erst ermöglicht
          und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf. In
          diesen Fällen ist die Haftung auf den Ersatz des vorhersehbaren,
          typischer- weise eintretenden Schaden beschränkt.
        </div>
        <br></br>
        <div>
          9.4 Soweit die Haftung des Anbieters nach den vorgenannten
          Vorschriften ausgeschlossen oder beschränkt wird, gilt dies auch für
          Erfüllungsgehilfen des Anbieters.
        </div>
        <br></br>
        <div>
          9.5. Schadensersatzansprüche verjähren innerhalb von 12 Monaten nach
          dem Schluss des Jahres, in dem der Anspruch entstanden ist und der
          Kunde von den Anspruch begründenden Umständen und der Person des
          Schädigers Kenntnis erlangt oder ohne grobe Fahrlässigkeit erlangen
          müsste.
        </div>
        <div className="impressumHeader">
          10 Laufzeit, Vergütung und Abrechnung
        </div>
        <div>
          10.1 Die Mindestlaufzeit jedes Pakets ist auf 6 Monate festgelegt und
          verlängert sich bei monatlicher Zahlweise durch Nicht-Kündigung des
          Kunden um jeweils einen Monat. Die Vertragslaufzeit kann bei
          Auftragserteilung auch direkt auf 12 Monate ausgedehnt werden. Bei
          Einmalzahlung verlängert sich die Laufzeit nicht automatisch. Der
          Anbieter behält sich vor, Preise und Produkte für die Zukunft zu
          ändern. Der Anbieter wird den Kunden über Änderungen, die ihn
          betreffen, rechtzeitig informieren.
        </div>
        <br></br>
        <div>
          10.2 Möchte der Kunde sein Abonnement über die Mindestlaufzeit hinaus
          verlängern, so kann er das bisher gebuchte Paket weiter nutzen oder
          beliebig ändern. Die Änderung des Pakets wird durch spiderjob
          vorgenommen.
        </div>
        <div className="impressumHeader">11 Hinweis zum Widerrufsrecht</div>
        <div>(1) Widerrufsbelehrung</div>
        <br></br>
        <div>Widerrufsrecht</div>
        <br></br>
        <div>
          Die Einwilligungserklärung können Sie jederzeit mit Wirkung für die
          Zukunft telefonisch (+49 7634 69 89 676) per Post (spiderjob GmbH,
          Freiburger Straße 33, 79427 Eschbach) oder per E-Mail
          (info@spiderjob.de) wider- rufen. Selbstverständlich können Sie auch
          zu jedem späteren Zeitpunkt mit Wirkung für die Zukunft einen
          eingelegten Widerruf wieder zurücknehmen.
        </div>
        <div className="impressumHeader">12 Sonstige Regelungen</div>
        <div>
          12.1 Es gilt das Recht der Bundesrepublik Deutschland, wobei die
          Geltung des UN-Kaufrechts ausgeschlos- sen wird.
        </div>{' '}
        <br></br>
        <div>
          12.2 Ist der Nutzer Kaufmann, juristische Person des öffentlichen
          Rechts oder öffentlich-rechtliches Sonder- vermögen, so ist der Sitz
          des Diensteanbieters ausschließlicher Gerichtsstand für alle
          Streitigkeiten aus dem Vertragsverhältnis.
        </div>
        <div className="impressumHeader">Widerrufsrecht</div>
        <div>
          Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen
          Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem
          Tag:
        </div>
        <div>
          a) im Falle eines Dienstleistungsvertrags oder eines Vertrags über die
          Lieferung von Wasser, Gas oder Strom, wenn sie nicht in einem
          begrenzten Volumen oder in einer bestimmten Menge zum Verkauf
          angeboten werden, von Fernwärme oder von digitalen Inhalten, die nicht
          auf einem körperlichen Datenträger geliefert werden: „des
          Vertragsabschlusses.“;
        </div>
        <div>
          b) im Falle eines Kaufvertrags: „, an dem Sie oder ein von Ihnen
          benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz
          genommen haben bzw. hat.“;
        </div>
        <div>
          c) im Falle eines Vertrags über mehrere Waren, die der Verbraucher im
          Rahmen einer einheitlichen Bestellung bestellt hat und die getrennt
          geliefert werden: „, an dem Sie oder ein von Ihnen benannter Dritter,
          der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben
          bzw. hat.“;
        </div>
        <div>
          d) im Falle eines Vertrags über die Lieferung einer Ware in mehreren
          Teilsendungen oder Stücken: „, an dem Sie oder ein von Ihnen benannter
          Dritter, der nicht der Beförderer ist, die letzte Teilsendung oder das
          letzte Stück in Besitz genommen haben bzw. hat.“;
        </div>
        <div>
          e) im Falle eines Vertrags zur regelmäßigen Lieferung von Waren über
          einen festgelegten Zeitraum hinweg: „, an dem Sie oder ein von Ihnen
          benannter Dritter, der nicht der Beförderer ist, die erste Ware in
          Besitz genommen haben bzw. hat.“
        </div>
        <br></br>
        <div>
          Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (spiderjob GmbH;
          Freiburger Straße 33, 79427 Eschbach; Tel. 07634 69 89 676; E-Mail:
          info@spiderjob.de) mittels einer eindeutigen Erklärung (z. B. ein mit
          der Post versandter Brief oder eine E-Mail) über Ihren Entschluss,
          diesen Vertrag zu widerrufen, informie- ren.
        </div>
        <br></br>
        <div>
          Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
          über die Ausübung des Widerrufs- rechts vor Ablauf der Widerrufsfrist
          absenden.
        </div>{' '}
        <div className="impressumHeader">Folgen des Widerrufs</div>
        <div>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
          die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
          Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
          eine andere Art der Lieferung als die von uns angebotene, günstigste
          Standardlieferung gewählt haben), unverzüglich und spätestens binnen
          vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
          Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
          Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
          ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
          wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
          Ihnen wegen dieser Rückzah- lung Entgelte berechnet.
        </div>
        <br></br>
        <div>
          Im Falle von Kaufverträgen, in denen Sie nicht angeboten haben, im
          Falle des Widerrufs die Waren selbst abzuholen, fügen Sie Folgendes
          ein: „Wir können die Rückzahlung verweigern, bis wir die Waren wieder
          zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass
          Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
          Zeitpunkt ist.“
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AGB;
