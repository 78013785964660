import React, { useState } from 'react';
import adPhoto from '../../assets/images/adPhoto.jpg';
import './AdComponent.css';

const AdComponent = () => {
  const [adVisible, setAdVisible] = useState(true);

  if (!adVisible) {
    return null;
  }

  return (
    <div className={`ad-component show-image}`}>
      <div
        className="close-ad"
        onClick={() => {
          setAdVisible(false);
        }}
      >
        Werbung schließen
      </div>
      {adVisible && (
        <div>
          <div className="ad-content">
            <div
              className="video-wrapper"
              onClick={() =>
                window.open('https://wms-engineering.de', '_blank')
              }
            >
              <video className="ad-video" controls onClick={() => {}}>
                <source src="https://api.spiderjob.de/resources/adVIdeo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="ad-label">Anzeige</div>
            </div>

            <div
              className="image-wrapper"
              onClick={() =>
                window.open('https://teck-tech.com/de/', '_blank')
              }
            >
              <img src={adPhoto} alt="Advertisement" />
              <div className="ad-label">Anzeige</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdComponent;
