import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepFour } from '../../../../store/stepsSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApplicantWaterLicences(props) {
  const dispatch = useDispatch();
  const selectedWater = useSelector(
    (state) => state.steps.stepFour.waterLicences
  );
  const [search, setSearch] = useState('');
  const [waterLicence, setWaterLicence] = useState([]);

  const handleWater = (event, values) => {
    dispatch(updateStepFour({ waterLicences: values }));
  };

  useEffect(() => {
    if (props.values)
      setWaterLicence(props.values.filter((item) => item.LicenseTypeId === 2));
  }, [props.values]);

  return (
    <>
      <Autocomplete
        className='create-profile-select'
        filterOptions={(x) =>
          x.filter((l) => l.Name.toLowerCase().startsWith(search))
        }
        multiple
        noOptionsText="Keine Option"
        id="voda"
        options={waterLicence}
        disableCloseOnSelect
        value={selectedWater}
        getOptionLabel={(option) => option.Name}
        isOptionEqualToValue={(option, value) => {
          return option.LicenseId === value.LicenseId;
        }}
        onChange={handleWater}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.LicenseId} style={{fontSize:"14px"}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Name}
            {` (${option?.Category.trim()})`}
          </li>
        )}
        style={{ width: '100%', margin: '10px 0' }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            placeholder="Wasser"
          />
        )}
      />
    </>
  );
}
