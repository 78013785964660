import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DeviceSize } from '../../../components/responsive';
import { useMediaQuery } from 'react-responsive';
import ComponentWrapper from '../../ComponentWrapper/ComponentWrapper';
import { GoogleAutocomplete } from '../../GoogleAutocomplete';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateTimePicker from 'react-datetime-picker';
import config from '../../../config/api.config';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPersonalData,
  setRegion,
  updateValid
} from '../../../store/stepsSlice';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap !important;
  align-items: center !important;
  padding: 30px 40px;

  .MuiInput-underline:before {
    border-bottom: 1px solid #dddddd;
  }
  label + .MuiInput-formControl {
    margin-top: 0;
  }
  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    padding: 20px;
  }
`;
export const Input = styled.input`
  height: 40px;
  width: 100%;
  position: relative;
  outline: none;
  font-size: 13px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #d6d6d6;
  padding-left: 20px;
  color: #7c7c7c;
  ${(props) =>
    props.noFill &&
    `
  background-color: #fff;
  
  `}

  ${(props) =>
    props.width60 &&
    `
 width: 60% !important;
  
  `}

  ${(props) =>
    props.backColor &&
    `
  background-color: #F4F4F4;
  
  `}

  ${(props) =>
    props.marginRight &&
    `
    margin-right: 10px;
  
  `}

  ${(props) =>
    props.marginBottom &&
    `
    margin-bottom: 10px;
  
  `}

  @media (max-width: 992px) {
    padding-left: 5px !important;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${(props) =>
    props.flexStart &&
    `
 justify-content: flex-start;
  `}
  ${(props) =>
    props.width70 &&
    `
  width: 70% !important;
  `}
  ${(props) =>
    props.noJustify &&
    `
  justify-content: initial;
  &>*{
        margin-inline-end: 5%;
  }
  `}
  ${(props) =>
    props.center &&
    `
  justify-content: center;
  `}
  ${(props) =>
    props.maxWidth &&
    `
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.flexWrap &&
    `
  flex-wrap: wrap;
  `}
  ${(props) =>
    props.alignStart &&
    `
    align-items: flex-start;
  `}
  ${(props) =>
    props.justifyStart &&
    `
    justify-content: flex-start;
  `}
  ${(props) =>
    props.justifyEnd &&
    `
    justify-content: flex-end;
  `}
  .makeStyles-formControl-15 {
    margin: 0;
    margin-botom: 24px;
  }
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  > div:nth-child(1) {
    margin-right: 20px;
  }
  > div:nth-child(2) {
    margin-right: 20px;
  }
  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    flex-direction: column;
    > div:nth-child(1) {
      margin-right: 0;
    }
    > div:nth-child(2) {
      margin-right: 0;
    }
  }
`;
export const Subcontainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .MuiSelect-select {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0;
  }
  > div {
    width: 100%;
  }
`;

export const Label = styled.label`
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  opacity: 0.8;
  justify-content: center;
 
`;

export const ProfileInput = ({
  label,
  name,
  type = 'text',
  required,
  value,
  style,
  onChange,
  ...props
}) => {
  const ref = useRef();

  return (
    <ComponentWrapper fluid noPaddingHorizontal>
      <Input
        style={style}
        type={type}
        name={name}
        id={name}
        required={required}
        value={value}
        noFill
        onChange={onChange}
        placeholder={label}
        ref={ref}
        {...props}
      />
    </ComponentWrapper>
  );
};

export default function StepOne({}) {
  const dispatch = useDispatch();

  const personalDataState = useSelector(
    (state) => state.steps.stepOne.personalData
  );
  const regionState = useSelector((state) => state.steps.stepOne.region);
  const [nationalities, setNationalities] = useState(null);
  const [titles, setTitles] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });
  const gender = [
    { value: 1, label: 'HERR' },
    { value: 2, label: 'FRAU' },
    { value: 3, label: 'DIVERS' }
  ];

  const GetNationalities = async () => {
    await axios
      .get(`${config.apiUrl}/Nationality`)
      .then(function (response) {
        setNationalities(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(
            error.response.data.ERROR ? error.response.data?.ERROR[0] : null
          );
        }
      });
  };

  const GetTitles = async () => {
    await axios
      .get(`${config.apiUrl}/Title`)
      .then(function (response) {
        setTitles(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(
            error.response.data.ERROR ? error.response.data.ERROR[0] : null
          );
        }
      });
  };

  useEffect(() => {
    GetTitles();
    GetNationalities();
  }, []);

  const [personalDataARR, setpersonalData] = useState({
    gender: personalDataState?.gender ?? null,
    title: personalDataState?.title ?? null,
    birthPlace: personalDataState?.birthPlace ?? '',
    nationality: personalDataState?.nationality ?? null,
    telefon: personalDataState?.telefon ?? '',
    mobile: personalDataState?.mobile ?? '',
    birthday: personalDataState?.birthday ?? null
  });

  const [autocompltData, setautocompltData] = useState({
    streetNumber: regionState?.streetNumber ?? '',
    streetName: regionState?.streetName ?? '',
    district: regionState?.district ?? '',
    city: regionState?.city ?? '',
    country: regionState?.country ?? '',
    postalCode: regionState?.postalCode ?? '',
    latitude: regionState?.latitude ?? '',
    longitude: regionState?.longitude ?? '',
    formattedAddress: regionState?.formattedAddress ?? ''
  });

  const handleChangeInput = (e) => {
    e.preventDefault();
    setpersonalData({ ...personalDataARR, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      [1, 2, 3].includes(personalDataARR.gender) &&
      autocompltData.formattedAddress &&
      autocompltData.country &&
      personalDataARR.birthday &&
      personalDataARR.nationality &&
      (personalDataARR.mobile || personalDataARR.telefon)
    ) {
      dispatch(updateValid(true));
    } else {
      dispatch(updateValid(false));
    }
  }, [
    personalDataARR.gender,
    autocompltData.formattedAddress,
    autocompltData.country,
    personalDataARR.birthPlace,
    personalDataARR.nationality,
    personalDataARR.birthday,
    personalDataARR.mobile,
    personalDataARR.telefon
  ]);

  useEffect(() => {
    dispatch(setPersonalData({ ...personalDataARR }));
  }, [personalDataARR]);

  useEffect(() => {
    dispatch(setRegion({ data: { ...autocompltData }, step: 'stepOne' }));
  }, [autocompltData]);

  const onGoogleSelected = (place) => {
    setautocompltData({
      streetNumber: place?.streetNumber,
      streetName: place?.streetName,
      district: place?.district,
      city: place?.city,
      country: place?.country,
      postalCode: place?.postalCode,
      latitude: place?.latitude,
      longitude: place?.longitude,
      formattedAddress: place?.formattedAddress
    });
  };
  return (
    <form>
      <Container>
        {isMobile ? (
          <h4 className="fw-bold fc-p mb-4 mt-3">1. Persönliche Daten</h4>
        ) : null}

        <InputsContainer>
          <Subcontainer>
            <ComponentWrapper fluid noPaddingHorizontal>
              <FormControl fullWidth>
                <Select
                  required
                  color="secondary"
                  name="gender"
                  className="create-profile-select"
                  value={personalDataARR.gender}
                  onChange={handleChangeInput}
                  displayEmpty={true}
                  renderValue={(value) =>
                    gender?.find((gen) => gen.value === value)?.label ??
                    'Anrede*'
                  }
                >
                  {gender?.map((gen) => (
                    <MenuItem value={gen.value} key={gen.value}>
                      {gen.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ComponentWrapper>

            <ProfileInput
              label="Telefon*"
              name="telefon"
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^\+?\d+$]/g, '');
                e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
              }}
              required={false}
              value={personalDataARR.telefon}
              onChange={handleChangeInput}
            />
            <ComponentWrapper fluid noPaddingHorizontal>
              <DatePicker
                className="input-datetime datetime-create-profile"
                dateFormat="MM/dd/yyyy"
                selected={personalDataARR.birthday}
                onChange={(date) =>
                  setpersonalData({ ...personalDataARR, birthday: date })
                }
                placeholderText="Geburtstag*"
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                yearDropdownItemNumber={100}
              />

             
            </ComponentWrapper>
          </Subcontainer>

          <Subcontainer>
            <ComponentWrapper fluid noPaddingHorizontal>
              <FormControl fullWidth>
                <Select
                  required
                  color="secondary"
                  name="title"
                  className="create-profile-select"
                  value={personalDataARR.title}
                  onChange={handleChangeInput}
                  displayEmpty={true}
                  renderValue={(value) =>
                    titles?.find((nat) => nat.TitleID === value)?.TitleName ??
                    'Titel'
                  }
                >
                  {titles &&
                    titles.map((title) => (
                      <MenuItem value={title.TitleID} key={title.TitleID}>
                        {title.TitleName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </ComponentWrapper>

            <ProfileInput
              label="Mobil*"
              name="mobile"
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^\+?\d+$]/g, '');
                e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
              }}
              required={false}
              value={personalDataARR.mobile}
              onChange={handleChangeInput}
            />
            <ComponentWrapper fluid noPaddingHorizontal>
              <FormControl fullWidth>
                <Select
                  defaultValue=""
                  color="secondary"
                  name="nationality"
                  className="create-profile-select"
                  value={personalDataARR.nationality}
                  onChange={handleChangeInput}
                  displayEmpty={true}
                  renderValue={(value) =>
                    nationalities?.find((nat) => nat.NationalityID === value)
                      ?.NationalityName ?? 'Staatsangehörigkeit*'
                  }
                >
                  {nationalities?.map((nat) => (
                    <MenuItem value={nat.NationalityID} key={nat.NationalityID}>
                      {nat.NationalityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ComponentWrapper>
          </Subcontainer>
          <Subcontainer>
            <ComponentWrapper fluid noPaddingHorizontal>
              <GoogleAutocomplete
                onPlaceSelected={onGoogleSelected}
                defaultValue={autocompltData.formattedAddress}
                placeholder="Standort*"
                className="create-profile-select"
                type="(regions)"
              />
            </ComponentWrapper>
            <ProfileInput
              label="Geburtsort"
              name="birthPlace"
              required={false}
              value={personalDataARR.birthPlace}
              onChange={handleChangeInput}
            />
          </Subcontainer>
        </InputsContainer>
      </Container>
    </form>
  );
}

/*yyyy-mm-dd */
